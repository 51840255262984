.#{$ns}footer {
  background-color: color(white);
  width: 100%;
  margin-top: 4px;
  position: relative;

  // codigos, previsiones, ayuda, faq, contact, glosario //
  &--codigos,
  &--previsiones,
  &--help,
  &--faq,
  &--contact,
  &--glossary {
    margin-top: 8px;
    margin-left: -2px;
  }

  // sujetos mercado //
  &--sujetomercado {
    margin-top: 5px;
  }

  // unidades de programacion //
  &--unidadespro {
    margin-top: 4px;
    margin-left: 1px;
  }

  // unidades físicas //
  &--unidadesfisicas {
    margin-top: 4px;
    margin-left: -3px;
  }

  // habilitados //
  &--habilitados {
    margin-top: 4px;
    margin-left: -1px;
  }

  //documentacion //
  &--doc {
    margin-top: 4px;
    margin-left: -2px;
  }

  // descargas //
  &--descargas {
    margin-top: 7px;
    margin-left: 0px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    background: color(white);
    margin-top: -4px;
    &--curves {
      // margin-left: rem(344);
      margin-top: rem(-1);
    }
    @media (max-width: 540px) {
      margin-top: -55px;
    }
    &--myesios {
      // margin-left: 349px;
      margin-top: -30px;
    }
  }

  &__menu {
    width: 100%;
    margin: 0 auto;
    padding: 45px 30px 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    margin-top: -4px;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &__column {
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
    min-width: 180px;
    margin-left: 5px;
    &:first-child {
      margin-left: 2px;
    }
    &:nth-child(2) {
      padding-left: 15px;
    }
    &:nth-child(3) {
      padding-left: 4px;
    }
  }

  &__item {
    font-size: 14px;
    color: #717171;
    font-family: $ns-font-proxima;
    font-weight: 600;

    &:hover {
      color: color(grey) !important;
    }
  }

  &__social {
    width: calc(100% - 2px);
    padding-bottom: 30px;
    border-bottom: 1px solid color(ns-border-ligth);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: -2px;
    margin-left: 2px;
    z-index: 2;
    @media screen and (max-width: 980px) {
      padding-top: 30px;
      position: relative;
      margin-top: 16px;
      margin-left: 15px;
      padding-bottom: 30px;
      width: calc(100% - 15px);
    }
    @media (max-width: 540px) {
      margin-left: 15px;
      width: 105%;
      margin-top: 0px;
      &::before {
        content: '';
        background-color: #dfdfdf;
        height: 0;
        width: 0;
        position: absolute;
        left: 12px;
        top: -1px;
      }
    }
  }

  &__copyright {
    font-family: $ns-font-proxima;
    font-weight: 400;
    width: calc(100% - 3px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0 20px 0;
    font-size: rem(14);
    padding: 0 20px;
    margin-top: 19px;
    margin-left: 3px;

    @media screen and (max-width: 980px) {
      gap: 31gipx;
      text-align: center;
      margin-top: 16px;
      line-height: 18.5px;
      margin-left: 16px;
      width: calc(100% - 16px);
    }
  }

  &__nav {
    color: color(grey) !important;
    a {
      color: #717171;
      &:hover {
        text-decoration: underline;
        color: #717171;
      }
    }
    @media (max-width: 540px) {
      width: 102%;
      margin-top: 3px;
      margin-bottom: -2px;
    }
  }

  &__logo-ree-text {
    color: color(black) !important;
    @media (max-width: 1025px) {
      display: flex;
      flex-direction: column;
      align-items: baseline;
    }
    @media (max-width: 540px) {
      margin-top: 13px;
      margin-left: 3px;
      margin-right: 5px;
    }
    a {
      font-family: $ns-font-proxima;
      font-weight: 700;
      color: color(ns-strong);
    }
  }
}

@media (max-width: 540px) {
  .#{$ns}footer {
    //acerca de esios
    &--about {
      .#{$ns}footer__wrapper {
        margin-top: 0 !important;
      }
    }
  }
  .esios-footer__wrapper {
    width: 100%;
    .esios-footer__menu {
      width: 100%;
    }
    .esios-footer__social {
      margin-left: 0px;
      width: 100%;
      margin-top: 0px;
    }
  }
}
