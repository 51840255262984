////
/// interrupcion component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}interrupcion
/// @since version 1.0.0
////

.#{$ns}interrupcion {
  &__title,
  h2 {
    line-height: 1.2;
    color: color(ns-primary);
    text-transform: uppercase;
  }

  &__title {
    font-size: 1.4375rem;
    font-weight: 800;
    font-family: $ns-font-proxima-extrabold;
    margin-block: 20px;
  }

  h2 {
    font-size: 1.1875rem;
    font-family: $ns-font-proxima-bold;
    margin-top: 20px;
  }
  p {
    font-weight: 400;
    color: color(ns-main-bg);
    margin-block: 10px;
    line-height: normal;
    a {
      font-weight: 600;
    }
  }

  ul {
    list-style-type: disc;
    list-style: none;
    padding: 16px 0 16px 40px;
    font-size: 1rem;
    line-height: normal;
    li {
      color: color(ns-main-bg);
    }
  }

  em {
    font-style: italic;
    color: color(ns-main-bg);
    font-family: $ns-font-proxima-regular;
    font-size: 1rem;
    line-height: 1.2;
  }

  strong {
    font-weight: 700;
    color: color(ns-main-bg);
  }

  a {
    color: color(ns-primary);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .btn-download + a,
  .btn-info + a {
    margin-top: 0.625rem;
  }
  .btn-info {
    text-align: center;
    border: 1px solid color(ns-light);
    color: color(ns-primary) !important;
    background-color: color(white);
    display: inline-block;
    padding: 0.4375rem 1.25rem;
    border-radius: 1.25rem;
    background-clip: padding-box;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.875rem;

    &:hover {
      text-decoration: none;
      background-color: rgba(247, 247, 247, 0.8);
    }
  }

  .btn-download {
    width: 66%;
    display: inline-block;
    padding: 0.4375rem 1.25rem;
    border-radius: 1.25rem;
    background-color: color(ns-primary);
    background-clip: padding-box;
    color: color(white) !important;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    font-size: 0.875rem;

    &:hover {
      text-decoration: none;
      background-color: rgba(0, 102, 153, 0.8);
    }
  }

  .btn-download.icon-download {
    padding-right: 3.125rem;
    background-position: right 3px center;
  }

  .icon-download {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgMjcgMjkiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgdmVyc2lvbj0iMS4xIgoJeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIKCXg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjdweCIgaGVpZ2h0PSIyOXB4Igo+Cgk8ZyBpZD0iTGF5ZXIlMjAxIj4KCQk8cGF0aCBkPSJNIDE3Ljc1IDEyIEwgMTMuNzg1IDE5IEwgMTcgMTkgTCAxNyAyMCBMIDEwIDIwIEwgMTAgMTkgTCAxMy4yMTUgMTkgTCA5LjI1IDEyIEwgMTIgMTIgTCAxMiAxMCBMIDE1IDEwIEwgMTUgMTIgTCAxNy43NSAxMiBaTSAxIDE0LjUgQyAxIDIxLjQwMzggNi41OTYzIDI3IDEzLjUgMjcgQyAyMC40MDM3IDI3IDI2IDIxLjQwMzggMjYgMTQuNSBDIDI2IDcuNTk2MiAyMC40MDM3IDIgMTMuNSAyIEMgNi41OTYzIDIgMSA3LjU5NjIgMSAxNC41IFoiIGZpbGw9IiNmZmZmZmYiLz4KCTwvZz4KPC9zdmc+);
    background-repeat: no-repeat;
    background-size: 1.5625rem;
  }

  .grid-xs-24 {
    width: 97%;
  }

  @media screen and (min-width: 540px) {
    .btn-download,
    .btn-info {
      width: auto;
      text-align: left;
    }
  }
}

@media screen and (max-width: 1199px) {
  .#{$ns}interrupcion {
    padding-left: rem(27.2);
  }
}

@media screen and (max-width: 767px) {
  .#{$ns}interrupcion {
    padding-left: 0;
  }
}
