////
/// Ayuda component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns} No compatible
/// @since version 1.0.0
////


.#{$ns}Obsoleto {
  height: auto;
  max-width: 37.5rem;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__title {
    font-family: $ns-font-proxima-extrabold;
    font-size: 1.4375rem;
    font-weight: 800;
    color: color(ns-primary);
    text-transform: uppercase;
    padding-block: 8px;
  }

  &__text {
    font-weight: 400;
    line-height: 1.2;
    color: color(ns-main-bg);
    padding-block: 10px;
  }

  a{
    color: color(ns-primary);
    font-weight: 600;
  }
}
