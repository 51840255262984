////
/// Contact component
///
/// Custom project
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}contact
/// @since version 1.0.0
////
.percentBar {
  width: 200px;
  height: 15px;
  margin: 0 auto;
  margin-top: 5px;
}

.mod-date {
  cursor: default;
  color: color(ns-main-bg) !important;
  font-family: $ns-font-cousine !important;
  position: relative;
  padding: 0 rem(25);
  width: fit-content;
  height: 1.4375rem;
  line-height: 1.4375rem;
  border-radius: 0.625rem;
  font-size: rem(12);
  font-weight: 600;
  background-color: #dbdbdb;
  z-index: 1;
  bottom: 2px;
  right: 2px;
  &--input-datepicker {
    background-color: transparent !important;
    border-radius: 0rem !important;
    padding: 0rem !important;
  }
}

#system-state-pvpc {
  cursor: default;
  color: color(ns-main-bg) !important;
  font-family: $ns-font-cousine !important;
  position: relative;
  padding: 0 rem(25);
  width: fit-content;
  height: 1.4375rem;
  line-height: 1.4375rem;
  border-radius: 0.625rem;
  font-size: rem(12);
  font-weight: 600;
  background-color: #dbdbdb;
  z-index: 1;
  bottom: 0px;
  right: 2px;
}

.mercadosyprecios {
  @media (max-width: 540px) {
    margin-top: -37px !important;
    margin-left: 11px !important;
  }
}

.generacion-consum,
.mercadosyprecios,
.evoluciondemanda,
.chari-chart {
  margin-top: -35px;
  min-height: 260px;
  font-family: $ns-font-proxima;
  font-size: 13px;
  font-weight: 700;
  svg,
  body {
    background: #ffffff;
  }

  svg {
    overflow: hidden;
    width: 100%;
  }

  .tick text {
    fill: #717171;
    font-family: $ns-font-proxima;
    font-size: 11px !important;
    font-weight: 700;
    line-height: 12px;
  }

  .serie-line {
    fill: transparent;
    stroke-width: 2px;
  }

  .dot {
    fill: red;
  }

  .trail-line {
    stroke: #444444;
    shape-rendering: crispEdges;
  }

  .yaxis line,
  .xaxis line {
    stroke: #dbdbdb;
    shape-rendering: crispEdges;
  }

  .yaxis line {
    stroke-dasharray: 8;
    stroke-width: 1;
  }

  .yaxis .label,
  .xaxis .label {
    font-weight: 700;
    fill: #444444;
    font-size: $ns-font-proxima !important;
    font-size: 11px;
  }
}
#mypGraphic4 {
  .yaxis {
    .label {
      font-size: rem(14);
    }
  }
}

#mypGraphic5 {
  .yaxis {
    .label {
      font-size: rem(14);
    }
  }
}

#mypGraphic6 {
  .yaxis {
    .label {
      font-size: rem(14);
    }
  }
}

.evoluciondemanda {
  svg {
    margin-left: 0;
  }
}

.generacion-consum,
.mercadosyprecios {
  .xaxis .label {
    transform: translate(rem(-46), 0);
  }
  .tick {
    text {
      font-family: $ns-font-cousine !important;
    }
  }
}

.evoluciondemanda {
  .xaxis {
    text {
      font-family: $ns-font-cousine !important;
      font-weight: 600 !important;
    }

    @media screen and (width: 540px) {
      .tick text {
        word-spacing: rem(-9) !important;
      }
    }
  }
  .yaxis {
    .tick {
      text {
        font-family: $ns-font-cousine !important;
        font-weight: 600 !important;
        transform: translateY(rem(-12)) !important;
      }
    }
    .label {
      font-size: rem(13) !important;
    }
  }
}

#mypGraphic1 {
  svg {
    width: 260px;
    height: 270px;
    position: relative;
    background-color: transparent;
  }
}

#widget21 {
  .chari-chart {
    margin-top: 29px;
    min-height: 200px !important;
    position: relative;
  }
}

.chari-chart {
  margin-top: 5px;
  min-height: 200px !important;
  position: relative;

  .chartLegend,
  .chartLegendR {
    width: 120px;
    margin: auto;
    position: absolute;
    top: 50.1%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-family: $ns-font-proxima;

    h3.title {
      color: color(ns-primary);
      margin: 0;
      font-size: rem(14);
      text-transform: uppercase;
      font-family: $ns-font-proxima;
      text-rendering: optimizeLegibility;
      font-weight: 600;
    }

    .value {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: rem(23);
      line-height: rem(25);
      font-family: $ns-font-cousine;
      color: color(ns-main-bg);

      &.be-size {
        font-size: rem(18) !important;
      }

      .unit {
        color: color(ns-main-bg);
        margin-left: rem(4);
      }
    }
    .description {
      font-size: rem(16);
      line-height: rem(16);
      color: color(ns-main-bg);
      font-family: $ns-font-proxima;
    }
  }

  .bargrid line {
    stroke: #dbdbdb;
    shape-rendering: crispEdges;
    stroke-dasharray: 10, 4;
  }

  .informativeWindow {
    display: grid;
    grid-template-columns: 1fr 10px;
    position: absolute;
    top: 0;
    height: 100%;
    width: 70%;
    @media screen and (max-width: 1000px) {
      width: 80%;
    }
    @media screen and (max-width: 540px) {
      width: 70%;
    }

    .widget-stacked-bar-tooltip-arrow {
      width: 0;
      height: 0;
      border-top: 11px solid transparent;
      border-bottom: 11px solid transparent;
      position: absolute;
      right: 0;
      top: 40px;
      border-left: 11px solid color(ns-grey);
      z-index: 3;
      display: block;
      margin-top: 1px;

      &:before {
        position: absolute;
        right: -9px;
        top: 50%;
        content: '';
        height: 0;
        width: 0;
        pointer-events: none;
        border: solid transparent;
        border-color: transparent;
        border-left-color: white;
        border-width: 10px;
        margin-top: -10px;
      }
    }
    .widget-stacked-bar-tooltip {
      position: relative;
      top: 0;
      width: 100%;
      height: 84%;
      left: 0;
      right: 71px;
      margin: auto;
      pointer-events: none;
      z-index: 1;
      margin-top: -8px;

      .tooltip-container {
        height: 100%;
        width: 100%;
        padding: 0.9375rem 1.25rem;
        box-sizing: border-box;
        background: white;
        color: #444444;
        font-size: 0.875rem;
        border: 1px solid #dbdbdb;

        .value.is-medium-num {
          font-size: rem(26) !important;
          font-family: $ns-font-cousine !important;
          color: color(ns-main-bg) !important;
        }
        .value.is-small-num {
          font-size: rem(22) !important;
          font-family: $ns-font-cousine !important;
          color: color(ns-main-bg) !important;
        }

        .tooltip-header {
          border-bottom: 4px solid;

          h3 {
            color: color(grey) !important;
            font-size: rem(14);
            text-transform: uppercase;
            font-family: $ns-font-proxima;
            font-weight: 600;
          }
        }
        .tooltip-data {
          .label {
            margin-right: 10px;
            color: color(grey) !important;
            font-size: rem(14) !important;
            font-family: $ns-font-proxima !important;
            line-height: normal;
          }
        }
      }
    }
  }
}

.chari-chart.dibujoDemanda {
  aspect-ratio: 6/6;
  width: 100%;
  min-height: 100px !important;
  max-height: 240px;
  margin: 0 auto;
  svg {
    background: transparent !important;
  }

  .chartLegend {
    .text {
      text-transform: uppercase;
      font-size: rem(12) !important;
      font-family: $ns-font-proxima !important;
      color: color(ns-primary);
    }
    .titlePie {
      margin-top: rem(10);
      padding-top: rem(8);
      border-top: 1px solid color(ns-grey);
      &--gd {
        margin-top: 0rem;
      }
    }

    .unit {
      font-size: 0.75rem;
      font-weight: 400;
      font-family: $ns-font-proxima;
      text-transform: none;
      padding-left: 0.3rem;
      padding-top: 0.2rem;
      letter-spacing: 0;
      text-transform: none;
      color: #444444;
      font-weight: 600 !important;
    }

    .totalValuePie {
      font-size: 16px !important;
      // letter-spacing: -1.6px;
      // line-height: 19.2px;
      color: #444444 !important;
      font-family: $ns-font-cousine;
    }
    .totalValuePie,
    .valuePie {
      color: color(ns-main-bg) !important;
    }
  }
}

.chari-chart.dibujo,
.chari-chart.dibujoR {
  aspect-ratio: 5/5;
  max-height: 275px !important;
}

.widget-summary-bar.gen-bar {
  min-height: 20px !important;
}

.chari-chart.large {
  min-height: 342px !important;
  aspect-ratio: 8/5;

  .g-main {
    rect {
      width: 40px !important;
      transform: translateX(10px) !important;
    }
  }
  @media screen and (max-width: 540px) {
    aspect-ratio: 6/5 !important;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 25px;

    svg {
      width: 100% !important;
    }
    .g-main {
      rect {
        width: 120px !important;
        transform: translateX(15vw) !important;
      }
    }
  }

  @media screen and (max-width: 375px) {
    .g-main {
      rect {
        width: 100px !important;
        transform: translateX(5vw) !important;
      }
    }
  }
}
@media screen and(max-width: 540px) {
  #mypGraphic2.chari-chart.large {
    min-height: 325px !important;
  }
}

.chari-chart.aspect {
  //min-height: 400px !important;
  width: 650px;
  height: 300px;
  &.supply {
    aspect-ratio: 16/14;
    min-height: initial !important;
    height: initial !important;
    position: relative;
    top: 0px;
    z-index: 0;
    .tick {
      text {
        font-family: $ns-font-cousine !important;
      }
    }
    .xaxis .label {
      transform: translate(-100px, 0) !important;
    }
    &.dashed-cero {
      .zeroline {
        stroke-dasharray: 8px;
        stroke: color(ns-light);
      }
    }
    // &.cero {
    //   .zeroline {
    //     transform: translateY(10px);
    //   }
    // }
  }

  &.myp-aspect {
    .serie-bar {
      rect {
        width: 15px !important;
      }
    }
  }
  @media screen and (max-width: 540px) {
  }
}
.#{$ns}balance__graph.chari-chart {
  aspect-ratio: 16/13;
  min-height: initial !important;
  position: relative;
  top: 0px;
  z-index: 0;
  padding-left: 15px;
  margin-top: -1px;
  min-width: rem(767);
  // max-width: 900px;
  // .series {
  //   // scale the graph
  //   transform: scaleY(0.78) translateY(80px) translateX(10px);

  .domain {
    display: none;
  }
  .serie-bar {
    rect {
      // width of graph bars
      width: 18px !important;
    }
  }

  //   .serie-line {
  //     fill: transparent;
  //     stroke-width: 5px;
  //   }
  // }
}
#chart-bar-line.chari-chart {
  aspect-ratio: 16/10;
  position: relative;
  top: 0px;
  z-index: 0;
  margin-top: -1px;
  min-height: initial !important;

  .y.axis {
    line {
      stroke: #878787;
      stroke-dasharray: 10, 4;
      stroke-opacity: 0.5;
    }
  }
  .domain {
    display: none;
  }
}

.chari-chart.multi-bar {
  aspect-ratio: 16/6;
  min-height: initial !important;
  position: relative;
  top: 0px;
  z-index: 0;
  margin-top: -1px;

  .axisLeftCenterLabel {
    transform: translate(-60px, 200px) rotate(270deg);
  }
  .y.axis {
    line {
      stroke: #878787;
      stroke-dasharray: 10, 4;
      stroke-opacity: 0.5;
    }
  }
  .domain {
    display: none;
  }
}
#widget47 {
  .esios-select {
    z-index: 2;
    width: 50% !important;
    .esios-selector {
      width: 200px;
      right: 0;
    }
  }
}

// int-exchange
.chari-chart.int-exchange {
  aspect-ratio: 16/16;
  min-height: initial !important;
  position: relative;
  top: 0px;
  z-index: 0;
  padding-left: 0px;
  margin-top: -1px;
}

#balance-graph {
  .axis line {
    stroke: #878787;
    stroke-dasharray: 10, 4;
    stroke-opacity: 0.5;
  }
  .domain {
    display: none !important;
  }

  .axisLabel.x {
    text-transform: uppercase !important;
  }
}

#mypGraphic5 {
  .axis line {
    stroke: #878787;
    stroke-dasharray: 10, 4;
    stroke-opacity: 0.5;
  }
  .domain {
    display: none !important;
  }
}

.chari-chart.analysis {
  aspect-ratio: 16/16;
  min-height: initial !important;
  position: relative;
  top: 0px;
  z-index: 0;
}

.chari-chart.demanda {
  aspect-ratio: 10/10;
  min-height: initial !important;
  position: relative;
  top: -40px;
  z-index: 0;
  padding: 0 !important;
  .yaxis {
    &.right {
      transform: translateY(rem(18)) !important;
      @media screen and (max-width: 540px) {
        transform: translateY(rem(11)) !important;
      }
      .tick text {
        font-family: $ns-font-cousine !important;
      }
    }
    &.left {
      transform: translateY(rem(18)) !important;
      @media screen and (max-width: 540px) {
        transform: translateY(rem(11)) !important;
      }
      .tick text {
        font-family: $ns-font-cousine !important;
      }
    }

    .label {
      font-size: rem(13) !important;
    }
  }
  // .xaxis {
  //   &.bottom {
  //     .tick text {
  //       font-family: $ns-font-cousine !important;
  //     }
  //   }
  //   .label {
  //     font-size: rem(13) !important;
  //     transform: translate(rem(-45), 0);
  //   }
  // }
  .series {
    transform: translateY(18px);
    @media screen and (max-width: 540px) {
      transform: translateY(11px) !important;
    }
    .zeroline {
      opacity: 0.5;
    }
    .serie-line {
      stroke-width: rem(2);
      display: none;
    }
    #area-847,
    #area-835 {
      transform: rotate(0.16deg);
    }
    #serie-835border,
    #serie-847border {
      display: block !important;
    }
    #area-847border {
      stroke: #00a2d8;
    }
    #area-835border {
      stroke: #dddddd;
    }
  }
  #serie-847,
  #serie-835 {
    display: none;
  }
}

.chari-chart.temporal-analysis {
  aspect-ratio: 16/13;
  min-height: initial !important;
  position: relative;
  top: 0px;
  z-index: 0;
}
.chari-chart.analysis-pie {
  aspect-ratio: 2/2;
  max-height: 300px !important;
  height: 100%;
  .esios-listEnergyItems__label {
    font-size: rem(10) !important;
  }
}
.#{$ns}pie-graph {
  max-width: 800px;
  width: 100%;

  &.chart {
    width: 280px;
    height: 280px;
    margin: 0 auto;
    position: relative;

    .chartLegend {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;

      h3.title {
        color: color(ns-primary);
        margin: 0;
        font-size: rem(14);
        text-transform: uppercase;
        font-family: $ns-font-proxima;
        text-rendering: optimizeLegibility;
        font-weight: 600;
        margin-bottom: 3px !important;
      }

      .value {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: rem(23);
        line-height: rem(25);
        font-family: $ns-font-cousine;
        color: color(ns-main-bg);

        .unit {
          color: color(ns-main-bg);
          margin-left: rem(4);
        }
      }
      .description {
        font-size: rem(16);
        line-height: rem(16);
        color: color(ns-main-bg);
        font-family: $ns-font-proxima;
        font-weight: 400 !important;
      }
    }
  }
}

@media (max-width: 540px) {
  .esios-market-prices {
    #mypGraphic1 {
      svg {
        width: auto;
        height: 270px;
        position: relative;
        top: 146px;
        left: 50%;
        background-color: transparent;
      }
      .chartLegend {
        top: 56.6%;
        left: calc(50% - 6px) !important;
      }
    }
  }
  .chari-chart.demanda {
    svg {
      background-color: transparent !important;
    }
  }

  .chari-chart.dibujoDemanda {
    margin: 0 72px;
  }
  #breakdownGraphic {
    .chari-chart.dibujoDemanda {
      @media screen and (max-width: 540px) {
        margin: 0 auto !important;
      }
    }
  }

  .chari-chart.demanda {
    .yaxis.right,
    .yaxis.left {
      .label {
        font-size: rem(13);
      }
    }
  }

  .#{$ns}balance__graph.chari-chart {
    min-width: auto;
  }
}
