.esios-analysis__info-chart {
  .m-map {
    position: relative;
    height: 520px;
  }
  .widget-nodata {
    background: color(ns-lighter);
    font-size: 0.8125rem;
    height: 100%;
    min-height: 520px;
    position: relative;
    width: 100%;
    & > div {
      border: 1px solid color(ns-red);
      bottom: 0;
      box-sizing: border-box;
      color: color(ns-red);
      display: inline-block;
      font-weight: 600;
      height: 65px;
      left: 0;
      margin: auto;
      padding: 24px;
      position: absolute;
      right: 0;
      text-align: center;
      text-transform: uppercase;
      top: 0;
      width: 250px;
    }
  }
  .geo-map {
    height: 520px;
    position: relative;
    font: 12px/1.5 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }
  .mod-analysis-map-legend {
    box-sizing: border-box;
    border: 1px solid $bg-color-2;
    margin: rem(30) 0;
    flex-grow: 1;
    padding: 1.25rem 2.5rem 1.875rem 2.5rem;

    .basic-legend {
      h3 {
        font-family: $ns-font-proxima-alt-bold;
        text-align: center;
        color: color(ns-primary);
        font-size: rem(16) !important;
        text-transform: uppercase !important;
        margin-bottom: rem(10);
        margin-top: rem(14);
      }

      .is-flex-center {
        display: flex;
      }

      .btn-filter {
        padding-top: 1.5rem;
        padding-bottom: 0;
        margin: 0 auto;
        margin-bottom: rem(15);
        display: flex;
        align-items: center;
        border: 1px solid #dbdbdb;
        border-radius: 1.25rem;
        padding: 0 1.25rem;
        &:hover {
          background-color: rgba(247, 247, 247, 0.8);
        }
        .is-myesios-page & {
          display: none;
        }
      }

      .toggle-switch {
        display: inline-block;
        position: relative;
        width: rem(32);
        height: rem(16);
        background-color: $bg-color-3;
        border-radius: 8px;
        margin: 0 rem(10);

        &:after {
          content: '';
          position: absolute;
          top: rem(2);
          right: rem(2);
          width: rem(12);
          height: rem(12);
          border-radius: rem(6);
          background-color: $bg-color-0;
        }

        &.locked:after {
          left: rem(2);
        }

        input[type='checkbox'] {
          width: rem(32);
          height: rem(16);
          visibility: hidden;
        }
      }

      ul {
        display: inline-block;
        width: 100%;
        margin: 0;
      }
      .legend-item {
        max-width: 33.33%;
        float: left;
        display: inline-block;

        &.item-content {
          justify-content: inherit;
          display: inline-block;

          .item {
            padding-right: rem(2);
            padding-left: rem(7);
            margin-left: 0;
            display: inline-block;

            > span {
              top: 0;
              position: relative;
              vertical-align: baseline;
            }
          }

          .value {
            display: inline-block;
            text-align: left;
            letter-spacing: 0rem;
            font-size: 1rem;
            font-family: $ns-font-cousine;
            font-weight: 400;
          }

          .unit {
            display: inline-block;
            padding-left: 0;
            font-size: 0.75rem;
            font-weight: 600;
            font-family: $ns-font-proxima, Arial, sans-serif;
            letter-spacing: 0;
            text-align: left;
          }
        }
      }

      .btn-lock-legend,
      .btn-unlock-legend {
        padding: rem(4) 0 rem(4) rem(15);
        font-weight: 700;
        text-transform: uppercase;
        color: $gray;
        font-size: rem(14);
        line-height: rem(20);
      }
    }
  }
}
