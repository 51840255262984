////
/// Ayuda component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}no Accesible
/// @since version 1.0.0
////

.#{$ns}Analisis-movil {
  min-height: rem(280);
  height: rem(280);

  .grid-xs-24 {
    width: 97%;
  }

  &__button {
    margin: 1.25rem 0;
    text-align: center;
    .button-start {
      width: 46%;
      display: inline-block;
      padding: 0.4375rem 1.25rem;
      border-radius: 1.25rem;
      background-color: color(ns-primary);
      background-clip: padding-box;
      color: color(white) !important;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;

      font-size: 0.875rem;

      &:hover {
        text-decoration: none;
        background-color: rgba(0, 102, 153, 0.8);
      }
    }
  }

  &__p {
    color: color(ns-main-bg);
    font-weight: 400;
  }

  &__title {
    line-height: 1.2;
    font-family: $ns-font-proxima-extrabold;
    text-transform: uppercase;
    font-size: 1.4375rem;
    font-weight: 800;
    color: color(ns-primary);
    margin: 0.67em 0;
  }

  @media screen and (min-width: 540px) {
    .grid-sm-16 {
      width: 63.66667%;
    }

    &__button {
      margin-bottom: 2.5rem;
      .button-start {
        width: auto;
        text-align: left;
        margin: 0 0.5rem;
      }
    }
  }
}
