////
/// icons component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}esios-icons
/// @since version 1.0.0
////

.#{$ns}icons {
  margin-right: 2px;
  margin-left: 2px;

  &__buttons {
    width: 23px;
    height: 23px;
    top: 5px;
    position: relative;
  }
  .icons-favorite {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IlVudGl0bGVkLVBhZ2VfeDI1XzIwMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIKCSB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iLTEuNCAtMS40IDI1LjggMjUuOCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAtMS40IC0xLjQgMjUuOCAyNS44IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggZmlsbD0ibm9uZSIgZD0ieiIvPgo8ZyBpZD0iTGF5ZXJfeDI1XzIwMSI+Cgk8ZyBvcGFjaXR5PSIwLjMwMiI+CgkJPHBhdGggaWQ9IkVsaXBzZSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMzMzMzMzIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0wLDExLjVDMCw1LjEsNS4xLDAsMTEuNSwwUzIzLDUuMSwyMywxMS41UzE3LjksMjMsMTEuNSwyMwoJCQlTMCwxNy45LDAsMTEuNXoiLz4KCQk8cGF0aCBpZD0iUG9seWdvbiIgZmlsbD0iIzMzMzMzMyIgZD0iTTE1LjUsMTdsLTQtMi44bC00LDIuOEw5LDEyLjRMNSw5LjZsNSwwTDExLjUsNUwxMyw5LjZsNSwwbC00LDIuOEwxNS41LDE3eiIvPgoJPC9nPgo8L2c+CjxwYXRoIGZpbGw9Im5vbmUiIGQ9InoiLz4KPC9zdmc+);
  }
  .icons-favorite:hover {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IlVudGl0bGVkLVBhZ2VfeDI1XzIwMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIKCSB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iLTEuNCAtMS40IDI1LjggMjUuOCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAtMS40IC0xLjQgMjUuOCAyNS44IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggZmlsbD0ibm9uZSIgZD0ieiIvPgo8cGF0aCBpZD0iRWxpcHNlIiBmaWxsPSJub25lIiBzdHJva2U9IiM3MDcwNzAiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTAsMTEuNUMwLDUuMSw1LjEsMCwxMS41LDBTMjMsNS4xLDIzLDExLjVTMTcuOSwyMywxMS41LDIzCglTMCwxNy45LDAsMTEuNXoiLz4KPHBhdGggaWQ9IlBvbHlnb24iIGZpbGw9IiM3MDcwNzAiIHN0cm9rZT0iIzcwNzA3MCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBkPSJNMTUuNSwxN2wtNC0yLjhsLTQsMi44TDksMTIuNEw1LDkuNmw1LDBMMTEuNSw1CglMMTMsOS42bDUsMGwtNCwyLjhMMTUuNSwxN3oiLz4KPHBhdGggZmlsbD0ibm9uZSIgZD0ieiIvPgo8L3N2Zz4=);
  }
  .icons-favorite-selected {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IlVudGl0bGVkLVBhZ2VfeDI1XzIwMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIKCSB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iLTEuNyAtMS44IDI2LjQgMjYuMyIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAtMS43IC0xLjggMjYuNCAyNi4zIiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggZmlsbD0ibm9uZSIgZD0ieiIvPgo8ZyBpZD0iTGF5ZXJfeDI1XzIwMSI+Cgk8ZyBvcGFjaXR5PSIwLjMwMiI+CgkJPHBhdGggaWQ9IkVsaXBzZSIgZmlsbD0iIzMzMzMzMyIgc3Ryb2tlPSIjMzMzMzMzIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0wLDExLjVDMCw1LjEsNS4xLDAsMTEuNSwwUzIzLDUuMSwyMywxMS41CgkJCVMxNy45LDIzLDExLjUsMjNTMCwxNy45LDAsMTEuNXoiLz4KCQk8cGF0aCBpZD0iUG9seWdvbiIgZmlsbD0iI0ZGRkZGRiIgZD0iTTE1LjUsMTdsLTQtMi44bC00LDIuOEw5LDEyLjRMNSw5LjZsNSwwTDExLjUsNUwxMyw5LjZsNSwwbC00LDIuOEwxNS41LDE3eiIvPgoJPC9nPgo8L2c+CjxwYXRoIGZpbGw9Im5vbmUiIGQ9InoiLz4KPC9zdmc+);
  }
  .icons-favorite-selected:hover {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IlVudGl0bGVkLVBhZ2VfeDI1XzIwMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIKCSB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iLTEuOSAtMi4zIDI2LjUgMjcuMSIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAtMS45IC0yLjMgMjYuNSAyNy4xIiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggZmlsbD0ibm9uZSIgZD0ieiIvPgo8ZyBpZD0iTGF5ZXJfeDI1XzIwMSI+Cgk8ZyBvcGFjaXR5PSIwLjcwMiI+CgkJPHBhdGggaWQ9IkVsaXBzZSIgZmlsbD0iIzMzMzMzMyIgc3Ryb2tlPSIjMzMzMzMzIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0wLDExLjVDMCw1LjEsNS4xLDAsMTEuNSwwUzIzLDUuMSwyMywxMS41CgkJCVMxNy45LDIzLDExLjUsMjNTMCwxNy45LDAsMTEuNXoiLz4KCQk8cGF0aCBpZD0iUG9seWdvbiIgZmlsbD0iI0ZGRkZGRiIgZD0iTTE1LjUsMTdsLTQtMi44bC00LDIuOEw5LDEyLjRMNSw5LjZsNSwwTDExLjUsNUwxMyw5LjZsNSwwbC00LDIuOEwxNS41LDE3eiIvPgoJPC9nPgo8L2c+CjxwYXRoIGZpbGw9Im5vbmUiIGQ9InoiLz4KPC9zdmc+);
  }
}
