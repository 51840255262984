////
/// Previsiones component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}previsiones
/// @since version 1.0.0
////
.#{$ns}previsiones {
  display: block;
  max-width: 72.5625rem;
  margin: 0 auto;
  padding: 0 24px 0 0;
  padding-top: 2px;
  margin-bottom: 35px;

  h1,
  h2 {
    color: color(ns-primary);
    text-transform: uppercase;
    font-family: $ns-font-proxima;
  }

  h1 {
    font-family: $ns-font-proxima;
    font-weight: 800;
    margin: 0.67em 0;
    font-size: rem(23);
    line-height: 1.2;
  }

  p {
    margin: 1rem 0;
    color: color(ns-main-bg);
    font-family: $ns-font-proxima;
    line-height: 1.2;
  }

  strong {
    color: color(ns-main-bg);
  }

  h2 {
    font-size: rem(19);
    font-weight: 700;
    font-family: $ns-font-proxima;
    margin: rem(15.77) 0;
    line-height: 1.2;
  }

  h3 {
    font-size: 1rem;
    color: color(grey);
    text-transform: uppercase;
    font-weight: 700;
    font-family: $ns-font-proxima;
    line-height: 1.2;

    strong {
      font-family: $ns-font-proxima;
      color: color(grey);
      font-weight: 700;
    }
  }

  ul {
    padding-left: rem(41);
    list-style: none;
    list-style-type: disc;
    margin: 15px 0 15px 0;
    line-height: 1.2;
  }

  a {
    color: color(ns-primary);
    font-weight: 600;
    font-family: $ns-font-proxima;
  }

  .previsiones-layout {
    position: relative;
    z-index: 3;
    background: no-repeat bottom;
  }

  @media screen and (min-width: 540px) {
    .previsiones-layout {
      background-image: url($ns-path-img + 'bg-static-page.png');
      padding-bottom: 13.75rem;
    }

    .grid-xs-24 {
      width: 97%;
    }
    .grid-sm-16 {
      width: 63.66667%;
    }

    .grid-sm-23 {
      width: 97.83333%;
    }
  }
}

@media screen and (max-width: 1199px) {
  .#{$ns}previsiones {
    padding: 0 rem(27.2);
  }
}

@media screen and (max-width: 767px) {
  .#{$ns}previsiones {
    padding: 0;
  }
}

@media screen and (max-width: 539px) {
  .#{$ns}previsiones {
    h1 {
      margin-top: 0;
    }
  }
}
