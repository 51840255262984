////
/// Tab component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns} Tab
/// @since version 1.0.0
////

.#{$ns}tab {
  .row {
    position: relative;
    margin: 0 1.5%;

    :after {
      clear: both;
    }
  }

  .btn {
    display: inline-block;
    font-size: 0.875rem;
    border-radius: 1.25rem;
    background-color: #069;
    background-clip: padding-box;
    color: #fff !important;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    padding: 0.4375rem 1.25rem;

    &:hover {
      text-decoration: none;
      background-color: rgba(0, 102, 153, 0.8);
    }
  }

  .icon-download {
    background-image: url($ns-path-img + '/download.svg');
    background-repeat: no-repeat;
    background-position: right 3px center;
    background-size: 1.5625rem;
    padding-right: 3rem;
  }

  .row:before,
  .row:after {
    display: table;
    content: '';
  }

  .ui {
    &-tabs {
      position: relative;
      padding: 0.2em;

      &-nav {
        position: relative;
        border-bottom: 1px solid color(ns-light);
        padding: 0;
        // margin-bottom: 2.5rem;
        display: flex;

        li {
          box-sizing: border-box;
          -webkit-box-flex: 1;
          -webkit-flex-grow: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          min-width: 24%;
          height: 2.5rem;
          text-align: center;
          margin: 0 0.5%;

          a {
            text-transform: uppercase;
            margin-top: -1px;
          }
        }
      }

      &-anchor {
        display: block;
        width: 100%;
        line-height: 2.4375rem;
        border: 1px solid color(ns-light);
        color: color(ns-darker);
        font-weight: 600;
        font-size: 1.1875rem;
        background-color: color(ns-lighter);
        padding: 0;
        transition: 0.1s;
      }
    }

    &-helper {
      &-clearfix {
        min-height: 0;
      }

      &-reset {
        //margin: 0;
        padding: 0;
        //  border: 0;
        outline: 0;
        line-height: 1.3;
        text-decoration: none;
        font-size: 100%;
        list-style: none;
      }
    }

    &-state {
      &-default {
        color: color(ns-main-bg);
        // border-bottom:3px solid #006699;font-weight:700;margin:0 auto
      }

      &-active {
        color: color(white);
        border: 0;
        font-weight: 400;
      }
    }

    &-tabs-panel {
      //position: relative;
      padding: 0;
      display: block;
      border-width: 0;
      min-height: 10vh;
      // padding: 1em 1.4em;
      background: none;
    }
  }

  .ui-tabs-inactive {
    display: none;
  }

  .ui-tabs-panel.ui-tabs-inactive {
    display: none;
  }

  .mod-tabs .ui-tabs .ui-tabs-nav li:first-child {
    margin-left: 0;
  }
  .mod-tabs {
    &--analysis {
      border-bottom: 1px solid #ddd !important;
      padding-bottom: 0;
    }
  }
  .mod-tabs.ui-widget.ui-widget-content {
    border: none;
    width: 105.2%;
    margin-left: 0px;
    margin-top: -4px;
  }
  .mod-tabs .ui-widget-header {
    background: none !important;
    border-bottom: 1px solid #dbdbdb;
    border-top: none;
    border-left: none;
    border-right: none;
  }
  .ui-tabs .ui-tabs-panel {
    border-top: 1px solid #ddd !important;
    padding: 1em 0;
  }
  .mod-tabs .ui-tabs .ui-tabs-nav li a {
    color: color(grey);
  }
  .mod-tabs .ui-tabs .ui-tabs-nav li a,
  .mod-tabs .ui-tabs-collapsible .ui-tabs-nav li.ui-tabs-active a,
  .mod-tabs.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
    padding: 0 !important;
    position: relative;
    font-family: $ns-font-proxima !important;
    font-weight: 600;
    font-size: 19px;
    text-transform: uppercase;
    text-decoration: none;
    border-bottom: none;
  }
  .esios-tab .mod-tabs.ui-tabs .ui-tabs-nav .ui-tabs-ancho {
    bottom: 0;
  }

  .mod-tabs.ui-tabs .ui-tabs-nav:before {
    content: '';
    position: absolute;
    bottom: -1px;
    width: 24px;
    height: 1px;
    background-color: color(ns-light);
  }

  .mod-tabs.ui-tabs .ui-tabs-nav .ui-tabs-active .ui-tabs-anchor,
  .mod-tabs.ui-tabs .ui-tabs-nav .ui-tabs-active {
    background-color: color(white);
    color: color(ns-primary);
    border-bottom: 4px solid color(white);
    padding: 0;
  }

  .ui-tabs-over {
    background-color: color(ns-grey-light);
  }

  .mod-tabs.ui-tabs .ui-tabs-nav:after,
  .mod-tabs.ui-tabs .ui-tabs-nav:before {
    content: '';
    position: absolute;
    bottom: -1px;
    width: 24px;
    height: 1px;
    background-color: color(ns-light);
  }

  .mod-tabs.ui-tabs .ui-tabs-nav:before {
    left: -18px;
  }
  .mod-tabs.ui-tabs .ui-tabs-nav:after {
    right: -18px;
  }

  .ui-helper-clearfix:before,
  .ui-helper-clearfix:after {
    content: '';
    display: table;
    border-collapse: collapse;
  }

  table {
    font-size: 12px;
    width: 100%;
    text-transform: uppercase;
    //position: absolute;
    th {
      padding: 0.625rem;
      vertical-align: top;
      font-weight: 600;
      text-align: left;
      position: relative;
    }
  }

  @media screen and (min-width: 540px) {
    .grid-sm-24 {
      width: 97%;
    }

    table {
      margin-bottom: 2.5rem;

      tr:nth-child(2n + 1) {
        background-color: color(white);
      }
      tr:nth-child(2n) {
        background-color: color(ns-lighter);
      }

      th,
      td {
        display: table-cell;
        padding: 0.625rem 1.25rem;
      }
    }
  }
  .ui-state-default.ui-corner-top.ui-tabs-active,
  .ui-state-default.ui-corner-top {
    margin-right: 8px !important;
  }

  .mod-tabs .ui-tabs .ui-tabs-nav li,
  .mod-tabs .ui-tabs .ui-tabs-nav a,
  .mod-tabs .ui-tabs-anchor {
    border-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .mod-tabs .ui-corner-top {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    //margin-top: 8px;
  }
}
.my-tabs {
  position: relative;
}
.tab__content {
  position: relative;
  z-index: -2;
  height: 0;
  overflow: hidden;
}
.tab__content.active {
  position: relative;
  z-index: 1;
  opacity: 1;
  height: auto;
}

#calendar-button {
  margin: 0 auto;
}

#tabwithcontent {
  margin-top: 25px;
  .mod-tabs .ui-widget-header {
    margin-left: 0 !important;
  }
}
@media screen and (max-width: 540px) {
  #tab-gd-content1,
  #tab-gd-content2,
  #tab-gd-content3 {
    display: none !important;
  }
  .tab__content.active tbody {
    display: block;
  }
  .esios-table__row {
    height: auto !important;
  }
}
