////
/// Dashboard component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}mapas-int
/// @since version 1.0.0
////

.#{$ns}mapas-int {
  max-width: 700px;
  width: 100%;

  * {
    font-family: $ns-font-proxima;
    width: 100.2%;
    margin-bottom: -0.6px;
  }

  &__wrap {
    display: block;
    max-width: rem(1161);
    margin: 0 auto;
  }

  &__row {
    position: relative;
  }

  &__row:before,
  &__row:after {
    display: table;
    content: '';
  }

  &__main-title {
    font-family: $ns-font-proxima;
    margin: 1rem 0 2.05rem 0;
    font-size: 23px;
    font-weight: 800;
    text-transform: uppercase;
    color: color(ns-primary);
    line-height: 1.2;
    margin-left: 2px;
  }

  &__title {
    margin-top: rem(18);
    font-size: rem(23);
    color: color(ns-main-bg);
    font-weight: 400;
    text-transform: uppercase;
    margin-left: 2.01px;
    :hover {
      color: color(ns-main-bg);
    }
    a {
      line-height: 24.32px;
    }
  }

  &__mod-article-item {
    padding-bottom: 2.23rem;

    p {
      color: color(ns-main-bg);
      padding-bottom: rem(15);
      font-size: rem(16);
      margin-left: 2px;
      margin-top: 1px;
      &:nth-child(5) {
        padding-bottom: rem(10) !important;
      }
      &:nth-child(6) {
        padding-bottom: rem(11.2) !important;
      }
      &:nth-child(7) {
        padding-bottom: rem(11.2) !important;
      }
      span {
        color: #444444;
      }
    }
  }

  &__mod-article-item:before,
  &__mod-article-item:after {
    display: table;
    content: '';
  }

  &__time {
    display: block;
    margin: 0 0 rem(16) 0;
    font-size: rem(19);
    font-weight: 700;
    color: color(ns-primary);
    font-style: normal;
    font-family: $ns-font-proxima;
    margin-right: 2px;
    margin-left: 2px;
    margin-top: 1px;
  }

  @include media-query(xs) {
    &__mod-article-item:last-child {
      border-bottom: 1px solid color(ns-border-ligth);
    }
  }
}

@media screen and (max-width: 1199px) {
  .#{$ns}mapas-int {
    padding-left: rem(27.2);
  }
}

@media screen and (max-width: 767px) {
  .#{$ns}mapas-int {
    padding-left: 0;
  }
}
