////
/// Header component
///
/// Custom project
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}header
/// @since version 1.0.0
////

.no__scroll {
  overflow-y: hidden;
}

.scroll {
  overflow-y: auto;
}

.#{$ns}header {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__top-header {
    display: flex;
    align-items: center;
    justify-content: center;
    background: color(ns-main-bg);
    padding: 6px 0;
    height: rem(40);
    z-index: 9 !important;

    @media screen and (max-width: 540px) {
      height: 70px;
      z-index: 4 !important;
      position: fixed;
      width: 100%;
      top: 0;
    }

    &__wrapper {
      max-width: 1212px;
      width: 96%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media screen and (max-width: 540px) {
        display: none;
      }

      .items {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4.1px;
        padding-right: rem(27);
        font-family: $ns-font-proxima;

        @media screen and (max-width: 540px) {
          display: none;
        }

        .item {
          color: color(white);
          font-size: rem(16);
        }

        .searcher {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;

          span {
            color: color(white);
          }

          .search-input {
            padding: 5px 12.8px;
            border-radius: 20px;
            border: none;
            background: color(ns-lighter);
            width: rem(195);
            height: rem(29);
            font-size: 14px;
            color: #444444;
          }
        }

        .top-nav {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;

          .options.active {
            li {
              margin-bottom: -1px;
              a {
                text-transform: uppercase;
                font-size: 14px;
                line-height: 16.8px;
                color: #444444;
                &:hover {
                  color: #444444;
                }
              }
              button#btnLogout {
                font-size: 14px;
                text-transform: uppercase;
                line-height: 16.8px;
                color: #444444;
              }
            }
          }

          .button-container {
            position: relative;
            top: 1px;
            left: 1px;

            .button {
              background: color(ns-lighter);
              background-repeat: no-repeat;
              background-position: center;
              background-size: rem(30px);
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              width: 30px;
              height: 30px;
              cursor: pointer;
              position: relative;
              color: color(ns-grey);
              font-size: rem(15);
            }

            .button-user {
              background-image: url(../assets/images/user.svg);
            }

            .button-help {
              background-image: url(../assets/images/question.svg);
            }

            .button-spanish {
              background-image: url(../assets/images/spanish.svg);
            }

            .button-english {
              background-image: url(../assets/images/english.svg);
            }

            .lng-options-button {
              color: #444444;
              &:hover {
                text-decoration: underline;
              }
            }

            &:nth-child(3) .options {
              left: -5px !important;

              &::before {
                right: 15px;
              }

              &::after {
                right: 15px;
              }
            }

            .options {
              position: absolute;
              z-index: 9;
              background: color(white);
              white-space: nowrap;
              // box-sizing: border-box;
              display: none;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              top: 40px;
              left: 50%;
              @include transform(translateX(-50%));
              @include box-shadow(5px 5px 0 #0000000d);
              border: 1px solid color(ns-border-ligth);
              text-transform: uppercase;
              font-size: rem(14);

              li button {
                text-transform: uppercase;
              }

              &.active {
                display: flex;
                padding: 20px;
              }

              &::before {
                content: '';
                position: absolute;
                top: -16px;
                border-bottom: 16px solid color(ns-light);
                border-left: 16px solid transparent;
                border-right: 16px solid transparent;
                z-index: 4;
              }

              &::after {
                content: '';
                position: absolute;
                top: -15px;
                z-index: 5;
                border-bottom: 15px solid color(white);
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
              }
            }
          }
        }
      }
    }
  }

  &__main-header {
    background: color(white);
    z-index: 5;
    @include box-shadow(1px 2px 2px #0000001c);
    position: relative;
    height: 114px;

    @media screen and (max-width: 540px) {
      position: fixed;
      width: 100%;
      top: 5px;
      height: auto;
    }

    &__wrapper {
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1200px;
      width: 96%;
      min-height: 112px;
      margin: 0 auto;

      @media screen and (max-width: 540px) {
        padding: 0 10px;
        min-height: 70px;

        a {
          display: flex;
          align-items: center;
          img {
            // max-width: 250px;
            // width: calc(100vw - 100px);
            max-height: 57px;
          }
        }
      }
    }

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 rem(13);

      @media screen and (min-width: 1200px) {
        padding: 0 rem(13);
      }

      @media screen and (max-width: 420px) {
        margin-right: 60px;
      }

      img {
        width: 100%;
        height: auto;
        margin-left: rem(7);
        margin-top: 2px;
      }
    }

    .main-items {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      max-width: 700px;
      white-space: nowrap;
      padding-right: rem(2);
      margin-left: rem(45);
      @media screen and (max-width: 540px) {
        display: none;
        z-index: 4;
      }

      .item {
        text-transform: uppercase;
        font-size: rem(16);
        padding-left: 2%;
        padding-right: rem(20) !important;

        a {
          text-decoration: none;
          width: 100%;
          height: 100%;
          border-bottom: 5px solid transparent;
          font-weight: 600;
          color: #444444;
          font-family: $ns-font-proxima;
          letter-spacing: -0.1px;
          font-size: 16px;

          &:hover {
            color: color(ns-primary);
          }

          &.selected {
            color: color(ns-primary);
            border-bottom: 5px solid color(ns-primary);
            display: inline;
          }
        }
      }
    }

    .menu-button {
      display: none;

      @media screen and (max-width: 540px) {
        display: flex;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 60px;
      }

      .bars {
        background: color(ns-lighter);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;

        .bar {
          background: color(black);
          width: 28px;
          height: 2px;
          display: flex;
          position: relative;
          border-radius: 50px;
          @include transition(all 0.2s ease-in-out);

          &:nth-child(1) {
            top: 0px;
          }
        }

        &.close {
          .bar {
            &:nth-child(1) {
              top: 4px;
              @include transform(rotate(135deg));
              @include transition(all 0.4s ease-in-out);
            }

            &:nth-child(2) {
              top: 0px;
              display: none;
              @include transition(all 0.4s ease-in-out);
            }

            &:nth-child(3) {
              top: -3px;
              @include transform(rotate(45deg));
              @include transition(all 0.4s ease-in-out);
            }
          }
        }
      }
    }
  }

  &__mod-rapid-access {
    background: color(ns-primary);
    width: 100%;
    display: none;

    @media screen and (max-width: 540px) {
      display: flex;
      align-items: center;
      justify-content: center;
      top: 76px;
      position: relative;
    }

    &__wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h1 {
        position: relative;
        z-index: 1;
        margin: 0;
        padding: 20px;
        color: color(white);
        text-transform: uppercase;
        cursor: pointer;
        font-size: rem(16);
        width: 100%;
        max-width: 100%;
        font-family: $ns-font-montserrat-bold;

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 15px;
          right: 20px;
          width: 1.5625rem;
          height: 1.5625rem;
          pointer-events: none;
          @include transition(transform 0.2s);
          background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IlVudGl0bGVkLVBhZ2VfeDI1XzIwMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIKCSB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iLTEuNiAtMS40IDIzLjkgMjMuNiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAtMS42IC0xLjQgMjMuOSAyMy42IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggZmlsbD0ibm9uZSIgZD0ieiIvPgo8ZyBpZD0iTGF5ZXJfeDI1XzIwMSI+Cgk8Zz4KCQk8cGF0aCBpZD0iRWxsaXBzZSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjRkZGRkZGIiBkPSJNMjEsMTAuNUMyMSwxNi4zLDE2LjMsMjEsMTAuNSwyMVMwLDE2LjMsMCwxMC41UzQuNywwLDEwLjUsMFMyMSw0LjcsMjEsMTAuNXoKCQkJIi8+CgkJPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTEyLjIsMTUuOEw3LDEwLjVsNS4yLTUuMlYxNS44eiIvPgoJPC9nPgo8L2c+CjxwYXRoIGZpbGw9Im5vbmUiIGQ9InoiLz4KPC9zdmc+);
          background-repeat: no-repeat;
          background-position: center;

          @media screen and (max-width: 540px) {
            top: 20px;
            right: 12px;
          }
        }

        &.is-open:after {
          @include transform(rotate(-90deg));
        }
      }

      &__content {
        width: 100%;
        height: 0px;
        overflow: hidden;
        @include transition(all 0.4s ease-in-out);

        &.expanded {
          @include transition(all 0.4s ease-in-out);
        }

        ul li {
          border-top: 1px solid #ffffff4d;
          margin: 8px 0;
          display: flex;
          align-items: center;

          a {
            width: 100%;
            padding: 20px;
            color: color(white);
            font-size: rem(14);
            text-transform: uppercase;
          }
        }
      }
    }
  }

  &__responsive-menu {
    background: color(white);
    position: fixed;
    display: none;
    top: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;

    &.is-open {
      .mod-menu-mobile {
        background: color(ns-lighter);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 5%;

        .searcher {
          width: 100%;

          .search-input {
            padding: 5px 10px;
            border-radius: 20px;
            border: none;
            background: color(ns-light);
            width: 90%;
          }
        }

        .button {
          background: color(ns-light);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 28px;
          height: 28px;
          cursor: pointer;
        }

        .top-nav {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;

          .button-container {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .button {
              background: color(ns-light);
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              width: 30px;
              height: 30px;
              cursor: pointer;
              position: relative;
              color: color(ns-icon-light);
              font-size: rem(16);

              &.button-spanish {
                background-image: url(../assets/images/spanish.svg);
              }

              &.button-english {
                background-image: url(../assets/images/english.svg);
              }
            }

            .options {
              position: fixed;
              z-index: 9;
              background: color(white);
              white-space: nowrap;
              display: none;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              top: 110px;
              left: 50%;
              @include transform(translateX(-50%));
              @include box-shadow(5px 5px 0 #0000000d);
              border: 1px solid color(ns-border-ligth);
              text-transform: uppercase;
              font-size: rem(14);
              width: 96vw;

              &.active {
                display: flex;
                padding: 20px;
              }

              &::before {
                content: '';
                position: absolute;
                top: -16px;
                border-bottom: 16px solid color(ns-light);
                border-left: 16px solid transparent;
                border-right: 16px solid transparent;
                z-index: 4;
                right: 10px;
              }

              &::after {
                content: '';
                position: absolute;
                top: -15px;
                right: 10px;
                z-index: 5;
                border-bottom: 15px solid color(white);
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
              }

              li button {
                text-transform: uppercase;
              }

              li:first-child {
                margin-bottom: 20px;
              }
            }
          }
        }
      }

      @media screen and (max-width: 540px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .menu-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          height: calc(100vh - 100px);
          overflow-x: hidden;
          overflow-y: auto;

          ul,
          li {
            width: 100%;
            text-align: center;
          }

          .title {
            background: color(ns-lighter);
            color: color(ns-primary);
            margin-bottom: 20px;
            width: 100%;
            text-align: center;
            font-size: rem(17);
            text-align: center;
            padding: 10px 0;
            border-top: 1px solid color(ns-light);
            font-family: $ns-font-proxima;
            font-weight: 800;
            text-transform: uppercase;
          }

          .link {
            font-size: rem(16);
            text-transform: uppercase;
            margin-bottom: 20px;
            color: color(grey);

            & > a {
              color: color(ns-darker);
            }
          }
        }
      }
    }
  }

  &__rapid-access {
    display: none;
  }
}
@media print {
  * {
    /*Chrome, Firefox and Safari*/
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
    forced-color-adjust: exact !important;
  }
  #esiosLogo {
    width: 20px !important;
  }

  #innerarrowdd {
    stroke: #ffffff !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
    forced-color-adjust: exact !important;
  }
  .inner-arrow {
    fill: none !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
    forced-color-adjust: exact !important;
  }
}
