#widget {
  &2 {
    width: 560px;
    max-width: 560px;
    margin-left: 10px;
    margin-top: -39px;
    svg {
      width: 570px !important;
    }
    height: 375px;
    @media screen and (max-width: 540px) {
      width: 325px;
      max-width: 325px;
      height: 375px;
    }
  }

  &20 {
    & > svg {
      background-color: black;
    }
  }

  &21 {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    div#widget21Data {
      margin-top: -4px;
      margin-left: -8px;
    }
  }
}

#char {
  &19 {
    & div#demand {
      font-size: 0.75rem;
      font-weight: 600;
      background-color: white;
      border: 1px solid color(ns-light);
      text-align: center;
      padding-left: 0;
      padding-right: 0;
      line-height: 1.4375rem;
      padding: 0 1.5625rem;
      margin-top: 3px;
      margin-right: -3px;
    }
  }
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

.spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
