////
/// Slider component
///
/// Custom project
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}slider
/// @since version 1.0.0
////

.#{$ns}slider {
  background: color(ns-primary, 0.8);
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  position: relative;

  @media screen and (max-width: 540px) {
    display: none;
  }

  &__wrapper {
    max-width: 1080px;
    width: 92%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 255px 1fr;
    align-items: center;
    height: 65px;
    position: relative;

    @media screen and (max-width: 540px) {
      grid-template-columns: 1fr;
      justify-content: flex-start;
      height: auto;
      min-height: 65px;
      width: 100%;
      position: relative;
      margin-top: 10px;

      &::after {
        content: '';
        width: 1px;
        height: 80%;
        top: 14px;
        position: absolute;
        left: 66px;
        background: color(white);
      }

      @media screen and (max-width: 539px) {
        &::after {
          height: 81%;
          left: 60px;
          top: 15px;
          display: none;
        }
      }
    }

    h3 {
      font-size: 19px;
      color: color(white);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;
      font-family: $ns-font-proxima;
      font-weight: 800;

      @media screen and (max-width: 540px) {
        display: flex;
        justify-content: flex-start;
        padding: 20px 0 0 20px;
        font-size: 19px;
        border: none;
        gap: 10px;
      }

      @media screen and (max-width: 540px) {
        padding: 23px 0 0 6px;
      }

      img {
        margin-left: 0.9rem;
        margin-right: 0.9rem;

        @media screen and (max-width: 540px) {
          margin-right: 9px;
        }
      }
    }

    .carouselSlider {
      height: 65px;
      display: flex;
      align-items: center;

      @media screen and (max-width: 540px) {
        padding: 0;
        height: auto;
        display: flex;
        align-items: flex-start;
      }
    }
  }

  &__progress-mobileBar {
    position: absolute;
    background: color(skyblue-light);
    height: 4px;
    bottom: 0;
    display: none;

    @media (max-width: 540px) {
      display: block;
    }
  }

  &__progress-desktopBar {
    background: color(skyblue-light);
    height: 4px;

    @media screen and (max-width: 540px) {
      display: none;
    }

    &-wrapper {
      position: absolute;
      width: 5551px;
      top: 0;
    }
  }

  &__articles {
    overflow: hidden;
    border-left: 1px solid color(ns-secondary);
    height: 65px;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    position: relative;

    @media screen and (max-width: 768px) {
      margin: 0 0 0 70px;
      border-left: none;
      height: auto;
    }

    @media screen and (max-width: 539px) {
      margin: 0 0 0 50px;
      margin-left: 56px;
    }

    .article {
      display: grid !important;
      grid-template-columns: 130px 1fr 50px;
      align-items: center;
      color: color(white);
      padding: 0 10px;

      @media screen and (max-width: 540px) {
        grid-template-columns: 1fr 40px;
        padding-right: 20px;
        margin-bottom: 20px;
        margin-left: 7px;
      }

      time {
        font-size: 19px;
        font-weight: bold;
        line-height: 2.1875rem;
        letter-spacing: 0.5px;
        font-family: $ns-font-proxima;
        @media screen and (max-width: 540px) {
          grid-column: 1/2;
          grid-row: 1/2;
          font-size: 15px;
          font-weight: normal;
          letter-spacing: 0.1px;
          margin-bottom: 1px;
        }
      }

      .title {
        margin: 0;
        font-size: 19px;
        font-family: $ns-font-proxima;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: uppercase;
        height: fit-content;
        color: color(white);

        @media screen and (max-width: 540px) {
          grid-column: 1/2;
          grid-row: 2/3;
          font-family: $ns-font-proxima;
          font-weight: 800;
          white-space: initial;
          overflow: initial;
          text-overflow: initial;
          font-size: 1rem;
          width: 85%;
          padding-bottom: 10px;
          margin-top: -7px;
          line-height: 18px;
        }
      }

      a {
        color: color(white);
        text-indent: -9999;
        width: 40px;
        height: 40px;
        overflow: hidden;
        font-size: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 540px) {
          grid-column: 2/3;
          grid-row: 1/3;
          margin-left: 10px;
          margin-top: 22px;
        }
      }
    }

    .tns-liveregion,
    .tns-controls,
    .tns-nav {
      display: none;
    }
  }

  &__prev-button {
    content: '';
    position: absolute;
    color: color(white);
    height: 65px;
    left: -5px;
    background-image: url($ns-path-img + '/prev.svg');
    background-size: contain;
    display: block;
    width: rem(18);
    height: rem(30);
    bottom: auto;
    top: 50%;
    margin-top: -0.9375rem;
    cursor: pointer;

    @media screen and (max-width: 540px) {
      visibility: hidden;
    }
  }
  &__next-button {
    content: '';
    position: absolute;
    color: color(white);
    height: 65px;
    right: -5px;
    background-image: url($ns-path-img + '/next.svg');
    background-size: contain;
    display: block;
    width: 1.125rem;
    height: 1.875rem;
    bottom: auto;
    top: 50%;
    margin-top: -0.9375rem;
    @include transform(rotate(180deg));
    cursor: pointer;

    @media screen and (max-width: 540px) {
      visibility: hidden;
    }
  }
}
