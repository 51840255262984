.#{$ns}previsiones-os {
  //overflow: hidden !important;

  #mypPrecioWidgetView .chari-chart text.label,
  #mypTermicaWidgetView .chari-chart text.label,
  #mypFotovoltaicaWidgetView .chari-chart text.label,
  #myPrevisionDiariaD1Wiew .chari-chart text.label,
  #myPrevisionDiariaD1EolicaWiew .chari-chart text.label,
  #myPrevisionIntraDiariaH3DemandaWiew .chari-chart text.label,
  #myPrevisionIntraDiariaH3DemandaFotovolWiew .chari-chart text.label,
  #myDemandaPrevistaPeninsularWiew .chari-chart text.label,
  #myDemandaPrevistaSNPWiew .chari-chart text.label,
  #myDemandaDiariaElectricaPeninsularWiew .chari-chart text.label,
  #myDemandaMensualElectricaPeninsularWiew .chari-chart text.label,
  #myDemandaSemanalElectricaPeninsularWiew .chari-chart text.label,
  #myPrevisionMaximaAnualPeninsularWiew .chari-chart text.label,
  #myGeneracionPrevistaSolarWiew .chari-chart text.label,
  #myPrevisionMaximaAnualPeninsular2Wiew .chari-chart text.label,
  #myPrevisionMinimaAnualPeninsularWiew .chari-chart text.label,
  #myPrevisionMaximaMensualPeninsularWiew .chari-chart text.label,
  #myPrevisionMinimaMensualPeninsularWiew .chari-chart text.label,
  #myMargenGeneracionWiew .chari-chart text.label,
  #myPrevisonDemandaResidualWiew .chari-chart text.label,
  #mypFacturacionWidgetView .chari-chart text.label {
    font-size: 0.8125rem;
    fill: rgb(68, 68, 68);
    font-family: proxima-nova, Arial, sans-serif;
    letter-spacing: initial;
  }

  #mypPrecioWidgetView .chari-chart text,
  #mypTermicaWidgetView .chari-chart text,
  #mypFotovoltaicaWidgetView .chari-chart text,
  #myPrevisionDiariaD1Wiew .chari-chart text,
  #myPrevisionDiariaD1EolicaWiew .chari-chart text,
  #myPrevisionIntraDiariaH3DemandaWiew .chari-chart text,
  #myPrevisionIntraDiariaH3DemandaFotovolWiew .chari-chart text,
  #myDemandaPrevistaPeninsularWiew .chari-chart text,
  #myDemandaPrevistaSNPWiew .chari-chart text,
  #myDemandaDiariaElectricaPeninsularWiew .chari-chart text,
  #myDemandaMensualElectricaPeninsularWiew .chari-chart text,
  #myDemandaSemanalElectricaPeninsularWiew .chari-chart text,
  #myPrevisionMaximaAnualPeninsularWiew .chari-chart text,
  #myGeneracionPrevistaSolarWiew .chari-chart text,
  #myPrevisionMaximaAnualPeninsular2Wiew .chari-chart text,
  #myPrevisionMinimaAnualPeninsularWiew .chari-chart text,
  #myPrevisionMaximaMensualPeninsularWiew .chari-chart text,
  #myPrevisionMinimaMensualPeninsularWiew .chari-chart text,
  #myMargenGeneracionWiew .chari-chart text,
  #mypPrevisionSemanalDemandaWidgetView .chari-chart text,
  #mypPrevisionEolicaWidgetView .chari-chart text,
  #mypPrevisionFotovoltaicaWidgetView .chari-chart text,
  #myPrevisionSolarTerWidgetView .chari-chart text,
  #myPrevisonDemandaResidualWiew .chari-chart text,
  #mypFacturacionWidgetView .chari-chart text {
    font-size: 0.6875rem;
    fill: rgb(113, 113, 113);
    cursor: default;
    font-weight: 700;
    font-family: Cousine;
    letter-spacing: -0.05em;
  }

  &__container.wrap {
    width: 100%;
    #spinner6,
    #spinner7,
    #spinner8,
    #spinner9,
    #spinner5,
    #spinner4,
    #spinner3,
    #spinner2,
    #spinner1,
    #spinner13,
    #spinner14,
    #spinner15,
    #spinner16,
    #spinner17,
    #spinner18,
    #spinner19,
    #spinner20,
    #spinner21,
    #spinner22,
    #spinner23,
    #spinner24,
    #spinner25,
    #spinner26,
    #spinner27,
    #spinner28,
    #spinner29,
    #spinner30,
    #spinner31,
    #spinner32,
    #spinner33,
    #spinner10 {
      min-height: 0;
      height: 90%;
      z-index: 999;
      position: absolute;
      top: 4px;
      left: 0;
      .overlay {
        background: #fff;
        height: 100%;
        width: 100%;
        position: absolute;
        opacity: 0.8;
      }
    }

    .row.mb-5 {
      margin-bottom: 20px !important;

      @media screen and (max-width: 540px) {
        flex-direction: column;
      }
      #mypVolumenWidgetView {
        .esios-toolbar__items {
          margin-right: -2px;
        }
      }
      #mypPrecioWidgetView {
        padding-top: 41px;
        width: 47.5%;
        @media screen and (max-width: 540px) {
          width: 100%;
          margin-top: -21px;
          padding-left: 10px;
          .esios-toolbar-time {
            top: -1px;
          }
          .esios-listEnergyItems__list {
            margin-top: -35px;
            margin-left: -1px;
            margin-top: 0px !important;
            width: 91%;
          }
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        .mod-date#volumen {
          top: 8px;
          left: 0px;
        }
        .esios-toolbar {
          width: 100%;
        }
        #mypGraphic3.chari-chart.aspect.myp-aspect {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          @media screen and (max-width: 540px) {
            width: 100%;
          }
          svg {
            margin-top: -43px;
            background-color: transparent;

            .yaxis.left {
              .tick line {
                transform: scaleX(1.1);
              }
            }
            .zeroline {
              transform: scaleX(1.1);
            }
            .xaxis .label {
              transform: translate(-45px, 0) !important;
            }
          }
        }
        #mypGraphic4 .yaxis .label {
          font-size: 0.675rem !important;
        }
      }
      #mypFacturacionWidgetView {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic4 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #mypFotovoltaicaWidgetView {
        margin-top: 41px;
        padding-right: 0px;
        width: 40% !important;
        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic5 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #myPrevisionDiariaD1Wiew {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic30 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #myPrevisionDiariaD1EolicaWiew {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic31 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #myPrevisionIntraDiariaH3DemandaWiew {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic32 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #myPrevisionIntraDiariaH3DemandaFotovolWiew {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic33 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #myDemandaPrevistaPeninsularWiew {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic13 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #myDemandaPrevistaSNPWiew {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic14 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #myDemandaDiariaElectricaPeninsularWiew {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic15 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #myDemandaMensualElectricaPeninsularWiew {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic16 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #myDemandaSemanalElectricaPeninsularWiew {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic17 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #myPrevisionMaximaAnualPeninsularWiew {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic18 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #myGeneracionPrevistaSolarWiew {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic19 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #myPrevisionMaximaAnualPeninsular2Wiew {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic20 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #myPrevisionMinimaAnualPeninsularWiew {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic21 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #myPrevisionMaximaMensualPeninsularWiew {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic22 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #myPrevisionMinimaMensualPeninsularWiew {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic23 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #myMargenGeneracionWiew {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic24 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #myPrevisonDemandaResidualWiew {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic25 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #mypPrevisionSemanalDemandaWidgetView {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          height: 50px;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic26 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #mypPrevisionEolicaWidgetView {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic27 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #mypPrevisionFotovoltaicaWidgetView {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic28 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #myPrevisionSolarTerWidgetView {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic29 {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
      #mypTermicaWidgetView {
        margin-top: 41px;
        padding-right: 0px;

        @media screen and (max-width: 540px) {
          margin-top: -13px;
          padding-right: 10px;
          .esios-listEnergyItems__list {
            margin-left: 0px;
            .row {
              display: flex;
              flex-wrap: wrap;
              gap: 0 !important;
              .col-xs-4 {
                width: fit-content !important;
                margin-bottom: 10px;
              }
            }
          }
        }
        .esios-market-prices__title {
          width: 100%;
          max-width: 522px;
          @media screen and (max-width: 540px) {
            width: 90% !important;
          }
        }
        .esios-toolbar-time {
          top: 10px;
          z-index: 0;
        }
        .esios-toolbar__items {
          margin-right: -2px;
        }
        #mypGraphic6 {
          width: 100% !important;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-left: 0px !important;
            width: 100%;
            background-color: transparent;
            margin-top: -43px;

            .g-main {
              transform: scale(0.95) translate(45px, 92px);
            }
          }

          & + .#{$ns}listEnergyItems__list {
            & > .row > .col-xs-4:last-child {
              min-width: rem(190);
            }
          }
        }
      }
    }
    #mypSubastasWidgetView {
      margin-top: 100px !important;
      padding-bottom: 31px;
      width: 100%;
      #tabwithcontent {
        @media (min-width: 1200px) {
          .esios-tab .mod-tabs.ui-widget.ui-widget-content {
            width: 105% !important;
          }
          .esios-tab .row {
            margin: 0 10px !important;
          }
        }
        @media (min-width: 450px) {
          .esios-tab .mod-tabs.ui-widget.ui-widget-content {
            width: 95% !important;
          }
          .esios-tab .row {
            margin: 0px !important;
          }
        }
      }
      @media screen and (max-width: 540px) {
        margin-top: 0px !important;
        padding-bottom: 0px;
        .row {
          width: calc(100% + 40px);
        }
        #tabwithcontent {
          margin-top: 6px;
          margin-bottom: 30px;
          padding-left: 7px;
          width: 100%;
          .esios-tab {
            margin-left: 0 !important;
          }
          .mod-tabs.ui-widget.ui-widget-content {
            width: 95% !important;
          }
          .mod-tabs.ui-tabs .ui-tabs-nav:after {
            right: -20px !important;
          }

          .mod-tabs.ui-widget.ui-widget-content ul li a {
            font-size: 16px !important;
          }
        }
        .esios-tabla.simple tbody .esios-table__row td {
          padding-bottom: 16px !important;
          &:before {
            width: 130px !important;
          }
        }
        .esios-toolbar-time {
          left: -5px;
          top: 9px;
        }
      }

      .esios-toolbar {
        width: 100% !important;
      }

      .esios-toolbar__items {
        margin-right: -2px;
      }
      .esios-toolbar-time {
        right: 5px;
        top: 8px;
      }
      .esios-tab {
        margin-left: -32px;
        margin-top: -6px;
        @media screen and (max-width: 540px) {
          margin-left: 23px !important;
        }
        .mod-tabs .ui-widget-header {
          width: 96%;
        }
        .mod-tabs.ui-tabs .ui-tabs-nav .ui-tabs-active,
        li.ui-state-default.ui-corner-top {
          margin-right: 7px;
        }

        .mod-tabs.ui-tabs .ui-tabs-nav:after {
          right: -12px;
          @media screen and (max-width: 540px) {
            right: 0px;
          }
        }
      }
      .esios-tabla.simple {
        padding-top: 34px;
        padding-bottom: 0.625rem;
        padding-left: 4px;
        @media screen and (max-width: 540px) {
          padding: rem(0) rem(0) rem(30) rem(0) !important;
        }
        .esios-table {
          width: 94%;
          @media screen and (max-width: 540px) {
            display: block;
            margin: 0 -1.25rem;
            width: 99%;
            font-size: 11px;
            color: black !important;
            table-layout: fixed;
            border-spacing: 5px;
            border-collapse: collapse;
            margin-top: 0;
            margin-left: 0px;
          }
          tr {
            height: 37px !important;
            @media screen and (max-width: 540px) {
              display: block;
              height: auto !important;
              vertical-align: middle;
              font-size: 13px;
              color: black !important;
              align-items: center;
            }
          }
          th:nth-child(2) {
            padding-left: 0px;
            padding-right: 0px;
          }
        }
        tbody .esios-table__row td {
          padding-left: 20px;
          padding-right: 0px;
          padding-top: 2px;
          padding-bottom: 2px;
          @media screen and (max-width: 540px) {
            padding-bottom: 6px;
          }
        }
      }
      a#calendar-button {
        font-family: $ns-font-proxima;
        position: relative;
        left: -33px;
        padding-top: 5px;
        padding-bottom: 5px;
        @media screen and (max-width: 540px) {
          left: -8px;
        }
      }
      .table-description {
        margin-top: -15px;
        margin-left: 4px;
        margin-bottom: 12px;
        @media screen and (max-width: 540px) {
          width: 90%;
          margin-left: 16px;
          margin-bottom: 47px;
        }
      }
    }
  }

  .offset-md-1 {
    margin-left: 2% !important;
    width: 56%;
  }

  .volume-leyends.offset-1 {
    margin-left: 3% !important;
  }

  #mypCosteWidgetView {
    .volume-leyends.offset-1 {
      margin-left: 1px !important;
      margin-top: -12px;
      width: 45%;
    }
  }
  .mypCalendarRow {
    .spinner-container {
      position: absolute;
      z-index: 2;
    }
    .esios-tab .grid-sm-24,
    .esios-tabla.simple .esios-table,
    .esios-tab .mod-tabs .ui-widget-header {
      width: 99% !important;
    }
  }

  &__title {
    font-size: 1.4375rem;
    text-transform: uppercase;
    color: color(ns-primary);
    font-family: $ns-font-proxima;
    margin: rem(15) 0 rem(5) 0;
    font-weight: 800;
    line-height: 27.6px;
    margin-bottom: -3px;
  }

  span.esios-toolbar__items {
    margin-right: -2px;
  }

  &__subtitle {
    font-size: rem(19);
    // text-transform: uppercase;
    color: color(ns-primary);
    font-family: $ns-font-proxima;
    margin: rem(15) 0 rem(15) 0;
    font-weight: 700;
    margin-left: 1px;
  }

  .widget-summary-item.energia-total-mercado-de-produccion {
    margin-top: 29px;
    margin-left: 1px;
  }

  &__form {
    gap: 10px;
    margin-bottom: 0px;
    width: 98.5%;
    margin-left: 6px;
    margin-top: -64px;
    margin-bottom: 20px;

    &--no-peninsulares {
      margin-top: -46px;
    }

    select:disabled {
      opacity: 0.6;
    }
    .search-filter {
      position: absolute;
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgMjAgMjAiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgdmVyc2lvbj0iMS4xIgoJeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIKCXg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4Igo+Cgk8ZyBpZD0iTGF5ZXIlMjAxIj4KCQk8cGF0aCBkPSJNIDEyLjE4NzkgMTIuODM4OSBDIDEyLjc1MzUgMTIuMDY3OSAxMy44Mjg3IDExLjkzODMgMTQuNTU0MiAxMi41OTc0IEwgMTcuNDgyNCAxNS4yNTc5IEMgMTguMjA3NiAxNS45MTcyIDE4LjE1NzIgMTYuOTc4IDE3LjQxNiAxNy41ODk1IEwgMTcuNDE2IDE3LjU4OTUgQyAxNi43MjE3IDE4LjE2MjQgMTUuNjY3MSAxOC4xMjYxIDE1LjAyMjIgMTcuNTQwMiBMIDEyLjQxOTQgMTUuMTc1MiBDIDExLjc3NDggMTQuNTg5NCAxMS42NTc4IDEzLjU2MTMgMTIuMTg3OSAxMi44Mzg5IEwgMTIuMTg3OSAxMi44Mzg5IFoiIGZpbGw9IiM4ODg4ODgiLz4KCQk8cGF0aCBkPSJNIDMgOC43NzggQyAzIDUuODMyNSA1LjI5MTMgMy4yNTc5IDguMjMwOCAzLjAxODEgQyAxMS4zMDMgMi43Njc2IDEzLjg5NzUgNS4xNDI2IDEzLjg5NzUgOC4zMzM1IEMgMTMuODk3NSAxMS41MjQ0IDExLjMwMyAxNC4xMTExIDguMjMwOCAxNC4xMTExIEMgNS4yOTEzIDE0LjExMTEgMyAxMS43MjM0IDMgOC43NzggWk0gMSA4LjgwMDMgQyAxIDEyLjc3NjYgNC4xNTM4IDE2IDguMjAwMSAxNiBDIDEyLjQyODggMTYgMTYgMTIuNTA4IDE2IDguMjAwMiBDIDE2IDMuODkyNSAxMi40Mjg4IDAuNjg2MiA4LjIwMDEgMS4wMjQ1IEMgNC4xNTM4IDEuMzQ4MiAxIDQuODIzOCAxIDguODAwMyBaIiBmaWxsPSIjOGE4YThiIi8+Cgk8L2c+Cjwvc3ZnPg==);
      background-repeat: no-repeat;
      background-position: 95% 50%;
      width: 20px;
      height: 20px;
      right: 20px;
      top: 11px;
    }
    .esios-tabla__search-container {
      position: relative;
      &.d-flex {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
    .esios-tabla__dropdown2 {
      cursor: pointer;
      text-transform: uppercase !important;
    }
    .esios-tabla__dropdown2 option {
      text-transform: initial !important;
    }
    .esios-tabla__input {
      width: 100% !important;
      margin: 0 !important;
      height: 40px;
      font-size: 14px;
      font-family: $ns-font-proxima;
      font-weight: 600;
    }
    .esios-tabla__input::placeholder {
      text-transform: uppercase;
      color: #444444;
    }
    .market-select-filter {
      gap: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      select {
        margin-top: 0 !important;
        height: 40px;
        width: 100%;
      }
    }
  }
  .mp-second-graphs {
    height: 530px;
    gap: 10px;

    .aspect {
      min-height: 400px !important;
    }

    @media screen and (max-width: 540px) {
      height: auto;

      &.mb-5 {
        margin-bottom: 0 !important;
      }
    }
  }

  .mp-four-graphs {
    height: 530px;
    gap: 10px;

    .aspect {
      min-height: 400px !important;
    }

    @media screen and (max-width: 540px) {
      height: auto;

      &.mb-5 {
        margin-bottom: 0 !important;
      }
    }
  }

  .mp-five-graphs {
    height: 530px;
    gap: 10px;

    .aspect {
      min-height: 400px !important;
    }

    @media screen and (max-width: 540px) {
      height: auto;

      &.mb-5 {
        margin-bottom: 0 !important;
      }
    }
  }

  .mp-six-graphs {
    height: 530px;
    gap: 10px;

    .aspect {
      min-height: 400px !important;
    }

    @media screen and (max-width: 540px) {
      height: auto;

      &.mb-5 {
        margin-bottom: 0 !important;
      }
    }
  }

  .mp-seven-graphs {
    height: 530px;
    gap: 10px;

    .aspect {
      min-height: 400px !important;
    }

    @media screen and (max-width: 540px) {
      height: auto;

      &.mb-5 {
        margin-bottom: 0 !important;
      }
    }
  }
  .mp-eight-graphs {
    height: 530px;
    gap: 10px;

    .aspect {
      min-height: 400px !important;
    }

    @media screen and (max-width: 540px) {
      height: auto;

      &.mb-5 {
        margin-bottom: 0 !important;
      }
    }
  }
  .mp-nine-graphs {
    height: 530px;
    gap: 10px;

    .aspect {
      min-height: 400px !important;
    }

    @media screen and (max-width: 540px) {
      height: auto;

      &.mb-5 {
        margin-bottom: 0 !important;
      }
    }
  }
  .mp-ten-graphs {
    height: 530px;
    gap: 10px;

    .aspect {
      min-height: 400px !important;
    }

    @media screen and (max-width: 540px) {
      height: auto;

      &.mb-5 {
        margin-bottom: 0 !important;
      }
    }
  }
  .mp-eleven-graphs {
    height: 530px;
    gap: 10px;

    .aspect {
      min-height: 400px !important;
    }

    @media screen and (max-width: 540px) {
      height: auto;

      &.mb-5 {
        margin-bottom: 0 !important;
      }
    }
  }
  .mp-twelve-graphs {
    height: 530px;
    gap: 10px;

    .aspect {
      min-height: 400px !important;
    }

    @media screen and (max-width: 540px) {
      height: auto;

      &.mb-5 {
        margin-bottom: 0 !important;
      }
    }
  }

  .mp-thirteen-graphs {
    height: 530px;
    gap: 10px;

    .aspect {
      min-height: 400px !important;
    }

    @media screen and (max-width: 540px) {
      height: auto;

      &.mb-5 {
        margin-bottom: 0 !important;
      }
    }
  }

  .mp-fourteen-graphs {
    height: 530px;
    gap: 10px;

    .aspect {
      min-height: 400px !important;
    }

    @media screen and (max-width: 540px) {
      height: auto;

      &.mb-5 {
        margin-bottom: 0 !important;
      }
    }
  }
  .mp-fifteen-graphs {
    height: 400px !important;
    gap: 10px;

    .aspect {
      min-height: 400px !important;
    }

    @media screen and (max-width: 540px) {
      height: auto;

      &.mb-5 {
        margin-bottom: 0 !important;
      }
    }
  }
  .mp-sixteen-graphs {
    height: 530px;
    gap: 10px;

    .aspect {
      min-height: 400px !important;
    }

    @media screen and (max-width: 540px) {
      height: auto;

      &.mb-5 {
        margin-bottom: 0 !important;
      }
    }
  }
  .mp-seventeen-graphs {
    height: 530px;
    gap: 10px;

    .aspect {
      min-height: 400px !important;
    }

    @media screen and (max-width: 540px) {
      height: auto;

      &.mb-5 {
        margin-bottom: 0 !important;
      }
    }
  }
  .mp-eighteen-graphs {
    height: 530px;
    gap: 10px;

    .aspect {
      min-height: 400px !important;
    }

    @media screen and (max-width: 540px) {
      height: auto;

      &.mb-5 {
        margin-bottom: 0 !important;
      }
    }
  }
  .mp-nineteen-graphs {
    height: 530px;
    gap: 10px;

    .aspect {
      min-height: 400px !important;
    }

    @media screen and (max-width: 540px) {
      height: auto;

      &.mb-5 {
        margin-bottom: 0 !important;
      }
    }
  }

  @media screen and (max-width: 1070px) {
    #mypVolumenWidgetView {
      padding-right: 0px;
    }

    .volume-leyends.offset-1.col-xs-5 {
      margin: 0px !important;
      padding-right: 0px;
    }

    #widget6 .row {
      margin-right: 0px;
      display: flex;
      justify-content: space-between;
    }
  }

  .mypPrecioWidgetView,
  .mypTermicaWidgetView,
  .mypFotovoltaicaWidgetView,
  .myPrevisionDiariaD1EolicaWiew,
  .myPrevisionIntraDiariaH3DemandaWiew,
  .myPrevisionIntraDiariaH3DemandaFotovolWiew,
  .myPrevisionDiariaD1Wiew,
  .myDemandaPrevistaPeninsularWiew,
  .myDemandaPrevistaSNPWiew,
  .myDemandaDiariaElectricaPeninsularWiew,
  .myDemandaMensualElectricaPeninsularWiew,
  .myDemandaSemanalElectricaPeninsularWiew,
  .myPrevisionMaximaAnualPeninsularWiew,
  .myGeneracionPrevistaSolarWiew,
  .myPrevisionMaximaAnualPeninsular2Wiew,
  .myPrevisionMinimaAnualPeninsularWiew,
  .myPrevisionMaximaMensualPeninsularWiew,
  .myPrevisionMinimaMensualPeninsularWiew,
  .myMargenGeneracionWiew,
  .mypPrevisionSemanalDemandaWidgetView,
  .mypPrevisionEolicaWidgetView,
  .mypPrevisionFotovoltaicaWidgetView,
  .myPrevisionSolarTerWidgetView,
  .myPrevisonDemandaResidualWiew,
  .mypFacturacionWidgetView {
    .esios-listEnergyItems__list {
      @media screen and (max-width: 540px) {
        .row {
          display: grid;
          gap: rem(20);
          grid-template-columns: 1fr 1fr;

          .legend-resume {
            display: none;
          }
        }
      }
    }
  }
  .mypPrecioWidgetView .esios-listEnergyItems__list,
  .mypTermicaWidgetView .esios-listEnergyItems__list,
  .mypFotovoltaicaWidgetView .esios-listEnergyItems__list,
  .myPrevisionDiariaD1EolicaWiew .esios-listEnergyItems__list,
  .myPrevisionIntraDiariaH3DemandaWiew .esios-listEnergyItems__list,
  .myPrevisionIntraDiariaH3DemandaFotovolWiew .esios-listEnergyItems__list,
  .myPrevisionDiariaD1Wiew .esios-listEnergyItems__list,
  .myDemandaPrevistaPeninsularWiew .esios-listEnergyItems__list,
  .myDemandaPrevistaSNPWiew .esios-listEnergyItems__list,
  .myDemandaDiariaElectricaPeninsularWiew .esios-listEnergyItems__list,
  .myDemandaMensualElectricaPeninsularWiew .esios-listEnergyItems__list,
  .myDemandaSemanalElectricaPeninsularWiew .esios-listEnergyItems__list,
  .myPrevisionMaximaAnualPeninsularWiew .esios-listEnergyItems__list,
  .myGeneracionPrevistaSolarWiew .esios-listEnergyItems__list,
  .myPrevisionMaximaAnualPeninsular2Wiew .esios-listEnergyItems__list,
  .myPrevisionMinimaAnualPeninsularWiew .esios-listEnergyItems__list,
  .myPrevisionMaximaMensualPeninsularWiew .esios-listEnergyItems__list,
  .myPrevisionMinimaMensualPeninsularWiew .esios-listEnergyItems__list,
  .myMargenGeneracionWiew .esios-listEnergyItems__list,
  .myPrevisonDemandaResidualWiew .esios-listEnergyItems__list,
  .mypPrevisionSemanalDemandaWidgetView .esios-listEnergyItems__list,
  .mypPrevisionEolicaWidgetView .esios-listEnergyItems__list,
  .mypPrevisionFotovoltaicaWidgetView .esios-listEnergyItems__list,
  .myPrevisionSolarTerWidgetView .esios-listEnergyItems__list,
  .mypFacturacionWidgetView .esios-listEnergyItems__list {
    position: relative;
    .esios-listEnergyItems__decorator {
      display: inline-block;
      margin-right: 2px;
      height: 10px;
      width: 10px;
      border-radius: 1.5625rem;
      margin-top: 2px;
      margin-left: 1px;
    }
  }
  .mypFacturacionWidgetView .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .myPrevisionDiariaD1Wiew .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .myPrevisionDiariaD1EolicaWiew .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .myPrevisionIntraDiariaH3DemandaWiew .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .myPrevisionIntraDiariaH3DemandaFotovolWiew .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .myDemandaPrevistaPeninsularWiew .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .myDemandaPrevistaSNPWiew .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .myDemandaDiariaElectricaPeninsularWiew .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .myDemandaMensualElectricaPeninsularWiew .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .myDemandaSemanalElectricaPeninsularWiew .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .myPrevisionMaximaAnualPeninsularWiew .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .myGeneracionPrevistaSolarWiew .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .myPrevisionMaximaAnualPeninsular2Wiew .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .myPrevisionMinimaAnualPeninsularWiew .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .myPrevisionMaximaMensualPeninsularWiew .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .myPrevisionMinimaMensualPeninsularWiew .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .myMargenGeneracionWiew .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .myPrevisonDemandaResidualWiew .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .mypPrevisionSemanalDemandaWidgetView .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .mypPrevisionEolicaWidgetView .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .mypPrevisionFotovoltaicaWidgetView .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .myPrevisionSolarTerWidgetView .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .mypFotovoltaicaWidgetView .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }
  .mypTermicaWidgetView .esios-listEnergyItems__list {
    &.no-data {
      margin-top: 20px !important;
    }
  }

  .chari-chart {
    aspect-ratio: 4/4;
    max-height: 250px;
    min-width: 230px;

    // svg {
    //   width: auto;
    //   height: auto;
    // }

    @media screen and (max-width: 540px) {
      aspect-ratio: 5/5 !important;
      position: relative;
      margin-top: -2px;
      margin-bottom: 7px;
      width: 100%;

      .chartLegend {
        left: 48% !important;
      }
    }
  }

  @media screen and (max-width: 540px) {
    .esios-layout__breadcrumbs {
      display: inline !important;
    }

    &__container.wrap {
      width: 100%;
    }

    #mypVolumenWidgetVie {
      #mypVolumenWidgetViewSelector #select_volumen {
        left: -15px;
      }
    }

    #total-volume.is-big-number {
      color: #444444;
      font-size: 38px;
      letter-spacing: -3.2px;
    }

    .widget-summary-item.energia-total-mercado-de-produccion {
      margin-top: 2px;
      margin-left: 1px;
    }

    #mypCosteWidgetViewSelector {
      #select_cost {
        left: 14px;
      }
    }

    .esios-table__head {
      display: inline;
    }
    &__title {
      line-height: rem(27);
    }
    .esios-toolbar__wrapper {
      top: -8px;
    }
    &__subtitle {
      margin-top: rem(9);
    }
    .esios-listEnergyItems {
      &__item-with-value {
        margin-bottom: rem(5);
        .esios-listEnergyItems__decorator {
          margin-top: 3px;
        }
      }
    }
    .esios-tab .mod-tabs.ui-widget.ui-widget-content {
      margin-top: -13px;
      margin-left: -20px;
      ul li a {
        font-size: 19px;
        font-weight: 600;
      }
    }
    #mypVolumenWidgetView {
      border-bottom: 1px solid color(ns-light);
    }
    #mypPrecioWidgetView {
      margin-top: 21px;
      width: 100%;
    }
    #mypFacturacionWidgetView {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }
    #myPrevisionDiariaD1Wiew {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }
    #myPrevisionDiariaD1EolicaWiew {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }
    #myPrevisionIntraDiariaH3DemandaWiew {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }
    #myPrevisionIntraDiariaH3DemandaFotovolWiew {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }
    #myDemandaPrevistaPeninsularWiew {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }
    #myDemandaPrevistaSNPWiew {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }
    #myDemandaDiariaElectricaPeninsularWiew {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }
    #myDemandaSemanalElectricaPeninsularWiew {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }
    #myPrevisionMaximaAnualPeninsularWiew {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }
    #myGeneracionPrevistaSolarWiew {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }
    #myPrevisionMaximaAnualPeninsular2Wiew {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }
    #myPrevisionMinimaAnualPeninsularWiew {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }
    #myPrevisionMaximaMensualPeninsularWiew {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }
    #myPrevisionMinimaMensualPeninsularWiew {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }
    #myPrevisionIntraDiariaH3DemandaFotovolWiew {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }
    #myMargenGeneracionWiew {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }
    #myPrevisonDemandaResidualWiew {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }
    #mypPrevisionSemanalDemandaWidgetView {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }
    #mypPrevisionEolicaWidgetView {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }
    #mypPrevisionFotovoltaicaWidgetView {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }
    #myPrevisionSolarTerWidgetView {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }
    #mypFotovoltaicaWidgetView {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
      width: 47% !important;
    }
    #mypTermicaWidgetView {
      border-bottom: 1px solid color(ns-light);
      .esios-toolbar-time {
        left: 8px;
      }
    }

    #mypSubastasWidgetView {
      margin-top: 44px;
    }
    .widget-summary {
      padding-bottom: rem(15);
      margin-bottom: rem(-8);
      .label {
        margin-bottom: rem(-2);
      }
    }
    .chari-chart.aspect {
      aspect-ratio: 8/8 !important;
      // max-height: 300px !important;
      min-height: initial !important;
      min-height: 350px !important;
      .g-main {
        rect {
          transform: initial !important;
        }
      }
      svg {
        top: 0 !important;
        left: 0 !important;
        transform: inherit !important;
        // max-height: 430px !important;
      }
      &.supply {
        .xaxis .label {
          transform: translate(-90px, 0) !important;
        }
      }
    }
  }
  .chari-chart .chartLegend {
    width: 120px;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 55% !important;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .esios-listEnergyItems__label {
    font-family: $ns-font-proxima;
    display: grid;
    align-items: flex-start;
    grid-template-columns: 15px 1fr;
    margin-bottom: rem(4);
    font-size: rem(14);
  }

  .widget-myp-volumen .widget-legend ul {
    margin: 0 !important;
  }

  .chari-chart .chartLegend h3.title,
  .chari-chart .chartLegend .title.h3,
  .chari-chart .chartLegendR h3.title,
  .chari-chart .chartLegendR .title.h3 {
    font-weight: 600 !important;
    font-size: 14px !important;
    font-family: $ns-font-proxima;
    line-height: 19.2px;
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid color(ns-light);
    .pagination-container {
      display: flex;
      margin-top: 10px;
      gap: 5px;

      .prev::after {
        content: '';
        display: block;
        position: relative;
        top: 0;
        left: 50%;
        width: 0;
        height: 0;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 5px solid #dbdbdb;
      }

      .next::after {
        content: '';
        display: block;
        position: relative;
        top: 0;
        left: 50%;
        width: 0;
        height: 0;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid #dbdbdb;
      }
    }
  }

  .border-top {
    border-top: 1px solid #333;
  }
  .widget-summary-item .label {
    text-transform: uppercase;
    color: color(grey);
    font-weight: 600;
    font-size: rem(14);
    font-family: $ns-font-proxima;
  }
  .widget-summary-item .value {
    line-height: 1;
    color: color(ns-main-bg);
    font-family: $ns-font-cousine;
  }

  .is-big-number {
    color: color(ns-main-bg);
    font-size: rem(38);
  }
  .is-bold {
    font-weight: 800 !important;
  }
  .is-medium-bold {
    font-weight: 500 !important;
  }
  .is-small-number {
    color: color(ns-main-bg);
    font-size: rem(23);
  }

  .unit {
    margin-left: 5px;
    font-size: rem(13);
    font-family: $ns-font-proxima;
    color: color(ns-main-bg);
    font-weight: 600;
  }

  .table-summary thead tr:first-child th,
  .table-summary tbody tr th {
    color: color(grey);
    font-size: rem(14) !important;
    text-transform: uppercase;
    padding-bottom: 34px;
    padding-left: 0px;
    padding-right: 9px;
    font-weight: 600;
    font-family: $ns-font-proxima;
    line-height: normal;

    .unit {
      font-size: rem(12) !important;
      padding-left: 0 !important;
      margin-left: 3px !important;
      color: #717171;
      font-family: $ns-font-proxima;
      text-transform: capitalize;
    }
  }
  .table-summary table {
    width: 102%;
    position: relative;
    left: -7px;
    bottom: 22px;
    margin-bottom: -21px;
  }
  .table-summary thead tr:first-child th {
    padding-bottom: 0;
    text-align: right;
    width: 117px;
  }
  .table-summary thead tr th:first-child {
    width: 80px;
  }

  .table-summary thead tr th:nth-child(2) {
    width: 117px;
    padding-left: 0px;
    padding-right: 4px;
    padding-top: 21px;
  }

  .table-summary thead tr th:nth-child(3) {
    width: 160px;
    padding-right: 5px;
    padding-left: 27px;
    padding-top: 21px;
    line-height: normal;
  }
  .table-summary thead tr th:nth-child(4) {
    width: 190px;
    padding-left: 16px;
    padding-right: 2px;
  }

  .table-summary thead tr,
  .table-summary tbody tr,
  .table-summary tbody td {
    vertical-align: top;
  }
  .table-summary tbody td {
    text-align: right;
    padding-left: 28px;
    letter-spacing: -1.6px;
  }
  .table-summary th {
    width: 50%;
  }

  .mypPrecioWidgetView .esios-listEnergyItems__label,
  .mypTermicaWidgetView .esios-listEnergyItems__label,
  .mypFotovoltaicaWidgetView .esios-listEnergyItems__label,
  .myPrevisionDiariaD1EolicaWiew .esios-listEnergyItems__label,
  .myPrevisionIntraDiariaH3DemandaWiew .esios-listEnergyItems__label,
  .myPrevisionIntraDiariaH3DemandaFotovolWiew .esios-listEnergyItems__label,
  .myPrevisionDiariaD1Wiew .esios-listEnergyItems__label,
  .myDemandaPrevistaPeninsularWiew .esios-listEnergyItems__label,
  .myDemandaPrevistaSNPWiew .esios-listEnergyItems__label,
  .myDemandaDiariaElectricaPeninsularWiew .esios-listEnergyItems__label,
  .myDemandaMensualElectricaPeninsularWiew .esios-listEnergyItems__label,
  .myDemandaSemanalElectricaPeninsularWiew .esios-listEnergyItems__label,
  .myPrevisionMaximaAnualPeninsularWiew .esios-listEnergyItems__label,
  .myGeneracionPrevistaSolarWiew .esios-listEnergyItems__label,
  .myPrevisionMaximaAnualPeninsular2Wiew .esios-listEnergyItems__label,
  .myPrevisionMinimaAnualPeninsularWiew .esios-listEnergyItems__label,
  .myPrevisionMaximaMensualPeninsularWiew .esios-listEnergyItems__label,
  .myPrevisionMinimaMensualPeninsularWiew .esios-listEnergyItems__label,
  .myMargenGeneracionWiew .esios-listEnergyItems__label,
  .myPrevisonDemandaResidualWiew .esios-listEnergyItems__label,
  .mypPrevisionSemanalDemandaWidgetView .esios-listEnergyItems__label,
  .mypPrevisionEolicaWidgetView .esios-listEnergyItems__label,
  .mypPrevisionFotovoltaicaWidgetView .esios-listEnergyItems__label,
  .myPrevisionSolarTerWidgetView .esios-listEnergyItems__label,
  .mypFacturacionWidgetView .esios-listEnergyItems__label {
    display: grid;
    grid-template-columns: 10px 1fr;
    align-items: flex-start;
    gap: 5px;
  }
  .mypPrecioWidgetView .esios-listEnergyItems__label .label,
  .mypFacturacionWidgetView .esios-listEnergyItems__label .label,
  .mypTermicaWidgetView .esios-listEnergyItems__label .label,
  .mypFotovoltaicaWidgetView .esios-listEnergyItems__label .label {
    font-size: rem(14);
    text-transform: uppercase;
    color: color(grey) !important;
    font-family: $ns-font-proxima;
    font-weight: 600;
  }
  .mypPrecioWidgetView .esios-listEnergyItems__label .value,
  .mypTermicaWidgetView .esios-listEnergyItems__label .value,
  .myPrevisionDiariaD1EolicaWiew .esios-listEnergyItems__label .value,
  .myPrevisionIntraDiariaH3DemandaWiew .esios-listEnergyItems__label .value,
  .myPrevisionIntraDiariaH3DemandaFotovolWiew
    .esios-listEnergyItems__label
    .value,
  .myDemandaPrevistaPeninsularWiew .esios-listEnergyItems__label .value,
  .myDemandaPrevistaSNPWiew .esios-listEnergyItems__label .value,
  .myDemandaDiariaElectricaPeninsularWiew .esios-listEnergyItems__label .value,
  .myDemandaMensualElectricaPeninsularWiew .esios-listEnergyItems__label .value,
  .myDemandaSemanalElectricaPeninsularWiew .esios-listEnergyItems__label .value,
  .myPrevisionMaximaAnualPeninsularWiew .esios-listEnergyItems__label .value,
  .myGeneracionPrevistaSolarWiew .esios-listEnergyItems__label .value,
  .myPrevisionMaximaAnualPeninsular2Wiew .esios-listEnergyItems__label .value,
  .myPrevisionMinimaAnualPeninsularWiew .esios-listEnergyItems__label .value,
  .myPrevisionMaximaMensualPeninsularWiew .esios-listEnergyItems__label .value,
  .myPrevisionMinimaMensualPeninsularWiew .esios-listEnergyItems__label .value,
  .myMargenGeneracionWiew .esios-listEnergyItems__label .value,
  .myPrevisonDemandaResidualWiew .esios-listEnergyItems__label .value,
  .mypFotovoltaicaWidgetView .esios-listEnergyItems__label .value,
  .myPrevisionDiariaD1Wiew .esios-listEnergyItems__label .value,
  .mypPrevisionSemanalDemandaWidgetView .esios-listEnergyItems__label .value,
  .mypPrevisionEolicaWidgetView .esios-listEnergyItems__label .value,
  .mypPrevisionFotovoltaicaWidgetView .esios-listEnergyItems__label .value,
  .myPrevisionSolarTerWidgetView .esios-listEnergyItems__label .value,
  .mypFacturacionWidgetView .esios-listEnergyItems__label .value {
    grid-column: 2/3;
    display: flex;
    flex-wrap: wrap;
    margin-top: 4px;
  }
  .mypPrecioWidgetView .esios-listEnergyItems__label .value .caption,
  .mypTermicaWidgetView .esios-listEnergyItems__label .value .caption,
  .myPrevisionDiariaD1Wiewue .esios-listEnergyItems__label .value .caption,
  .mypFotovoltaicaWidgetView .esios-listEnergyItems__label .value .caption,
  .myPrevisionDiariaD1EolicaWiew .esios-listEnergyItems__label .value .caption,
  .myPrevisionIntraDiariaH3DemandaWiew
    .esios-listEnergyItems__label
    .value
    .caption,
  .myPrevisionIntraDiariaH3DemandaFotovolWiew
    .esios-listEnergyItems__label
    .value
    .caption,
  .myDemandaPrevistaPeninsularWiew
    .esios-listEnergyItems__label
    .value
    .caption,
  .myDemandaPrevistaSNPWiew .esios-listEnergyItems__label .value .caption,
  .myDemandaDiariaElectricaPeninsularWiew
    .esios-listEnergyItems__label
    .value
    .caption,
  .myDemandaMensualElectricaPeninsularWiew
    .esios-listEnergyItems__label
    .value
    .caption,
  .myDemandaSemanalElectricaPeninsularWiew
    .esios-listEnergyItems__label
    .value
    .caption,
  .myPrevisionMaximaAnualPeninsularWiew
    .esios-listEnergyItems__label
    .value
    .caption,
  .myGeneracionPrevistaSolarWiew .esios-listEnergyItems__label .value .caption,
  .myPrevisionMaximaAnualPeninsular2Wiew
    .esios-listEnergyItems__label
    .value
    .caption,
  .myPrevisionMinimaAnualPeninsularWiew
    .esios-listEnergyItems__label
    .value
    .caption,
  .myPrevisionMaximaMensualPeninsularWiew
    .esios-listEnergyItems__label
    .value
    .caption,
  .myPrevisionMinimaMensualPeninsularWiew
    .esios-listEnergyItems__label
    .value
    .caption,
  .myMargenGeneracionWiew .esios-listEnergyItems__label .value .caption,
  .mypPrevisionSemanalDemandaWidgetView
    .esios-listEnergyItems__label
    .value
    .caption,
  .mypPrevisionEolicaWidgetView .esios-listEnergyItems__label .value .caption,
  .mypPrevisionFotovoltaicaWidgetView
    .esios-listEnergyItems__label
    .value
    .caption,
  .myPrevisionSolarTerWidgetView .esios-listEnergyItems__label .value .caption,
  .myPrevisonDemandaResidualWiew .esios-listEnergyItems__label .value .caption,
  .mypFacturacionWidgetView .esios-listEnergyItems__label .value .caption {
    font-size: rem(23);
    font-family: $ns-font-cousine;
    color: color(ns-main-bg) !important;
    letter-spacing: -0.145rem;
  }

  .legend-resume.up .direction-arrow {
    border-bottom: 1.25rem solid #74ba05;
    border-left: 1.25rem solid transparent;
    border-right: 1.25rem solid transparent;
  }
  .legend-resume.down .direction-arrow {
    border-top: 1.25rem solid #df4a32;
    border-left: 1.25rem solid transparent;
    border-right: 1.25rem solid transparent;
  }

  .legend-resume {
    text-align: center;
    text-transform: uppercase;
    font-size: rem(14);
  }
  .legend-resume .direction-arrow {
    width: 0.0625rem;
    margin: 0.625rem auto 0;
  }
  .legend-resume {
    color: color(grey);
  }
  .legend-resume.up {
    color: #85b200;
  }
  .legend-resume.down {
    color: #df4a32;
  }

  &__btn-info {
    border: 1px solid color(ns-light);
    background-color: color(white);
    padding: 0.4375rem 1.25rem;
    border-radius: 1.25rem;
    text-transform: uppercase;
    margin: 0 rem(8);
    font-size: 0.875rem;
    font-weight: 700;
    font-family: $ns-font-default;
    color: color(ns-primary);
    width: fit-content;
    margin-top: 10px !important;
    cursor: pointer;
  }
  &__btn-info:hover {
    text-decoration: none;
    color: color(ns-primary);
  }
  .center {
    margin: 0 auto;
  }
  .mypCosteWidgetView .table-summary tbody td {
    font-family: $ns-font-cousine !important;
  }

  .mypSubastasWidgetView .esios-tabla.simple tbody .esios-table__row td {
    font-size: 13px !important;
    font-family: $ns-font-proxima;
  }
  .mypSubastasWidgetView .esios-table th {
    vertical-align: middle;
  }
  .mypSubastasWidgetView .esios-table__cell {
    font-weight: 800;
    font-size: 1rem !important;
    align-items: left;
    justify-content: flex-start;
  }

  .chari-chart .bargrid line:last-child {
    stroke-dasharray: none;
    stroke: rgb(113, 113, 113);
  }

  #serie-686-dots circle {
    fill: #02669c !important;
  }

  #serie-687-dots circle {
    fill: #666666 !important;
  }

  @media screen and (max-width: 540px) {
    #widget7 > div {
      flex-direction: column;
    }

    #mypCosteWidgetViewSelector #select_cost {
      left: 0px;
    }

    #mypGraphic2.chari-chart.aspect.myp-aspect {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      @media screen and (max-width: 540px) {
        width: 100%;
      }
      svg {
        margin-top: -43px;
        background-color: transparent;

        .yaxis.left {
          .tick line {
            transform: scaleX(1.1);
          }
        }
        .zeroline {
          transform: scaleX(1.1);
        }
        .xaxis .label {
          transform: translate(-45px, 0) !important;
        }
      }
    }

    #infoWin {
      margin-left: 10px;
    }

    .volume-leyends {
      margin-left: 0px !important;
    }

    .offset-md-1 {
      margin-left: 0% !important;
      width: 100%;
    }
    .cost {
      flex-direction: column;
      .volume-leyends {
        margin-left: 0 !important;
      }
    }
    #mypCosteWidgetView {
      .volume-leyends.offset-1 {
        width: 100%;
        margin-top: -10px;
        margin-left: 0px !important;
      }
    }

    .volume-leyends.offset-1 {
      margin-left: 0% !important;
    }
    .mypSubastasWidgetView .esios-tabla.simple tbody {
      display: block;
    }
    .mypSubastasWidgetView .esios-tabla.simple tbody .esios-table__row td {
      font-size: 0.875rem !important;
    }
    .mypSubastasWidgetView .esios-table th {
      display: none;
    }
  }
}

.myp-boxes .esios-boxdoc {
  margin-top: 2px;
  margin-left: 21px;
  width: 97.7%;
  height: 435px;
  @media screen and (max-width: 1132px) {
    height: 510px;
  }
  @media screen and (max-width: 540px) {
    width: 100%;
    padding-left: 21px;
    padding-right: 41px;
    margin-left: -12px;
    margin-bottom: -15px !important;
    &__list {
      margin-top: 0px !important;
      margin-bottom: 28px;
    }
    ul {
      margin-top: -1px !important;
    }
    &__footer {
      display: flex;
      flex-direction: column;
      gap: 32px !important;
      width: 100%;
      margin-left: 0px;
      justify-content: space-around;
      margin-top: 57px;
    }
  }
}

@media screen and (max-width: 768px) {
  .esios-boxdoc {
    margin-left: 0px !important;
    width: 100% !important;
  }
  .esios-boxmarket {
    width: 100% !important;
  }
}

aside#currentNews {
  .myp-boxes .esios-boxdoc__footer {
    @media screen and (max-width: 540px) {
      width: 77%;
      position: relative;
      left: 15%;
    }
  }
  @media screen and (max-width: 1132px) {
    .myp-boxes {
      .esios-boxmarket {
        height: 510px;
      }
    }
  }

  @media screen and (max-width: 540px) {
    .myp-boxes {
      .esios-boxmarket {
        height: auto !important;
      }
    }
  }
}
.myp-boxes .esios-boxdoc ul {
  flex-direction: column;
}
.myp-boxes .esios-boxdoc__list li {
  width: 100%;
}
.myp-boxes .esios-boxdoc__footer {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.myp-boxes .esios-boxdoc__footer button {
  width: fit-content;
  margin: 0;
}
.myp-boxes .esios-boxmarket__btn {
  width: 100% !important;
  font-size: 14px;
  font-weight: 700;
  font-family: $ns-font-proxima;
  color: #006699;
  vertical-align: baseline;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: rem(7) rem(20);
  text-decoration: none;
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.thin {
  width: 55% !important;
}

html:lang(es) .#{$ns}market-prices {
  #tabwithcontent {
    .ui-tabs-anchor {
      &:before {
        content: 'sentido';
      }
      @media screen and (max-width: 540px) {
        &:before {
          content: '';
        }
      }
    }
  }
}

html:lang(en) .#{$ns}market-prices {
  #tabwithcontent {
    .ui-tabs-anchor {
      &:before {
        content: 'direction';
      }
      @media screen and (max-width: 540px) {
        &:before {
          content: '';
        }
      }
    }
  }
}

#table-summary-mobile-ap,
#table-summary-mobile-energy,
#table-summary-mobile-market {
  display: none;
}

@media screen and (max-width: 540px) {
  .esios-market-prices {
    #widget6 .row {
      justify-content: center;
    }

    .row.mb-5 {
      --bs-gutter-x: 0;
    }

    #mypGraphic1.chari-chart.aspect.myp-aspect {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      @media screen and (max-width: 540px) {
        width: 100%;
      }
      svg {
        margin-top: -43px;
        background-color: transparent;

        .yaxis.left {
          .tick line {
            transform: scaleX(1.1);
          }
        }
        .zeroline {
          transform: scaleX(1.1);
        }
        .xaxis .label {
          transform: translate(-45px, 0) !important;
        }
      }
    }
  }
  #table-summary-laptop {
    display: none;
  }
  #table-summary-mobile-ap,
  #table-summary-mobile-energy,
  #table-summary-mobile-market {
    display: block;
    left: 0px;
    width: 95%;
    margin-bottom: 5px !important;
    tbody tr {
      margin-bottom: 0.2rem;
    }
    tbody tr th {
      text-align: right;
      vertical-align: middle;
      padding-bottom: 0px !important;
      color: color(ns-darker) !important;
    }
    .th-title {
      color: color(ns-main-bg) !important;
    }
  }
}

.esios-layout__wrap--mercados-y-precios {
  margin-top: -1px !important;
  // padding-bottom: 200px;
  padding-bottom: 30px;
}

/*.row-previsiones {
  background-color: #3385ad;
  height: 70px;
  align-content: center;
  text-align: center;
}*/

.menu {
  color: #fff;
}

.row-previsiones ul,
.row-previsionesli {
  margin: 0;
  padding: 0;
}
.row-previsiones ul {
  background: gray;
  list-style: none;
  width: 100%;
}
.row-previsiones li {
  float: left;
  position: relative;
  width: auto;
}
.row-previsiones a {
  background: #30a6e6;
  color: #ffffff;
  display: block;
  font: bold 12px/20px sans-serif;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.row-previsiones li:hover a {
  background: #000000;
}

.row-previsiones-active {
  background-color: #006699;
  height: 50px;
  align-content: center;
  text-align: center;
}

/* Style the tab */
.tab {
  width: 100%;
  overflow: hidden;
  // border: 1px solid #ccc;
  // background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  //background-color: inherit;
  background-color: #3385ad;
  color: white;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #006699;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #006699;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  //border: 1px solid #ccc;
  //border-top: none;
}

.svg_tab6 {
  display: block;
  margin-left: 60px;
  margin-top: 10px;
}

.svg_tab5 {
  display: block;
  margin-left: 60px;
  margin-top: 10px;
}

.svg_tab4 {
  display: block;
  margin-left: 60px;
  margin-top: 10px;
}

.svg_tab3 {
  display: block;
  margin-left: 60px;
  margin-top: 10px;
}

.svg_tab2 {
  display: block;
  margin-left: 65px;
  margin-top: 10px;
}

.svg_tab1 {
  display: block;
  margin-left: 65px;
  margin-top: 10px;
}

.tablinks {
  width: 180px;
}

.chari-chart.aspect {
  //min-height: 400px !important;
  width: 450px;
  height: 300px;
  &.supply {
    aspect-ratio: 16/14;
    min-height: initial !important;
    height: initial !important;
    position: relative;
    top: 0px;
    z-index: 0;
    .tick {
      text {
        font-family: $ns-font-cousine !important;
      }
    }
    .xaxis .label {
      transform: translate(-100px, 0) !important;
    }
    &.dashed-cero {
      .zeroline {
        stroke-dasharray: 8px;
        stroke: color(ns-light);
      }
    }
    // &.cero {
    //   .zeroline {
    //     transform: translateY(10px);
    //   }
    // }
  }

  &.myp-aspect {
    .serie-bar {
      rect {
        width: 15px !important;
      }
    }
  }
  @media screen and (max-width: 540px) {
  }
}

.titulo-cabecera {
  margin-top: 20px;
  margin-bottom: 20px;
}
