////
/// HEADING element
///
/// This is the headers' style sheet
////
h1,
h2,
h3,
h4,
h5,
h6 {
	margin 			:	0;
	font-family 	:	$ns-font-default;
	font-weight 	:	normal;
	color			: 	color(ns-main);
	line-height  	:	1;
}

h1, %h1	{ font-size: rem($ns-h1); }
h2, %h2	{ font-size: rem($ns-h2); }
h3, %h3	{ font-size: rem($ns-h3); }
h4, %h4	{ font-size: rem($ns-h4); }
h5, %h5	{ font-size: rem($ns-h5); }
h6, %h6	{ font-size: rem($ns-h6); }