////
/// Dashboard component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}dashboard
/// @since version 1.0.0
////

////
/// Politica y Privacidad component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}politicaprivacidad
/// @since version 1.0.0
////

.#{$ns}pp {
  font-family: $ns-font-proxima;
  width: 65%;
  margin-left: 1px;

  hr {
    border: 0;
    margin: 1.71rem 0;
    background-color: #a1a1a1;
    height: 1px;
    box-sizing: content-box;
    margin-top: 26px;
    margin-bottom: 28px;

    @include media-query(sm) {
      margin: 0.95rem 0;
    }
  }

  &__title {
    text-transform: uppercase;
    color: color(ns-primary);
    font-family: $ns-font-proxima;

    &--primary-title {
      font-family: $ns-font-proxima;
      margin: 15.4px 0;
      font-size: 1.4375rem;
      font-weight: 800;
      margin-bottom: 17px;

      @include media-query(sm) {
        margin: 0.8em 0;
      }
    }
  }

  & p {
    font-family: $ns-font-proxima !important;
    font-weight: 400;
    color: color(ns-main-bg);
    line-height: 19.2px;
    font-size: 1rem;
    margin: 14px 0;
    width: calc(100% + 5px);
    margin-top: 16px;
    @media screen and (max-width: 540px) {
      line-height: 1.25;
    }
  }

  & a {
    color: color(ns-primary);
    font-weight: 600;
  }

  & li {
    color: color(ns-main-bg);
    font-size: 1rem;
    line-height: 19.2px;
    width: calc(100% + 6px);

    @include media-query(sm) {
      line-height: 1.24;
    }
  }

  & strong {
    color: #444444;
    font-weight: bold;
  }

  & a strong {
    color: color(ns-primary);
    font-weight: bold;
  }

  & > nav > ul > li > a {
    font-family: $ns-font-proxima;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    color: color(ns-primary);
    line-height: 16.8px;
    letter-spacing: normal;
  }

  & > nav > ul > li > nav a {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.875rem;
    color: #717171;
    line-height: 1.8;
    margin-left: 1.25rem;
  }

  & h2 {
    font-family: $ns-font-proxima;
    line-height: 1.2;
    color: color(ns-primary);
    font-size: 1.1875rem;
    font-weight: bold;
    margin: 15px 0;
    padding: 2px 0px;
    &#condiciones-generales {
      margin-bottom: 22px;
    }
  }

  & h5 {
    font-family: $ns-font-proxima;
    color: color(ns-primary);
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 20px 0;
    &#condicion-de-usuario {
      margin-bottom: 25px;
    }
    &#condiciones-de-acceso-y-uso {
      margin-top: 25px;
      margin-bottom: 25px;
      & ~ p {
        margin-bottom: 16px;
        width: 100%;
      }
    }
    &#politica-de-privacidad {
      margin-top: -6px;
      margin-bottom: 25px;
      & ~ p {
        width: calc(100% + 7px);
      }
      & ~ ol > li {
        width: 95%;
      }
    }
    &#enlaces {
      margin-top: 24px;
      margin-bottom: 25px;
      & ~ ol > li {
        width: 100%;
      }
    }
    &#cookies {
      margin-top: 23px;
      margin-bottom: 25px;
      & ~ p {
        width: calc(100% + 7px);
      }
    }
    &#condiciones-respecto-al-contenido {
      margin-top: 24px;
      margin-bottom: 25px;
      & ~ p {
        width: 95%;
      }
    }
    &#exclusion-de-garantias-y-responsabilidad {
      margin-top: 25px;
      margin-bottom: 25px;
      & ~ p {
        width: calc(100% + 7px);
      }
      & ~ ol > li {
        width: calc(100% + 10px);
      }
    }
    &#gestion-de-usuarios {
      margin-top: 24px;
      margin-bottom: 26px;
    }
    &#duracion {
      margin-top: 24px;
      margin-bottom: 25px;
    }
    &#jurisdiccion-y-legislacion-aplicable {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }

  & #informacion-general ~ ul {
    margin-top: 15px;
    margin-bottom: 30px;
    margin-left: 40px;

    @include media-query(sm) {
      margin-left: 20px;
    }
  }

  & #condiciones-de-acceso-y-uso ~ ul {
    margin-left: 40px;
    @media screen and (max-width: 540px) {
      margin-left: 20px;
    }
    & ~ li ~ p {
      margin-bottom: 10px;
    }
  }

  & #politica-de-privacidad ~ ul {
    margin-bottom: 17px;
    li {
      width: calc(100% + 6px);
    }
  }

  & #politica-de-privacidad ~ ol {
    list-style-type: decimal;
    margin-left: 40px;
  }

  & #privacy-policy ~ ol {
    list-style-type: decimal;
    margin-left: 40px;
  }

  & > ul:nth-child(24) {
    margin-left: 80px !important;
  }

  & > ul:nth-child(26) {
    margin-left: 80px !important;
  }

  & > ul:nth-child(36) {
    margin-left: 40px !important;
  }

  & > ol:nth-child(51) {
    margin-left: 80px !important;
  }

  & #enlaces ~ ol > li:last-child > ol {
    margin-left: 40px;
  }

  & #links ~ ol > li:last-child > ol {
    margin-left: 40px;
  }

  & #condiciones-respecto-al-contenido ~ ol {
    margin-left: 80px !important;
  }

  & #exclusion-de-garantias-y-responsabilidad ~ ol {
    margin-left: 40px !important;
  }
}

@media screen and (max-width: 1199px) {
  .#{$ns}pp {
    padding-left: rem(27.2);
  }
}

@include media-query(sm) {
  .#{$ns}pp {
    width: 100%;
    padding-left: 0;
  }

  .#{$ns}header__mod-rapid-access__wrapper h1,
  .#{$ns}header__mod-rapid-access__wrapper .h1 {
    padding: 22px 15px 20px 20px;
    font-size: 1.5rem;
    font-family: 'Proxima-Nova-ExtraBold';
  }
}

@include media-query(xs) {
  .is-xs-hidden {
    display: none !important;
    visibility: hidden;
  }
  .#{$ns}pp {
    .#{$ns}layout__breadcrumbs {
      display: none;
    }
  }
}

.page.contact-page .page-contents .page-contents ul + ul + ul + table tr td {
  padding: 25px 20px;
}

.#{$ns}pp nav ul li:nth-child(1) {
  margin-bottom: 2px;
}

.#{$ns}pp nav ul li li:nth-child(1) {
  margin-bottom: unset;
  margin-top: 2px;
}

.#{$ns}pp ul:nth-child(6) li {
  line-height: 1.2;
}
