////
/// Tabla component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}time-selector
/// @since version 1.0.0
////

.#{$ns}toolbar-time {
  position: relative;
  width: fit-content;
  &:hover,
  &:focus {
    //background-color: #006699;
    color: white !important;
  }

  &__mod-date {
    cursor: pointer;
    color: color(ns-main-bg) !important;
    font-family: $ns-font-cousine !important;
    position: relative;
    padding: 0 rem(25);
    width: fit-content;
    height: 1.4375rem;
    line-height: 1.4375rem;
    border-radius: 0.625rem;
    font-size: rem(12);
    font-weight: 600;
    background-color: #dbdbdb;
    padding-right: rem(30);

    &:after {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #717171;
      content: '';
      display: block;
      position: absolute;
      right: rem(12);
      top: 50%;
      -webkit-transform: translatey(-75%);
      -ms-transform: translatey(-75%);
      transform: translatey(-75%);
      pointer-events: none;
    }
  }

  &__selector {
    box-sizing: border-box;
    display: none;
    justify-content: space-between;
    position: absolute;
    top: rem(36);
    right: 0;
    z-index: 8;
    // width: 12.5rem;
    width: fit-content;
    padding: 0.9375rem;
    border: 1px solid #dbdbdb;
    color: #444444;
    background-color: white;
    box-shadow: 5px 5px 0 #0000000d;

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid white;
      display: inline-block;
      position: absolute;
      z-index: 5;
      top: -18px;
      right: 24px;
    }
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid #dbdbdb;
      display: inline-block;
      position: absolute;
      z-index: 4;
      top: -19px;
      right: 24px;
    }
  }

  &.active &__selector {
    display: flex;
  }

  .select-time-container {
    font-family: 'Cousine' !important;
    position: relative;
    display: flex;
    gap: 2px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;

    // display: none;
  }
  .custom-select {
    min-width: 80px;
  }
  .custom-select .select-time-options {
    max-height: 0;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
    position: absolute;
    background: #ffffff;
    min-width: 80px;
  }

  .custom-select.active .select-time-options {
    max-height: 280px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
    width: 50%;
    border: 1px solid color(ns-light);
    top: 37px;
  }

  .option,
  .selected {
    padding: 6px 15px;
    cursor: pointer;
    text-align: center;
    font-size: rem(14) !important;
    color: color(ns-main-bg) !important;
  }

  .selected {
    min-width: 40px;
    border: 1px solid color(ns-light);
    padding-left: 20px;
    text-align: center;
    display: flex;
    position: relative;
    font-family: $ns-font-cousine-bold;

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid color(ns-darker);
      right: rem(9);
      position: relative;
      top: 50%;
      z-index: 1;
      pointer-events: none;
      position: absolute;
      transform: translateY(-50%);
    }
  }

  .option {
    transition: background 0.3s ease-in-out;
    font-family: $ns-font-cousine;
  }

  .option:hover {
    background: color(ns-lighter);
    cursor: pointer;
    transition: background 0.3s ease-in-out;
  }
}

#char2 {
  .#{$ns}toolbar-time {
    position: relative;
    width: fit-content;
    left: 51px;
  }
}

#pvpcContent {
  .#{$ns}toolbar-time {
    position: relative;
    width: fit-content;
    left: 1px;
    width: 86px;
    @include media-query(xs) {
      #timer {
        bottom: -16px !important;
        right: -1px !important;
      }
    }
  }
  @include media-query(xs) {
    .#{$ns}toolbar-time {
      #timer {
        bottom: -16px !important;
        right: -1px !important;
      }
    }
  }
}

.mod-date-embed {
  &:hover,
  &:focus {
    color: white;
    .esios-toolbar-time__mod-date {
      color: white !important;
    }
    &::before {
      border-top-color: white !important;
    }
    .esios-toolbar-time {
      &__mod-date {
        &::after {
          border-top-color: white !important;
        }
      }
    }
  }
}

.#{$ns}embed-indicators {
  #char25 {
    .#{$ns}toolbar-time {
      &__mod-date {
        background-color: transparent;
        &:hover,
        &:focus {
          color: white !important;
        }
      }
    }
    .mod-date-embed {
      height: 27px;
      margin-top: 16px;
      left: 0px !important;
      margin-left: -26px;
      &::before {
        top: 14px;
        right: 112px;
      }
      #datepicker {
        background-color: transparent;
        position: relative;
        top: 0px;
      }
      .esios-toolbar-time {
        margin-top: 0px;
      }
    }
  }
  #char6,
  #char7 {
    .#{$ns}toolbar-time {
      &__mod-date {
        background-color: transparent;
        &:hover,
        &:focus {
          color: white !important;
        }
      }
    }
    .mod-date-embed {
      &::before {
        top: 15px;
        right: 111px;
      }
    }
  }
  #char42,
  #char8,
  #char9 {
    .mod-date-embed {
      &::before {
        top: 15px;
        right: 110px;
      }
    }
  }

  #char10 {
    .mod-date-embed {
      &::before {
        top: 15px;
        right: 112px;
      }
      #datepicker {
        &:hover {
          color: #ffffff;
        }
      }
      .esios-toolbar-time__mod-date {
        background-color: transparent !important;
      }
      &:hover,
      &:focus {
        background-color: #006699;
        color: white !important;
      }
    }
  }
}
