.#{$ns}intercambios {
  &__row {
    display: flex;
    justify-content: space-between;

    padding-bottom: 10px;

    @media screen and (max-width: 540px) {
      flex-direction: column;
      #widget40,
      #widget46 {
        width: 100% !important;
      }

      .col-3,
      .col-9 {
        width: 100% !important;
      }
    }
    &.first__row {
      @media screen and (max-width: 540px) {
        flex-direction: column;
        .esios-intercambios__col {
          width: 100% !important;
        }
      }
    }
  }

  .esios-toolbar.col-12.mb-4 {
    align-items: center;
  }

  .esios-balance__row-table {
    position: relative;
    #spinner41table {
      min-height: 300px;
      background-color: color(white);
      opacity: 0.5;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    width: 48%;
  }

  &__title {
    font-size: 1.4375rem;
    text-transform: uppercase;
    color: color(ns-primary);
    font-family: $ns-font-proxima;
    margin: rem(15) 0 rem(5) 0;
    font-weight: 800;
    line-height: 27.6px;
    margin-bottom: -3px;
  }

  &__title-info {
    font-size: 1rem;
    text-align: center;
    margin: 15px 0;
  }
  #map-wrapper,
  .graph-wrapper {
    position: relative;
    min-height: 500px;
    // CONDITIONAL
    // aspect-ratio: 16/16;
    margin-top: -10px;
    @media screen and (max-width: 540px) {
      min-height: 270px;
    }
    #char38 {
      position: absolute;
      inset: 0;
    }
    #char39 {
      display: flex;
      z-index: 1;
      position: absolute;
      inset: 0;

      #rect1 {
        margin: 0 !important;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
      }
    }

    #spinner39 {
      inset: 0 !important;
    }
    .white-overlay {
      background: rgba(255, 255, 255, 0.482);
      display: flex;
      position: absolute;
      inset: 0;
    }
  }
  .graph-wrapper {
    .bar__line {
      stroke-width: 4px;
      stroke: #333;
      transform: translateX(5px);
    }
    .bar__line_import {
      stroke-width: 4px;
      stroke: #717171;
    }
    .bar__line_export {
      stroke-width: 4px;
      stroke: #fd8fff;
    }
    .bars {
      rect {
        width: 11px !important;
        @media screen and (max-width: 540px) {
          width: 8px !important;
        }
        @media screen and (max-width: 400px) {
          width: 6px !important;
        }
        @media screen and (max-width: 350px) {
          width: 4px !important;
        }
      }
    }
  }

  #map-wrapper {
    min-height: 518px;
    @media screen and (max-width: 540px) {
      min-height: auto;
    }
  }

  &__container {
    position: relative;
  }

  &__widget-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__container-data {
    color: #444444;
    width: 100%;
    margin-top: 20px;
    @media screen and (max-width: 540px) {
      margin-bottom: 30px;
    }
  }

  &__label {
    line-height: 16px;
    font-weight: 600;
    color: #717171;
    font-size: 14px;
    font-family: 'Proxima-Nova', Arial, sans-serif;
    text-transform: uppercase;
  }

  &__legend {
    display: flex;
    align-items: center;
    min-height: 40px;
    gap: 10px;

    .icon-eye {
      height: 21px !important;
    }
  }

  &__legend-value {
    color: #444444 !important;
    font-family: 'Cousine', monospace;
    font-size: 1.4375rem;
    display: inline-block;
  }

  &__legend-unit {
    color: #444444 !important;
    font-size: 0.75rem;
    font-family: 'Proxima-Nova', Arial, sans-serif;
    font-weight: 600;
    letter-spacing: 0.1px;
    margin-left: 5px;
  }

  &__no-data-graphic {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f7f7f7;
    border-style: none none solid none;
    background-position: center;
    border-width: 1px;
    border-color: black;
    background-image: url('../../src/assets/images/background-graphic.png');
    background-repeat: repeat-x;

    svg {
      display: none;
    }
  }

  .spinner-container {
    min-height: 0;
    height: 100%;
    z-index: 2;
    position: absolute;
    left: 0;
  }

  .overlay {
    background: #fff;
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0.8;
  }

  .icon-eye {
    font-size: rem(18);
    margin-right: 5px;
    background-image: url($ns-path-img + '/ojo.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 16px;
    width: 30px;
    margin-right: -1px;
    cursor: pointer;
  }

  .icon-eye.disabled {
    color: #dbdbdb !important;
    background-color: #dbdbdb !important;
  }

  .esios-analysis__info-chart .mod-analysis-map-legend {
    border: 0px solid;
    flex-grow: 0;
    padding: 0px;
    .is-flex-center {
      display: none !important;
    }
    @media screen and (max-width: 540px) {
      margin-bottom: 0 !important;
    }
  }
  .esios-tab .mod-tabs.ui-widget.ui-widget-content {
    margin-top: -13px;
    margin-left: -20px;
    ul li a {
      font-size: 19px;
      font-weight: 600;
    }
  }
  #char40 {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 340px;
    width: 100%;
  }

  #char40Container {
    // background: red;
    min-height: 760px;
    @media screen and (max-width: 540px) {
      min-height: 450px;
    }
  }

  #map40 {
    min-height: 600px;

    @media screen and (max-width: 540px) {
      min-height: auto;
    }
    .esios-pvpc__no-data {
      transform: translate(-50%, -50%);
      left: 36%;
      top: 40%;
    }
  }

  #char40 {
    min-height: 24.375rem;

    .esios-pvpc__no-data {
      transform: translate(-50%, -50%);
      left: 36%;
      top: 40%;
    }
  }

  #char45 {
    min-height: 24.375rem;

    .esios-pvpc__no-data {
      transform: translate(-50%, -50%);
      left: 36%;
      top: 22%;
    }
  }

  #map40 .legend-item {
    display: flex !important;
  }

  #geo-map-container-600 .widget-nodata {
    display: none;
  }
  #geo-map-container-600.esios-intercambios__no-data-graphic {
    aspect-ratio: 1/1;
  }
  #widget40 {
    width: 48%;
    .esios-toolbar {
      width: 100% !important;
      margin-bottom: 10px;
    }
    .esios-toolbar-time {
      margin-top: 10px;
    }
  }

  .circle {
    display: inline-block;
    margin-right: 0.3125rem;
    height: 0.625rem;
    width: 0.625rem;
    border-radius: 1.5625rem;
  }

  #widget39 {
    min-height: 830px;
    @media screen and (max-width: 540px) {
      min-height: auto;
    }
    #chart-bar-line {
      user-select: none;
    }
    .legends {
      margin-top: 5px !important;
      .legend {
        display: flex;
        align-items: center;
        gap: 5px;
        color: color(ns-main-bg);
        & > div {
          color: #717171;
          font-weight: 600;
          font-family: $ns-font-proxima-regular;
          text-transform: uppercase;
        }
        .valcontainer {
          display: flex;
        }
        .magnitud,
        .value {
          font-family: $ns-font-cousine;
          font-size: 1.3rem;
          text-transform: initial;
          color: color(ns-main-bg);
        }
        .magnitud {
          font-size: 0.8rem;
          margin-left: 6px;
          font-family: $ns-font-proxima-regular !important;
        }

        .circle {
          display: inline-block;
          margin-right: 0.3125rem;
          height: 0.625rem;
          width: 0.625rem;
          border-radius: 1.5625rem;

          &.blue {
            background: #4793f6;
          }
          &.green {
            background: #077842;
          }
          &.pink {
            background: #ff63c0;
          }
          &.orange {
            background: #ff5100;
          }
        }
        .line {
          display: inline-flex;
          width: 16px;
          height: 3px;
          margin-bottom: 3px;
          border-radius: 2px;
          background: color(black);
        }
      }
    }

    .mod-action {
      display: flex;
      justify-content: center;
      padding: 10px 0px 40px 0px;
      padding-bottom: 0 !important;
      .btn-info {
        display: inline-block;
        padding: 0.4375rem 1.25rem;
        border-radius: 1.25rem;
        background-color: white;
        background-clip: padding-box;
        color: #006699 !important;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        -webkit-font-smoothing: antialiased;
        width: auto;
        transition: background 0.2s ease-in-out;
        margin: 0 auto;
        font-size: 0.875rem;
        border: 1px solid #dbdbdb;
        font-family: 'Proxima-Nova', Arial, sans-serif;
        font-weight: 700;
      }
    }
  }
  .programmed__interconections {
    min-height: 600px;
    .esios-market-prices__title {
      margin-bottom: 12px !important;
    }
    @media screen and (max-width: 540px) {
      min-height: auto;
    }
    margin-top: 20px;
    .mod-date {
      bottom: 5px;
    }
  }
  #widget46 {
    width: 48%;

    @media screen and (max-width: 540px) {
      flex-direction: column;
      .col-6 {
        width: 100% !important;
      }
    }
    #map-wrapper {
      svg {
        height: auto;
      }
    }
  }
  #widget41 {
    #char41 {
      position: absolute;
      inset: 0;
      z-index: 1;
      margin-top: 10px;
    }
    .legends {
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-family: $ns-font-proxima;
      font-size: 0.8rem;
      margin-top: 20px;
      display: flex;
      margin: 0 auto;
      flex-wrap: wrap;
      &.hidden {
        display: none !important;
      }
      .legend {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 5px;
        color: color(ns-main-bg);

        .badge {
          display: flex;
          width: 16px;
          height: 8px;
          border-radius: 2px;
          background: color(black);
          padding: 0;
        }
        .box {
          display: flex;
          width: 16px;
          height: 16px;
          border-radius: 2px;
          background: color(black);
          padding: 0;
        }
        .line {
          display: inline-flex;
          width: 16px;
          height: 3px;
          margin-bottom: 3px;
          border-radius: 2px;
          background: color(black);
        }
      }
    }
    #area-graph-0-legends {
      .legend {
        min-width: 60px;
      }
    }
    #area-graph-1-legends {
      .legend {
        min-width: 60px;
      }
    }
    #area-graph-2-legends {
      .legend {
        &:nth-child(1),
        &:nth-child(2) {
          width: 100%;
          max-width: 140px;
          align-self: flex-start;
        }
      }
    }
    #area-graph-3-legends {
    }
    .esios-toolbar .toolbar-info {
      z-index: 999 !important;
    }
    #mobile__selector {
      .select-time-container {
        min-width: 108px;
        grid-template-columns: 1fr;

        .select-time-options {
          display: none;
          position: absolute;
          top: 35px;
          z-index: 100;
          max-height: 240px;
          overflow-y: auto;
          background-color: #ffffff;
        }
        &.active .select-time-options {
          display: grid;
        }
      }
    }
    .tabcontentclass {
      padding: 0 !important;
    }
    .esios-tab .ui-tabs .ui-tabs-panel {
      @media screen and (max-width: 540px) {
        padding-top: 0 !important;
        margin-top: 15px !important;
        padding-left: 0 !important;
      }
    }
    .esios-balance__row-table {
      @media screen and (max-width: 540px) {
        margin: 0 auto;
        margin-top: 16px;
        padding: 0 !important;
      }
    }
    .esios-intercambios .esios-tab .mod-tabs.ui-widget.ui-widget-content {
      @media screen and (max-width: 540px) {
        margin-top: 10px !important;
      }
    }
    .esios-tab .table-title-row,
    .esios-tab .mod-tabs .ui-widget-header {
      @media screen and (max-width: 540px) {
        display: none !important;
      }
    }
  }

  .map__item {
    position: absolute;
    border: 1px solid #333;
    width: fit-content;
    font-size: 24px;
    padding: 10px;
    background: color(white);
    border-radius: 5px;

    .bold {
      font-weight: 800;
    }
    &:nth-child(1) {
    }
    &:nth-child(2) {
      right: 10%;
      top: 70px;
    }
    &:nth-child(3) {
      right: 20%;
      bottom: 90px;
      top: initial;
    }
    &:nth-child(4) {
      left: 10%;
      bottom: 120px;
      top: initial;
    }
  }
  // }
  .map__image {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  #mypVolumenWidgetView {
    border-bottom: 1px solid color(ns-light);
  }
  #mypPrecioWidgetView {
    margin-top: 21px;
    width: 100%;
  }
  #mypFacturacionWidgetView {
    border-bottom: 1px solid color(ns-light);
    .esios-toolbar-time {
      left: 17px;
    }
  }

  #mypSubastasWidgetView {
    margin-top: 0;
    .spinner-container {
      top: 0 !important;
    }
    .content {
      min-height: 150px;

      #calendar-button {
        height: fit-content !important;
      }
    }
    .esios-pvpc__no-data {
      top: 50%;
      left: 50%;
      margin: 0;
      transform: translate(-50%, -50%);
    }
  }

  .esios-toolbar {
    min-height: 2.5rem;

    @media screen and (max-width: 540px) {
      &.esios-intercambios__col {
        width: 100%;
        display: flex;
        align-items: flex-end;
      }
    }
  }

  .intexchange {
    .esios-table {
      tbody .esios-table__row td {
        font-size: rem(13) !important;
        font-family: $ns-font-proxima, Arial, sans-serif;
      }
      tbody .esios-table__row td {
        font-family: $ns-font-cousine !important;
      }
      tbody .esios-table__row td:first-child {
        font-size: rem(13) !important;
        font-family: $ns-font-proxima, Arial, sans-serif !important;
      }
    }
    @media screen and (max-width: 540px) {
      #tabwithcontent {
        li.ui-state-default.ui-corner-top {
          .ui-tabs-anchor {
            font-size: 17px;
          }
        }
        .esios-tab .mod-tabs.ui-tabs .ui-tabs-nav:after {
          right: -25px;
        }
        .esios-table td:before {
          width: 130px !important;
        }
      }
    }
    @media screen and (max-width: 380px) {
      #tabwithcontent {
        li.ui-state-default.ui-corner-top {
          .ui-tabs-anchor {
            font-size: 15px;
          }
        }
      }
    }
  }
}

.leaflet-bottom,
.leaflet-top {
  z-index: 2 !important;
}

.esios-layout__wrap--intercambios .geo-map {
  height: 400px !important;
}
@media screen and (max-width: 540px) {
  .layout__breadcrumbs__wrapper--intercambios {
    margin-top: 0px !important;

    div.cartodb-infowindow {
      z-index: 2 !important;
    }
  }
}

.intexchange-myp {
  margin-top: 120px !important;
  .esios-table {
    tbody .esios-table__row td {
      font-size: rem(13) !important;
      font-family: $ns-font-proxima, Arial, sans-serif;
    }
    tbody .esios-table__row td {
      font-family: $ns-font-cousine !important;
    }
    tbody .esios-table__row td:first-child {
      font-size: rem(13) !important;
      font-family: $ns-font-proxima, Arial, sans-serif !important;
    }
  }
  @media screen and (max-width: 540px) {
    margin-top: -20px !important;
    #tabwithcontent {
      li.ui-state-default.ui-corner-top {
        .ui-tabs-anchor {
          font-size: 17px;
        }
      }
      .esios-tab .mod-tabs.ui-tabs .ui-tabs-nav:after {
        right: -25px;
      }
      .esios-table td:before {
        width: 130px !important;
      }
    }
  }
  @media screen and (max-width: 380px) {
    #tabwithcontent {
      li.ui-state-default.ui-corner-top {
        .ui-tabs-anchor {
          font-size: 15px;
        }
      }
    }
  }
}
