////
/// Links component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}help
/// @since version 1.0.0
////
.#{$ns}link {
  margin-bottom: 10px;

  &__title {
    color: color(ns-primary);
    font-weight: 800;
    font-family: $ns-font-proxima-extrabold;
    margin: 0.67em 0;
    padding-top: 15px;
    text-transform: uppercase;
    font-size: 1.4375rem;
  }

  .grid-xs-24 {
    // width: 97%;
  }

  img {
    display: inline-block;
    max-width: 100%;
    height: auto;
    margin: 15px;
  }

  .row {
    position: relative;
    margin: 0 -1.5%;
  }

  .other-links {
    a {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      width: 100%;
      color: #444444;
      box-sizing: border-box;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0 0 5px 0;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
    }

    li {
      display: flex;
      width: 48%;
      align-items: center;
      float: left;
      padding: 0;
      box-sizing: border-box;
      border-bottom: 1px solid color(ns-light);
      padding: 1.25rem 2.5rem !important;
      a {
        width: 100% !important;
      }
    }

    p > a {
      display: block;
      align-items: center;
      width: 100%;
      color: color(ns-main-bg);
      flex-direction: column;
      box-sizing: border-box;

      &:hover {
        text-decoration: none;
      }
    }

    p {
      width: 70%;
      margin: 1em auto 0;
      text-align: center;
      font-family: $ns-font-proxima;
      color: color(ns-main-bg);
      font-weight: 400;
      line-height: 19.2px;
    }

    strong {
      color: color(ns-main-bg);
    }
  }
  @media screen and (max-width: 540px) {
    .other-links {
      li {
        width: 100% !important;
        a {
          flex-direction: column;
        }
      }
    }
  }

  @media screen and (min-width: 540px) {
    .other-links {
      li:nth-child(2n + 1) {
        clear: both;
      }

      li {
        border: 1px solid color(white);
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;

        &:hover {
          border: 1px solid color(ns-light);
          box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.05);
        }

        a {
          flex-direction: row;
        }
      }

      .image-wrapper {
        float: left;
        width: 30%;
      }
      p {
        float: left;
        width: 60%;
        margin: 0;
        margin-left: 10%;
        text-align: left;
      }
    }

    .grid-sm-12 {
      width: 47%;
    }
  }
}

@media screen and (max-width: 1199px) {
  .#{$ns}link {
    padding-left: rem(27.2);
  }
}

@media screen and (max-width: 767px) {
  .#{$ns}link {
    padding-left: 0;
  }
}

@media screen and (max-width: 540px) {
  .layout-links {
    .esios-layout__wrap {
      padding: 0 20px;
    }

    .esios-layout__main {
      margin-top: 58px;
    }

    .esios-layout__breadcrumbs {
      display: none !important;
    }
  }
}
