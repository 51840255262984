////
/// Unidades de programacion component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}unidadesprogramacion
/// @since version 1.0.0
////

.#{$ns}unidadesprogramacion {
  font-family: $ns-font-proxima;
  padding-top: 4px;
  margin-left: 1px;
  &__button-remove {
    position: absolute;
    right: 20px;
    top: 50%;
    background-image: url('../../src/assets/images/button-remove.svg');
    background-size: 14px 14px;
    background-repeat: no-repeat;
    padding-right: 13px;
    opacity: 0.4;
    &.hidden {
      visibility: hidden;
    }
  }

  #unidad-prog-buttons {
    padding-top: 0;
  }

  &__selectors {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 540px) {
      flex-shrink: 0;
      width: 100%;
      max-width: 100%;
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      margin-top: var(--bs-gutter-y);
      display: flex;
      flex-direction: column;
      :first-child {
        order: 6;
      }
    }
  }

  &__content {
    font-size: 0.75rem;
    margin-bottom: 2rem;
    @media screen and (max-width: 540px) {
      display: block;
    }

    th {
      color: color(ns-main);
      font-weight: bold;
    }
    td {
      color: color(ns-main);
    }
  }
  &__subtitle {
    color: color(black);
    font-family: $ns-font-proxima-regular;
  }
  &__margin {
    margin-bottom: 5%;
  }
  h1 {
    font-family: $ns-font-proxima-extrabold;
    color: color(ns-primary);
    margin: rem(15) 0 rem(5) 0;
    font-size: 1.4375rem;
    font-weight: 800;
    text-transform: uppercase;
  }
  p {
    color: color(ns-main);
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  &__buttons {
    text-align: center;
    margin: 2.25rem;
  }
  &__wrap {
    @media screen and (max-width: 540px) {
      width: 100%;
    }
  }
  &__tabla {
    &--dropdown2 {
      text-transform: uppercase;
      height: 55px;
      width: rem(168);
      font-family: $ns-font-proxima-regular;
      -webkit-text-size-adjust: 100%;
      zoom: 1;
      position: relative;
      border: 1px solid #dbdbdb;
      font-size: rem(12);
      padding: rem(11) rem(22) rem(11) rem(20);
      white-space: normal;
    }
    &__tdContentDate {
      color: color(black);
      font-size: 0.8rem;
      font-family: 'proxima-nova', Arial, sans-serif;
      height: 25px;
    }
    &__td2 {
      color: color(black);
      font-size: 0.8rem;
      font-family: 'proxima-nova', Arial, sans-serif;
      margin: 0.87em 0;
      text-transform: uppercase;
      height: 40px;
      width: 100%;
    }
    &__input {
      padding-left: 20px;
      padding-right: 25px;
      border: 1px solid color(ns-border-ligth);
      display: inline-block;
      height: 55px;
      width: 90%;
      margin-top: 15px;
      margin-left: 1%;
      margin-right: 0.4%;
      max-height: 15.625rem;
      overflow-y: scroll;
      @media screen and (max-width: 540px) {
        width: 100%;
        margin-left: 0;
        margin-top: 30px;
        padding-right: 48px;
      }
    }
    &__calendarIcon {
      margin-bottom: -3%;
      margin-left: 5px;
    }
    &__inputCalendar {
      border: 1px solid color(ns-border-ligth);
      display: inline-block;
      height: 37px;
      width: 98%;
      margin-top: 8%;
      padding-left: 20px;
      margin-right: 7%;
      margin-bottom: 5%;
      max-height: rem(250);
    }
    .thead {
      -webkit-text-size-adjust: 100%;
      -webkit-box-direction: normal;
      color: #444444;
      font-family: 'proxima-nova', Arial, sans-serif;
      line-height: 1.2;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      border-collapse: collapse;
      border-spacing: 0;
      font-size: 0.875rem;
      hyphens: auto;
      word-wrap: break-word;
    }
    .content-paragraph__table {
      margin-left: 20px;
      &__text {
        color: color(black);
        &--bold {
          color: color(black);
        }
        &--link {
          color: color(black);
        }
      }
    }
    tr:nth-child(even) {
      background-color: #f7f7f7;
    }

    &.simple {
      .esios-table > * {
        font-size: rem(14);
        font-family: $ns-font-proxima-regular;
      }
      .esios-table {
        margin-bottom: 0;
        text-transform: capitalize;
      }

      tr:nth-child(odd) {
        background-color: #f7f7f7;
        height: 32px;
      }
      tr:nth-child(even),
      .esios-table__head .esios-table__row {
        background-color: #ffffff !important;
        height: 30px;
      }
      .esios-table__head .esios-table__row {
        height: 35px !important;
      }
      tbody .esios-table__row td {
        font-size: rem(13);
        color: color(ns-main-bg) !important;
      }
      .esios-table__cell {
        font-family: $ns-font-proxima-regular;
        color: color(ns-main-bg) !important;
        p {
          color: color(ns-main-bg);
        }
      }
    }

    .content-paragraph {
      &__text {
        color: color(black);
        &--bold {
          color: color(black);
        }
        &--link {
          color: color(black);
        }
      }
    }
    @include media-query(xs) {
      display: none;
    }
  }
  @media screen and (max-width: 540px) {
    &__button-remove {
      top: 60%;
    }
  }
}
.esios-button {
  padding-top: 1.5rem;
}

.#{$ns}tablaUnidadesProgramacion {
  font-size: 0.875rem;
  margin-top: 1rem;

  .unidades-buscar {
    width: 100%;
    margin-left: 0%;
    font-size: 12px;
    font-weight: 600;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .esios-table__cell p {
    color: color(ns-main-bg);
    font-size: 0.875rem;
  }

  .unidades-page {
    .page {
      color: color(ns-main-bg);
      font-weight: 600;
      font-size: 0.875rem;
    }

    .select {
      border: 0;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  @media screen and (min-width: 540px) {
    table tr:nth-child(2n + 1) {
      background-color: color(white);
    }

    table tr:nth-child(2n) {
      background-color: color(ns-lighter);
    }

    table th,
    table td {
      display: table-cell;
      padding: 0.625rem 0.5rem;
      font-size: 12px;
      font-family: $ns-font-proxima;
    }
    #paginator-progra {
      margin-top: -12px;
      margin-bottom: -1px;
    }
  }
}

.esios-unidadesprogramacion__dropdown {
  margin-top: 16px;
  padding-left: 0;
  padding-right: 0;
  width: 178px;
  height: 53px;
  border: none;
  margin-right: 5px;
  position: relative;
  @media screen and (max-width: 540px) {
    width: 100%;
    height: 36px;
  }
  .ui-selectmenu-button.ui-button {
    width: 178;
    background: #ffffff;
    position: relative;
    border: 1px solid #dbdbdb;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 0;
    padding: 11px 22px 11px 20px;
    margin-bottom: 0;
    min-height: 55px;
    display: flex;
    align-items: center;
    white-space: normal;
    @media screen and (max-width: 540px) {
      width: 100%;
      margin: unset;
      font-size: 14px;
      font-weight: bold;
      min-height: 36px;
      padding: 10px 20px;
    }
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #717171;
      right: 0.625rem;
      z-index: 1;
      pointer-events: none;
      position: absolute;
      top: 50%;
      margin-top: -3px;
    }
    .ui-selectmenu-icon {
      display: none;
    }
  }
  .ui-selectmenu-menu {
    width: calc(100% - 12px);
    .ui-menu {
      border-radius: 0;
      border: 1px solid #dbdbdb;
      box-sizing: border-box;
      width: 100%;
      max-height: 240px;
    }
    .ui-menu-item-wrapper {
      text-transform: capitalize;
      padding: 4px 16px;
      font-size: 14px;
      background: none;
      border: none;
      color: #444444;
      font-family: 'Proxima-Nova-Regular';
      &.ui-state-active {
        background-color: #f7f7f7;
      }
    }
    .ui-state-disabled {
      display: none;
    }
    .ui-menu-item {
      color: #444444;
    }
  }
  .ui-selectmenu-text {
    color: #000000;
    font-family: 'Proxima-Nova-Regular';
    margin-right: 0;
  }
}

@media screen and (max-width: 1199px) {
  .#{$ns}unidadesprogramacion {
    padding-left: rem(27.2);
  }
}

@media screen and (max-width: 767px) {
  .#{$ns}unidadesprogramacion {
    padding-left: 0;
  }
}

@media screen and (max-width: 539px) {
  .#{$ns}tablaUnidadesProgramacion {
    .#{$ns}table {
      tbody {
        display: block;

        tr:nth-child(2n + 1) {
          background-color: color(ns-lighter);
        }

        td {
          padding: rem(10) 0 rem(10) 0;
          line-height: 1.2;
          display: flex;
          align-items: center;

          &::before {
            width: 35%;
            padding: 0 calc(5% + 10px) 0 rem(19);
            hyphens: none;
            -ms-hyphens: none;
            -webkit-hyphens: none;
          }
        }
      }
    }
  }

  .#{$ns}unidadesprogramacion {
    &__tabla {
      &--dropdown2 {
        width: 100%;
        height: rem(39);
        font-size: rem(14);
      }
    }
  }
}

.mod-documents {
  &--unidadespro {
    margin-right: 4px !important;
    width: 50% !important;

    .#{$ns}boxmarket {
      &.#{$ns}boxmarket {
        &--unidadespro {
          width: 100% !important;

          &:first-child {
            margin-left: 0 !important;
          }
        }
      }
    }
  }

  @media screen and (max-width: 539px) {
    &.mod-documents {
      &--unidadespro {
        width: 100% !important;
        margin-top: rem(54);

        & + .mod-documents {
          width: 100% !important;
          margin-right: 0;

          .#{$ns}boxmarket {
            width: 100% !important;
          }

          &:nth-child(2) {
            .#{$ns}boxmarket {
              margin-left: 0 !important;
            }
          }
        }
      }
    }
  }
}

.esios-boxmarket {
  &--unidadespro {
    width: 97.5% !important;
    height: 100%;
    background: white;
    border: 1px solid #dbdbdb;
    padding: 2.5rem;
    margin: 0;
    margin-bottom: 30px !important;
    margin-left: 0px !important;
    &:first-child {
      margin-left: 25px !important;
      width: 97.2%;
      margin-bottom: -3px !important;
      h2 {
        line-height: 30px;
        margin-left: 10px;
        width: 95.5%;
        margin-top: -4px;
        margin-bottom: -55px;
      }
      .esios-boxmarket__button {
        width: 87%;
        margin-left: 6px;
        margin-bottom: 4px;
      }
      .esios-boxdoc__footer {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 0.5rem;
        margin: 1.25rem 0;
        margin-left: -26px;
        margin-top: 16px;
        margin-bottom: 23px;
      }
    }
    .col-lg-6.col-md-6.col-sm-6.col-xs-12 {
      &:nth-child(2) {
        margin-left: -26px !important;
        width: 50%;
        .esios-boxmarket__list {
          margin-top: 38px;
          width: 100%;
        }
      }
    }
    p.esios-boxmarket__list {
      width: calc(100% + 3px);
    }
  }
}
