////
/// Contact component
///
/// Custom project
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}contact
/// @since version 1.0.0
////
.#{$ns}web-calculadora {
  max-width: 100%;
  color: color(ns-main-bg);
  min-height: 300px;

  #tabwithbody {
    padding: 0 rem(35);
    @media screen and (max-width: 540px) {
      padding: 0;
    }
    .col-sm-6 label {
      font-weight: 600;
      margin-bottom: 5px;
      color: #717171;
    }
    .row {
      margin-top: 0;
    }
    .datepicker-btn {
      font-weight: 600;
      text-align: center;
      text-indent: -34px;
    }
  }

  #results {
    margin-top: 20px;
  }

  #buttonShareModal1,
  .esios-toolbar__item.print {
    display: none;
  }
  .esios-toolbar__item a {
    font-family: inherit;
  }

  #tabwithcontent {
    .#{$ns}tab {
      .row {
        position: relative;
        margin: 0 1.5%;

        :after {
          clear: both;
        }
      }

      .btn {
        display: inline-block;
        font-size: 0.875rem;
        border-radius: 1.25rem;
        background-color: #069;
        background-clip: padding-box;
        color: #fff !important;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        padding: 0.4375rem 1.25rem;

        &:hover {
          text-decoration: none;
          background-color: rgba(0, 102, 153, 0.8);
        }
      }

      .icon-download {
        background-image: url($ns-path-img + '/download.svg');
        background-repeat: no-repeat;
        background-position: right 3px center;
        background-size: 1.5625rem;
        padding-right: 3rem;
      }

      .row:before,
      .row:after {
        display: table;
        content: '';
      }

      .ui {
        &-tabs {
          position: relative;
          padding: 0px !important;

          &-nav {
            position: relative;
            border-bottom: 1px solid color(ns-light);
            padding: 0;
            display: flex;

            li {
              box-sizing: border-box;
              -webkit-box-flex: 1;
              -webkit-flex-grow: 1;
              -ms-flex-positive: 1;
              flex-grow: 1;
              min-width: 24%;
              height: auto;
              text-align: center;
              margin: 0 0.5%;
              cursor: pointer;

              a {
                text-transform: initial;
                margin-top: -1px;
              }
            }
          }

          &-anchor {
            display: block;
            width: 100%;
            line-height: 2.4375rem;
            border: 1px solid color(ns-light);
            color: color(grey);
            font-weight: 600;
            font-size: 1.1875rem;
            background-color: color(ns-lighter);
            padding: 0;
            transition: 0.1s;
          }
        }

        &-helper {
          &-clearfix {
            min-height: 0;
          }

          &-reset {
            padding: 0;
            outline: 0;
            line-height: 1.3;
            text-decoration: none;
            font-size: 100%;
            list-style: none;
          }
        }

        &-state {
          &-default {
            color: color(ns-main-bg);
            .tittle-tab {
              color: #777777;
            }
          }

          &-active {
            color: color(white);
            border: 0;
            font-weight: 400;

            .tittle-tab {
              color: #006699 !important;
            }
          }
        }

        &-tabs-panel {
          padding: 0;
          display: block;
          border-width: 0;
          min-height: 10vh;
          background: none;
        }
      }

      .ui-tabs-inactive {
        display: none;
      }

      .ui-tabs-panel.ui-tabs-inactive {
        display: none;
      }

      .mod-tabs .ui-tabs .ui-tabs-nav li:first-child {
        margin-left: 0;
      }

      .mod-tabs.ui-widget.ui-widget-content {
        border: none !important;
        width: 100% !important;
      }

      .mod-tabs .ui-widget-header {
        background: none !important;
        border: none !important;
      }

      .ui-tabs .ui-tabs-panel {
        border-top: 1px solid #ddd !important;
        padding: 1em 0;
      }

      .mod-tabs .ui-tabs .ui-tabs-nav li a {
        color: color(grey);
      }

      .mod-tabs .ui-tabs .ui-tabs-nav li a,
      .mod-tabs .ui-tabs-collapsible .ui-tabs-nav li.ui-tabs-active a,
      .mod-tabs.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
        padding: 0 !important;
        position: relative;
        font-family: $ns-font-proxima-regular !important;
        text-decoration: none;
        height: 102%;

        div.tittle-tab {
          pointer-events: none;
          line-height: 20px;
          margin: 1em;
          font-weight: 700 !important;
        }

        div.tittle-tab span {
          color: #777777;
          pointer-events: none;
          display: block;
          text-align: initial;
        }
      }

      .esios-tab .mod-tabs.ui-tabs .ui-tabs-nav .ui-tabs-ancho {
        bottom: 0;
      }

      ul {
        height: 90px;
      }

      .ui-tabs-active a {
        height: 91px !important;
      }

      .mod-tabs.ui-tabs .ui-tabs-nav:before {
        content: '';
        position: absolute;
        bottom: -1px;
        width: 24px;
        height: 1px;
        background-color: color(ns-light);
      }

      .ui-tabs-anchor {
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;

        .tittle-tab {
          font-size: 1rem;
          font-family: 'Proxima-Nova', Arial, sans-serif;
        }
      }

      .mod-tabs.ui-tabs .ui-tabs-nav .ui-tabs-active .ui-tabs-anchor,
      .mod-tabs.ui-tabs .ui-tabs-nav .ui-tabs-active {
        background-color: color(white);
        color: color(ns-primary);
        border-bottom: 1px solid color(white);
        padding: 0;

        a div.tittle-tab span {
          color: #006699 !important;
          pointer-events: none;
          display: block;
        }

        a div.tittle-tab {
          font-weight: 700 !important;
          color: #006699 !important;
          pointer-events: none;
        }
      }

      .mod-tabs.ui-tabs .ui-tabs-nav .ui-tabs-active {
        border-right-width: 0px;
        border-left-width: 0px;
      }

      .ui-tabs-nav li {
        border-right-width: 0px;
        border-left-width: 0px;
      }

      .mod-tabs.ui-tabs .ui-tabs-nav:after,
      .mod-tabs.ui-tabs .ui-tabs-nav:before {
        display: none;
      }

      .ui-helper-clearfix:before,
      .ui-helper-clearfix:after {
        content: '';
        display: table;
        border-collapse: collapse;
      }

      table {
        font-size: 12px;
        text-transform: uppercase;

        th {
          padding: 0.625rem;
          vertical-align: top;
          font-weight: 600;
          text-align: left;
          position: relative;
        }
      }
    }
  }

  .#{$ns}accordion {
    display: none;
  }

  p {
    color: color(ns-main-bg);
  }

  h1 {
    font-family: $ns-font-proxima;
    font-weight: 800;
  }

  .grid-sm-24 {
    width: 97%;
    border-bottom: 1px solid color(ns-light);
  }

  table {
    margin-bottom: 2.5rem;

    tr {
      background-color: color(ns-lighter);

      @media (max-width: 540px) {
        tr:first-child {
          font-weight: 600 !important;
          margin-top: 20px;
        }
      }

      &:nth-child(5n) {
        border-bottom: 1px solid color(ns-light);

        td {
          padding-bottom: 16px;
        }
      }
    }

    td {
      color: #444;
      font-family: $ns-font-proxima, Arial, sans-serif;
    }

    th,
    td {
      display: table-cell;
      padding: 0;
    }

    table label {
      color: #444;

      td {
        color: #444;
        font-family: $ns-font-proxima, Arial, sans-serif;
      }

      th,
      td {
        display: table-cell;
        padding: 0;
      }

      table label {
        color: #444;
      }
    }
  }

  h1 {
    color: color(ns-primary);
    margin: 0;
    font-size: 23px;
    font-family: $ns-font-proxima;
    font-weight: 800;
    text-transform: uppercase;
  }

  h2 {
    color: color(ns-primary);
    margin: 15px 0;
    font-size: 19px;
    font-weight: bold;
  }

  hr {
    border: 0;
    margin: 1.875rem 0;
    height: 1px;
    box-sizing: content-box;
  }

  p {
    margin: 16px 0;
    color: color(ns-main-bg) !important;
    font-family: $ns-font-default;
  }

  a {
    color: color(ns-primary);
    font-size: rem(16);
    font-family: $ns-font-proxima-alt-bold;
  }

  p.text-dates {
    margin-top: 0px;
  }

  td.section-left.no-data {
    color: #cf4634;
    padding-left: 20px;
    padding-top: 15px;
  }
  td.section-right,
  td.section-right > * {
    font-family: $ns-font-cousine !important;
  }

  label.inputs {
    // margin-top: 24px;
    // padding-bottom: 16px;
    margin-top: 5px;
    padding-bottom: 8px;
  }

  div#supply-buttons1 {
    position: absolute;
    bottom: 0px;
  }

  p.text-tab {
    margin: 0px;
    strong {
      color: #717171;
    }
  }

  table label {
    color: #444444;

    @media (max-width: 540px) {
      margin-right: 2px;
    }
  }

  .date-selectors {
    position: relative;
  }

  .date-selector {
    height: 100%;
    z-index: 1;
  }

  .date-hour-selector {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: rem(4);
    margin-top: 6px;
    align-items: center;

    &.noMinutesSelector {
      grid-template-columns: 100px 1fr 1fr;
    }
  }
  .ui-tabs-active span.icon-0,
  .ui-tabs-active span.icon-1 {
    pointer-events: none;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVAlRTFnaW5hJTIwMSIgdmlld0JveD0iMCAwIDQ2IDQ2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQ2cHgiIGhlaWdodD0iNDZweCINPg0JPGcgaWQ9IkNhcGElMjAxIj4NCQk8cGF0aCBpZD0iRWxsaXBzZSIgZD0iTSAwIDIzIEMgMCAxMC4yOTcyIDEwLjI5NzMgMCAyMyAwIEMgMzUuNzAyNyAwIDQ2IDEwLjI5NzIgNDYgMjMgQyA0NiAzNS43MDI4IDM1LjcwMjcgNDYgMjMgNDYgQyAxMC4yOTczIDQ2IDAgMzUuNzAyOCAwIDIzIFoiIGZpbGw9IiMwMDY1OWIiLz4NCQk8Zz4NCQkJPHBhdGggZD0iTSAzMC42NDQ1IDcgTCAxMyAyNS44MTE0IEwgMTkuOTQ1OCAyNS40NjY5IEwgMTUuMzU1NSAzOSBMIDMzIDIwLjE4ODYgTCAyNi4wNTM2IDIwLjUzMzEgTCAzMC42NDQ1IDcgWiIgZmlsbD0iI2ZmZmZmZiIvPg0JCTwvZz4NCTwvZz4NPC9zdmc+);
  }

  .datepicker-mod {
    height: 42px !important;
    input {
      font-weight: bold;
      font-size: rem(14);
    }
  }

  span.icon-0,
  span.icon-1 {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVAlRTFnaW5hJTIwMSIgdmlld0JveD0iMCAwIDQ2IDQ2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQ2cHgiIGhlaWdodD0iNDZweCINPg0JPGcgaWQ9IkNhcGElMjAxIj4NCQk8cGF0aCBkPSJNIDIgMjMgQyAyIDExLjQwMTkgMTEuNDAxOSAyIDIzIDIgQyAzNC41OTgxIDIgNDQgMTEuNDAxOSA0NCAyMyBDIDQ0IDM0LjU5ODEgMzQuNTk4MSA0NCAyMyA0NCBDIDExLjQwMTkgNDQgMiAzNC41OTgxIDIgMjMgWk0gMCAyMyBDIDAgMzUuNzAyOCAxMC4yOTczIDQ2IDIzIDQ2IEMgMzUuNzAyNyA0NiA0NiAzNS43MDI4IDQ2IDIzIEMgNDYgMTAuMjk3MiAzNS43MDI3IDAgMjMgMCBDIDEwLjI5NzMgMCAwIDEwLjI5NzIgMCAyMyBaIiBmaWxsPSIjNzE3MTcxIi8+DQkJPGc+DQkJCTxwYXRoIGQ9Ik0gMzAuNjQ0NSA3IEwgMTMgMjUuODExNCBMIDE5Ljk0NTggMjUuNDY2OSBMIDE1LjM1NTUgMzkgTCAzMyAyMC4xODg2IEwgMjYuMDUzNiAyMC41MzMxIEwgMzAuNjQ0NSA3IFoiIGZpbGw9IiM3MTcxNzEiLz4NCQk8L2c+DQk8L2c+DTwvc3ZnPg==);
    background-repeat: no-repeat;
    display: inline-block;
    height: 46px;
    width: 46px;
    position: relative;
    right: 5px;
  }

  #lumios-clear {
    text-indent: inherit;
    width: inherit;
    text-align: center;
    text-indent: -20px;
    display: inline-block;
    padding: 0.4375rem 1.25rem;
    border-radius: 1.25rem;
    background-color: #717171;
    background-clip: padding-box;
    color: white !important;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    -webkit-font-smoothing: antialiased;
  }

  #lumios-clear:hover {
    background-color: #006699;
  }

  #supply-buttons1 {
    width: 100%;
  }

  .btn-lumios-borrar {
    background-color: #717171;
    background-position: center right 2.5px !important;
    height: 30px;
    padding-right: 38px;
  }

  .icon-delete {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVAlRTFnaW5hJTIwMSIgdmlld0JveD0iMCAwIDI1IDI1IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjI1cHgiIGhlaWdodD0iMjVweCINPg0JPGcgaWQ9IkNhcGElMjAxIj4NCQk8cGF0aCBkPSJNIDcuODMzMyA5LjE2NjcgTCA5LjE2NjcgNy44MzMzIEwgMTIuNSAxMS4xNjUgTCAxNS44MzMzIDcuODMzMyBMIDE3LjE2NjcgOS4xNjY3IEwgMTMuODM1IDEyLjUgTCAxNy4xNjY3IDE1LjgzMzMgTCAxNS44MzMzIDE3LjE2NjcgTCAxMi41IDEzLjgzNSBMIDkuMTY2NyAxNy4xNjY3IEwgNy44MzMzIDE1LjgzMzMgTCAxMS4xNjUgMTIuNSBMIDcuODMzMyA5LjE2NjcgWk0gMCAxMi41IEMgMCAxOS40MDM4IDUuNTk2MyAyNSAxMi41IDI1IEMgMTkuNDAzNyAyNSAyNSAxOS40MDM4IDI1IDEyLjUgQyAyNSA1LjU5NjIgMTkuNDAzNyAwIDEyLjUgMCBDIDUuNTk2MyAwIDAgNS41OTYyIDAgMTIuNSBaIiBmaWxsPSIjZmZmZmZmIi8+DQk8L2c+DTwvc3ZnPg==);
    background-repeat: no-repeat;
  }

  .esios-toolbar-time {
    width: 100%;
    position: relative;
    border: 1px solid color(ns-light);
  }

  .esios-toolbar-time__mod-date {
    cursor: pointer;
    color: #444444 !important;
    font-family: 'Cousine' !important;
    position: relative;
    padding: rem(10) rem(14) rem(8) rem(2);
    width: 100%;
    height: auto;
    border-radius: rem(0);
    font-size: rem(12);
    background-color: color(white);
    text-align: center;
  }

  .esios-toolbar-time__mod-date::after {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgMTUgMTUiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgdmVyc2lvbj0iMS4xIg0JeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSINCXg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMTVweCIgaGVpZ2h0PSIxNXB4Ig0+DQk8ZyBpZD0iTGF5ZXIlMjAxIj4NCQk8Zz4NCQkJPHBhdGggZD0iTSAxIDcuNSBDIDEgMy45MTAyIDMuOTEwMSAxIDcuNSAxIEMgMTEuMDg5OSAxIDE0IDMuOTEwMiAxNCA3LjUgQyAxNCAxMS4wODk4IDExLjA4OTkgMTQgNy41IDE0IEMgMy45MTAxIDE0IDEgMTEuMDg5OCAxIDcuNSBaTSAwIDcuNSBDIDAgMTEuNjQyMiAzLjM1NzggMTUgNy41IDE1IEMgMTEuNjQyMiAxNSAxNSAxMS42NDIyIDE1IDcuNSBDIDE1IDMuMzU3OCAxMS42NDIyIDAgNy41IDAgQyAzLjM1NzggMCAwIDMuMzU3OCAwIDcuNSBaIiBmaWxsPSIjNzE3MTcxIi8+DQkJCTxwYXRoIGQ9Ik0gMTAuNjMwOSA5LjEzMjYgTCA4IDcgTCA4IDEuOTY2MiBMIDcgMi4xNzM3IEwgNyA4IEwgMTAuMTkzNSA5Ljg2NjcgTCAxMC42MzA5IDkuMTMyNiBaIiBmaWxsPSIjNzE3MTcxIi8+DQkJPC9nPg0JPC9nPg08L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: center;

    width: rem(30);
    height: rem(30);
    border-left: none;
    /* border-right: 5px solid transparent; */
    border-top: none;
    content: '';
    display: block;
    position: absolute;
    right: rem(2);
    top: 50%;
    @include transform(translateY(-50%));
  }

  .esios-toolbar-time__selector {
    top: rem(55);
  }

  .datepicker-mod {
    position: relative;
    background-color: color(white);
    height: 100%;
  }

  .datepicker-btn {
    cursor: pointer;
    color: #444444 !important;
    font-family: $ns-font-proxima-regular !important;
    position: relative;
    padding: rem(10) rem(14) rem(8) rem(2);
    width: 100%;
    height: 100%;
    border-radius: rem(0);
    font-size: rem(12);
    background-color: transparent;
    text-align: center;
    z-index: 1;
    border: 1px solid color(ns-light);
    font-size: rem(12);
    background-color: transparent;
    text-align: center;
  }

  .datepicker-mod::after {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgMTUgMTUiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgdmVyc2lvbj0iMS4xIg0JeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSINCXg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMTVweCIgaGVpZ2h0PSIxNXB4Ig0+DQk8ZyBpZD0iTGF5ZXIlMjAxIj4NCQk8Zz4NCQkJPHBhdGggZD0iTSAxIDcuNSBDIDEgMy45MTAyIDMuOTEwMSAxIDcuNSAxIEMgMTEuMDg5OSAxIDE0IDMuOTEwMiAxNCA3LjUgQyAxNCAxMS4wODk4IDExLjA4OTkgMTQgNy41IDE0IEMgMy45MTAxIDE0IDEgMTEuMDg5OCAxIDcuNSBaTSAwIDcuNSBDIDAgMTEuNjQyMiAzLjM1NzggMTUgNy41IDE1IEMgMTEuNjQyMiAxNSAxNSAxMS42NDIyIDE1IDcuNSBDIDE1IDMuMzU3OCAxMS42NDIyIDAgNy41IDAgQyAzLjM1NzggMCAwIDMuMzU3OCAwIDcuNSBaIiBmaWxsPSIjNzE3MTcxIi8+DQkJCTxwYXRoIGQ9Ik0gMTAuNjMwOSA5LjEzMjYgTCA4IDcgTCA4IDEuOTY2MiBMIDcgMi4xNzM3IEwgNyA4IEwgMTAuMTkzNSA5Ljg2NjcgTCAxMC42MzA5IDkuMTMyNiBaIiBmaWxsPSIjNzE3MTcxIi8+DQkJPC9nPg0JPC9nPg08L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: center;
    z-index: 0;
    width: rem(40);
    height: rem(40);
    border-left: none;
    /* border-right: 5px solid transparent; */
    border-top: none;
    content: '';
    display: block;
    position: absolute;
    right: rem(0);
    top: 50%;
    @include transform(translateY(-50%));
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgMTUgMTUiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgdmVyc2lvbj0iMS4xIgoJeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIKCXg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMTVweCIgaGVpZ2h0PSIxNXB4Igo+Cgk8ZyBpZD0iTGF5ZXIlMjAxIj4KCQk8cGF0aCBkPSJNIDEwIDMgTCAxMCAyIEwgNSAyIEwgNSAzIEwgNSA1IEwgNCA1IEwgNCAzIEwgNCAyIEwgMSAyIEwgMSAxNCBMIDE0IDE0IEwgMTQgMiBMIDExIDIgTCAxMSAzIEwgMTEgNSBMIDEwIDUgTCAxMCAzIFpNIDEzIDEzIEwgMiAxMyBMIDIgNiBMIDEzIDYgTCAxMyAxMyBaTSAxMiAxMCBMIDEyIDkgTCAxMSA5IEwgMTEgMTAgTCAxMiAxMCBaTSAxMCAxMiBMIDEwIDExIEwgOSAxMSBMIDkgMTIgTCAxMCAxMiBaTSA4IDEyIEwgOCAxMSBMIDcgMTEgTCA3IDEyIEwgOCAxMiBaTSA2IDEyIEwgNiAxMSBMIDUgMTEgTCA1IDEyIEwgNiAxMiBaTSA0IDEyIEwgNCAxMSBMIDMgMTEgTCAzIDEyIEwgNCAxMiBaTSAxMCAxMCBMIDEwIDkgTCA5IDkgTCA5IDEwIEwgMTAgMTAgWk0gOCAxMCBMIDggOSBMIDcgOSBMIDcgMTAgTCA4IDEwIFpNIDYgMTAgTCA2IDkgTCA1IDkgTCA1IDEwIEwgNiAxMCBaTSA0IDEwIEwgNCA5IEwgMyA5IEwgMyAxMCBMIDQgMTAgWk0gMTIgOCBMIDEyIDcgTCAxMSA3IEwgMTEgOCBMIDEyIDggWk0gMTAgOCBMIDEwIDcgTCA5IDcgTCA5IDggTCAxMCA4IFpNIDggOCBMIDggNyBMIDcgNyBMIDcgOCBMIDggOCBaTSA2IDggTCA2IDcgTCA1IDcgTCA1IDggTCA2IDggWk0gMTAgMSBMIDEwIDIgTCAxMSAyIEwgMTEgMSBMIDEwIDEgWk0gNCAxIEwgNCAyIEwgNSAyIEwgNSAxIEwgNCAxIFoiIGZpbGw9IiM3MTcxNzEiLz4KCTwvZz4KPC9zdmc+);
    background-repeat: no-repeat;
    background-position: center;
    background-position: center;
    background-color: #f7f7f7;
    border: none;
    border-left: 1px solid #dbdbdb;
  }

  .datepicker-mod.no-datepicker {
    opacity: 0.6;

    &::after {
      background-image: none;
    }
  }

  .link-dates {
    width: 20px;
    height: 20px;
    border: 1px solid color(ns-light);
    position: absolute;
    top: 50%;
    left: 70px;
    @include transform(translateY(-50%));
    border-radius: 50%;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IlVudGl0bGVkLVBfeDI1X0UxZ2luYV94MjVfMjAxIgoJIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTYgMTMiCgkgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTYgMTMiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8cGF0aCBmaWxsPSJub25lIiBkPSJ6Ii8+CjxnIGlkPSJDYXBhX3gyNV8yMDEiPgoJPGc+CgkJPHBhdGggZmlsbD0iIzcxNzE3MSIgZD0iTTYuNSwzLjNjMC0wLjksMC43LTEuNywxLjUtMS43czEuNSwwLjcsMS41LDEuN1Y1QzEwLjMsNSwxMSw0LjMsMTEsMy4zbDAsMEMxMSwxLjUsOS43LDAsOCwwUzUsMS41LDUsMy4zCgkJCUM1LDQuMyw1LjcsNSw2LjUsNWwwLDBWMy4zeiIvPgoJCTxwYXRoIGZpbGw9IiM3MTcxNzEiIGQ9Ik05LjUsOS43YzAsMC45LTAuNywxLjctMS41LDEuN3MtMS41LTAuNy0xLjUtMS43VjhDNS43LDgsNSw4LjcsNSw5LjdsMCwwQzUsMTEuNSw2LjMsMTMsOCwxM3MzLTEuNSwzLTMuMwoJCQlDMTEsOC43LDEwLjMsOCw5LjUsOGwwLDBWOS43eiIvPgoJCTxyZWN0IHk9IjYiIGZpbGw9IiM3MTcxNzEiIHdpZHRoPSI0IiBoZWlnaHQ9IjEiLz4KCQk8cmVjdCB4PSIxMiIgeT0iNiIgZmlsbD0iIzcxNzE3MSIgd2lkdGg9IjQiIGhlaWdodD0iMSIvPgoJCTxwYXRoIGZpbGw9IiM3MTcxNzEiIGQ9Ik0xMiw1bDItMmgxbC0yLDJIMTJ6Ii8+CgkJPHBhdGggZmlsbD0iIzcxNzE3MSIgZD0iTTEyLDhsMiwyaDFsLTItMkgxMnoiLz4KCQk8cGF0aCBmaWxsPSIjNzE3MTcxIiBkPSJNNCw1TDIsM0gxbDIsMkg0eiIvPgoJCTxwYXRoIGZpbGw9IiM3MTcxNzEiIGQ9Ik00LDhsLTIsMkgxbDItMkg0eiIvPgoJPC9nPgo8L2c+CjxwYXRoIGZpbGw9Im5vbmUiIGQ9InoiLz4KPC9zdmc+);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px;
    z-index: 0;
    cursor: pointer;

    &.active {
      background-color: color(ns-primary);
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IlVudGl0bGVkLVBfeDI1X0UxZ2luYV94MjVfMjAxIgoJIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTYgMTMiCgkgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTYgMTMiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8cGF0aCBmaWxsPSJub25lIiBkPSJ6Ii8+CjxnIGlkPSJDYXBhX3gyNV8yMDEiPgoJPGc+CgkJPHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iTTYuNSwzLjNjMC0wLjksMC43LTEuNywxLjUtMS43czEuNSwwLjcsMS41LDEuN1Y1QzEwLjMsNSwxMSw0LjMsMTEsMy4zbDAsMEMxMSwxLjUsOS43LDAsOCwwUzUsMS41LDUsMy4zCgkJCUM1LDQuMyw1LjcsNSw2LjUsNWwwLDBWMy4zeiIvPgoJCTxwYXRoIGZpbGw9IiNmZmZmZmYiIGQ9Ik05LjUsOS43YzAsMC45LTAuNywxLjctMS41LDEuN3MtMS41LTAuNy0xLjUtMS43VjhDNS43LDgsNSw4LjcsNSw5LjdsMCwwQzUsMTEuNSw2LjMsMTMsOCwxM3MzLTEuNSwzLTMuMwoJCQlDMTEsOC43LDEwLjMsOCw5LjUsOGwwLDBWOS43eiIvPgoJCTxwYXRoIGlkPSJFbGlwc2UiIGZpbGw9IiNmZmZmZmYiIGQ9Ik03LDYuNUM3LDUuMSw3LjQsNCw4LDRzMSwxLjEsMSwyLjVTOC42LDksOCw5UzcsNy45LDcsNi41eiIvPgoJPC9nPgo8L2c+CjxwYXRoIGZpbGw9Im5vbmUiIGQ9InoiLz4KPC9zdmc+);
      background-repeat: no-repeat;
      border: 1px solid color(ns-main-bg);
      color: color(white);
      background-position: center;
      background-size: 15px;
    }
  }

  .link-dates::before {
    position: absolute;
    content: '';
    border: 1px solid color(ns-light);
    width: 50px;
    height: 10px;
    top: -11px;
    left: 8px;
    border-bottom: none;
    border-right: none;
  }

  .link-dates::after {
    position: absolute;
    content: '';
    border: 1px solid color(ns-light);
    width: 50px;
    height: 10px;
    bottom: -11px;
    left: 8px;
    border-top: none;
    border-right: none;
  }

  input#web-calculadora {
    width: 100%;
  }

  .datepicker-mod {
    height: auto;
  }

  table {
    width: 67%;
  }

  td {
    color: #444444;
    font-family: $ns-font-proxima, Arial, sans-serif;
  }

  td.main-right {
    float: right;

    &:nth-child(-n + 3) {
      margin-top: 10px;
    }
  }

  td.section-left {
    padding-left: 20px;
    width: 78%;
  }

  input#web-calculadora__datepicker-start,
  input#web-calculadora__datepicker-end {
    font-size: 18px;
    font-weight: bold;
  }

  input#web-calculadora1,
  input#web-calculadora2,
  input#web-calculadora3 {
    font-size: 18px;
    font-family: $ns-font-proxima;
    margin-top: 0px;
    height: 42px;
    width: 100%;
    padding: 0.625rem 1.25rem !important;
    text-align: right;
    font-weight: 600;
  }

  div#tab-web-calculadora2 {
    margin-bottom: 30px;
    margin-top: -30px;
    padding: 0 rem(35);
    @media screen and (max-width: 540px) {
      padding: 0;
    }
  }

  p.text-dates.p {
    color: #fff !important;
  }

  label.p {
    color: #fff;
  }

  .esios-web-calculadora__panel {
    border: 1px solid #dbdbdb;
    display: flex;
    padding: 30px;
    background-color: color(ns-lighter);
  }

  .mod-highlight {
    padding: 0;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    min-height: rem(75);
    padding-left: rem(100);
    background-color: transparent;

    @media screen and (max-width: 768px) {
      padding-left: 0;
    }

    &--pvpc {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;

      @media screen and (max-width: 540px) {
        margin-top: 0rem !important;
        margin-bottom: 0rem !important;
      }
    }

    &--analysis {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;

      @media screen and (max-width: 540px) {
        margin-top: 0rem !important;
        margin-bottom: 0rem !important;
      }
    }

    h1 {
      color: #cf4634;
      font-size: 1.875rem;
      margin-bottom: 0;
      margin-top: 0;
      font-family: $ns-font-proxima-bold;
    }

    p {
      margin: 0;
      text-transform: uppercase;
      color: #717171;
      font-family: $ns-font-proxima-regular;
      font-size: rem(14);
    }

    &.pvpc-home {
      @media screen and (max-width: 768px) {
        margin-bottom: rem(10);
        margin-top: rem(15);
        min-height: auto;
      }
    }

    &.pvpc-home a {
      position: relative;
      padding-left: rem(11.2);
      display: block;

      @media screen and (max-width: 768px) {
        padding-left: 0;
      }

      &:hover {
        text-decoration: none;
      }

      &:before {
        content: '';
        position: absolute;
        left: rem(-100);
        width: 6.875rem;
        height: 4.6875rem;
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
        background-image: url($ns-path-img + '/pvpc.svg');
        background-repeat: no-repeat;
        background-position: left;

        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }
  }

  .consumption-mobile {
    display: none;
  }

  @media screen and (max-width: 540px) {
    margin-top: 30px;

    td {
      text-align: end;
    }

    td.section-left {
      text-align: initial;
    }

    tr.info > td {
      text-align: initial;
    }

    div.main-right {
      margin-left: 0px !important;
      padding: 20px !important;

      p {
        margin: 0px;
      }
    }

    .billing {
      width: 60%;
      margin-left: 0px;

      p.billing-p {
        float: left;
      }
    }

    .esios-web-calculadora__panel {
      flex-direction: column;
    }

    table {
      width: 100%;
    }

    .consumption-mobile {
      display: block;
    }

    .consumption-desktop {
      display: none;
    }

    label.inputs {
      margin-bottom: rem(6) !important;
    }

    .mobile-space {
      margin-top: rem(15);
    }

    .esios-home__container-pvpc {
      display: none;
    }
  }

  .esios-btn__container {
    @media screen and (max-width: 540px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    a.esios-btn {
      display: flex;
      justify-content: center;

      @media screen and (max-width: 540px) {
        width: 42%;
        margin-top: 52px;
        margin-left: 0;
      }
    }
  }

  .link-dates::before {
    position: absolute;
    content: '';
    border: 1px solid color(ns-light);
    width: 50px;
    height: 10px;
    top: -11px;
    left: 8px;
    border-bottom: none;
    border-right: none;
  }

  .link-dates::after {
    position: absolute;
    content: '';
    border: 1px solid color(ns-light);
    width: 50px;
    height: 10px;
    bottom: -11px;
    left: 8px;
    border-top: none;
    border-right: none;
  }

  input#web-calculadora {
    width: 100%;
  }

  .datepicker-mod {
    height: auto;
  }

  td {
    color: #444444;
    font-family: $ns-font-proxima, Arial, sans-serif;
  }

  .info td,
  .info label {
    font-weight: bold;
    font-weight: 600;
    font-family: $ns-font-proxima;
  }

  div.main-right {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 20px 20px 20px;
    border: 1px solid #dbdbdb;
    flex-grow: 1;
    margin-left: 40px;
    background-color: white;
  }

  div.main-right p {
    font-weight: bold;
  }

  td.main-right {
    float: right;

    .billing {
      width: 127%;
      position: relative;
      right: 60px;
    }
  }

  td.section-left {
    padding-left: 20px;
    width: 72%;

    @media (max-width: 540px) {
      width: 59%;
      position: relative;
      left: -19px;
      text-align: start;
    }
  }

  input#web-calculadora__datepicker-start,
  input#web-calculadora__datepicker-end {
    font-size: 18px;
    font-weight: bold;
  }

  input#web-calculadora1,
  input#web-calculadora2,
  input#web-calculadora3 {
    font-size: 0.875rem;
    // font-weight: bold;
    font-family: $ns-font-proxima;
    margin-top: 0px;
    height: 42px;
    width: 100%;
  }

  p.text-dates.p {
    color: #fff !important;
  }

  label.p {
    color: #fff;
  }

  .esios-web-calculadora__panel {
    padding: 30px;
    background-color: color(ns-lighter);
  }

  .col-sm-3 {
    @media screen and (max-width: 540px) {
      margin-bottom: 18px;
    }
  }

  .col-sm-6:nth-child(2) {
    @media screen and (max-width: 540px) {
      margin-top: 14px;
    }
  }

  .mod-highlight {
    padding: 0;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    min-height: rem(75);
    padding-left: rem(100);
    background-color: transparent;

    @media screen and (max-width: 768px) {
      padding-left: 0;
    }

    &--pvpc {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;

      @media screen and (max-width: 540px) {
        margin-top: 0rem !important;
        margin-bottom: 0rem !important;
      }
    }

    &--analysis {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;

      @media screen and (max-width: 540px) {
        margin-top: 0rem !important;
        margin-bottom: 0rem !important;
      }
    }

    h1 {
      color: #cf4634;
      font-size: 1.875rem;
      margin-bottom: 0;
      margin-top: 0;
      font-family: $ns-font-proxima-bold;
    }

    p {
      margin: 0;
      text-transform: uppercase;
      color: #717171;
      font-family: $ns-font-proxima-regular;
      font-size: rem(14);
    }

    &.pvpc-home {
      @media screen and (max-width: 768px) {
        margin-bottom: rem(10);
        margin-top: rem(15);
        min-height: auto;
      }
    }

    &.pvpc-home a {
      position: relative;
      padding-left: rem(11.2);
      display: block;

      @media screen and (max-width: 768px) {
        padding-left: 0;
      }

      &:hover {
        text-decoration: none;
      }

      &:before {
        content: '';
        position: absolute;
        left: rem(-100);
        width: 6.875rem;
        height: 4.6875rem;
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
        background-image: url($ns-path-img + '/pvpc.svg');
        background-repeat: no-repeat;
        background-position: left;

        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }
  }

  .consumption-mobile {
    display: none;
  }

  @media screen and (max-width: 540px) {
    .consumption-mobile {
      display: block;
    }

    .consumption-desktop {
      display: none;
    }

    #supply-buttons1 {
      position: relative !important;
      margin-top: rem(45);
    }
    .mobile-space {
      margin-top: rem(15);
    }

    .esios-home__container-pvpc {
      display: none;
    }

    p.billing-p {
      float: right;
      font-weight: bold;
    }
  }

  .link-dates::before {
    position: absolute;
    content: '';
    border: 1px solid color(ns-light);
    width: 50px;
    height: 10px;
    top: -11px;
    left: 8px;
    border-bottom: none;
    border-right: none;
  }

  .link-dates::after {
    position: absolute;
    content: '';
    border: 1px solid color(ns-light);
    width: 50px;
    height: 10px;
    bottom: -11px;
    left: 8px;
    border-top: none;
    border-right: none;
  }

  input#web-calculadora {
    width: 100%;
  }

  .datepicker-mod {
    height: auto;
  }

  td {
    color: #444444;
    font-family: $ns-font-proxima, Arial, sans-serif;
  }

  td.main-right {
    float: right;

    @media (max-width: 540px) {
      text-align: right;
    }
  }

  td.section-left {
    padding-left: 20px;
  }

  td label {
    color: color(ns-main-bg);
  }

  p.text-dates.p {
    color: #fff !important;
  }

  label.p {
    color: #fff;
  }

  .esios-web-calculadora__panel {
    padding: 30px;
    //background-color: #f1f1f1;
  }

  .billing {
    // @media (max-width: 540px) {
    //   margin-left: -45%;
    //   margin-right: 0%;
    //   margin-bottom: 0px;
    //   margin-top: 34px;
    //   text-align: center;
    //   width: 195% !important;
    // }
  }

  #TOTAL {
    font-size: 1.75rem;
    color: #444444;
    font-family: 'Cousine';
    font-weight: 600;
  }

  p.billing-p {
    float: right;
    font-weight: bold;
  }

  div#tab-web-calculadora1 {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0 rem(35);
    @media screen and (max-width: 540px) {
      padding: 0;
    }
    strong {
      color: black;
    }
  }

  div#tab-web-calculadora2 {
    strong {
      color: black;
    }
  }

  .ui-tabs-active span.icon-0,
  .ui-tabs-active span.icon-1 {
    pointer-events: none;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVAlRTFnaW5hJTIwMSIgdmlld0JveD0iMCAwIDQ2IDQ2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQ2cHgiIGhlaWdodD0iNDZweCINPg0JPGcgaWQ9IkNhcGElMjAxIj4NCQk8cGF0aCBpZD0iRWxsaXBzZSIgZD0iTSAwIDIzIEMgMCAxMC4yOTcyIDEwLjI5NzMgMCAyMyAwIEMgMzUuNzAyNyAwIDQ2IDEwLjI5NzIgNDYgMjMgQyA0NiAzNS43MDI4IDM1LjcwMjcgNDYgMjMgNDYgQyAxMC4yOTczIDQ2IDAgMzUuNzAyOCAwIDIzIFoiIGZpbGw9IiMwMDY1OWIiLz4NCQk8Zz4NCQkJPHBhdGggZD0iTSAzMC42NDQ1IDcgTCAxMyAyNS44MTE0IEwgMTkuOTQ1OCAyNS40NjY5IEwgMTUuMzU1NSAzOSBMIDMzIDIwLjE4ODYgTCAyNi4wNTM2IDIwLjUzMzEgTCAzMC42NDQ1IDcgWiIgZmlsbD0iI2ZmZmZmZiIvPg0JCTwvZz4NCTwvZz4NPC9zdmc+) !important;
  }
}

@include media-query(xs) {
  .layout-calculator {
    #esios-layout-banner {
      display: none;
    }
  }
  .#{$ns}web-calculadora {
    h1 {
      font-size: rem(20);

      &.#{$ns}web-calculadora__title {
        font-size: rem(23);
      }
    }
    .#{$ns}accordion {
      display: block;
      margin-top: rem(12);
      margin-bottom: rem(24);

      .ui-accordion-header {
        display: flex;
        gap: 10px;

        .icon-0,
        .icon-1 {
          right: 0;
        }
        .tittle-tab {
          width: calc(100% - 60px);
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          color: color(ns-main-bg);

          span {
            margin-left: 5px;
            color: color(ns-main-bg);
          }
        }
      }

      &.ui-accordion {
        .ui-accordion-content {
          padding-bottom: rem(12);
          margin-top: 0;
        }
      }

      .text-tab {
        font-size: rem(16);
        color: color(ns-main-bg);
        font-family: $ns-font-proxima;
      }
    }

    #tabwithcontent {
      display: none;
    }

    .tab-web-calculadora {
      padding: 0 rem(35);
      @media screen and (max-width: 540px) {
        padding: 0;
      }
      display: none;
    }

    .datepicker-mod {
      position: relative;

      &::after {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgMTUgMTUiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgdmVyc2lvbj0iMS4xIg0JeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSINCXg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMTVweCIgaGVpZ2h0PSIxNXB4Ig0+DQk8ZyBpZD0iTGF5ZXIlMjAxIj4NCQk8Zz4NCQkJPHBhdGggZD0iTSAxIDcuNSBDIDEgMy45MTAyIDMuOTEwMSAxIDcuNSAxIEMgMTEuMDg5OSAxIDE0IDMuOTEwMiAxNCA3LjUgQyAxNCAxMS4wODk4IDExLjA4OTkgMTQgNy41IDE0IEMgMy45MTAxIDE0IDEgMTEuMDg5OCAxIDcuNSBaTSAwIDcuNSBDIDAgMTEuNjQyMiAzLjM1NzggMTUgNy41IDE1IEMgMTEuNjQyMiAxNSAxNSAxMS42NDIyIDE1IDcuNSBDIDE1IDMuMzU3OCAxMS42NDIyIDAgNy41IDAgQyAzLjM1NzggMCAwIDMuMzU3OCAwIDcuNSBaIiBmaWxsPSIjNzE3MTcxIi8+DQkJCTxwYXRoIGQ9Ik0gMTAuNjMwOSA5LjEzMjYgTCA4IDcgTCA4IDEuOTY2MiBMIDcgMi4xNzM3IEwgNyA4IEwgMTAuMTkzNSA5Ljg2NjcgTCAxMC42MzA5IDkuMTMyNiBaIiBmaWxsPSIjNzE3MTcxIi8+DQkJPC9nPg0JPC9nPg08L3N2Zz4=);
        background-repeat: no-repeat;
        background-position: center;
        z-index: 0;
        width: rem(30);
        height: rem(30);
        border-left: none;
        /* border-right: 5px solid transparent; */
        border-top: none;
        content: '';
        display: block;
        position: absolute;
        right: rem(0);
        top: 50%;
        @include transform(translateY(-50%));
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgMTUgMTUiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgdmVyc2lvbj0iMS4xIgoJeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIKCXg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMTVweCIgaGVpZ2h0PSIxNXB4Igo+Cgk8ZyBpZD0iTGF5ZXIlMjAxIj4KCQk8cGF0aCBkPSJNIDEwIDMgTCAxMCAyIEwgNSAyIEwgNSAzIEwgNSA1IEwgNCA1IEwgNCAzIEwgNCAyIEwgMSAyIEwgMSAxNCBMIDE0IDE0IEwgMTQgMiBMIDExIDIgTCAxMSAzIEwgMTEgNSBMIDEwIDUgTCAxMCAzIFpNIDEzIDEzIEwgMiAxMyBMIDIgNiBMIDEzIDYgTCAxMyAxMyBaTSAxMiAxMCBMIDEyIDkgTCAxMSA5IEwgMTEgMTAgTCAxMiAxMCBaTSAxMCAxMiBMIDEwIDExIEwgOSAxMSBMIDkgMTIgTCAxMCAxMiBaTSA4IDEyIEwgOCAxMSBMIDcgMTEgTCA3IDEyIEwgOCAxMiBaTSA2IDEyIEwgNiAxMSBMIDUgMTEgTCA1IDEyIEwgNiAxMiBaTSA0IDEyIEwgNCAxMSBMIDMgMTEgTCAzIDEyIEwgNCAxMiBaTSAxMCAxMCBMIDEwIDkgTCA5IDkgTCA5IDEwIEwgMTAgMTAgWk0gOCAxMCBMIDggOSBMIDcgOSBMIDcgMTAgTCA4IDEwIFpNIDYgMTAgTCA2IDkgTCA1IDkgTCA1IDEwIEwgNiAxMCBaTSA0IDEwIEwgNCA5IEwgMyA5IEwgMyAxMCBMIDQgMTAgWk0gMTIgOCBMIDEyIDcgTCAxMSA3IEwgMTEgOCBMIDEyIDggWk0gMTAgOCBMIDEwIDcgTCA5IDcgTCA5IDggTCAxMCA4IFpNIDggOCBMIDggNyBMIDcgNyBMIDcgOCBMIDggOCBaTSA2IDggTCA2IDcgTCA1IDcgTCA1IDggTCA2IDggWk0gMTAgMSBMIDEwIDIgTCAxMSAyIEwgMTEgMSBMIDEwIDEgWk0gNCAxIEwgNCAyIEwgNSAyIEwgNSAxIEwgNCAxIFoiIGZpbGw9IiM3MTcxNzEiLz4KCTwvZz4KPC9zdmc+);
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .datepicker-btn {
      cursor: pointer;
      color: #444444;
      font-family: $ns-font-proxima-regular;
      position: relative;
      padding: rem(10) rem(14) rem(8) rem(2);
      width: 100%;
      height: 100%;
      border-radius: rem(0);
      font-size: rem(12);
      background-color: transparent;
      text-align: center;
      z-index: 1;
      border: 1px solid color(ns-light);
      font-size: rem(12);
      background-color: transparent;
      text-align: center;
    }
  }
}
