////
/// Dashboard component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}dashboard
/// @since version 1.0.0
////

.#{$ns}dashboard {
  min-height: auto;
  height: auto;

  &__dashboard-title {
    color: red;
  }

  .chart {
    // width: 300px;
    // height: 300px;
    position: relative;

    .chartLegend {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;

      h3.title {
        color: #006699;
        margin: 0;
      }

      .value {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
      }
    }
  }
}
