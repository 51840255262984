////
/// ListEnergy component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}esios-listEnergyItems
/// @since version 1.0.0
////

.#{$ns}listEnergyItems {
  &__list {
    margin-top: 20px;
    cursor: default;
  }
  &__wrap {
    @media screen and (max-width: 540px) {
      width: 160%;
    }
    margin-top: rem(27);
    &--home {
      margin-top: 20px !important;
      margin-left: 23px !important;
      @media screen and (max-width: 540px) {
        margin-top: 44px !important;
        margin-left: 1px !important;
        width: 100%;
      }
    }
  }

  &__item-with-value {
    display: flex;
    justify-content: space-between;
    font-size: rem(14) !important;
    line-height: rem(15);
    color: color(ns-main-bg);
    font-family: $ns-font-proxima;
    font-weight: 600;
    gap: rem(5);
    margin-bottom: 0.3rem !important;

    p {
      color: color(black);
    }
    .legend {
      text-transform: uppercase;
      color: color(grey);
    }
    .bold {
      font-family: $ns-font-proxima;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 8px;
    }
    .value {
      font-size: 16px;
      color: #444444;
      display: inline-block;
      white-space: nowrap;
      font-family: $ns-font-cousine;
      letter-spacing: -1.6px;
      font-weight: 400;
      .unit {
        font-size: rem(12);
        color: color(ns-main-bg);
      }
    }
  }

  li.mt-3 {
    margin-top: 29px !important;
  }

  &__list {
    line-height: rem(16);
  }
  &__label {
    display: flex;
    align-items: center;
    gap: 3px;
    color: color(ns-main-bg);
    font-size: 14px;
    font-weight: 600;
    font-family: $ns-font-proxima;
  }
  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.875rem;
    margin: rem(8) 0;
    p {
      color: color(black);
    }
  }
  &__item {
    white-space: nowrap;
  }
  &__decorator-square {
    display: inline-block;
    margin-right: 0.3125rem;
    height: 0.625rem;
    width: 0.625rem;
  }
  &__decorator {
    display: inline-block;
    margin-right: 2px;
    height: 10px;
    width: 10px;
    border-radius: 1.5625rem;
    margin-top: 4px;
    margin-left: 1px;
  }
  &__line {
    display: inline-block;
    vertical-align: top;
    margin-right: rem(2);
    height: 0.125rem;
    width: 0.625rem;
  }
}
