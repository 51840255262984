.#{$ns}list-print-view {
  display: none;

  &__item {
    width: 50%;
    display: inline-block;
    vertical-align: bottom !important;
    padding: rem(20) 0;

    th {
      padding: rem(10);
      color: #444444 !important;
      font-weight: 600;
      font-size: rem(14);
    }

    td {
      color: color(ns-main-bg);
      font-size: rem(14);
    }
  }
}

@media print {
  .#{$ns}list-print-view {
    display: flex;
    flex-flow: wrap;
    &__item {
      tr:nth-child(2n) {
        -webkit-print-color-adjust: exact;
        background-color: transparent;
      }

      &:nth-child(4n + 2) {
        -webkit-print-color-adjust: exact;
        background-color: #f7f7f7 !important;
      }

      &:nth-child(4n + 3) {
        -webkit-print-color-adjust: exact;
        background-color: #f7f7f7 !important;
      }
    }
  }
}
