.esios-analysis__info-chart {
  div.cartodb-popup {
    background: none;
    transform: translateX(-40%);

    div.cartodb-popup-content-wrapper {
      position: relative;
      background: $bg-color-0;
      box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.05);
      border: 1px solid $border-color-1;
      overflow: visible;
      min-width: 230px;

      &::after,
      &::before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        margin-top: -1px;
      }

      &::after {
        border-color: rgba(0, 0, 0, 0);
        border-top-color: #fff;
        border-width: 15px;
        margin-left: -15px;
      }

      &::before {
        border-color: rgba(0, 0, 0, 0);
        border-top-color: $border-color-1;
        border-width: 17px;
        margin-left: -17px;
      }
    }

    div.cartodb-popup-content {
      overflow: visible;
      p {
        font-size: 28px;
        font-weight: 700;
        font-family: $ns-font-cousine;
        letter-spacing: -0.1rem;
      }
    }

    div.cartodb-popup-tip-container {
      background: none;
    }

    h2 {
      color: $text-color-2;
      font-size: $small-size;
    }

    p {
      @extend .is-small-number;
      @extend .is-bold;
      font-size: $number-medium;
    }

    a.cartodb-popup-close-button {
      background: none;
      right: 0;
      top: 7px;
      font-size: 12px;
      font-weight: bold;
      color: $text-color-2;
      text-indent: 0;
      line-height: 26px;
      text-align: center;
    }
  }
}
