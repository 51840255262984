////
/// Tab component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns} Tab
/// @since version 1.0.0
////

.#{$ns}resultados {
  font-family: $ns-font-proxima;
  min-height: 470px;
  color: color(ns-main-bg) !important;

  .header {
    display: flex;
    justify-content: space-between;
  }

  .esios-market-prices__form {
    margin-top: 0px;
    width: 100%;
    margin-left: 0px;

    .esios-tabla__search-container.d-flex {
      padding-left: 0px;
      padding-right: 0px;
    }

    &.row {
      --bs-gutter-x: 0px;
    }
  }

  form {
    margin-bottom: 10px;
  }

  #btn-filter {
    padding-right: 25px;
    padding-left: 10px;
    border-radius: 50px;
    position: relative;
  }

  #btn-filter:hover {
    background-color: #f7f7f7;
  }

  #btn-filter::after {
    content: '';
    margin-top: 6px;
    right: 3px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #717171;
    z-index: 1;
    pointer-events: none;
    position: absolute;
  }

  .filter-off::after {
    transform: rotate(0deg);
  }

  .filter-on::after {
    transform: rotate(180deg);
  }

  .esios-documentacion__boton-remove {
    top: 35%;
  }

  .all-filters {
    display: flex;
    margin-top: 10px;
  }

  .option-filter {
    display: flex;
    align-items: center;
    input,
    select {
      margin-left: 5px;
    }
    &:nth-child(2) {
      margin-left: 15px;
    }
  }

  .esios-market-prices__form .search-filter {
    right: 7px;
  }

  &__btn {
    display: inline-block;
    font-size: 0.875rem;
    border: 1px solid #dbdbdb;
    border-radius: 1.25rem;
    background-color: #fff;
    background-clip: padding-box;
    color: #069;
    font-weight: 700;
    margin-right: 0.3rem;
    text-transform: uppercase;
    text-align: center;
    padding: 0.4375rem 1.25rem;
    font-size: 14px;
    font-family: $ns-font-proxima;
    margin-top: rem(-1);

    &:hover {
      text-decoration: none;
      background-color: #f7f7f7;
      cursor: pointer;
    }
  }
  .esios-documentacion__c {
    margin-top: 0px;
  }

  .esios-documentacion__tabla__input {
    margin-top: 0px;
  }

  .esios-documentacion__wrap2 {
    margin-top: 0px;
  }

  .spinner-container {
    margin-top: 45px;
  }

  #dl-results {
    display: none !important;
  }

  .esios-tabla__search-container {
    display: none !important;
  }

  #pagination {
    display: none !important;
  }

  &__button-remove {
    position: absolute;
    right: 12px;
    top: 25px;
    background-image: url('../../src/assets/images/button-remove.svg');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    padding-right: 17px;
    opacity: 0.4;
    border: none;
    &.hidden {
      visibility: hidden;
    }
    @media screen and (max-width: 540px) {
      top: 27px;
    }
  }

  .btn-selected {
    background-color: #069;
    color: white;
  }

  &__filter-buttons {
    margin-bottom: rem(8);
    margin-left: rem(2);
  }

  &__containerInput {
    position: relative;
  }
  &__title {
    color: color(ns-primary);
    font-size: 23px;
    font-weight: 800;
    font-family: $ns-font-proxima;
    margin-top: rem(15.41);
    margin-bottom: rem(16);
    text-transform: uppercase;
    margin-left: rem(1);
    margin-top: rem(18);
  }
  &__opinion {
    color: color(ns-main-bg);
    font-family: $ns-font-proxima;
  }
  &__strongOpinion {
    color: #444444;
    font-family: $ns-font-proxima;
  }
  li {
    padding: 0.625rem 1.25rem;
  }
  ul.table-data li:nth-child(odd) {
    background-color: color(ns-lighter);
  }
  &__contenido {
    word-wrap: break-word;
    overflow: hidden;
    font-family: $ns-font-proxima;
    color: color(ns-main-bg) !important;
    width: 70%;
  }
  &__contenidoBody {
    color: color(ns-main-bg) !important;
    font-family: $ns-font-proxima;
    line-height: 1.2;
    margin: 0 0 rem(16);
  }
  &__contenidoBody p,
  &__contenidoBody h2 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__search-filter {
    position: absolute;
    background-repeat: no-repeat;
    background-position: 95% 50%;
    right: 10px;
    top: 38px;
    transform: translateY(calc(-50% + 1px));
    right: 8px;
  }

  &__search-filter img {
    width: 100%;
    height: auto;
  }

  p {
    font-family: $ns-font-proxima;
    color: color(ns-main-bg) !important;
    font-size: 16px;
    font-weight: 400;
    width: 99%;
    margin-top: 1px;
    margin-left: 1px;
  }
  h2 {
    font-family: $ns-font-proxima;
    color: color(ns-main-bg) !important;
    font-size: 16px;
  }

  .ul {
    color: color(ns-main-bg) !important;
  }
  .li {
    color: color(ns-main-bg) !important;
  }
  &__contenidoTitle {
    font-size: 16px;
    text-transform: uppercase;
    color: #006699;
    font-weight: 600;
    font-family: $ns-font-proxima;
    margin: 1rem 0 1.125rem;
    display: inline-block;
    margin-left: rem(1);

    &:hover {
      color: color(ns-primary);
    }
  }
  &__buscador {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    -webkit-font-smoothing: antialiased;
    color: color(ns-main-bg);
    font: inherit;
    margin: 0;
    line-height: normal;
    appearance: none;
    box-shadow: none;
    background: none;
    border-radius: 0;
    box-sizing: border-box !important;
    padding: 0.625rem 1.25rem;
    border: 1px solid #dbdbdb;
    font-size: 0.875rem;
    background-color: white;
    width: 100%;
    margin-top: rem(15);
    // margin-bottom: rem(20);
    font-weight: 600;
  }

  .mod-tabs .ui-tabs .ui-tabs-nav li,
  .mod-tabs .ui-tabs .ui-tabs-nav a,
  .mod-tabs .ui-tabs-anchor {
    border-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .mod-tabs .ui-corner-top {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }

  ul {
    margin-bottom: rem(34);
  }
}

@media screen and (max-width: 1199px) {
  .#{$ns}resultados {
    padding-left: rem(27.2);
  }
}

@media screen and (max-width: 767px) {
  .#{$ns}resultados {
    padding-left: 0;
  }
}

@media screen and (max-width: 539px) {
  .#{$ns}resultados {
    &__filter-buttons {
      margin-top: rem(36);
      margin-bottom: rem(40);
    }

    &__btn {
      margin-bottom: rem(12);
    }

    &__contenido {
      width: 100%;
    }

    #btn-filter {
      text-align: right;

      &::after {
        margin-top: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .search-page {
    .esios-layout-banner {
      top: 0px;
    }
    .esios-layout-back__container {
      top: 0px;
    }

    .esios-layout__main {
      top: 0px;
    }
  }
}

@media screen and (max-width: 540px) {
  .all-filters {
    flex-direction: column;
  }

  .esios-resultados__filter-buttons {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .esios-documentacion__wrap {
    margin-bottom: 15px;
  }

  .esios-documentacion__content {
    margin-top: 0px;
  }

  #document-list-myp {
    display: block;
  }

  .select-filter.market-select-filter {
    display: flex;
    flex-direction: column;
  }

  .esios-resultados .option-filter:nth-child(2) {
    margin-left: 0px;
  }
}

@media screen and (max-width: 451px) {
  .search-page {
    #esios-layout-banner {
      display: none !important;
    }

    .esios-layout-banner {
      top: 0px;
    }

    .esios-layout__breadcrumbs {
      display: none !important;
    }

    .esios-layout-back__container {
      top: 0px;
    }

    .esios-layout__main {
      top: 0px;
    }
  }
}
