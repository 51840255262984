////
/// Conexion Codigos component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}conexion
/// @since version 1.0.0
////

.#{$ns}conexion {
  margin-left: 0px;
  margin-right: 20px;
  margin-top: 15px;

  span {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    color: #444444;
    font-family: $ns-font-proxima;
    font-size: 1rem;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    position: absolute;
    width: 22px;
    height: 15px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IlVudGl0bGVkLVBhZ2VfeDI1XzIwMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIKCSB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDIyIDE1IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyMiAxNSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxwYXRoIGZpbGw9Im5vbmUiIGQ9InoiLz4KPGcgaWQ9IkxheWVyX3gyNV8yMDEiPgoJPHBhdGggZmlsbD0iI0RDNEEzOCIgZD0iTTIyLDBINS42TDAsNS45VjE1aDIyVjB6Ii8+Cgk8cGF0aCBvcGFjaXR5PSIwLjUwMiIgZmlsbD0iI0ZGRkZGRiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAgICAiIGQ9Ik02LDBMMCw2aDZWMHoiLz4KCTxnPgoJCTxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0xNCwxMVY4aDEuN2MwLjQsMCwwLjcsMCwxLTAuMmMwLjMtMC4xLDAuNS0wLjIsMC43LTAuNHMwLjMtMC40LDAuNC0wLjZDMTcuOSw2LjUsMTgsNi4zLDE4LDYKCQkJYzAtMC4zLDAtMC41LTAuMS0wLjhjLTAuMS0wLjItMC4yLTAuNS0wLjQtMC42Yy0wLjItMC4yLTAuNC0wLjMtMC43LTAuNEMxNi41LDQuMSwxNi4yLDQsMTUuOSw0SDEzdjdIMTRMMTQsMTF6IE0xNCw1aDEuOAoJCQljMC4zLDAsMC42LDAuMSwwLjgsMC4zQzE2LjksNS41LDE3LDUuNywxNyw2YzAsMC4zLTAuMSwwLjUtMC4zLDAuN0MxNi40LDYuOSwxNi4yLDcsMTUuOCw3SDE0VjVMMTQsNXoiLz4KCTwvZz4KPC9nPgo8cGF0aCBmaWxsPSJub25lIiBkPSJ6Ii8+Cjwvc3ZnPg==);
    background-repeat: no-repeat;
    background-position: left;
  }
  ul li {
    color: #444444;
  }
  .layout {
    display: grid;
    height: 100%;
    width: 100%;
    overflow: hidden;
    grid-template-rows: 44px 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .line {
    width: 20px;
    border-bottom: 1px solid #dbdbdb;
    height: 41px;
  }

  .line-inter {
    float: left;
    width: 10px;
    border-bottom: 1px solid #dbdbdb;
    height: 41px;
  }

  ul {
    font-size: 1rem;
    line-height: 1.2;
    display: block;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    padding-left: 40px;
  }
  strong {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    color: #444444;
    font-family: $ns-font-proxima;
    font-size: 1rem;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
  }
  em {
    color: #444444;
    font-style: italic;
  }
  div:nth-child(1) p:nth-child(3) a:nth-child(2) {
    left: 0rem;
  }
  div.about-page :nth-child(2) a:nth-child(2) {
    left: 0rem;
  }

  div.about-page :nth-child(1) :nth-child(1) a {
    left: 0rem;
  }
  div.about-page p:nth-child(9) a:nth-child(2),
  div.about-page p:nth-child(11) a:nth-child(2) {
    left: 0rem;
  }
  div.about-page p:nth-child(12) a:nth-child(2) {
    left: 2.1875rem;
    position: relative;
  }
  div.about-page ul:nth-child(3) {
    margin-left: 0rem !important;
  }
  div.about-page ul:nth-child(14) {
    margin-left: 0rem !important;
  }
  a:nth-child(2) {
    left: 2.1875rem;
    position: relative;
  }
  div.contact-page table {
    margin-bottom: 4%;
    margin-top: 2%;
  }
  div.contact-page table:nth-child(3) a:nth-child(2) {
    left: 2.1875rem;
  }
  div.contact-page a:nth-child(2) {
    color: #444444 !important;
    left: 0rem;
    font-weight: 700;
  }
  div.contact-page p:nth-child(19) a:nth-child(2) {
    left: 2.1875rem;
  }
  div.contact-page p:nth-child(20) span:nth-child(1) {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    color: #444444;
    font-family: $ns-font-proxima;
    font-size: 1rem;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    position: relative;
    width: 0px;
    height: 0px;
    background-image: unset;
    background-repeat: no-repeat;
    background-position: left;
  }
  div.contact-page p:nth-child(20) span:nth-child(3) {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    color: #444444;
    font-family: $ns-font-proxima;
    font-size: 1rem;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    position: relative;
    width: 0px;
    height: 0px;
    background-image: unset;
    background-repeat: no-repeat;
    background-position: left;
  }
  div.contact-page p:nth-child(21) a:nth-child(2) {
    left: 2.1875rem;
  }
  div.contact-page p:nth-child(22) a:nth-child(2) {
    left: 2.1875rem;
  }
  h3 {
    margin-top: 2%;
    margin-bottom: 2%;
    strong {
      -webkit-text-size-adjust: 100%;
      -webkit-box-direction: normal;
      font-family: $ns-font-proxima;
      line-height: 1.2;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      color: #717171 !important;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1rem;
    }
  }
  a {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    font-size: 1rem;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    background-color: transparent;
    color: #006699 !important;
    text-decoration: none;
    font-weight: 600;
    font-family: $ns-font-proxima;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  p {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    color: #444444;
    font-family: $ns-font-proxima;
    font-size: 1rem;
    line-height: 1.196;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 14px;
    font-weight: 400;
  }
  h2 {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    font-family: $ns-font-proxima;
    font-weight: 700;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    color: #006699;
    text-transform: uppercase;
    font-size: 1.1875rem;
    margin-bottom: 15px;
  }
  &__title {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    font-family: $ns-font-proxima;
    font-weight: 800;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    color: #006699;
    text-transform: uppercase;
    font-size: 23px;
    margin-bottom: 27px;
    letter-spacing: 0.1px;
  }

  // Content

  html,
  body {
    height: 100%;
    color: #1a1b1c;
  }

  label,
  a {
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    display: inline-block;
    color: inherit;
    transition: border 0.2s;
    padding: 0.4px 2px;
  }

  .layout {
    display: grid;
    height: 100%;
    width: 100%;
    overflow: hidden;
    grid-template-rows: 50px 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  input[type='radio'] {
    display: none;
    font-family: $ns-font-proxima;
  }
  input[type='radio'] + .page {
    transition: max-height 0.5s;
  }
  .page {
    grid-column-start: 1;
    grid-row-start: 2;
    grid-column-end: span 3;
    display: flex;
  }
  .page-contents > * {
    display: none;
    animation: fadeEffect 0.5s;
    @for $c from 1 through 20 {
      &:nth-child(#{$c}) {
        transition-delay: 0.08s + ($c * 0.08s);
      }
    }
  }

  input[type='radio']:checked + .page {
    transition: max-height 0.5s;
    .page-contents > * {
      display: block;
      transform: translateY(0px);
      margin-top: 5px;
      margin-bottom: 15.8px;
      margin-left: 4px;
    }
  }

  .page-contents {
    max-width: 97%;
    width: 1100px;
    margin-top: 10px;
    padding-left: rem(8);
    animation: fadeEffect 0.5s;
    ul + li + p {
      color: red;
    }

    .content-paragraph__table {
      margin-left: 20px;
      &__text {
        color: color(black);
        &--bold {
          color: color(black);
        }
        &--link {
          color: color(black);
        }
      }
    }
    .content-paragraph {
      &__text {
        color: color(black);
        &--bold {
          color: color(black);
        }
        &--link {
          color: color(black);
        }
      }
    }
  }

  .u-bold {
    font-weight: 500;
  }
  label.nav {
    font-family: $ns-font-proxima !important;
    font-size: 17px;
    display: flex;
    height: 50px;
    font-weight: 100;
    font-weight: 600;
    font-family: $ns-font-proxima;
    align-items: center;
    justify-content: center;
    color: #717171;
    cursor: pointer;
    border: 1px solid #dbdbdb;
    background: #f7f7f7;
    user-select: none;
    padding-left: 0;
    transition: background 0.4s, padding-left 0.2s;
    height: 41px;
    width: 343px;
    &:hover:not(.nav-selected) {
      background-color: #efefef;
    }
    input[type='radio']:checked + .page + & {
      background: white;
      color: #006699;
      font-family: $ns-font-proxima;
      width: 346px;
      height: 41px;
      margin-left: 18px;
    }
  }

  label.nav-selected {
    background: white;
    color: #006699;
    border-bottom: 1px solid white;
  }

  .page-contents p:nth-child(8) {
    max-width: 99%;
  }

  .page-contents p:nth-child(9) {
    width: 99%;
  }

  .page-contents p:nth-child(12) a {
    margin-left: 0px;
  }

  .page-contents p:nth-child(15) a:nth-child(2) {
    margin-left: -35px;
    padding-left: 0;
    width: 182px;
  }

  .#{$ns}accordion {
    display: none;
  }
}

@media screen and (max-width: 1199px) {
  .#{$ns}conexion {
    padding-left: rem(27.2);
  }
}

@media screen and (max-width: 767px) {
  .#{$ns}conexion {
    padding-left: 0;
  }
}

@media screen and (max-width: 540px) {
  .#{$ns}conexion {
    margin-right: 0;

    &__title {
      margin-bottom: rem(13);
      width: 104%;
      margin-top: 18px;
      margin-left: 1px;
    }

    .layout {
      display: none;
    }

    .#{$ns}accordion {
      display: block;
      width: 100%;

      h2 {
        font-size: rem(14);
        padding: rem(11.62) 0;
        margin-bottom: 1px;
      }

      ul + table td {
        padding: rem(10);

        h2 {
          font-size: rem(14);

          & ~ p {
            & > strong + span {
              position: static;
              background-image: none;

              &:before {
                content: none;
              }
            }
          }
        }

        span {
          position: static;
          background-image: none;

          &:before {
            content: '';
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IlVudGl0bGVkLVBhZ2VfeDI1XzIwMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIKCSB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDIyIDE1IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyMiAxNSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxwYXRoIGZpbGw9Im5vbmUiIGQ9InoiLz4KPGcgaWQ9IkxheWVyX3gyNV8yMDEiPgoJPHBhdGggZmlsbD0iI0RDNEEzOCIgZD0iTTIyLDBINS42TDAsNS45VjE1aDIyVjB6Ii8+Cgk8cGF0aCBvcGFjaXR5PSIwLjUwMiIgZmlsbD0iI0ZGRkZGRiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAgICAiIGQ9Ik02LDBMMCw2aDZWMHoiLz4KCTxnPgoJCTxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0xNCwxMVY4aDEuN2MwLjQsMCwwLjcsMCwxLTAuMmMwLjMtMC4xLDAuNS0wLjIsMC43LTAuNHMwLjMtMC40LDAuNC0wLjZDMTcuOSw2LjUsMTgsNi4zLDE4LDYKCQkJYzAtMC4zLDAtMC41LTAuMS0wLjhjLTAuMS0wLjItMC4yLTAuNS0wLjQtMC42Yy0wLjItMC4yLTAuNC0wLjMtMC43LTAuNEMxNi41LDQuMSwxNi4yLDQsMTUuOSw0SDEzdjdIMTRMMTQsMTF6IE0xNCw1aDEuOAoJCQljMC4zLDAsMC42LDAuMSwwLjgsMC4zQzE2LjksNS41LDE3LDUuNywxNyw2YzAsMC4zLTAuMSwwLjUtMC4zLDAuN0MxNi40LDYuOSwxNi4yLDcsMTUuOCw3SDE0VjVMMTQsNXoiLz4KCTwvZz4KPC9nPgo8cGF0aCBmaWxsPSJub25lIiBkPSJ6Ii8+Cjwvc3ZnPg==);
            width: rem(22);
            height: rem(15);
            display: inline-block;
            margin-right: rem(12);
          }

          & + a {
            color: color(ns-main-bg) !important;
          }
        }
      }
    }

    a:nth-child(2) {
      position: static;
    }

    .icon-download-pdf {
      position: static;
      display: inline-block;
      vertical-align: top;
      margin-right: rem(12);
    }

    p {
      & + table {
        tbody {
          td {
            padding: rem(10);
          }
        }

        .row-icon.icon-download-pdf {
          & + a {
            font-size: rem(14);
          }
        }

        & + ul {
          & > li {
            & > p {
              margin: rem(16) 0;
            }
          }
        }
      }
    }
  }
}

.page.contact-page .page-contents .page-contents ul + ul + ul + table tr td {
  padding: 25px 20px;
}

body {
  justify-content: initial;
}
