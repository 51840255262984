.#{$ns}usuarios {
    a {
        text-decoration: none !important;
    }

    &__search {
        text-align: right;
    }

    &__input {
        padding: 5px 15px;
        border-radius: 5px;
        border: 2px solid #dddddd
    }

    &__inputs-edit {
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.428571429;
        color: #555555;
        background-color: white;
        background-image: none;
        border: 1px solid #cccccc;
        border-radius: 4px;
    }

    &__edit {
        margin: 0 10px;
        padding: 5px;
        background: #02a802;
        color: white !important;
        border-radius: 5px;
        text-decoration: none;

        &--see-user {
            margin-left: 0;
        }
    }

    &__api-key {
        margin: 0 10px;
        padding: 5px;
        background: #428bca;
        color: white !important;
        border-radius: 5px;
        border-color: #357ebd;
        text-decoration: none;
    }

    &__pagination {
        margin-top: 10px;
        text-align: right;
    }

    &__pag-element {
        display: inline-block;
        margin: 0;
        padding: 5px 10px;
        border: 1px solid #dddddd;
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    td,
    th {
        border-top: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }

    tr:first-child th {
        border: none;
    }

    tr:nth-child(even) {
        background-color: #f5f0f0;
    }
}