////
/// Propuesta component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}propuesta
/// @since version 1.0.0
////

.#{$ns}propuesta {
  max-width: 1032.64px;
  width: 100%;
  margin-bottom: 56px;

  .texto-intro {
    margin-bottom: 18px;
    font-family: $ns-font-proxima;
    font-size: 16px;
    line-height: 1.2rem;
    font-weight: 400;
    p {
      font-size: 16px;
    }
  }

  h1 {
    color: color(ns-primary);
    margin: 18px 0 20px 0;
    font-size: 23px;
    font-family: $ns-font-proxima;
    text-transform: uppercase;
    font-weight: 800;
  }

  h2 {
    color: color(ns-primary);
    margin: 15px 0;
    font-size: 19px;
    font-weight: 700;
    font-family: $ns-font-proxima;
  }

  hr {
    border: 0;
    margin: 1.875rem 0;
    background-color: color(ns-border-ligth);
    height: 1px;
    box-sizing: content-box;
  }

  p {
    color: #444444;
    font-family: $ns-font-proxima;
    line-height: 1.2;
    font-size: 0.875rem;
    hyphens: auto;
    word-wrap: break-word;
    font-weight: 400;
  }

  a {
    font-family: $ns-font-proxima;
    line-height: 1.2;
    font-size: 0.875rem;
    word-wrap: break-word;
    color: #444444;
    font-weight: 700;
    max-width: 90%;
    position: relative;
  }

  span {
    color: #444444;
    font-family: $ns-font-proxima;
    line-height: 1.2;
    font-size: 0.875rem;
    word-wrap: break-word;
    text-align: center;
    margin-right: rem(20);
  }

  span.icon-download-zip {
    color: #444444;
    font-family: $ns-font-proxima;
    line-height: 1.2;
    font-size: 0.875rem;
    position: absolute;
    width: 22px;
    height: 15px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgdmlld0JveD0iMCAwIDIyIDE1IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSIKCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiCgl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjIycHgiIGhlaWdodD0iMTVweCIKPgoJPGc+CgkJPHBhdGggZD0iTSAyMiAwIEwgNS41NTQzIDAgTCAwIDUuODg3MSBMIDAgMTUgTCAyMiAxNSBMIDIyIDAgWiIgZmlsbD0iI2Q5YTMwMCIvPgoJCTxwYXRoIGQ9Ik0gNiAwIEwgMCA2IEwgNiA2IEwgNiAwIFoiIGZpbGw9IiNlY2QxODAiLz4KCQk8cGF0aCBkPSJNIDEyIDYgTCAxNSA2IEwgMTUgNCBMIDEyIDQgTCAxMiA2IFoiIGZpbGw9IiNlY2QxODAiLz4KCQk8cGF0aCBkPSJNIDEyIDIgTCAxNSAyIEwgMTUgMCBMIDEyIDAgTCAxMiAyIFoiIGZpbGw9IiNlY2QxODAiLz4KCQk8cGF0aCBkPSJNIDE0IDQgTCAxNyA0IEwgMTcgMiBMIDE0IDIgTCAxNCA0IFoiIGZpbGw9IiNlY2QxODAiLz4KCQk8cGF0aCBkPSJNIDEyIDEwIEwgMTUgMTAgTCAxNSA4IEwgMTIgOCBMIDEyIDEwIFoiIGZpbGw9IiNlY2QxODAiLz4KCQk8cGF0aCBkPSJNIDE0IDggTCAxNyA4IEwgMTcgNiBMIDE0IDYgTCAxNCA4IFoiIGZpbGw9IiNlY2QxODAiLz4KCQk8cGF0aCBkPSJNIDEyIDEyIEwgMTcgMTIgTCAxNyAxMCBMIDEyIDEwIEwgMTIgMTIgWiIgZmlsbD0iI2VjZDE4MCIvPgoJPC9nPgo8L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: left;
    padding-top: rem(20);
  }

  span.icon-download-pdf {
    color: #444444;
    font-family: $ns-font-proxima;
    line-height: 1.2;
    border-spacing: 0;
    font-size: 0.875rem;
    word-wrap: break-word;
    position: absolute;
    width: 22px;
    height: 15px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IlVudGl0bGVkLVBhZ2VfeDI1XzIwMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIKCSB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDIyIDE1IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyMiAxNSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxwYXRoIGZpbGw9Im5vbmUiIGQ9InoiLz4KPGcgaWQ9IkxheWVyX3gyNV8yMDEiPgoJPHBhdGggZmlsbD0iI0RDNEEzOCIgZD0iTTIyLDBINS42TDAsNS45VjE1aDIyVjB6Ii8+Cgk8cGF0aCBvcGFjaXR5PSIwLjUwMiIgZmlsbD0iI0ZGRkZGRiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAgICAiIGQ9Ik02LDBMMCw2aDZWMHoiLz4KCTxnPgoJCTxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0xNCwxMVY4aDEuN2MwLjQsMCwwLjcsMCwxLTAuMmMwLjMtMC4xLDAuNS0wLjIsMC43LTAuNHMwLjMtMC40LDAuNC0wLjZDMTcuOSw2LjUsMTgsNi4zLDE4LDYKCQkJYzAtMC4zLDAtMC41LTAuMS0wLjhjLTAuMS0wLjItMC4yLTAuNS0wLjQtMC42Yy0wLjItMC4yLTAuNC0wLjMtMC43LTAuNEMxNi41LDQuMSwxNi4yLDQsMTUuOSw0SDEzdjdIMTRMMTQsMTF6IE0xNCw1aDEuOAoJCQljMC4zLDAsMC42LDAuMSwwLjgsMC4zQzE2LjksNS41LDE3LDUuNywxNyw2YzAsMC4zLTAuMSwwLjUtMC4zLDAuN0MxNi40LDYuOSwxNi4yLDcsMTUuOCw3SDE0VjVMMTQsNXoiLz4KCTwvZz4KPC9nPgo8cGF0aCBmaWxsPSJub25lIiBkPSJ6Ii8+Cjwvc3ZnPg==);
    background-repeat: no-repeat;
    background-position: left;
    padding-top: rem(20);
  }

  table:nth-child(4) {
    border: 1px solid #404040;
    padding: 15px;
    margin-top: 39px;
    margin-bottom: 44px;
  }

  p:nth-child(2) {
    margin-top: 8px;
    margin-bottom: 17px;
  }

  td:nth-child(2) {
    font-family: $ns-font-proxima;
  }

  p:nth-child(1) {
    margin-top: 13px;
    margin-bottom: 11px;
  }

  p:nth-child(1) strong:nth-child(2) {
    color: #444444;
  }

  a:nth-child(2) {
    padding: rem(2);
    font-family: $ns-font-proxima;
    font-weight: 600;
    line-height: 1.2;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: transparent;
    text-decoration: none;
    color: #444444;
    font-weight: 700;
    max-width: 90%;
    position: relative;
    left: rem(20);
    margin-left: rem(15);
  }

  td {
    color: #444444;
    font-family: $ns-font-proxima;
    line-height: 1.2;
    width: 85%;
    font-size: 0.875rem;
    font-weight: 600;
    word-wrap: break-word;
    position: relative;
    display: table-cell;
    padding: 0.625rem 1.25rem;
    vertical-align: middle;

    &:last-child {
      font-family: $ns-font-proxima;
      padding-left: 21px;
      font-weight: 400;
    }

    a:focus,
    a:hover {
      text-decoration: underline;
    }
  }

  table:last-child {
    width: 100%;
    margin-top: 40px;
  }

  th {
    color: #444444 !important;
    font-family: $ns-font-proxima;
    line-height: 1.2;
    font-size: 14px;
    text-align: left;
    position: relative;
    display: table-cell;
    padding: 0.625rem 1.25rem;
    vertical-align: top;
    font-weight: 600;
    :nth-child(2) {
      padding-right: 0;
      margin-right: 0;
    }
    :nth-child(3) {
      padding-left: 0;
      margin-left: 0;
    }
  }

  tbody {
    color: #444444;
    font-family: $ns-font-proxima;
    line-height: 1.2;
    border-spacing: 0;
    font-size: 0.875rem;
    word-wrap: break-word;
  }

  tr {
    color: #444444;
    font-family: $ns-font-proxima;
    line-height: 1.2;
    font-weight: 400;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: white;
  }

  tr:nth-child(even) {
    background-color: #f7f7f7;
  }

  @media screen and (max-width: 540px) {
    padding-left: 0;
    h1 {
      letter-spacing: 0px;
      line-height: 26px;
    }

    th {
      padding: rem(10);
    }

    td {
      padding: rem(10);
    }
  }
}

@media screen and (max-width: 1199px) {
  .#{$ns}propuesta {
    padding-left: rem(27.2);
  }
}

@media screen and (max-width: 767px) {
  .#{$ns}propuesta {
    padding-left: 0;
  }
}

@media screen and (max-width: 539px) {
  .#{$ns}propuesta {
    table:last-child {
      margin-top: 0;
    }
  }
}
