////
/// Dashboard component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}gestion-demanda
/// @since version 1.0.0
////

.#{$ns}gd {
  width: 100%;
  &__row--content0 {
    .esios-tab .mod-tabs .ui-widget-header {
      margin-left: -19px !important;
    }
    .tab1-gd-content {
      & > div {
        margin-left: 0px;
      }

      p {
        width: 100% !important;
      }

      a {
        font-weight: 700;
        color: #006699;
        text-decoration: none;
      }
    }
    // #tab1-gd-content2 {
    //   padding-bottom: 3rem;
    // }
    #tab1-gd-content3 {
      button {
        margin: 2rem 0 6rem 0;
      }
      strong {
        color: #444444;
      }
    }
  }
  &__secondary-values ul {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;

    & li {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 5px;
        left: -20px;
        width: 8px;
        height: 8px;
        background-color: rgb(219, 219, 219);
        border-radius: 50%;
      }
    }

    @media screen and (min-width: 540px) {
      & {
        padding-left: 1.25rem;
      }
    }
  }
  #tabwithcontent1 {
    .row {
      margin: 0;
    }
  }

  #tabwithcontent1 {
    @media (max-width: 540px) {
      display: none;
    }
  }

  #accordion1,
  #accordion2 {
    display: none;

    @media (max-width: 540px) {
      display: block;
      padding-left: 20px;
      width: 93%;

      h2 {
        font-size: rem(14);
        font-family: $ns-font-proxima;
        color: color(ns-main-bg);
        font-weight: 700;
        line-height: 1.2;
      }

      .ui-accordion-header.ui-corner-top.ui-accordion-header-collapsed.ui-corner-all.ui-state-default {
        text-align: start !important;
        padding: rem(13) rem(30) rem(13) rem(13.4) !important;
        font-weight: 700;
        margin-top: 11px;
      }
      .ui-accordion-header.ui-corner-top.ui-state-default.ui-accordion-header-active.ui-state-active {
        text-align: start !important;
        padding-left: rem(13.4) !important;
        font-weight: 700;
      }
      .ui-accordion-header.ui-corner-top.ui-state-default.ui-state-focus.ui-state-hover {
        text-align: start !important;
        padding-left: rem(13.4) !important;
      }
      .esios-gd__secondary-values {
        margin-left: 1px;
        width: 95%;
        margin-top: -20px;
        padding-bottom: 12px;
      }

      .esios-gd__secondary-values > p,
      .esios-gd__secondary-values > ul li,
      .esios-gd__secondary-values > ul li p,
      .esios-gd__secondary-values > a[class$='__mail'],
      .esios-gd__secondary-values em,
      .esios-gd__secondary-values div > p {
        font-size: 14px !important;
        width: 99%;
        line-height: normal;
      }

      .esios-gd__secondary-values > p,
      .esios-gd__secondary-values div > p {
        margin-bottom: 30px;
      }

      .esios-gd__before-mail {
        margin-bottom: -5px !important;
      }
      .esios-gd__mod-action-buttons {
        justify-content: center;
      }
      .btn.icon-download {
        display: inline-block;
        font-size: 0.875rem;
        border-radius: 1.25rem;
        background-color: #069;
        background-clip: padding-box;
        color: #fff !important;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        padding: 0.4375rem 4.55rem;
        background-position: right 3px center;
      }
    }
  }

  @media (max-width: 540px) {
    .tab__content {
      display: none;
    }

    .esios-gd__row--content0 {
      border-bottom: 0px solid #dbdbdb;
    }
  }

  #accordion1 {
    a {
      font-weight: 600;
      color: #006699;
      text-decoration: none;
    }
    .esios-gd__secondary-values p:nth-child(3) {
      margin: 0 !important;
    }
    button {
      margin: 3rem 0 1rem 0;
    }
    strong {
      white-space: initial !important;
      font-size: 14px !important;
      letter-spacing: -0.01rem;
      font-family: 'Proxima-Nova', Arial, sans-serif;
      color: #444444;
    }
    ul {
      margin: 0;
      list-style: none;
      padding-left: 3rem;
    }
    .ui-accordion-header {
      white-space: initial !important;
    }

    .esios-gd__secondary-values > p {
      margin-bottom: 5px !important;
    }

    .tab__content {
      display: none;

      .is-sm-hidden {
        display: none;
      }
    }

    .esios-gd__secondary-values > ul li:before {
      display: none;
    }
  }

  &__main-title {
    margin-left: -1px;
    margin-top: 14px;
  }

  &__row {
    .esios-gd__widget-toolbar {
      .esios-toolbar {
        &__component {
          margin-top: 1px;
          margin-left: -1px;
        }
        &__items {
          margin-right: -2px;
        }
        #timer1 {
          top: 5px;
        }
      }
    }
    #indicadores {
      margin-left: -2px;
    }
    .col-xs-7 {
      padding: 0px;
      margin-left: -4px;
      margin-top: 2px;
      .esios-toolbar-time#selectTimeDesglose {
        left: 6px;
      }

      .esios-selector__btn {
        .esios-selector__selected {
          cursor: pointer;
          color: #444444 !important;
          font-family: 'Cousine' !important;
          position: relative;
          padding: 0 1.5625rem;
          width: fit-content;
          height: 1.4375rem;
          line-height: 1.4375rem;
          border-radius: 0.625rem;
          font-size: 0.75rem;
          font-weight: 600;
          background-color: #dbdbdb;
          padding-right: 1.875rem;
          font-family: 'Cousine' !important;
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
          margin-bottom: 1.25rem;
          width: 100px;
          padding-left: 15px;
          top: -3px;
          span {
            font-weight: bold;
            color: #000 !important;
          }
          &::after {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #717171;
            content: '';
            display: block;
            position: absolute;
            right: 0.75rem;
            top: 50%;
            -webkit-transform: translatey(-75%);
            -ms-transform: translatey(-75%);
            transform: translatey(-75%);
            pointer-events: none;
          }
        }
      }
      .esios-selector__options {
        top: 30px;
        width: 8.75rem;
        padding: 0;
        position: absolute;
        z-index: 2;
        height: auto;
        border: none;
        border-top: none;
        display: grid !important;
        max-height: initial;
        overflow: hidden;
        overflow-y: auto;
        display: flex;
        flex-direction: column;

        .arrow-con {
          background: #f7f7f7;
          position: relative;

          .shadow {
            content: '';
            position: absolute;
            border: 1px solid #bcbcbc;
            background: color(white);
            min-height: 65px;
            width: 96%;
            height: 100%;
            left: 0px;
            top: 15px;
            bottom: 0;
            z-index: 999;
            box-shadow: 5px 5px 5px #333;
          }
        }
        .top-arrow {
          content: '';
          width: 0;
          height: 0;
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-bottom: 15px solid white;
          margin: 0 auto;
          position: relative;
          z-index: 99999;
          top: 2px;
          right: 0.2rem;
        }
        .top-arrow-sd {
          width: 0;
          height: 0;
          border-left: 18px solid transparent;
          border-right: 18px solid transparent;
          border-bottom: 15px solid #bcbcbc;
          margin: 0 auto;
          position: absolute;
          z-index: 999;
          top: 1px;
          left: -3px;
          right: 0.2rem;
        }

        .esios-selector__option {
          margin: 8px 10px;
          padding: 2px 10px;
          text-align: center;
          position: relative;
          z-index: 99999999;
          background-color: white;
          border-radius: 4px;
          left: -3px;
          color: #000 !important;
          font-size: 12px !important;
          cursor: pointer !important;
          font-family: $ns-font-cousine;

          &:hover {
            background-color: color(ns-lighter);
          }
          &:nth-child(1) {
            margin-bottom: 0 !important;
          }
          &:last-child {
            margin-top: 0 !important;
          }
        }
      }
      &.gd-options-buttons {
        display: flex;
      }
      #select_desglose_widget {
        .esios-toolbar-time__mod-date.option-selected {
          font-family: $ns-font-cousine !important;
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }
  }

  &__aside_chart {
    #gdDesgloseWidgetView {
      .esios-toolbar {
        &__component {
          margin-top: 1px;
        }
        &__items {
          margin-right: -2px;
        }
      }
    }
    @media (max-width: 540px) {
      #gdDesgloseWidgetView {
        .esios-gd__main-title--aside {
          margin-left: -6px;
        }
        .esios-toolbar {
          &__component {
            margin-left: -6px;
          }
        }
      }
    }
  }

  &__selector {
    display: inline-block;

    .move-time-selector {
      margin: 0 !important;
    }
  }

  .btn {
    display: inline-block;
    font-size: 0.875rem;
    border-radius: 1.25rem;
    background-color: #069;
    background-clip: padding-box;
    color: #fff !important;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    padding: 0.4375rem 1.25rem;

    &:hover {
      text-decoration: none;
      background-color: rgba(0, 102, 153, 0.8);
    }
  }

  &__layoutPadding {
    padding-top: 0;
    padding-right: 0;
    margin-top: -1px !important;
  }

  &__wrap {
    display: block;
    max-width: rem(1161);
    margin: 0 auto;
    &--analysis {
      .row-flex {
        display: flex;
        flex-wrap: nowrap;
      }
    }
    &--myesios {
      max-width: rem(765);
      margin-top: rem(60);
      .#{$ns}myesios__container {
        .#{$ns}home__title {
          font-family: $ns-font-proxima;
          margin-bottom: 16px;
        }
        p {
          font-family: $ns-font-proxima;
          color: #444444;
          margin-bottom: rem(14);
        }
        img {
          width: 88%;
          margin-left: rem(17);
          margin-bottom: rem(11);
          @media (max-width: 540px) {
            width: 100%;
            margin-left: 0;
          }
          &[alt='Es my esios empty 2'] {
            width: 74%;
            margin-left: 71px;
            margin-top: 1px;
            @media (max-width: 540px) {
              width: 100%;
              margin-left: 0;
            }
          }
        }
      }
    }
    .row {
      margin-left: -9px;
      margin-top: -1px;
      margin-bottom: 1px;
      .esios-home__widget-legend {
        margin-left: -18px;
        // margin-top: -70px;

        ul {
          margin-top: 0 !important;
          li {
            height: fit-content;
          }
          li:first-child {
            padding-left: 30px !important;
          }
        }
      }

      .btn.icon-download {
        display: inline-block;
        font-size: 0.875rem;
        border-radius: 1.25rem;
        background-color: #069;
        background-clip: padding-box;
        color: #fff !important;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        padding: 0.4375rem 2.25rem;
        background-position: right 3px center;
        background-image: url($ns-path-img + '/download-gd.svg');
        background-repeat: no-repeat;
        background-position: right 3px center;
        background-size: 1.5625rem;
        padding-right: 3rem;

        &:hover {
          text-decoration: none;
          background-color: rgba(0, 102, 153, 0.8);
        }
      }
      #button5 {
        margin-top: rem(12);
      }
      #accordion1,
      #accordion2 {
        display: none;

        @media (max-width: 540px) {
          display: block;
          padding-left: 20px;
          width: 93%;
          h2 {
            font-size: rem(14);
            font-family: $ns-font-proxima;
            color: color(ns-main-bg);
            font-weight: 700;
            line-height: 1.2;
          }

          .ui-accordion-header.ui-corner-top.ui-accordion-header-collapsed.ui-corner-all.ui-state-default {
            text-align: start !important;
            padding: rem(13) rem(30) rem(13) rem(13.4) !important;
            font-weight: 700;
            margin-top: 11px;
          }
          .ui-accordion-header.ui-corner-top.ui-state-default.ui-accordion-header-active.ui-state-active {
            text-align: start !important;
            padding-left: rem(13.4) !important;
            font-weight: 700;
          }
          .ui-accordion-header.ui-corner-top.ui-state-default.ui-state-focus.ui-state-hover {
            text-align: start !important;
            padding-left: rem(13.4) !important;
          }
          .esios-gd__secondary-values {
            margin-left: 1px;
            width: 95%;
            margin-top: -20px;
            padding-bottom: 12px;
          }

          .esios-gd__secondary-values > p,
          .esios-gd__secondary-values > ul li,
          .esios-gd__secondary-values > ul li p,
          .esios-gd__secondary-values > a[class$='__mail'],
          .esios-gd__secondary-values em,
          .esios-gd__secondary-values div > p {
            font-size: 14px !important;
            width: 99%;
            line-height: normal;
          }

          .esios-gd__secondary-values > p,
          .esios-gd__secondary-values div > p {
            margin-bottom: 30px;
          }

          .esios-gd__before-mail {
            margin-bottom: -5px !important;
          }
          .esios-gd__mod-action-buttons {
            justify-content: center;
          }
          .btn.icon-download {
            display: inline-block;
            font-size: 0.875rem;
            border-radius: 1.25rem;
            background-color: #069;
            background-clip: padding-box;
            color: #fff !important;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
            padding: 0.4375rem 4.55rem;
            background-position: right 3px center;
          }
        }
      }
      .esios-toolbar__component {
        margin-top: 3px;
      }
      .esios-tab .mod-tabs .ui-widget-header {
        margin-left: 27px;
      }
    }
  }

  &__row {
    position: relative;

    &.graph_gd {
      display: flex;
      flex-direction: column;
      .chari-chart .chartLegend {
        top: 51.1%;
        left: 50%;
        font-family: $ns-font-proxima;
      }
    }

    &--content0,
    &--content1 {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #dbdbdb;
    }
  }
  #dmGraphic {
    margin-right: 14px;
  }
  &__col1 {
    padding-right: 1.5%;
    padding-top: rem(20);
  }

  &__col2 {
    width: 49%;
    background-color: color(ns-lighter);
  }

  &__row--left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &_1 {
      flex: 1;
    }
  }

  &__row-interr {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 18px;
  }

  &__interr-right {
    padding: 0 rem(10.219);
    width: 101%;
    margin-left: -5px;
    margin-top: -1px;
  }

  &__interr-col-left {
    flex: 50%;
    margin-top: -47px;
  }

  &__interr-col-right {
    flex: 1;
    .esios-gd__main-title.snp-interrumptible-title {
      font-family: $ns-font-proxima;
      margin-bottom: 7px;
    }
  }

  &__main-title {
    font-family: $ns-font-proxima;
    margin-top: 0.67em;
    font-size: rem(23);
    font-weight: 800;
    text-transform: uppercase;
    color: color(ns-primary);
    line-height: 1.2;

    &--aside {
      padding-top: rem(20);
    }

    &--tabs {
      padding-left: rem(50);
    }
  }

  &__title {
    font-family: $ns-font-proxima;
    font-size: rem(19);
    font-weight: 700;
    color: color(ns-primary);
    text-transform: uppercase;
    margin: rem(15.77) 0;
    margin-bottom: 15px;
    line-height: 22.8px;
  }

  &__mod-action-buttons {
    font-family: $ns-font-proxima;
    margin: rem(20) 0 rem(40);
    display: flex;
    margin-top: 16px;

    &--center {
      margin: rem(20) 0 rem(20);
      justify-content: center;
      margin-right: 21px;
      margin-top: 16px;
    }
  }

  &__btn-download,
  &__btn-info {
    display: inline-block;
    padding: rem(7) rem(20);
    @include border-radius(rem(20));
    background-color: color(ns-primary);
    background-clip: padding-box;
    color: white;
    font-weight: 700;
    font-size: rem(14);
    text-transform: uppercase;
    text-align: center;
    -webkit-font-smoothing: antialiased;

    &:hover {
      text-decoration: none;
      color: white;
      background-color: color(ns-primary, 0.8);
      transition: background 0.2s ease-in-out;
    }
  }

  &__btn-download.icon-download {
    padding-right: rem(50);
    background-position: right 3px center;
    background-image: url($ns-path-img + '/download-gd.svg');
    background-repeat: no-repeat;
    background-size: rem(25);
    color: white;
  }

  &__widget-toolbar {
    &--tabs {
      padding-left: rem(50);
    }
  }

  &__toolbar {
    display: flex;
    padding: 0.6rem 0rem 0rem 3.1rem;
  }

  &__widget,
  &__widget-chart,
  &__widget-custom {
    position: relative;
    min-height: rem(130);
    clear: both;
  }

  &__widget {
    &.widget-gd-demanda {
      .chari-chart.demanda {
        .yaxis {
          .zeroline {
            stroke: transparent;
          }
        }
      }
    }
  }
  .visual-legend ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: rem(-60);
    z-index: 1;
    min-height: rem(70);
    height: auto;

    li {
      margin-right: rem(24);
    }

    .esios-home__widget-legend .legend-item .item .icon-eye.disabled,
    .esios-home__widget-legend .widget-summary-item .item .icon-eye.disabled {
      color: #dbdbdb !important;
      background-color: #dbdbdb !important;
    }
  }

  .g-main marker {
    fill: color(ns-lighter);
  }
  .legend-potencial-pie .legend-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text-content {
      display: flex;
      align-items: center;
      gap: 5px;
      color: color(ns-main-bg);
      font-weight: 600;
      font-size: 0.9rem;
    }

    .legend-circle {
      display: flex;
      height: rem(10);
      width: rem(10);
      border-radius: 50%;
    }

    .unit,
    .is-number {
      color: color(ns-main-bg) !important;
    }
    .is-number {
      font-size: 16px;
      font-family: $ns-font-cousine;
      letter-spacing: -1.6px;
      font-weight: 400;
    }
    .unit {
      font-family: $ns-font-proxima;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: normal;
    }
  }

  &__eyes {
    display: -ms-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  &__legend-item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  &__legend-icon-eye {
    height: rem(18);
    width: rem(25);
    -ms-flex-preferred-size: rem(25);
    flex-basis: rem(25);
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: rem(5);
    cursor: pointer;
  }

  .icon-eye {
    &--light {
      background-color: color(ns-border-ligth);
    }

    &--blue {
      background-color: color(skyblue-light);
    }

    &--orange {
      background-color: color(warning);
    }
  }

  &__widget-summary-item {
    margin: 0.8rem 0.1rem;
  }

  &__value {
    & * {
      color: color(ns-main-bg);
    }
  }

  &__widget-summary-item &__value {
    line-height: 1;
    letter-spacing: -0.15rem;
  }

  &__is-number {
    font-family: $ns-font-cousine !important;
    display: flex;
    align-items: baseline;

    &--first {
      font-size: rem(38);
      .esios-gd__is-number {
        .value {
          font-weight: 600 !important;
        }
      }
    }

    &--second {
      font-size: rem(28);
      font-weight: 400 !important;
      .esios-gd__value {
        font-weight: 400 !important;
      }
    }
  }

  &__unit {
    font-size: rem(12);
    font-weight: 600 !important;
    font-family: $ns-font-proxima;
    text-transform: none;
    padding-left: rem(5);
    letter-spacing: 0;
    text-transform: none;

    &--first {
      padding-left: rem(8);
    }
  }

  &__label {
    text-transform: uppercase;
    color: color(ns-darker);
    font-weight: 600;
    font-size: rem(14);
    max-width: fit-content;
  }

  hr {
    height: 1px;
    margin: rem(20) 0;
    box-sizing: content-box;
    color: color(ns-main-bg);
  }

  .col-xs-7 {
    padding: 0px;
  }

  &__aside_chart {
    display: block;
    width: 100%;
    height: 100%;
    padding-left: 21px;
    padding-right: 2.7rem;
  }

  &__align-to-the-left {
    width: 100%;
  }

  &__widget-pie-inner-summary {
    & h3 {
      margin: 0;
      margin-top: rem(8);
      color: color(ns-primary);
      font-size: rem(11);
      font-weight: 700;
      text-transform: uppercase;
    }

    & span {
      color: color(ns-main-bg);
      font-size: rem(12);
    }

    & hr {
      margin: rem(5);
      color: color(ns-main-bg);
    }
  }

  &__is-micro-number,
  .basic-legend &__value {
    letter-spacing: -0.1em;
    font-size: rem(16);
    font-family: $ns-font-cousine;
    color: color(ns-main-bg);
  }

  .basic-legend &__legend-item {
    display: flex;
    justify-content: space-between;
  }

  .legend {
    margin-top: 1rem !important;
    padding-right: 1.1rem;
  }

  .basic-legend &__item {
    position: relative;
    max-width: 70%;
    color: color(ns-main-bg);
    cursor: default;
    font-size: rem(14);
  }

  &__legend-circle {
    display: inline-block;
    margin-right: rem(5);
    height: rem(10);
    width: rem(10);
    border-radius: rem(25);
    background-color: color(skyblue-light);
  }

  &__breakdown {
    position: relative;
  }

  &__breakdown-selector {
    cursor: pointer;
    color: color(ns-main-bg);
    font-family: $ns-font-cousine;
    position: relative;
    padding: 0 rem(15);
    width: rem(100);
    height: 1.4375rem;
    line-height: 1.4375rem;
    border-radius: 0.625rem;
    font-size: rem(12);
    font-weight: 600;
    background-color: #dbdbdb;
    padding-right: rem(30);
    text-transform: uppercase;

    &:after {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #717171;
      content: '';
      display: block;
      position: absolute;
      right: rem(12);
      top: 50%;
      -webkit-transform: translatey(-75%);
      -ms-transform: translatey(-75%);
      transform: translatey(-75%);
      pointer-events: none;
    }
  }

  &__breakdown-drop {
    box-sizing: border-box;
    display: none;
    justify-content: space-between;
    position: absolute;
    top: rem(36);
    left: 50%;
    transform: translateX(-50%);
    z-index: 4;
    width: rem(144);
    padding: rem(10);
    border: 1px solid #dbdbdb;
    color: #444444;
    background-color: white;
    box-shadow: 5px 5px 0 #0000000d;

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid white;
      display: inline-block;
      position: absolute;
      z-index: 5;
      top: -19px;
      left: 50%;
      transform: translateX(-50%);
    }

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid #dbdbdb;
      display: inline-block;
      position: absolute;
      z-index: 4;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__breakdown-list {
    padding: rem(8);
  }

  &__breakdown-li {
    font-family: $ns-font-cousine;
    font-size: rem(12);
    font-weight: 400;
    color: color(ns-main-bg);
    text-align: center;
    line-height: rem(15);
    padding: rem(5);

    &:hover {
      background-color: color(ns-lighter);
      cursor: pointer;
    }
  }

  &__secondary-values {
    padding-top: 0.2rem;
    width: 80%;
    margin-left: 23px;
    margin-top: -4px;
    @media screen and(min-width: 540px) {
      margin-top: 25px !important;
    }

    em {
      font-style: italic;
      font-size: rem(16);
      letter-spacing: 0.01rem;
      color: color(ns-main-bg);
    }

    .esios-gd__before-mail {
      margin-bottom: -5px !important;
    }

    & > p,
    div > p,
    ul li,
    ul li p {
      font-family: $ns-font-proxima;
      font-size: rem(16);
      font-weight: 400;
      line-height: rem(19.2);
      color: color(ns-main-bg);
    }

    & > p {
      margin: 1rem 0;
      font-size: 16px;
      letter-spacing: 0.01rem;
      width: 99%;

      &[class='esios-gd__before-mail'] {
        margin-bottom: 0;
      }
    }

    & > ul {
      margin: rem(16) 0;
      padding-left: rem(20);

      & li {
        position: relative;
        font-size: 16px !important;
        width: 100%;
        line-height: 19.2px;

        & p {
          margin-top: rem(16);
        }

        &:before {
          content: '';
          position: absolute;
          top: rem(5);
          left: rem(-20);
          width: rem(8);
          height: rem(8);
          border-radius: 50%;
          background-color: color(ns-light);
        }
      }
    }

    & > a[class$='__mail'] {
      font-family: $ns-font-proxima;
      font-size: rem(16);
      font-weight: 400;
      line-height: rem(19.2);
      color: color(ns-primary);
    }
  }

  @media screen and (max-width: 540px) {
    &__wrap .row {
      margin-left: 2px;
      width: 98%;
    }
    .visual-legend ul {
      grid-template-columns: 1fr;
      width: 100%;
      margin-left: 10px;
    }

    &__wrap .row .esios-home__widget-legend {
      margin-left: -18px;
      border-bottom: 1px solid #dfdfdf;

      margin-top: -20px !important;
      margin-bottom: 10px;

      ul {
        margin-top: 0 !important;
        li:first-child {
          padding-left: 0 !important;
        }
      }
    }
    &__widget {
      margin-top: -17px;
    }
    &__widget,
    &__widget-chart,
    &__widget-custom {
      margin-bottom: 2.5rem;
      margin-top: 25px;
    }

    hr {
      display: none;
    }

    &__row {
      position: relative;

      &--content1 {
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
      }
    }

    &__col1 {
      padding-left: rem(20);
    }

    &__col2 {
      width: 100%;
    }

    &__layoutPadding {
      padding-left: 0;
    }

    &__row--left {
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    &__eyes {
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    &__row-interr {
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      padding-bottom: 0px !important;
      .esios-toolbar__component {
        margin-bottom: 28px !important;
      }
    }

    &__widget {
      margin-top: -15px;
    }
    .col-xs-7 {
      position: relative;
      left: 49%;
      top: -33px;
    }

    &__row
      .col-xs-7
      #select_desglose_widget
      .esios-toolbar-time__mod-date.option-selected {
      margin-left: -26px;
      margin-right: 10px;
    }

    &__row.graph_gd {
      display: flex;
      flex-direction: column;
      margin-top: -25px;
    }

    .widget-legend.basic-legend.legend-potencial-pie {
      margin-top: 5px;
      width: 105%;
      display: flex;
      flex-direction: column;
      align-content: space-between;
      margin-left: 14px;
      margin-bottom: -20px;
    }

    &__main-title--tabs {
      padding-left: 22px;
      width: 93%;
    }

    &__wrap .row .esios-toolbar__component {
      margin-top: 3px;
    }

    #tabwithcontent {
      display: none !important;
    }
    #tab-gd-content3 {
      display: none !important;
    }
  }

  .move-time-selector {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 100px 1fr;
    align-items: center;
    justify-content: space-between;

    .selector {
      display: grid;
      grid-template-columns: 30px 1fr 30px;

      .selected {
        border: none !important;
        padding: 6px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        display: inline-block;

        &::before {
          display: none;
        }
      }

      .select-time-container {
        grid-template-columns: 1fr;
        position: relative;

        .#{$ns}toolbar-time {
          &__mod-date {
            margin-bottom: rem(20);
            width: 100px;
            padding-left: 15px;
          }
        }
      }

      .select-time-container.active::after {
        content: '';
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid white;
        margin: 0 auto;
        position: absolute;
        z-index: 1000;
        bottom: rem(8);
        right: rem(15);
      }

      .select-time-container.active .select-time-options {
        display: grid !important;
        max-height: rem(245);
        overflow: hidden;
        overflow-y: auto;
      }

      .select-time-container .select-time-options {
        top: rem(35);
        width: rem(140);
        padding: rem(10) rem(10) rem(10) rem(10);
        position: absolute;
        z-index: 2;
        background: white;
        box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.1);
        // box-shadow: 5px 5px 0px color(ns-light);
        border: 1px solid color(ns-light);
        border-top: none;
        display: none;
      }

      .button {
        background: color(ns-lighter);
        position: relative;
        cursor: pointer;
        border: 1px solid color(ns-light);
      }

      .prev-button {
        border-right: none;
      }

      .prev-button::after {
        content: '';
        position: absolute;
        // left: 7px;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 5px solid #717171;
        pointer-events: none;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        content: '';
      }

      .next-button {
        border-left: none;
      }

      .next-button::after {
        content: '';
        // width: 10px;
        position: absolute;
        left: 7px;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid #717171;
        pointer-events: none;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        content: '';
      }
    }

    &.one-column {
      grid-template-columns: 1fr !important;
      margin-top: 10px;

      .selected {
        text-align: left;
        justify-content: left;
        z-index: 1;
        background-color: color(white);
      }

      .select-time-options .option {
        text-align: left;
        padding: rem(5);
        font-size: rem(12) !important;
        width: 100%;
        text-align: center;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }

  .chari-chart.dibujoDemanda {
    .chartLegend .text {
      font-weight: 700;
      font-size: 0.7rem !important;
    }
  }

  .chari-chart.dibujoDemanda .chartLegend .totalValuePie,
  .chari-chart.dibujoDemanda .chartLegend .valuePie {
    font-family: $ns-font-cousine;
    font-size: 1rem;
    font-weight: 400;
    display: flex;
    letter-spacing: -0.1rem;
  }

  .esios-gd__secondary-values {
    padding-bottom: 3rem;
    .is-sm-hidden {
      display: none;
    }
    .btn-download {
      margin-top: 1rem;
      padding-left: 40px;
      padding-right: 30px;

      &:hover {
        color: #ffffff !important;
        background-color: rgb(0, 102, 153) !important;
      }
    }
    .icon-download {
      background-position: 5px;
    }
    h2 {
      font-family: $ns-font-proxima;
      font-size: rem(19);
      font-weight: 700;
      color: color(ns-primary);
      text-transform: uppercase;
      margin: rem(15.77) 0;
      margin-bottom: 15px;
      line-height: 22.8px;
    }
    a {
      font-weight: 600;
      color: #006699;
      text-decoration: none;
    }
  }
}

.esios-gd__secondary-values {
  h2 {
    font-size: 1.1875rem;
    color: rgb(0, 102, 153);
    text-transform: uppercase;
    font-weight: bold;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  a {
    color: rgb(0, 102, 153);
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
  }

  a:hover {
    text-decoration: underline;
  }
  .btn-download:hover {
    background-color: rgba(0, 102, 153, 0.8);
    text-decoration: none;
  }
  @media screen and (min-width: 540px) {
    .mod-action-buttons {
      margin-bottom: 2.5rem;
    }
    .is-sm-hidden {
      visibility: hidden;
      display: none !important;
    }
    .mod-resume-wrapper .mod-action-buttons a {
      margin: 0px;
    }
    .mod-action-buttons {
      margin-bottom: 2.5rem;
    }
    .btn-download {
      width: auto;
      text-align: left;
    }
  }
  .btn-download {
    display: inline-block;
    background-color: rgb(0, 102, 153);
    background-clip: padding-box;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    color: white !important;
    padding: 0.4375rem 1.25rem;
    border-radius: 1.25rem;
  }
  .mod-action-buttons {
    text-align: center;
    margin: 1.25rem 0px;
  }
  .btn-download.icon-download {
    padding-right: 3.125rem;
    background-position: right 3px center;
  }
  .mod-action-buttons {
    display: inline-block;
    text-align: center;
    margin: 1.25rem 0px;
  }
  .icon-download {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgMjcgMjkiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgdmVyc2lvbj0iMS4xIgoJeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIKCXg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjdweCIgaGVpZ2h0PSIyOXB4Igo+Cgk8ZyBpZD0iTGF5ZXIlMjAxIj4KCQk8cGF0aCBkPSJNIDE3Ljc1IDEyIEwgMTMuNzg1IDE5IEwgMTcgMTkgTCAxNyAyMCBMIDEwIDIwIEwgMTAgMTkgTCAxMy4yMTUgMTkgTCA5LjI1IDEyIEwgMTIgMTIgTCAxMiAxMCBMIDE1IDEwIEwgMTUgMTIgTCAxNy43NSAxMiBaTSAxIDE0LjUgQyAxIDIxLjQwMzggNi41OTYzIDI3IDEzLjUgMjcgQyAyMC40MDM3IDI3IDI2IDIxLjQwMzggMjYgMTQuNSBDIDI2IDcuNTk2MiAyMC40MDM3IDIgMTMuNSAyIEMgNi41OTYzIDIgMSA3LjU5NjIgMSAxNC41IFoiIGZpbGw9IiNmZmZmZmYiLz4KCTwvZz4KPC9zdmc+);
    background-size: 1.5625rem;
    background-repeat: no-repeat;
  }
  & ul {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;

    & li {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 5px;
        left: -20px;
        width: 8px;
        height: 8px;
        background-color: rgb(219, 219, 219);
        border-radius: 50%;
      }
    }

    @media screen and (min-width: 540px) {
      & {
        padding-left: 1.25rem;
      }
    }
  }
}

.esios-tab .ui-tabs .ui-tabs-panel {
  border-top: 1px solid #ddd !important;
  padding: 2.4em 0rem 0rem 0.5rem !important;
}

.esios-tab .icon-download {
  margin: 1.2rem 0 0.6rem !important;
}

.esios-layout__related-content {
  border-top: 1px solid #dbdbdb;
  padding-top: 2.5rem;
  background-color: white;
  padding-bottom: 29px;

  &--gd {
    border-top: none !important;
    .esios-gd__main-title.esios-gd__main-title--tabs {
      margin-bottom: 25px;
    }
    .grid-xs-24.grid-sm-16.esios-gd__toolbar {
      margin-top: -28px;
    }
  }
}

.esios-layout__subcontent {
  padding: 0rem;
}

#button5 {
  margin-top: 0rem;
}

#button7 {
  margin-bottom: 3.1rem;
}

.ui-tabs .ui-tabs-nav {
  padding: 0em 0.2em 0;
}

.no-data-graphic-demand {
  background-color: #f7f7f7;
  position: absolute;
  width: 98%;
  height: 80%;
  border-style: none none solid none;
  border-width: 1px;
  border-color: black;
  background-size: cover !important;
  background-image: url('../../src/assets/images/background-graphic-small.png');

  &:last-child {
    margin-left: 12px;
  }
}

div#gdDemanda {
  margin-left: 12px;
  background-size: 800px 310px;
}

.dibujoDemanda .chartLegend .unit {
  font-weight: 600 !important;
}

@media (max-width: 540px) {
  .esios-layout__wrap--gd {
    padding: 0 !important;
  }
  .grid-xs-24.grid-sm-16.esios-gd__toolbar {
    padding-left: 18px !important;
  }
}
