////
/// Dashboard component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}dashboard
/// @since version 1.0.0
////

.#{$ns}smercado {
  h1 {
    text-transform: uppercase;
    color: color(ns-primary);
    font-family: $ns-font-proxima;
    margin: 0.67em 0;
    font-size: rem(23);
    font-weight: 800;
  }

  &__div-header {
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 1.5rem;
    margin-top: -10px;
    @media only screen and (max-width: 542px) {
      flex-direction: column-reverse;
      width: 88vw;
    }
  }

  &__div-select {
    width: 64%;
    margin-top: 29px;
    margin-left: 1.5%;
    @media screen and (max-width: 542px) {
      margin-left: 0;
      width: 86vw;
    }

    & > select {
      width: 100%;
    }

    & > span {
      width: 100% !important;
    }

    & .ui-selectmenu-text {
      color: #444 !important;
      font-size: 12px;
    }
  }

  & .esios-tabla__input {
    margin-left: 0%;
  }

  & p {
    font-family: $ns-font-proxima !important;
    margin-top: rem(28);
    font-weight: 400;
    color: color(ns-main-bg);
    line-height: 1.2;
    font-size: 1rem;
    margin: 19px 0 15px 0;
    letter-spacing: rem(0.2);
  }

  &__button-remove {
    position: absolute;
    left: 30%;
    top: 57%;
    background-image: url('../../src/assets/images/button-remove.svg');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    padding-left: 13px;
    opacity: 0.4;
    &.hidden {
      visibility: hidden;
    }
  }

  &__box-market {
    margin-bottom: rem(-6);
    .esios-boxmarket {
      width: 97.5%;
      height: 100%;
      background: white;
      border: 1px solid #dbdbdb;
      padding: 2.5rem;
      margin: 0;
      margin-bottom: 50px !important;
      margin-left: 0px !important;
      &:first-child {
        margin-left: 25px !important;
        width: 97.2%;
        margin-bottom: 12px !important;
        h2 {
          line-height: 30px;
          margin-left: 10px;
          width: 95.5%;
          margin-top: -4px;
          margin-bottom: -55px;
        }
        .esios-boxmarket__button {
          width: 87%;
          margin-left: 6px;
          margin-bottom: 4px;
        }
        .esios-boxdoc__footer {
          width: 100%;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: 0.5rem;
          margin: 1.25rem 0;
          margin-left: -26px;
          margin-top: 16px;
          margin-bottom: 23px;
        }
      }
    }
    @media only screen and (max-width: 592px) {
      margin-bottom: 20px;
    }
    .col-lg-6.col-md-6.col-sm-6.col-xs-12 {
      &:nth-child(1) {
        width: 100%;
      }
      &:nth-child(2) {
        margin-left: -26px !important;
        width: 50%;
        .esios-boxmarket__list {
          margin-top: 38px;
          width: 100%;
        }
      }
    }
  }

  &__buttons {
    padding-top: 2rem;
    padding-bottom: 2.5rem;
    @media only screen and (max-width: 542px) {
      padding-top: 0;
      padding-bottom: 16px;
    }
  }

  &__search {
    height: 56px;
    padding-right: 35px;
    width: 33%;
    font-size: 12px;
    color: #222222;
    font-weight: 600;
    font-family: $ns-font-proxima;
    @media screen and (max-width: 542px) {
      margin-left: 0%;
      width: 98%;
      height: 56px;
      margin-top: 25px;
      height: 56px;
      font-size: 12px;
      color: #333333;
      font-weight: 600;
      letter-spacing: 0px;
    }
  }

  &__select {
    height: 56px;
    width: 60%;
    text-transform: uppercase;
    font-family: $ns-font-proxima-regular;
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    color: #000000;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    vertical-align: middle;
    padding-left: 20px;
    padding-right: 20px;
    zoom: 1;
    position: relative;
    border: 1px solid #dbdbdb;
    font-size: 0.875rem;
    font-weight: 500;
    @media screen and (max-width: 542px) {
      margin-top: 29px;
    }
    .ui-selectmenu-button.ui-button {
      width: 250px;
      background: #ffffff;
      padding: 9px 20px;
      position: relative;
      margin-bottom: 0.5rem;
      border: 1px solid #dbdbdb;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
      border-radius: 0;
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #717171;
        right: 0.625rem;
        z-index: 1;
        pointer-events: none;
        position: absolute;
        top: 50%;
        margin-top: -3px;
      }
      .ui-selectmenu-icon {
        display: none;
      }
    }
    .ui-selectmenu-menu {
      width: 250px;
      .ui-menu {
        border-radius: 0;
        border: 1px solid #dbdbdb;
        box-sizing: border-box;
        width: 100%;
      }
      .ui-menu-item-wrapper {
        text-transform: capitalize;
        padding: 8px 16px;
        font-size: 12px;
        background: none;
        border: none;
        color: #444444;
        &.ui-state-active {
          background-color: #f7f7f7;
        }
      }
      .ui-menu-item {
        color: #444444;
      }
    }
    .ui-selectmenu-text {
      color: #000000;
      font-family: $ns-font-proxima;
      font-weight: 600;
      @media screen and (max-width: 542px) {
        padding-top: 4px;
        font-family: $ns-font-proxima;
        font-size: 14px;
      }
    }
  }

  & tr:nth-child(2n) {
    background-color: #f7f7f7;
  }
  @media screen and (max-width: 542px) {
    &__button-remove {
      left: 91%;
      top: 76%;
    }
  }

  .ui-state-disabled {
    display: none;
  }
}

.esios-smercado__dropdown {
  margin-top: 18px;
  .ui-selectmenu-button.ui-button {
    width: 60%;
    height: 56px;
    background: #ffffff;
    padding: 10px 20px;
    position: relative;
    margin-bottom: 1px;
    border: 1px solid #dbdbdb;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 0;
    @media only screen and (max-width: 542px) {
      height: 39px;
      padding: 11px 20px 10px 22px;
    }
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #717171;
      right: 0.625rem;
      z-index: 1;
      pointer-events: none;
      top: 50%;
      position: absolute;
    }
    .ui-selectmenu-icon {
      display: none;
    }
  }
  .ui-selectmenu-menu {
    width: 250px;
    .ui-menu {
      border-radius: 0;
      border: 1px solid #dbdbdb;
      box-sizing: border-box;
      width: 100%;
    }
    .ui-menu-item-wrapper {
      text-transform: capitalize;
      padding: 4px 16px;
      font-size: 12px;
      background: none;
      border: none;
      color: #444444;
      &.ui-state-active {
        background-color: #f7f7f7;
      }
    }
    .ui-menu-item {
      color: #444444;
    }
  }
  .ui-selectmenu-text {
    color: #000000;
    font-family: $ns-font-proxima;
    padding-top: 10px;
    font-weight: 600;
    @media screen and (max-width: 542px) {
      padding-top: 0px;
      font-family: $ns-font-proxima;
      font-size: 14px;
    }
  }

  .esios-table__head {
    @media only screen and (max-width: 542px) {
      display: none;
    }
  }
}

#pagination-sujetos {
  margin-top: -14px;
  margin-bottom: -2px;
}

@media screen and (max-width: 1199px) {
  .#{$ns}smercado {
    padding-left: rem(27.2);
  }
}

@media screen and (max-width: 767px) {
  .#{$ns}smercado {
    padding-left: 0;
  }
}

@media screen and (max-width: 540px) {
  .#{$ns}table {
    display: block;
    word-break: normal;
    word-wrap: normal;
    margin: 0 rem(-20);
    width: calc(100% + 40px);
    margin-top: rem(20);
    &__head {
      display: none;
    }
    &__row {
      display: block;
      height: auto;
    }
    &__content-mobile {
      display: inline-block;
      vertical-align: top;
      width: 44%;
    }
    td {
      display: block;
      text-align: left;
      width: 100%;
      &:before {
        content: attr(data-title);
        display: inline-block;
        vertical-align: top;
        font-family: $ns-font-proxima;
        font-size: rem(14);
        font-weight: 600;
        width: rem(121);
        padding-right: rem(15);
      }
    }
    td.not-found {
      margin: 0;
      background-color: #fff;
      padding: 0;
      position: relative;
      margin-top: 7px;
      &::after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: #dbdbdb;
        position: absolute;
        left: 0;
        top: 25px;
      }
    }
    .esios-table tr:nth-child(even) {
      background-color: #f7f7f7;
    }
    &__figure {
      width: rem(22);
      display: inline-block;
      vertical-align: middle;
    }
    &__title {
      a {
        max-width: calc(100% - 25px);
        font-weight: normal;
      }
    }
    &--sujetomercado {
      display: table;
    }
  }

  .#{$ns}smercado {
    &__buttons {
      &#sujeto-mercado-buttons .#{$ns}btn__container,
      &#unidad-fisica-buttons .#{$ns}btn__container {
        margin-top: 25px;
      }
    }

    &__box-market {
      .col-lg-6.col-md-6.col-sm-6.col-xs-12 {
        padding: 0;

        .#{$ns}boxmarket {
          width: 100%;
          margin-left: 0 !important;
        }

        &:nth-child(1) {
          width: 100%;

          .#{$ns}boxmarket {
            margin-bottom: 0 !important;
            border-bottom: 0;
          }
        }

        &:nth-child(2) {
          width: 100%;
          margin-left: 0 !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 539px) {
  .#{$ns}smercado__box-market {
    .col-lg-6.col-md-6.col-sm-6.col-xs-12 {
      &:last-child {
        .#{$ns}boxmarket {
          padding-bottom: rem(29);

          &__container {
            min-height: auto;
          }

          &__list {
            &#listMarket {
              ul {
                li:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }

          &__text {
            margin: 0;
          }
        }
      }
    }
  }
}
@media print {
  .esios-layout__print-logo.sujetos {
    margin-top: 192px;
  }
}
