////
/// Document component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}Document
/// @since version 1.0.0
////

.#{$ns}document {
  &__title {
    font-size: 23px;
    font-family: $ns-font-proxima;
    text-transform: uppercase;
    color: color(ns-primary);
    font-weight: 800;
    margin-bottom: 1rem;
  }

  &__container-button {
    width: 25%;
  }

  &__container-buttonsnp {
    width: 45%;
  }

  &__container {
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;
  }

  &__content {
    padding: 2rem 3.75rem;
    min-height: 18.75rem;
    width: 100%;
  }
}
