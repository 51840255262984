////
/// Contact component
///
/// Custom project
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}contact
/// @since version 1.0.0
////
.#{$ns}period-selector {
  .date-selectors {
    position: relative;
  }
  .date-selector {
    height: 100%;
    z-index: 1;
  }
  .date-hour-selector {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: rem(7);
    margin-top: 6px;
    align-items: center;

    &.noMinutesSelector {
      grid-template-columns: 100px 1fr 1fr;
    }
  }

  .esios-toolbar-time {
    width: 100%;
    background: color(white);
    position: relative;
    border: 1px solid color(ns-light);
  }

  .esios-toolbar-time__mod-date {
    cursor: pointer;
    color: #444444 !important;
    font-family: 'Cousine' !important;
    position: relative;
    padding: rem(10) rem(14) rem(8) rem(2);
    width: 100%;
    height: auto;
    border-radius: rem(0);
    font-size: rem(12);
    background-color: color(white);
    text-align: center;
  }

  .esios-toolbar-time__mod-date::after {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgMTUgMTUiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgdmVyc2lvbj0iMS4xIg0JeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSINCXg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMTVweCIgaGVpZ2h0PSIxNXB4Ig0+DQk8ZyBpZD0iTGF5ZXIlMjAxIj4NCQk8Zz4NCQkJPHBhdGggZD0iTSAxIDcuNSBDIDEgMy45MTAyIDMuOTEwMSAxIDcuNSAxIEMgMTEuMDg5OSAxIDE0IDMuOTEwMiAxNCA3LjUgQyAxNCAxMS4wODk4IDExLjA4OTkgMTQgNy41IDE0IEMgMy45MTAxIDE0IDEgMTEuMDg5OCAxIDcuNSBaTSAwIDcuNSBDIDAgMTEuNjQyMiAzLjM1NzggMTUgNy41IDE1IEMgMTEuNjQyMiAxNSAxNSAxMS42NDIyIDE1IDcuNSBDIDE1IDMuMzU3OCAxMS42NDIyIDAgNy41IDAgQyAzLjM1NzggMCAwIDMuMzU3OCAwIDcuNSBaIiBmaWxsPSIjNzE3MTcxIi8+DQkJCTxwYXRoIGQ9Ik0gMTAuNjMwOSA5LjEzMjYgTCA4IDcgTCA4IDEuOTY2MiBMIDcgMi4xNzM3IEwgNyA4IEwgMTAuMTkzNSA5Ljg2NjcgTCAxMC42MzA5IDkuMTMyNiBaIiBmaWxsPSIjNzE3MTcxIi8+DQkJPC9nPg0JPC9nPg08L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: center;

    width: rem(30);
    height: rem(30);
    border-left: none;
    /* border-right: 5px solid transparent; */
    border-top: none;
    content: '';
    display: block;
    position: absolute;
    right: rem(2);
    top: 50%;
    @include transform(translateY(-50%));
  }
  .esios-toolbar-time__selector {
    top: rem(55);
  }

  .datepicker-mod {
    position: relative;
    background-color: color(white);
    height: 100%;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 7px;
  }
  .datepicker-btn {
    cursor: pointer;
    color: #444444 !important;
    font-family: $ns-font-proxima-regular !important;
    position: relative;
    padding: rem(10) rem(14) rem(8) rem(2);
    width: 100%;
    height: 100%;
    border-radius: rem(0);
    font-size: rem(12);
    background-color: transparent;
    text-align: center;
    z-index: 1;
    border: 1px solid color(ns-light);
    font-size: rem(12);
    background-color: transparent;
    text-align: center;
  }
  .datepicker-mod::after {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgMTUgMTUiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgdmVyc2lvbj0iMS4xIg0JeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSINCXg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMTVweCIgaGVpZ2h0PSIxNXB4Ig0+DQk8ZyBpZD0iTGF5ZXIlMjAxIj4NCQk8Zz4NCQkJPHBhdGggZD0iTSAxIDcuNSBDIDEgMy45MTAyIDMuOTEwMSAxIDcuNSAxIEMgMTEuMDg5OSAxIDE0IDMuOTEwMiAxNCA3LjUgQyAxNCAxMS4wODk4IDExLjA4OTkgMTQgNy41IDE0IEMgMy45MTAxIDE0IDEgMTEuMDg5OCAxIDcuNSBaTSAwIDcuNSBDIDAgMTEuNjQyMiAzLjM1NzggMTUgNy41IDE1IEMgMTEuNjQyMiAxNSAxNSAxMS42NDIyIDE1IDcuNSBDIDE1IDMuMzU3OCAxMS42NDIyIDAgNy41IDAgQyAzLjM1NzggMCAwIDMuMzU3OCAwIDcuNSBaIiBmaWxsPSIjNzE3MTcxIi8+DQkJCTxwYXRoIGQ9Ik0gMTAuNjMwOSA5LjEzMjYgTCA4IDcgTCA4IDEuOTY2MiBMIDcgMi4xNzM3IEwgNyA4IEwgMTAuMTkzNSA5Ljg2NjcgTCAxMC42MzA5IDkuMTMyNiBaIiBmaWxsPSIjNzE3MTcxIi8+DQkJPC9nPg0JPC9nPg08L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: center;
    z-index: 0;
    width: rem(30);
    height: rem(30);
    border-left: none;
    border-top: none;
    content: '';
    display: block;
    position: absolute;
    right: rem(7);
    top: 50%;
    @include transform(translateY(-50%));
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgMTUgMTUiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgdmVyc2lvbj0iMS4xIgoJeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIKCXg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMTVweCIgaGVpZ2h0PSIxNXB4Igo+Cgk8ZyBpZD0iTGF5ZXIlMjAxIj4KCQk8cGF0aCBkPSJNIDEwIDMgTCAxMCAyIEwgNSAyIEwgNSAzIEwgNSA1IEwgNCA1IEwgNCAzIEwgNCAyIEwgMSAyIEwgMSAxNCBMIDE0IDE0IEwgMTQgMiBMIDExIDIgTCAxMSAzIEwgMTEgNSBMIDEwIDUgTCAxMCAzIFpNIDEzIDEzIEwgMiAxMyBMIDIgNiBMIDEzIDYgTCAxMyAxMyBaTSAxMiAxMCBMIDEyIDkgTCAxMSA5IEwgMTEgMTAgTCAxMiAxMCBaTSAxMCAxMiBMIDEwIDExIEwgOSAxMSBMIDkgMTIgTCAxMCAxMiBaTSA4IDEyIEwgOCAxMSBMIDcgMTEgTCA3IDEyIEwgOCAxMiBaTSA2IDEyIEwgNiAxMSBMIDUgMTEgTCA1IDEyIEwgNiAxMiBaTSA0IDEyIEwgNCAxMSBMIDMgMTEgTCAzIDEyIEwgNCAxMiBaTSAxMCAxMCBMIDEwIDkgTCA5IDkgTCA5IDEwIEwgMTAgMTAgWk0gOCAxMCBMIDggOSBMIDcgOSBMIDcgMTAgTCA4IDEwIFpNIDYgMTAgTCA2IDkgTCA1IDkgTCA1IDEwIEwgNiAxMCBaTSA0IDEwIEwgNCA5IEwgMyA5IEwgMyAxMCBMIDQgMTAgWk0gMTIgOCBMIDEyIDcgTCAxMSA3IEwgMTEgOCBMIDEyIDggWk0gMTAgOCBMIDEwIDcgTCA5IDcgTCA5IDggTCAxMCA4IFpNIDggOCBMIDggNyBMIDcgNyBMIDcgOCBMIDggOCBaTSA2IDggTCA2IDcgTCA1IDcgTCA1IDggTCA2IDggWk0gMTAgMSBMIDEwIDIgTCAxMSAyIEwgMTEgMSBMIDEwIDEgWk0gNCAxIEwgNCAyIEwgNSAyIEwgNSAxIEwgNCAxIFoiIGZpbGw9IiM3MTcxNzEiLz4KCTwvZz4KPC9zdmc+);
    background-repeat: no-repeat;
    background-position: center;
  }

  .datepicker-mod.no-datepicker {
    opacity: 0.6;

    &::after {
      background-image: none;
    }
  }

  .link-dates {
    width: 20px;
    height: 20px;
    border: 1px solid color(ns-light);
    position: absolute;
    top: 50%;
    left: 70px;
    @include transform(translateY(-50%));
    border-radius: 50%;
    background-color: color(ns-lighter);
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IlVudGl0bGVkLVBfeDI1X0UxZ2luYV94MjVfMjAxIgoJIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTYgMTMiCgkgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTYgMTMiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8cGF0aCBmaWxsPSJub25lIiBkPSJ6Ii8+CjxnIGlkPSJDYXBhX3gyNV8yMDEiPgoJPGc+CgkJPHBhdGggZmlsbD0iIzcxNzE3MSIgZD0iTTYuNSwzLjNjMC0wLjksMC43LTEuNywxLjUtMS43czEuNSwwLjcsMS41LDEuN1Y1QzEwLjMsNSwxMSw0LjMsMTEsMy4zbDAsMEMxMSwxLjUsOS43LDAsOCwwUzUsMS41LDUsMy4zCgkJCUM1LDQuMyw1LjcsNSw2LjUsNWwwLDBWMy4zeiIvPgoJCTxwYXRoIGZpbGw9IiM3MTcxNzEiIGQ9Ik05LjUsOS43YzAsMC45LTAuNywxLjctMS41LDEuN3MtMS41LTAuNy0xLjUtMS43VjhDNS43LDgsNSw4LjcsNSw5LjdsMCwwQzUsMTEuNSw2LjMsMTMsOCwxM3MzLTEuNSwzLTMuMwoJCQlDMTEsOC43LDEwLjMsOCw5LjUsOGwwLDBWOS43eiIvPgoJCTxyZWN0IHk9IjYiIGZpbGw9IiM3MTcxNzEiIHdpZHRoPSI0IiBoZWlnaHQ9IjEiLz4KCQk8cmVjdCB4PSIxMiIgeT0iNiIgZmlsbD0iIzcxNzE3MSIgd2lkdGg9IjQiIGhlaWdodD0iMSIvPgoJCTxwYXRoIGZpbGw9IiM3MTcxNzEiIGQ9Ik0xMiw1bDItMmgxbC0yLDJIMTJ6Ii8+CgkJPHBhdGggZmlsbD0iIzcxNzE3MSIgZD0iTTEyLDhsMiwyaDFsLTItMkgxMnoiLz4KCQk8cGF0aCBmaWxsPSIjNzE3MTcxIiBkPSJNNCw1TDIsM0gxbDIsMkg0eiIvPgoJCTxwYXRoIGZpbGw9IiM3MTcxNzEiIGQ9Ik00LDhsLTIsMkgxbDItMkg0eiIvPgoJPC9nPgo8L2c+CjxwYXRoIGZpbGw9Im5vbmUiIGQ9InoiLz4KPC9zdmc+);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px;
    z-index: 0;
    cursor: pointer;

    &.active {
      background-color: color(ns-primary);
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IlVudGl0bGVkLVBfeDI1X0UxZ2luYV94MjVfMjAxIgoJIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTYgMTMiCgkgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTYgMTMiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8cGF0aCBmaWxsPSJub25lIiBkPSJ6Ii8+CjxnIGlkPSJDYXBhX3gyNV8yMDEiPgoJPGc+CgkJPHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iTTYuNSwzLjNjMC0wLjksMC43LTEuNywxLjUtMS43czEuNSwwLjcsMS41LDEuN1Y1QzEwLjMsNSwxMSw0LjMsMTEsMy4zbDAsMEMxMSwxLjUsOS43LDAsOCwwUzUsMS41LDUsMy4zCgkJCUM1LDQuMyw1LjcsNSw2LjUsNWwwLDBWMy4zeiIvPgoJCTxwYXRoIGZpbGw9IiNmZmZmZmYiIGQ9Ik05LjUsOS43YzAsMC45LTAuNywxLjctMS41LDEuN3MtMS41LTAuNy0xLjUtMS43VjhDNS43LDgsNSw4LjcsNSw5LjdsMCwwQzUsMTEuNSw2LjMsMTMsOCwxM3MzLTEuNSwzLTMuMwoJCQlDMTEsOC43LDEwLjMsOCw5LjUsOGwwLDBWOS43eiIvPgoJCTxwYXRoIGlkPSJFbGlwc2UiIGZpbGw9IiNmZmZmZmYiIGQ9Ik03LDYuNUM3LDUuMSw3LjQsNCw4LDRzMSwxLjEsMSwyLjVTOC42LDksOCw5UzcsNy45LDcsNi41eiIvPgoJPC9nPgo8L2c+CjxwYXRoIGZpbGw9Im5vbmUiIGQ9InoiLz4KPC9zdmc+);
      background-repeat: no-repeat;
      border: 1px solid color(ns-main-bg);
      color: color(white);
      background-position: center;
      background-size: 15px;
    }
  }

  .link-dates::before {
    position: absolute;
    content: '';
    border: 1px solid color(ns-light);
    width: 50px;
    height: 10px;
    top: -11px;
    left: 8px;
    border-bottom: none;
    border-right: none;
  }
  .link-dates::after {
    position: absolute;
    content: '';
    border: 1px solid color(ns-light);
    width: 50px;
    height: 10px;
    bottom: -11px;
    left: 8px;
    border-top: none;
    border-right: none;
  }
}
