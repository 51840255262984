////
/// Ayuda component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}Titulares de Operacion
/// @since version 1.0.0
////

.#{$ns}titulares {
  display: flex;

  &__container {
    &--main {
      width: 63.66667%;
    }
    &--aside {
      flex-grow: 100;
      padding: 0 1.5%;
      background-color: #f7f7f7;
      margin-left: 4.16667%;
      display: none;
    }
  }

  &__subtitle {
    font-size: 1.4375rem !important;
    color: #444444 !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;
    font-family: 'Proxima-Nova', Arial, sans-serif !important;
  }

  &__title-aside {
    color: #006699;
    text-transform: uppercase;
  }

  & p {
    font-family: $ns-font-proxima !important;
    font-weight: 400;
    color: color(ns-main-bg);
    line-height: 1.2;
    font-size: 1rem;
    margin: 15px 0;
    line-height: normal;
  }

  & a {
    color: color(ns-primary);
    font-weight: 600;
    font-size: 16px;
    font-family: $ns-font-proxima;
    line-height: 1.2;
  }

  & a:hover {
    text-decoration: underline;
  }
}

@include media-query(xs) {
  .#{$ns}titulares {
    &__container--main {
      width: 100%;
    }
  }
}
