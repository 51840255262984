////
/// Informacion component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}WEB Informa
/// @since version 1.0.0
////

.#{$ns}informacion {
  .container-title {
    &__color {
      color: color(ns-primary);
      font-family: $ns-font-proxima-extrabold;
      text-transform: uppercase;
      font-size: 1.4375rem;
      font-weight: 800;
      margin-block: 15px;
    }
  }

  @media screen and (min-width: 540px) {
    .grid-xs-24 {
      width: 97%;
    }
    .grid-sm-16 {
      width: 63.66667%;
    }
  }

  .container-paragraph {
    &--article {
      padding: 1.25rem 0;
      font-family: "proxima-nova", Arial, sans-serif;
      line-height: $ns-line-height;

      &__subtitle {
        line-height: $ns-line-height;
        margin-bottom: 0;
        font-size: 1.4rem;
        color: color(ns-main-bg);
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 15px;
      }

      &__time {
        display: block;
        margin: 0 0 0.7rem 0;
        font-size: 1.1875rem;
        font-weight: 700;
        color: color(ns-primary);
      }

      &__text {
        font-family: $ns-font-proxima-alt-light;
        margin-bottom: 12px;
        color: color(ns-main-bg);
      }

      &__bold {
        color: color(ns-primary);
        font-weight: 600;
        &--custom{
          @media screen and (max-width: 540px) {
            overflow: hidden;
            display: block;
          }
        }
      }
    }
  }
}
