////
/// Accordion component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}accordion
/// @since version 1.0.0
////

.#{$ns}accordion {
  &.ui-accordion {
    .ui-accordion-header {
      border: rem(1) solid color(ns-light);
      border-radius: 0;
      background: color(white);
      color: color(ns-grey-accordion);
      font-size: rem(14);
      text-align: center;
      font-family: $ns-font-proxima;
      font-weight: 600;
      padding: rem(13) rem(30) rem(13) rem(7);
      margin-bottom: rem(10);
      position: relative;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &-active {
        border-bottom: 0;
        margin-bottom: 0;
      }

      &:before {
        content: '';
        position: absolute;
        right: 20px;
        top: 12px;
        width: 8px;
        height: 8px;
        border-top: 3px solid #dbdbdb;
        border-left: 3px solid #dbdbdb;
        -webkit-transform: rotate();
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        pointer-events: none;
      }

      &:after {
        content: '';
        position: absolute;
        right: 20px;
        bottom: 16px;
        width: 8px;
        height: 8px;
        border-top: 3px solid color(ns-light);
        border-left: 3px solid color(ns-light);
        -webkit-transform: rotate(225deg);
        -ms-transform: rotate(225deg);
        transform: rotate(225deg);
        pointer-events: none;
      }
    }

    .ui-accordion-content {
      border-color: color(ns-light);
      padding: 0 rem(12);
      overflow: hidden;
      border-radius: 0;

      .noMobile {
        display: none;
      }
    }
  }
}
