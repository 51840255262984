////
/// OperationNew component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}OperationNew
/// @since version 1.0.0
////

.#{$ns}new {
  &__title {
    font-size: 23px;
    font-family: $ns-font-proxima;
    text-transform: uppercase;
    color: color(ns-primary);
    font-weight: 800;
    margin-bottom: 1rem;
    line-height: 1.2;
  }

  &__container {
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;
  }

  &__content {
    padding: 2rem 4.6rem 2rem 3.75rem;
    width: 74%;
    p {
      color: #717171 !important;
      font-weight: 400;
      font-size: rem(16);
      line-height: 1.2rem;
      font-family: $ns-font-proxima;
      margin: 1rem 0;
      a {
        font-weight: 600 !important;
        color: #006699 !important;
        text-decoration: none;
      }
    }
  }

  &__related {
    width: 26%;
    background-color: #f7f7f7;
  }

  &__aside-title {
    text-transform: uppercase;
    font-size: 19px;
    line-height: 1.2;
    padding: 2rem 4.1rem 1rem 1rem;
    text-transform: uppercase;
    font-family: 'Proxima-Nova', Arial, sans-serif;
    font-weight: 600;
    color: #006699;
  }

  &__list-news {
    padding: 0rem 3rem 0rem 1rem;
    li {
      margin-bottom: 1rem;
      a {
        text-transform: uppercase;
        font-size: 0.875rem;
        color: #717171;
        line-height: 1.05rem;
        font-family: 'Proxima-Nova', Arial, sans-serif;
        font-weight: 600;
      }
    }
  }
}

@include media-query(xs) {
  .#{$ns}new {
    &__content {
      width: 100%;
      padding: rem(32) rem(20);
    }

    &__related {
      width: 100%;
      margin-bottom: 28px;
    }
  }
}
