////
/// Dashboard component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}dashboard
/// @since version 1.0.0
////

.#{$ns}about {
  max-width: 700px;
  width: 100%;

  &__container {
    overflow-y: scroll;
    max-height: 270px;
  }
  &__wrap {
    height: 320px;
  }
  &__button {
    border: 1px solid color(ns-light);
    background-color: color(white);
    padding: 0.4375rem 1rem;
    border-radius: 1.25rem;
    text-transform: uppercase;
    margin: 0 rem(4);
    a {
      color: color(ns-primary);
      font-size: rem(14);
      font-family: $ns-font-proxima;
    }
    a:hover {
      text-decoration: none;
    }
  }
  &__button:hover {
    background-color: rgba(247, 247, 247, 0.8);
  }
  &__button-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &__close-container {
    position: relative;
    top: -23px;
    right: 4px;
  }
  &__close {
    i {
      color: color(ns-primary);
      font-size: rem(16);
    }
    position: absolute;
    right: -5%;
  }
  &__close:hover {
    cursor: pointer;
  }

  h1 {
    margin: 15px 0;
    font-size: 23px;
    font-family: $ns-font-proxima;
    text-transform: uppercase;
    color: color(ns-primary);
    font-weight: 800;
  }
  h2 {
    margin: 15px 0;
    font-size: 19px;
    font-family: $ns-font-proxima;
    font-weight: 700;
    color: color(ns-primary);
    margin-top: rem(20);
    text-transform: uppercase;
  }

  hr {
    border: 0;
    margin: 1.875rem 0;
    background-color: #a1a1a1;
    height: 1px;
    box-sizing: content-box;
  }
  p {
    margin: 16px 0;
    color: color(ns-main-bg);
    line-height: normal;
    font-family: $ns-font-proxima;
    font-weight: 400;
    font-size: 16px;
  }
  .is-glossary-item {
    position: relative;
    border-bottom: 1px dotted #006699;
    cursor: pointer;
    color: #444444;
    font-size: 16px;
    font-weight: 700;
    &:focus,
    &:hover {
      background-color: #ffffaa;
    }
  }
  ul {
    list-style: none;
    padding-left: 20px;
  }
  li {
    position: relative;
    color: color(ns-main-bg);
    line-height: normal;
    font-family: $ns-font-proxima;
    font-size: 16px !important;
  }
  li::before {
    content: '';
    position: absolute;
    top: 0.3125rem;
    left: -1.25rem;
    width: 7px;
    height: 7px;
    background-color: #dbdbdb;
    border-radius: 50%;
  }
  strong {
    font-family: $ns-font-proxima;
    font-size: 16px;
    font-weight: 700;
    color: #444444;
  }

  a {
    color: #006699;
    font-family: $ns-font-proxima;
    font-weight: 600;
    letter-spacing: rem(0.3) !important;
  }
  a strong {
    color: #006699;
    font-family: $ns-font-proxima;
  }

  .emphasis {
    color: color(ns-primary) !important;
  }

  @media (max-width: 430px) {
    .tooltip-sm {
      padding: rem(20) rem(20);
      width: 75%;
    }
    &__container-sm {
      padding: rem(10) rem(10);
    }
    &__wrap-sm {
      padding: rem(20) rem(12);
    }
  }

  @include media-query(xs) {
    #modal-responsive {
      display: none;
      min-width: 100vw;
      min-height: 100vh;
      position: fixed;
      top: 0%;
      left: 0%;
      z-index: 10;
      background: white;
    }
    .arrow:before {
      display: none;
    }
    #modal {
      display: none;
    }
    &__container-sm {
      padding: rem(20) rem(20);
    }
    &__wrap-sm {
      padding: rem(20) rem(12);
    }
    &__close-sm {
      i {
        color: color(ns-primary);
        font-size: rem(16);
      }
      position: absolute;
      right: 5%;
      top: 5%;
    }
    &__button-sm {
      border: 1px solid color(ns-light);
      background-color: color(white);
      padding: 0.4375rem 1rem;
      border-radius: 1.25rem;
      text-transform: uppercase;
      margin: 0 rem(4);
      a {
        color: color(ns-primary);
        font-size: rem(14);
        font-family: $ns-font-proxima;
      }
      a:hover {
        text-decoration: none;
      }
    }
    &__button-sm:hover {
      background-color: rgba(247, 247, 247, 0.8);
    }
    &__button-container-sm {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  @media screen and (max-width: 1024px) {
    .tooltip-sm {
      top: 607px;
    }
  }
  @media screen and (max-width: 768px) {
    .tooltip-sm {
      top: 587px;
    }
  }
  .arrow:before {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid color(white);
    content: '';
    position: absolute;
    top: -15px;
    left: 180px;
    filter: drop-shadow(2px -2px 2px color(darken-light));
  }
}

.tooltip-sm {
  display: none;
  background-color: white;
  position: absolute;
  padding: 2.5rem 2.5rem;
  width: 502px;
  max-width: 502px;
  border: 1px solid #dbdbdb;
  height: 350px;
  border-radius: 0px;
  box-shadow: 5px 5px 0 rgba(20, 15, 15, 0.05);
  top: 622px;
  left: 77px;

  h1 {
    font-size: 19px;
    font-family: $ns-font-proxima;
    color: color(ns-primary);
    margin-top: rem(1);
    margin-bottom: rem(19);
  }
  p {
    color: black;
    font-size: rem(14);
    line-height: 1.4;
    font-family: $ns-font-proxima;
  }
}

@media screen and (max-width: 1199px) {
  .#{$ns}about {
    padding-left: rem(28);
  }
}

@media screen and (max-width: 767px) {
  .#{$ns}about {
    padding-left: 0;
  }
}
