////
/// A element
///
/// Here we got the a styles
////
a {
    color: color(black);

    &:focus, &:active {
        outline: 0;
        
    }
}