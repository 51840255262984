////
/// Fonts
///
/// This class is used for font load
///
/// @access public
/// @author Dfront Specialist Team
/// @group Generic
/// @since version 1.0.0
////
//* Example for use @font-face
//
//	@include font-face([name-font],
//		$ns-path-font+'[name-font]',
//		[font-weight],
//		[font-style],
//		[type-font(eot | woff | svg | ttf)]
//	);
//
//*/

// Proxima Nova
@include font-face(
  'Proxima-Nova-Thin',
  $ns-path-font + 'Proxima-Nova/Proxima-Nova-Thin',
  '100',
  'normal',
  'otf'
);
@include font-face(
  'Proxima-Nova-Regular',
  $ns-path-font + 'Proxima-Nova/Proxima-Nova-Regular',
  '300',
  'normal',
  'otf'
);
@include font-face(
  'Proxima-Nova-Bold',
  $ns-path-font + 'Proxima-Nova/Proxima-Nova-Bold',
  '400',
  'normal',
  'otf'
);
@include font-face(
  'Proxima-Nova-ExtraBold',
  $ns-path-font + 'Proxima-Nova/Proxima-Nova-ExtraBold',
  '500',
  'normal',
  'otf'
);
@include font-face(
  'Proxima-Nova-Black',
  $ns-path-font + 'Proxima-Nova/Proxima-Nova-Black',
  '700',
  'normal',
  'otf'
);

@include font-face(
  'Proxima-Nova-Alt-Bold',
  $ns-path-font + 'Proxima-Nova/Proxima-Nova-Alt-Bold',
  '900',
  'normal',
  'otf'
);
@include font-face(
  'Proxima-Nova-Alt-Light',
  $ns-path-font + 'Proxima-Nova/Proxima-Nova-Alt-Light',
  '900',
  'normal',
  'otf'
);
@include font-face(
  'Proxima-Nova-Alt-Thin',
  $ns-path-font + 'Proxima-Nova/Proxima-Nova-Alt-Thin',
  '800',
  'normal',
  'otf'
);

// Montserrat
@include font-face(
  'Montserrat-Thin',
  $ns-path-font + 'Montserrat/Montserrat-Thin',
  '100',
  'normal',
  'ttf'
);
@include font-face(
  'Montserrat-Light',
  $ns-path-font + 'Montserrat/Montserrat-Light',
  '300',
  'normal',
  'ttf'
);
@include font-face(
  'Montserrat-Regular',
  $ns-path-font + 'Montserrat/Montserrat-Regular',
  '400',
  'normal',
  'ttf'
);
@include font-face(
  'Montserrat-Medium',
  $ns-path-font + 'Montserrat/Montserrat-Medium',
  '500',
  'normal',
  'ttf'
);
@include font-face(
  'Montserrat-Bold',
  $ns-path-font + 'Montserrat/Montserrat-Bold',
  '700',
  'normal',
  'ttf'
);
@include font-face(
  'Montserrat-Black',
  $ns-path-font + 'Montserrat/Montserrat-Black',
  '900',
  'normal',
  'ttf'
);

// Roboto
@include font-face(
  'Roboto-Thin',
  $ns-path-font + 'Roboto/Roboto-Thin',
  '100',
  'normal',
  'ttf'
);
@include font-face(
  'Roboto-Light',
  $ns-path-font + 'Roboto/Roboto-Light',
  '300',
  'normal',
  'ttf'
);
@include font-face(
  'Roboto-Regular',
  $ns-path-font + 'Roboto/Roboto-Regular',
  '400',
  'normal',
  'ttf'
);
@include font-face(
  'Roboto-Medium',
  $ns-path-font + 'Roboto/Roboto-Medium',
  '500',
  'normal',
  'ttf'
);
@include font-face(
  'Roboto-Bold',
  $ns-path-font + 'Roboto/Roboto-Bold',
  '700',
  'normal',
  'ttf'
);
@include font-face(
  'Roboto-Black',
  $ns-path-font + 'Roboto/Roboto-Black',
  '900',
  'normal',
  'ttf'
);

@include font-face(
  'Font-Awesome-Solid',
  $ns-path-font + 'font-awesome/fa-solid-900',
  '900',
  'normal',
  'ttf'
);

@include font-face(
  'Font-Awesome-Regular',
  $ns-path-font + 'font-awesome/fa-regular-400',
  '400',
  'normal',
  'ttf'
);

@include font-face(
  'Font-Awesome-Brands',
  $ns-path-font + 'font-awesome/fa-brands-400',
  '400',
  'normal',
  'ttf'
);

@include font-face(
  'Cousine',
  $ns-path-font + 'Cousine/Cousine-Regular',
  '400',
  'normal',
  'ttf'
);

@include font-face(
  'Cousine-Bold',
  $ns-path-font + 'Cousine/Cousine-Bold',
  '600',
  'normal',
  'ttf'
);

// Font-awesome

$fa-font-path: $ns-path-font + 'font-awesome';

@import '../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';

@import '../../../node_modules/@fortawesome/fontawesome-free/scss/solid';

@import '../../../node_modules/@fortawesome/fontawesome-free/scss/brands';

@import '../../../node_modules/@fortawesome/fontawesome-free/scss/regular';
