.#{$ns}info {
  width: 63.66667%;
  min-height: rem(284);

  &__title {
    text-transform: uppercase;
    color: color(ns-primary);
    font-family: $ns-font-proxima;
    margin: 0.67em 0;
    font-size: rem(23);
    font-weight: 800;
  }

  &__subtitle {
    margin-bottom: 0;
    margin-top: 1rem;
    font-size: 1.4375rem;
    color: #444444;
    font-weight: 400;
    text-transform: uppercase;
    font-family: $ns-font-proxima;
  }

  &__container {
    padding: 1.25rem 0;
  }

  &__time {
    display: block;
    margin: 0 0 0.7rem 0;
    font-size: 1.1875rem;
    font-weight: 700;
    color: color(ns-primary);
    font-family: $ns-font-proxima;
  }

  & p {
    font-family: $ns-font-proxima-alt-light !important;
    font-weight: 400;
    color: color(ns-main-bg);
    line-height: 1.2;
    font-size: 1rem;
    margin: 15px 0;
  }

  & span {
    color: #444444;
  }

  & a {
    color: color(ns-primary);
    text-decoration: none !important;
    font-weight: 600;
  }

  & a:hover {
    text-decoration: underline;
  }

  @include media-query(xs) {
    & {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1199px) {
  .#{$ns}info {
    padding-left: rem(28);
  }
}

@media screen and (max-width: 539px) {
  .#{$ns}info {
    padding-left: 0;
    min-height: rem(320);
  }
}
