////
/// Generacion y Consumo component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}dashboard
/// @since version 1.0.0
////

.#{$ns}calendar {
  line-height: 1.2;
  margin-bottom: 20px;

  .esios-help__title {
    margin-top: rem(18);
    font-family: $ns-font-proxima;
    font-weight: 800;
  }
  .title-for-print {
    .next-button,
    .prev-button {
      display: none;
    }
    .move-time-selector {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 100px 1fr;
      align-items: center;
      justify-content: space-between;

      .selector {
        display: grid;
        grid-template-columns: 100px;

        .selected {
          border: none !important;
          padding: 3px 30px 2px 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          display: inline-block;
          background-color: color(ns-lighter);

          &::before {
            display: none;
          }
        }

        .select-time-container {
          grid-template-columns: 1fr;
          position: relative;

          .#{$ns}toolbar-time {
            &__mod-date {
              margin-bottom: rem(20);
              width: 100px;
              padding-left: 15px;
            }
          }
        }

        .select-time-container.active .select-time-options {
          display: grid !important;
          max-height: rem(212);
          overflow: hidden;
          overflow-y: auto;
          z-index: 99999;
        }

        .select-time-container .select-time-options {
          top: rem(18);
          width: rem(80);
          padding: rem(10) 0 rem(10) 0;
          position: absolute;
          z-index: 2;
          background: white;
          box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.1);
          border: 1px solid color(ns-light);
          border-top: none;
          display: none;
        }

        .button {
          background: color(ns-lighter);
          position: relative;
          cursor: pointer;
          border: 1px solid color(ns-light);
        }

        .prev-button {
          border-right: none;
        }

        .prev-button::after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-right: 5px solid #717171;
          pointer-events: none;
          top: 50%;
          left: 50%;
          @include transform(translate(-50%, -50%));
          content: '';
        }

        .next-button {
          border-left: none;
        }

        .next-button::after {
          content: '';
          position: absolute;
          left: 7px;
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 5px solid #717171;
          pointer-events: none;
          top: 50%;
          left: 50%;
          @include transform(translate(-50%, -50%));
          content: '';
        }
      }

      &.one-column {
        grid-template-columns: 1fr !important;
        margin-top: 10px;

        .selected {
          text-align: left;
          justify-content: left;
          z-index: 1;
          background-color: color(ns-light);
          border-radius: rem(10);
          margin-top: rem(-8);
          padding-right: rem(28);
          font-size: rem(12) !important;
          font-family: $ns-font-cousine;
          font-weight: 600;

          &:after {
            content: '';
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #717171;
            right: 0.625rem;
            z-index: 1;
            pointer-events: none;
            top: rem(8);
            position: absolute;
          }
        }

        .select-time-options .option {
          text-align: left;
          padding: rem(5);
          font-size: rem(12) !important;
          width: 100%;
          text-align: center;

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .esios-toolbar {
    margin-bottom: 1rem;
  }

  .esios-toolbar__share {
    margin-top: 0.5rem;
    z-index: 99999 !important;
  }

  .esios-toolbar .title-share:after {
    left: 6px !important;
  }

  .esios-toolbar__share:before,
  .esios-toolbar__share:after {
    left: 0px !important;
  }

  .ui-tabs-panel {
    .ui-widget-content {
      width: 100%;
      margin: 0;
    }
  }

  .ui-datepicker.ui-widget.ui-widget-content {
    background-image: none;
    box-shadow: none;
    .ui-datepicker-unselectable.ui-state-disabled .ui-state-default {
      background: color(white);
      padding: rem(7) rem(5);
      opacity: 1;
    }
    &.ui-datepicker-multi {
      margin: 0 rem(-11);
      .ui-datepicker-group {
        width: 25%;
        padding: 0 rem(11);
        margin-top: 24px;
      }
    }

    .print-page &.ui-datepicker-multi {
      margin: 0;
      .ui-datepicker-group {
        width: 33%;
        padding: 0 rem(11);
      }
    }

    .ui-datepicker-header {
      padding: 0;
      width: auto;

      div.ui-datepicker-title {
        font-family: $ns-font-proxima-bold;
        text-transform: uppercase;
        text-shadow: none;
        font-size: 14px;
        justify-content: center;
        line-height: 1.2;
        height: auto;
        padding-bottom: rem(10);
        margin: 0;

        &::before,
        &::after {
          content: none;
        }

        .ui-datepicker-month {
          color: color(ns-main-bg);
        }

        .ui-datepicker-year {
          color: color(ns-main-bg);
        }
      }
    }

    .ui-datepicker-calendar {
      font-family: $ns-font-cousine;

      thead {
        th {
          padding: rem(7) rem(5);
          width: rem(35);

          span {
            font-family: $ns-font-cousine;
            font-size: rem(14);
            line-height: rem(17);
          }
        }
      }

      tbody {
        tr {
          td {
            height: rem(31);
            width: rem(35);
            position: relative;

            &.ui-datepicker-week-end {
              background-color: color(white);
            }

            .#{$ns}tooltip__link {
              font-family: $ns-font-proxima;
              font-size: rem(11);
              color: color(ns-darker);
              line-height: rem(16);
              padding: 0;
              margin-bottom: rem(6);

              &:last-child {
                margin-bottom: 0;
              }
            }

            &.ui-datepicker-today:not(.ui-datepicker-current-day)
              .#{$ns}tooltip__link {
              border-bottom: 0;
              font-weight: 400;
              margin-bottom: rem(6);
            }
          }
        }
      }

      .ui-state-default {
        border: 0;
        background: color(white);
        font-family: $ns-font-cousine;
        font-size: rem(14);
        padding: rem(7) rem(5);
        line-height: rem(17);
        text-align: center;

        &.not-working {
          color: color(ns-primary);
          background-color: color(ns-lighter);
        }

        &.anual-spec {
          border: rem(1) solid color(ns-red);
          cursor: pointer;
        }

        &.anual-auction {
          color: color(white);
          background-color: color(ns-red);
        }

        &.monthly-spec {
          color: color(ns-primary);
          border: rem(1) solid color(ns-primary);
          border-radius: rem(20);
          padding: rem(2) rem(5);
          margin: rem(5) 0;
        }

        &.monthy-auction {
          color: color(white);
          background-color: color(ns-primary);
          border: rem(1) solid color(ns-primary);
          border-radius: rem(20);
          padding: rem(2) rem(5);
          margin: rem(5) 0;
        }

        &.trim-spec {
          color: color(ns-green);
          border: rem(1) solid color(ns-green);
          border-radius: rem(5);
          padding: rem(2) rem(5);
          margin: rem(5) 0;
        }

        &.trim-auction {
          color: color(white);
          background-color: color(ns-green);
          border: rem(1) solid color(ns-green);
          border-radius: rem(5);
          padding: rem(2) rem(5);
          margin: rem(5) 0;
        }
      }
    }
  }

  .ui-widget-content {
    border: 0;
    z-index: 0 !important;
  }

  .ui-widget.ui-widget-content {
    border: 0;
    margin-left: -6px;
    margin-top: -3px;
  }

  &__mobile {
    display: none;
  }

  &__download-btn {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IlVudGl0bGVkLVBhZ2VfeDI1XzIwMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIKCSB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAtMC44IDIzIDIzIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgLTAuOCAyMyAyMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxwYXRoIGZpbGw9Im5vbmUiIGQ9InoiLz4KPGcgaWQ9IkxheWVyX3gyNV8yMDEiPgoJPGcgb3BhY2l0eT0iMC40Ij4KCQk8cGF0aCBpZD0iRWxpcHNlIiBmaWxsPSJub25lIiBzdHJva2U9IiM3MTcxNzEiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTEuMiwxMC43YzAtNS42LDQuNi0xMC4yLDEwLjItMTAuMnMxMC4yLDQuNiwxMC4yLDEwLjIKCQkJUzE3LDIwLjksMTEuNCwyMC45UzEuMiwxNi4zLDEuMiwxMC43eiIvPgoJCTxwYXRoIGZpbGw9IiM3MTcxNzEiIGQ9Ik02LjUsMTQuN2g5Ljd2LTMuNWgtMS44djEuOEg4LjN2LTEuOEg2LjVWMTQuN3oiLz4KCQk8cGF0aCBmaWxsPSIjNzE3MTcxIiBkPSJNOC4zLDkuNEgxMFYxMmgyLjdWOS40aDEuOEwxMS42LDVoLTAuNUw4LjMsOS40eiIvPgoJPC9nPgo8L2c+CjxwYXRoIGZpbGw9Im5vbmUiIGQ9InoiLz4KPC9zdmc+);
    background-repeat: no-repeat;
    width: rem(23);
    height: rem(23);
    vertical-align: middle;

    &:hover {
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IlVudGl0bGVkLVBhZ2VfeDI1XzIwMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIKCSB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAtMC44IDIzIDIzIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgLTAuOCAyMyAyMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxwYXRoIGZpbGw9Im5vbmUiIGQ9InoiLz4KPHBhdGggZmlsbD0ibm9uZSIgZD0ieiIvPgo8ZyBpZD0iTGF5ZXJfeDI1XzIwMSI+Cgk8ZyBvcGFjaXR5PSIwLjcwMiI+CgkJPHBhdGggaWQ9IkVsaXBzZV8xXyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMzMzMzMzIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0xLjIsMTAuNkMxLjIsNSw1LjcsMC40LDExLjQsMC40UzIxLjUsNSwyMS41LDEwLjYKCQkJUzE3LDIwLjksMTEuNCwyMC45UzEuMiwxNi4zLDEuMiwxMC42eiIvPgoJCTxwYXRoIGZpbGw9IiMzRTNFM0UiIGQ9Ik02LjUsMTQuN2g5Ljd2LTMuNmgtMS44djEuOEg4LjN2LTEuOEg2LjVWMTQuN3oiLz4KCQk8cGF0aCBmaWxsPSIjM0UzRTNFIiBkPSJNOC4zLDkuM0gxMFYxMmgyLjdWOS4zaDEuOGwtMi44LTQuNWgtMC41TDguMyw5LjN6Ii8+Cgk8L2c+CjwvZz4KPC9zdmc+);
    }
  }

  &__cell-download {
    position: relative;
  }

  h1,
  h2 {
    font-family: $ns-font-proxima;
    color: color(ns-primary);
  }

  h1 {
    font-size: 1.4375rem;
    margin: 0.37em 0;
    font-weight: 800;
  }

  h2 {
    font-size: 1.1875rem;
  }

  .ui-tabs-nav {
    display: flex;
    position: relative;
    padding: 0;
    margin-bottom: 2.5rem;
    justify-content: space-between;
    text-align: center;
    text-decoration: none;
    list-style: none;
    background: transparent;
    border: 0;
    border-bottom: rem(1) solid color(ns-light);
    border-radius: 0;

    .ui-tabs-active {
      border-bottom: 0;
      background-color: color(white);
    }

    .ui-state-default {
      width: 49.5%;
      box-sizing: border-box;
      cursor: pointer;
      background-color: #f7f7f7;
      border-radius: 0;
      border: 0;
      background-image: none;

      &.ui-tabs-active {
        background-color: color(white);

        .ui-tabs-anchor {
          border: rem(1) solid color(ns-light);
          border-bottom: 0;
        }
      }

      .ui-tabs-anchor {
        border: rem(1) solid color(ns-light);
        border-bottom: 0;
        font-family: $ns-font-proxima;
        text-transform: uppercase;

        display: block;
        width: 100%;
        line-height: 2.4375rem;
        color: color(ns-darker);
        font-weight: 600;
        font-size: rem(19);
        padding: 0;
        -webkit-transition: background 0.1s;
        transition: background 0.1s;
        text-decoration: none;
        &:hover {
          background-color: color(ns-grey-light);
        }
      }
    }
  }

  li.ui-state-default.ui-corner-top.ui-tabs-active {
    .ui-tabs-anchor {
      background-color: white;
      color: #006699;
      cursor: text;
    }
    &:hover {
      background-color: white;
    }
  }

  .print-btn {
    margin-top: rem(20);
  }
  .ui-tabs-panel {
    .is-xs-hidden {
      .row {
        display: flex;
        justify-content: space-between;
        position: relative;

        .grid-sm-6 {
          width: 24.5%;
          padding: 0 1.5%;
          min-height: 1px;

          .mod-calendar {
            .clndr {
              position: relative;

              &:after {
                content: '';
                height: 1px;
                width: 100%;
                display: block;
                background-color: #dbdbdb;
                position: absolute;
                top: 52px;
                left: 0;
              }

              .clndr-controls {
                .month {
                  padding-bottom: 0.625rem;
                  font-size: 0.875rem;
                  font-weight: 700;
                  text-align: center;
                  text-transform: uppercase;
                }
              }

              table {
                margin-bottom: 1.25rem;
                font-size: 0.875rem;
                width: 100%;
                border-collapse: collapse;
                border-spacing: 0;
                font-family: 'Cousine';

                .header-days {
                  .header-day {
                    color: #444444;
                  }
                }

                td {
                  text-align: center;
                  padding: 0.4375rem 0.3125rem;
                  position: relative;

                  .day-contents {
                    color: #444444;
                  }

                  .mod-calendar-tooltip {
                    position: absolute;
                    left: 50%;
                    -webkit-transform: translate(-50%, -100%);
                    -ms-transform: translate(-50%, -100%);
                    transform: translate(-50%, -100%);
                    display: none; // si desactivo, saldra cuadro de mensaje, tengoque hacer hover para para vez que pase el mouse
                    box-sizing: border-box;
                    top: 20px;
                    margin-top: -20px;
                    margin-bottom: 20px;
                    padding: 0 1.25rem;
                    z-index: 2;
                    border: 1px solid #dbdbdb;
                    background-color: white;
                    color: #717171;
                    font-family: 'proxima-nova', Arial, sans-serif;
                    font-size: 0.75rem;
                    font-weight: 600;
                    text-transform: uppercase;
                    cursor: default;

                    a {
                      display: block;
                      margin: 0 auto;
                      color: #444444;
                      font-weight: 400;
                      margin-bottom: 0.5em;
                      background-color: transparent;
                    }

                    a:first-of-type {
                      padding-top: 1em;
                      border-top: 1px solid #dbdbdb;
                    }

                    p {
                      padding-bottom: 1em;
                      margin-top: 0.75em;
                      margin-bottom: 0;
                    }

                    &:before {
                      pointer-events: none;
                      left: 50%;
                      border: solid transparent;
                      content: ' ';
                      height: 0;
                      width: 0;
                      position: absolute;
                      pointer-events: none;
                      margin-top: -1px;
                      border-color: rgba(0, 0, 0, 0);
                      border-top-color: #dbdbdb;
                      border-width: 17px;
                      margin-left: -17px;
                      bottom: -33px;
                    }

                    &:after {
                      pointer-events: none;
                      left: 50%;
                      border: solid transparent;
                      content: ' ';
                      height: 0;
                      width: 0;
                      position: absolute;
                      pointer-events: none;
                      margin-top: -1px;
                      border-color: rgba(0, 0, 0, 0);
                      border-top-color: #fff;
                      border-width: 15px;
                      margin-left: -15px;
                      bottom: -29px;
                    }
                  }
                }

                .not-working-day {
                  background-color: #f7f7f7;
                  color: #006699;
                }

                .quarter-auction {
                  color: white;
                  display: inline-block;
                  font-weight: 400;
                  line-height: 16px;
                  margin: 0.3125rem 0;
                  border-radius: 5px;
                  padding: 0.125rem 0.3125rem !important;
                  background-color: #85b200;
                  cursor: pointer;

                  .day-contents {
                    color: white;
                  }
                }

                .quarter-specifications {
                  display: inline-block;
                  font-weight: 400;
                  line-height: 16px;
                  margin: 0.3125rem 0;
                  border-radius: 5px;
                  padding: 0.125rem 0.3125rem !important;
                  border: 1px solid #85b200;
                  cursor: pointer;

                  .day-contents {
                    color: #85b200;
                  }
                }

                .month-specifications {
                  color: #006699;
                  display: inline-block;
                  font-weight: 400;
                  line-height: 16px;
                  margin: 0.3125rem 0;
                  border-radius: 20px;
                  padding: 0.125rem 0.3125rem !important;
                  border: 1px solid #006699;
                  cursor: pointer;
                }

                .month-auction {
                  color: white;
                  display: inline-block;
                  font-weight: 400;
                  line-height: 16px;
                  margin: 0.3125rem 0;
                  border-radius: 20px;
                  padding: 0.125rem 0.3125rem !important;
                  background-color: #006699;
                  cursor: pointer;

                  .day-contents {
                    color: white;
                  }
                }

                .year-auction {
                  background-color: #cf4634;
                  border: 1px solid #cf4634;
                  padding: 0.3125rem !important;
                  cursor: pointer;

                  .day-contents {
                    color: white;
                  }
                }

                .year-specifications {
                  border: 1px solid #cf4634;
                  padding: 0.3125rem !important;
                  cursor: pointer;

                  .day-contents {
                    color: #cf4634;
                  }
                }
              }
            }
          }
        }

        .legend {
          width: 100%;
          display: flex;

          .legend-part {
            p {
              height: 58px;
            }

            p:first-child {
              line-height: 30px;
            }
          }

          .legend-part.col1 {
            width: 20%;

            .part {
              display: -webkit-box;
              display: -webkit-flex;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: horizontal;
              -webkit-box-direction: normal;
              -webkit-flex-direction: row;
              -ms-flex-direction: row;
              flex-direction: row;
              -webkit-justify-content: space-around;
              -ms-flex-pack: distribute;
              justify-content: space-around;
              width: 100%;

              div {
                display: flex;

                span {
                  width: 100%;
                  font-size: 0.875rem;
                  margin: 0 auto;
                  text-align: center;
                }
              }
            }
          }

          .legend-part.col2 {
            width: 27%;
          }

          .legend-part.col3 {
            width: 28%;
          }

          .legend-part.col4 {
            width: 25%;
          }
        }
      }
    }
  }

  .ui-tabs-active {
    color: #717171;
  }

  .ui-tabs-inactive {
    display: none;
  }

  &__legend {
    width: 100%;
    display: flex;
    border: rem(1) solid color(ns-light);

    &-title-txt {
      color: color(ns-main-bg);
      font-weight: bold;
    }

    &-col {
      border-right: rem(1) solid color(ns-light);
    }

    &-content {
      display: flex;
      justify-content: space-between;
    }

    &-part {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      span {
        text-align: center;
        font-family: $ns-font-proxima;
      }
    }

    &-txt {
      width: 100%;
      color: color(ns-main-bg);
      font-size: rem(16);
      text-transform: capitalize;
    }

    &-title-text {
      font-weight: bold;
    }

    &-working-day,
    &-not-working-day {
      line-height: rem(30);
      padding: 0 rem(7);
      width: rem(30);
      font-size: rem(14);
      margin-bottom: rem(6);
    }

    &-working-day {
      color: color(ns-main-bg);
    }

    &-not-working-day {
      color: color(ns-primary);
      background-color: color(ns-lighter);
    }

    &-month-specifications {
      border: rem(1) solid color(ns-primary);
      border-radius: rem(20);
      color: color(ns-primary);
      line-height: rem(16);
      font-size: rem(14);
      padding: rem(2) rem(5);
      margin: rem(7) 0;

      &--inverse {
        background-color: color(ns-primary);
        color: color(white);
      }
    }

    &-year-specifications {
      color: color(ns-red);
      border: rem(1) solid color(ns-red);
      padding: rem(5);
      font-size: rem(14);
      margin: rem(4) 0;

      &--inverse {
        color: color(white);
        background-color: color(ns-red);
      }
    }

    &-quarter-specifications {
      color: color(ns-green);
      border: rem(1) solid color(ns-green);
      border-radius: rem(5);
      padding: rem(2) rem(5);
      font-size: rem(14);
      margin: rem(7) 0;

      &--inverse {
        color: color(white);
        background-color: color(ns-green);
      }
    }

    &--three {
      .#{$ns}calendar__legend {
        &-col {
          width: calc(75% / 2);
          padding: rem(15) rem(43);

          &:first-child {
            width: 25%;
          }

          &:last-child {
            border-right: 0;
          }
        }
        .print-page &-col {
          padding: rem(15) rem(10);
        }
      }
    }

    &--four {
      .#{$ns}calendar__legend {
        display: flex;

        &-col {
          width: calc(80% / 3);
          padding: rem(14) rem(12);

          &:first-child {
            width: 20%;
          }

          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }

  /* Datepicker */
  .ui-datepicker {
    table {
      &.ui-datepicker-calendar {
        tbody {
          tr {
            td {
              opacity: 1;
              padding: 0 !important;
            }
          }
        }
      }
    }
  }
  .ui-datepicker-inline {
    width: 100% !important;
    display: flex !important;
    flex-wrap: wrap;
  }

  .ui-datepicker-multi .ui-datepicker-group {
    float: none;
  }

  .ui-datepicker-unselectable {
    &:hover {
      .#{$ns}tooltip {
        display: block;
      }
    }
  }

  .ui-state-disabled {
    pointer-events: auto;
    display: table-cell !important;
  }

  @media print {
    margin-top: 0px !important;
  }
}
@media screen and (max-width: 1199px) {
  .#{$ns}calendar {
    padding-left: rem(27.2);
  }
}

@include media-query(lg) {
  .#{$ns}calendar {
    .ui-datepicker {
      &.ui-datepicker-multi {
        .ui-datepicker-group {
          width: calc(100% / 3);
        }
      }
    }
  }
  .print-page .#{$ns}calendar {
    .ui-datepicker {
      &.ui-datepicker-multi {
        .ui-datepicker-group {
          width: calc(100% / 3);
        }
      }
    }
  }
}

@include media-query(md) {
  .#{$ns}calendar {
    .ui-datepicker {
      &.ui-datepicker-multi {
        .ui-datepicker-group {
          width: 50%;
        }
      }
    }
  }
  .print-page .#{$ns}calendar {
    .ui-datepicker {
      &.ui-datepicker-multi {
        .ui-datepicker-group {
          width: calc(100% / 3);
        }
      }
    }
  }
}

@include media-query(sm) {
  .#{$ns}calendar {
    &__mobile {
      display: block;

      &-table {
        font-family: $ns-font-proxima;
        font-size: rem(14);
        width: 100%;

        thead {
          tr {
            border-bottom: rem(1) solid color(ns-light);
          }
        }

        th {
          color: color(black);
          padding: rem(10);
          text-transform: capitalize;
          font-family: $ns-font-proxima-bold;
          color: color(ns-main-bg);
        }

        tbody {
          td {
            padding: rem(10);
            color: color(ns-main-bg);
            span {
              color: color(ns-main-bg);
            }

            &:first-child {
              text-align: center;
              padding: rem(5);
              font-family: $ns-font-cousine;
            }

            &.#{$ns}calendar__cell-download {
              padding: 0;
            }
          }
        }
      }

      &-not-working {
        td {
          &:first-child {
            span {
              background-color: color(ns-lighter);
              color: color(ns-primary);
              padding: rem(3);
            }
          }
        }
      }

      &-anual-spec {
        td {
          padding: rem(7) rem(5);

          &:first-child {
            span {
              color: color(ns-red);
              background-color: color(white);
              border: rem(1) solid color(ns-red);
              padding: rem(5) rem(9);
            }
          }
        }
      }

      &-anual-auction {
        td {
          padding: rem(7) rem(5);

          &:first-child {
            span {
              color: color(white);
              background-color: color(ns-red);
              padding: rem(5) rem(9);
            }
          }
        }
      }

      &-monthly-spec {
        td {
          &:first-child {
            span {
              color: color(ns-primary);
              background-color: color(white);
              border: rem(1) solid color(ns-primary);
              padding: rem(2) rem(6);
              border-radius: rem(20);
            }
          }
        }
      }

      &-monthly-auction {
        td {
          &:first-child {
            span {
              color: color(white);
              background-color: color(ns-primary);
              border: rem(1) solid color(ns-primary);
              padding: rem(2) rem(6);
              border-radius: rem(20);
            }
          }
        }
      }
    }
  }

  .widget-myp-auction-calendar {
    display: none;
    margin-bottom: rem(10);
  }

  .ui-tabs-panel.ui-widget-content.ui-corner-bottom {
    display: none;
  }
}

@include media-query(xs) {
  .#{$ns}calendar {
    padding-left: 0;
  }
}
