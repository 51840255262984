////
/// Codigos de Red component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}codigos
/// @since version 1.0.0
////

.#{$ns}codigos {
  font-family: $ns-font-proxima;
  padding-left: 0px;
  padding-right: 1.25rem;
  width: 98%;
  margin-bottom: 35px;
  padding-top: 2px;

  h1 {
    color: color(ns-primary);
    margin: 17.1px 0 20px 0;
    font-size: 23px;
    font-family: $ns-font-proxima;
    text-transform: uppercase;
  }
  h2 {
    color: color(ns-primary);
    margin: 16px 0;
    letter-spacing: 0.01px;
    font-size: 19px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: $ns-font-proxima;
  }
  p {
    color: #444444;
    font-family: $ns-font-proxima;
    font-weight: 400;
    margin-top: 16px;
    margin-bottom: -3px;
    margin-left: 0;
    margin-right: 0;
    line-height: 20px !important;
  }
  ul {
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  li {
    &:first-child {
      p:first-child {
        margin-bottom: 23px !important;
      }
    }
    &:last-child {
      p:first-child {
        margin-bottom: 24px !important;
      }
    }
    color: color(ns-main);
    & p {
      margin-bottom: 17px !important;
    }
  }
  .mod-action-buttons {
    margin-bottom: 37px;
    text-align: center;
  }
  a.btn-info {
    display: inline-block;
    border: 1px solid color(ns-light);
    color: color(ns-primary);
    background-color: color(white);
    padding: 4.8px 20px;
    border-radius: 1.25rem;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    font-size: 0.875rem;
    text-transform: uppercase;
    outline: 0 !important;
    letter-spacing: 0;
  }
  a.btn-info:hover {
    text-decoration: none;
    border: 1px solid color(ns-light);
    background-color: color(ns-lighter);
    outline: 0 !important;
  }
  a.btn-info:focus {
    box-shadow: none !important;
  }

  a.btn-info:active {
    text-decoration: none;
    outline: 0 !important;
    box-shadow: none !important;
    background-color: color(ns-light) !important;
  }
  .btn-info:not(:disabled):not(.disabled):active,
  .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: color(ns-primary) !important;
    background-color: transparent !important;
    border-color: color(ns-light) !important;
  }
  a {
    color: color(ns-primary);
    font-weight: bold;
    text-decoration: none;
    outline: 0 !important;
    letter-spacing: 0.18px;
    margin-top: -0.88px;

    &:hover {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 540px) {
    padding-right: 0;
    // margin-top: rem(20);
    width: 100%;
    h1 {
      line-height: rem(26);
      margin-top: 0;
    }
    h2 {
      line-height: rem(22);
    }
    a.btn-info {
      display: inline-block;
      border: 1px solid color(ns-light);
      color: color(ns-primary);
      background-color: color(white);
      padding: 8px 20px;
      border-radius: 1.25rem;
      font-weight: 700;
      text-align: center;
      text-decoration: none;
      font-size: 0.875rem;
      line-height: 16px;
      text-transform: uppercase;
      outline: 0 !important;
      letter-spacing: 0;
      width: 95%;
    }
    // li {
    //   &:first-child {
    //     p:first-child {
    //       margin-bottom: 60px !important;
    //     }
    //   }
    // }
    .mod-action-buttons {
      margin-bottom: 17px;
      margin-top: 60px;
    }
  }
}

@media screen and (max-width: 1199px) {
  .#{$ns}codigos {
    padding-left: rem(27.2);
  }
}

@media screen and (max-width: 767px) {
  .#{$ns}codigos {
    padding-left: 0;
  }
}
