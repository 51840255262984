////
/// Unidades-Fisicas component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}unidades
/// @since version 1.0.0
////

.#{$ns}unidades {
  font-family: $ns-font-proxima;
  margin-top: 19px;
  &__wrap {
    width: 18.1%;
    @media screen and (max-width: 540px) {
      width: 100%;
      display: inline-block;
    }
    &--input {
      position: relative;
      padding: 0px;
      width: 20.5%;
      @media screen and (max-width: 540px) {
        grid-row: 5;
        width: 100%;
        order: 5;
      }
    }
  }
  &__button-remove {
    position: absolute;
    right: 6px;
    top: 35px;
    background-image: url('../../src/assets/images/button-remove.svg');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    padding-right: 13px;
    opacity: 0.4;
    &.hidden {
      visibility: hidden;
    }
    @media screen and (max-width: 540px) {
      top: 50px;
    }
  }
  &__title {
    text-transform: uppercase;
    font-size: 1.4375rem;
    font-weight: 800;
    color: color(ns-primary);
    font-family: $ns-font-proxima-extrabold;
    margin: 0.67em 0;
  }
  &__content {
    font-size: 0.875rem;
    margin-bottom: 2rem;
    padding: 0px 0.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-left: 0px;
    margin-left: -3px;
    @media screen and (max-width: 540px) {
      padding: 0;
      flex-wrap: wrap;
      flex-direction: column;
    }

    th {
      color: color(ns-main);
      font-weight: bold;
    }
    td {
      color: color(ns-main);
    }
  }
  &__subtitle {
    color: color(black);
    font-family: $ns-font-proxima-regular;
  }
  h1 {
    font-family: $ns-font-proxima-extrabold;
    color: color(ns-primary);
    margin: rem(15) 0 rem(5) 0;
    font-size: 1.4375rem;
    font-weight: 800;
    text-transform: uppercase;
  }
  h2 {
    font-size: 1.5625rem;
    color: color(ns-primary);
    text-transform: uppercase;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    text-align: center;
    margin-bottom: 2.5rem;
  }
  p {
    color: color(ns-main);
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  &__input {
    padding-left: 20px;
    border: 1px solid color(ns-border-ligth);
    display: inline-block;
    height: 55px;
    width: 350px;
    margin-top: 2.8%;
    margin-left: 1%;
    margin-right: 0.4%;
    margin-bottom: 5%;
    max-height: rem(250);
    overflow-y: scroll;
  }

  &__dropdown {
    text-transform: uppercase;
    height: 55px;
    font-family: $ns-font-proxima-regular;
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    color: #444444;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    vertical-align: middle;
    padding-left: 20px;
    padding-right: 20px;
    zoom: 1;
    position: relative;
    border: 1px solid #dbdbdb;
    font-size: 0.875rem;
    font-weight: 500;
    @media screen and (max-width: 540px) {
      height: 36px;
    }
  }

  &__buttons {
    text-align: center;
    margin: 2.25rem;
  }

  &__tabla {
    &--dropdown2 {
      border: none;
      top: 1.2rem;
      color: #444444;
      text-transform: uppercase;
      font-family: $ns-font-proxima-regular;
      -webkit-text-size-adjust: 100%;
      zoom: 1;
      position: relative;
      font-size: 12px;
      overflow: hidden !important;
      text-overflow: ellipsis;
      width: 100%;
    }
    &__tdContentDate {
      color: color(black);
      font-size: 0.8rem;
      font-family: 'proxima-nova', Arial, sans-serif;
      height: 25px;
    }
    &__td2 {
      color: color(black);
      font-size: 0.8rem;
      font-family: 'proxima-nova', Arial, sans-serif;
      margin: 0.87em 0;
      text-transform: uppercase;
      height: 40px;
      width: 100%;
    }
    &__input {
      padding-left: 20px;
      padding-right: 30px;
      border: 1px solid color(ns-border-ligth);
      display: inline-block;
      height: 55px;
      width: 100%;
      margin-top: 1rem;
      margin-left: 1%;
      margin-right: 0.4%;
      max-height: 15.625rem;
      overflow-y: scroll;
      font-size: 12px;
      color: #444444 !important;
      @media screen and (max-width: 540px) {
        width: 100%;
        display: block;
        margin: unset;
        margin-top: 30px;
      }
    }
    &__calendarIcon {
      margin-bottom: -3%;
      margin-left: 5px;
    }
    &__inputCalendar {
      border: 1px solid color(ns-border-ligth);
      display: inline-block;
      height: 37px;
      width: 98%;
      margin-top: 8%;
      padding-left: 20px;
      margin-right: 7%;
      margin-bottom: 5%;
      max-height: rem(250);
    }
    tr:nth-child(even) {
      background-color: #f7f7f7;
    }

    @include media-query(xs) {
      display: none;
    }
  }
}

.#{$ns}tablaUnidades {
  font-size: 0.875rem;
  margin-top: -11px;

  .#{$ns}table {
    margin-bottom: 40px;
  }

  .esios-pagination__results {
    margin-left: 0px;
    margin-top: -1px;
  }
  #pagination-unidades {
    margin-top: -12px;
    margin-bottom: -2px;
    margin-left: 12px;
  }

  .unidades-buscar {
    width: 100%;
    margin-left: 0%;
    font-size: 12px;
    font-weight: 600;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .esios-table__cell p {
    color: color(ns-main-bg);
    font-size: 0.875rem;
  }

  .unidades-page {
    .page {
      color: color(ns-main-bg);
      font-weight: 600;
      font-size: 0.875rem;
    }

    .select {
      border: 0;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  @media screen and (min-width: 540px) {
    table tr:nth-child(2n + 1) {
      background-color: color(white);
      width: 100%;
    }

    table tr:nth-child(2n) {
      background-color: color(ns-lighter);
    }

    table th,
    table td {
      display: table-cell;
      padding: 0.625rem 1.25rem;
      width: 16%;
    }
  }
}

.esios-unidades__dropdown {
  margin-left: 2%;
  margin-top: 1.45%;
  position: relative;
  @media screen and (max-width: 540px) {
    margin: 5px 0;
    width: 100%;
  }
  .ui-selectmenu-text {
    color: #444444;
    font-family: 'Proxima-Nova-Regular';
    font-size: 12px;
    @media screen and (max-width: 540px) {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .ui-selectmenu-button.ui-button {
    width: 170px;
    background: #ffffff;
    padding: 18px 0px;
    position: relative;
    margin-bottom: 0rem;
    border: none;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 0;
    @media screen and (max-width: 540px) {
      padding: 8px 0;
      width: 100%;
    }
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #717171;
      right: 0.625rem;
      z-index: 1;
      pointer-events: none;
      position: absolute;
      top: 50%;
      margin-top: -3px;
    }
    .ui-selectmenu-icon {
      display: none;
    }
  }
  .ui-selectmenu-menu {
    width: 194px;
    .ui-menu {
      border-radius: 0;
      border: 1px solid #dbdbdb;
      box-sizing: border-box;
      position: relative;
      width: 100%;
      max-height: 240px;
      left: -1.4rem;
    }
    .ui-menu-item-wrapper {
      text-transform: capitalize;
      padding: 8px 16px;
      font-size: 14px;
      background: none;
      border: none;
      color: #444444;
      &.ui-state-active {
        background-color: #f7f7f7;
      }
    }
    .ui-state-disabled {
      display: none;
    }
    .ui-menu-item {
      color: #444444;
    }
  }
  .ree-select-potencia {
    overflow: visible !important;
    text-overflow: clip;
    white-space: normal;
    width: calc(100% + 4px);
    height: 100%;
    top: 0;

    & + .ui-selectmenu-button {
      white-space: normal;
      padding: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: calc(100% - 23px);
    }
  }
}

@media screen and (max-width: 1199px) {
  .#{$ns}unidades {
    padding-left: rem(27.2);
  }
}

@media screen and (max-width: 767px) {
  .#{$ns}unidades {
    padding-left: 0;
  }
}

@media screen and (max-width: 539px) {
  .#{$ns}tablaUnidades {
    .#{$ns}table {
      tbody {
        display: block;

        tr:nth-child(2n + 1) {
          background-color: color(ns-lighter);
        }

        td {
          padding: rem(10) 0 rem(10) 0;
          line-height: 1.2;
          display: flex;
          align-items: center;

          &::before {
            width: 35%;
            padding: 0 calc(5% + 10px) 0 rem(19);
            hyphens: none;
            -ms-hyphens: none;
            -webkit-hyphens: none;
          }
        }
      }
    }
  }
}
@media print {
  .esios-layout__print-logo.unidades {
    margin-top: 192px;
  }
}
