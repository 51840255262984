////
/// Red de Mercado component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}Redmercado
/// @since version 1.0.0
////

.#{$ns}Redmercado {
  padding-left: 0px;
  margin: 0 -1.5%;
  font-family: $ns-font-proxima;
  &__title,
  h2 {
    line-height: 1.2;
    color: color(ns-primary);
    text-transform: uppercase;
  }

  &__title {
    font-size: 1.4375rem;
    font-weight: 800;
    font-family: $ns-font-proxima;
    margin-block: 20px;
    margin-bottom: 13px;
  }

  h2 {
    font-size: 1.1875rem;
    font-family: $ns-font-proxima;
    margin-top: 21px;
    margin-bottom: 4.9px;
    font-weight: 700;
  }

  & h2 + p {
    margin-top: 15px;
    margin-bottom: -1px;
  }

  & ul + p {
    margin-top: 13px;
  }

  h3 {
    color: color(ns-darker);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1rem;
    margin-top: 15px;
    letter-spacing: 0.25px;
  }

  strong {
    font-weight: 700;
    color: color(ns-darker);
    font-family: $ns-font-proxima;
  }

  .grid-xs-24 {
    width: 97%;
    padding: 0 1.5%;
  }

  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 400;
    color: color(ns-main-bg);
    font-family: $ns-font-proxima;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 0;
    max-width: 1032px;

    a {
      font-weight: 600;
      font-family: $ns-font-proxima;
    }
  }

  .icon-download-pdf + a {
    left: 2.1875rem !important;
  }

  ol {
    margin-left: 37px;
    line-height: normal;
    margin-top: 15px;
    list-style: auto;
    margin-bottom: 17px;
    + h3 {
      margin-top: 20px;
      margin-left: -2px;
    }
    li {
      color: color(ns-main-bg);
      margin-bottom: -1px;
    }
  }
  ul {
    list-style-type: disc;
    list-style: none;
    padding: 0px 0 3px 40px;
    font-size: 1rem;
    line-height: normal;
    max-width: 1032px;
    font-family: $ns-font-proxima;
    margin: rem(16) 0;
    li {
      color: color(ns-main-bg);
      line-height: normal;
      margin-bottom: 0px;

      strong {
        color: rgb(68, 68, 68);
      }

      &:first-child {
        margin-top: -1px;
      }
      &:nth-child(2) {
        margin-top: 1px;
      }
      &:nth-child(3) {
        margin-top: 2px;
      }
    }
  }

  a {
    color: rgb(0, 102, 153);
    text-decoration: none;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }

  hr {
    background-color: #666;
    height: 1px;
    border: 0;
    margin: 0;
    width: 1032px;
    h2 {
      &:nth-child(2) {
        & p:first-child {
          margin-bottom: 12px;
        }
      }
    }
  }

  em {
    font-style: italic;
    color: color(ns-main-bg);
    font-family: $ns-font-proxima;
    font-size: 1rem;
    line-height: 1.2;
  }

  @media screen and (min-width: 540px) {
    .grid-sm-23 {
      width: 92.8333%;
      margin-bottom: 28px;
    }

    hr {
      margin: 1.875rem 0;
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 1199px) {
  .#{$ns}Redmercado {
    padding-left: rem(27.2);
  }
}

@media screen and (max-width: 767px) {
  .#{$ns}Redmercado {
    padding-left: 0;
    margin-bottom: rem(42);

    &__title {
      margin-top: 0;
    }

    .grid-xs-24 {
      width: 100%;
    }
  }
}
