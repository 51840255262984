////
/// Select component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}esios-select
/// @since version 1.0.0
////

.#{$ns}select {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &__label {
    color: color(ns-darker);
    margin: rem(7) 0 rem(7) 0;
    font-family: $ns-font-default;
    font-size: 14px;
    line-height: 2.25rem;
    font-weight: 600;
  }

  &__selected {
    text-align: left;
    border: 1px solid color(ns-border-ligth);
    padding: rem(10) rem(20);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: $ns-font-default;
    color: color(ns-darker);
    position: relative;
    &--gd {
      cursor: pointer;
      color: color(ns-main-bg) !important;
      font-family: $ns-font-cousine !important;
      position: relative;
      padding: 0 rem(25);
      width: fit-content;
      height: 1.4375rem;
      line-height: 1.4375rem;
      border-radius: 0.625rem;
      font-size: rem(12);
      font-weight: 600;
      background-color: #dbdbdb;
      padding-right: rem(30);
    }
  }
  &__arrow {
    text-align: right;
    i {
      color: color(ns-darker);
    }
  }

  &__text {
    font-family: $ns-font-proxima;
    color: #444444;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin-right: 26px;
    text-transform: uppercase;
    cursor: pointer;
  }
  &__selectable {
    border: 1px solid color(ns-border-ligth);
    box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.05);
    display: inline-block;
    max-height: rem(262);
    padding: rem(8) 0;
    overflow-y: auto;
    position: absolute;
    z-index: 5;
    top: 98.5%;
    left: 0%;
    background-color: white;
    width: 100%;
    font-family: $ns-font-default;
    color: color(ns-darker);
  }
  &__selectableHidden {
    display: none;
  }
  &__value {
    font-family: $ns-font-proxima;
    color: color(ns-darker);
    font-weight: 400;
    padding: 8px 16px;
    &:hover {
      font-family: $ns-font-proxima;
      background: color(ns-lighter);
      //color: color(ns-darker);
    }
  }

  .esios-selector {
    position: relative;
    margin-bottom: 0.5rem;
    margin-top: 0.625rem;
    font-size: 0.875rem;
    border-radius: 0;

    &__selected {
      padding: 0.625rem 1.25rem;
      text-align: left;
      width: 100%;
      text-transform: uppercase;
      font-weight: 600;
      line-height: 1.125rem;
      border: 1px solid #dbdbdb;
      &--content {
        color: color(ns-main-bg) !important;
      }

      &:after {
        content: '';
        position: absolute;
        right: 15px;
        top: 12px;
        display: block;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid #717171;
        pointer-events: none;
        transform: rotate(90deg);
      }
    }

    &__options {
      cursor: pointer;
      position: absolute;
      overflow-y: auto;
      background-color: color(white);
      border: 1px solid #dbdbdb;
      width: 100%;
      border-radius: 0;
      box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
      border-top: none;
      border-bottom: none;
    }

    &__option {
      padding: 0.625rem 1.25rem;
      font-weight: 400;
      color: color(ns-main-bg);

      &:hover {
        cursor: pointer;
        background-color: color(ns-lighter);
      }

      &--selected {
        background-color: color(ns-lighter);
      }

      &:last-child {
        border-bottom: 1px solid #dbdbdb;
      }
    }
  }
}

.#{$ns}selector {
  &__btn {
    position: relative;
  }
  &__delete {
    position: absolute;
    top: 10px;
    right: 26px;
    padding: 9px;
    background-size: 18px;
    background-repeat: no-repeat;
    background-image: url('../assets/images/button-remove.png');
  }
}

.selected {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
