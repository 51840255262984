////
/// Supply Curves component
///
/// Custom project
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}supply-curves
/// @since version 1.0.0
////
.#{$ns}supply-curves {
  margin-top: rem(10);

  h1 {
    font-weight: 800;
    font-family: $ns-font-proxima;
    font-size: 23px;
    margin: 0.67em 0px;
  }

  text.label {
    font-size: 0.8125rem !important;
  }

  .supplyCurvesWidgetView {
    position: relative;
    width: 730px;
  }

  .spinner-container {
    position: absolute;
    z-index: 9999;
  }
  &__timeline-info {
    font-size: 16px;
    text-transform: uppercase;
    color: color(ns-main-bg);
    font-family: $ns-font-proxima;
    margin: rem(15) auto 0 auto;
    text-align: center;
    font-weight: 600;
    margin-left: 63px;
  }
  &__subtitle,
  .move-select label {
    font-size: rem(14);
    font-weight: 600;
    text-transform: uppercase;
    color: color(grey);
    font-family: $ns-font-proxima;
    margin: rem(15) 0 rem(16) 0;
  }

  .fixed-form {
    margin-bottom: rem(40);
    width: 90%;
    & .move-time-selector.one-column {
      .selected {
        font-family: $ns-font-proxima;
        font-size: 14px;
        font-weight: 600;
      }
    }

    .esios-toolbar-time .select-time-container {
      grid-template-columns: 1fr !important;
    }
    .esios-period-selector .date-hour-selector {
      grid-template-columns: 1fr 1fr !important;
      // margin-left: -8px; check in which case its correct
      margin-left: 0px;
      margin-bottom: 20px !important;
    }
    .esios-period-selector .esios-toolbar-time {
      width: 100%;
      left: 0px;

      .selected {
        font-family: $ns-font-proxima;
        font-weight: 600;
      }
      .select-time-options .option {
        font-family: $ns-font-proxima;
      }
    }
    .esios-period-selector .datepicker-btn.hasDatepicker {
      font-size: 14px !important;
      font-weight: 400;
      padding-right: 30px;
    }
    .esios-period-selector .esios-toolbar-time__mod-date {
      font-size: 14px !important;
      font-weight: 400;
      font-family: $ns-font-proxima !important;
    }

    .esios-period-selector .datepicker-mod {
      margin-left: 5px;
      height: 43px;
    }

    .hour-selector.p-hour2 {
      margin-top: -4px;
    }
    .esios-period-selector #supply-buttons {
      .esios-btn__container {
        margin-top: 16px;
      }
    }
    .mod-dashboard .analysis-help p {
      margin: 0;
      text-transform: uppercase;
      color: #717171;
      font-weight: 400;
      font-size: rem(15);
      line-height: 1rem;
      font-family: $ns-font-proxima;
      padding-right: 24px;
    }
    .mod-dashboard label {
      display: inline-block;
      color: #717171;
      text-transform: uppercase;
      font-size: 0.875rem;
      font-weight: 600;
      -webkit-flex-basis: 27%;
      -ms-flex-preferred-size: 27%;
      flex-basis: 27%;

      font-family: $ns-font-proxima;
    }
    .group-in {
      margin-top: 33px;
      font-family: $ns-font-proxima;
      font-size: 14px;
      font-weight: 600;
    }
    .group-in-dates {
      margin-top: 20px;
    }
    .move-select {
      margin-top: -4px !important;
    }

    .move-time-selector {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 120px 1fr;
      align-items: center;
      justify-content: space-between;
      label {
        margin-top: 2px;
      }
      .selector {
        display: grid;
        grid-template-columns: 30px 1fr 30px;
        margin-bottom: 14px;

        .selected {
          border: none !important;
          padding: 6px 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          display: inline-block;
          &[value='hour'] {
            font-size: 12px !important;
            font-weight: 600 !important;
            font-family: $ns-font-proxima;
          }
          &::before {
            display: none;
          }
        }
        .select-time-container {
          grid-template-columns: 1fr;
          position: relative;
        }

        .select-time-container.active .select-time-options {
          display: grid !important;
          max-height: rem(245);
          margin-top: rem(3);
          overflow: hidden;
          overflow-y: auto;
        }
        .select-time-container .select-time-options {
          width: 100%;
          top: 35px;
          position: absolute;
          z-index: 2;
          background: white;
          box-shadow: 5px 5px 0px color(ns-light);
          border: 1px solid color(ns-light);
          border-top: none;
          display: none;
        }

        .button {
          background: color(ns-lighter);
          position: relative;
          cursor: pointer;
          border: 1px solid color(ns-light);
        }
        .prev-button {
          border-right: none;
        }
        .prev-button::after {
          content: '';
          position: absolute;
          // left: 7px;
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-right: 5px solid #717171;
          pointer-events: none;
          top: 50%;
          left: 50%;
          @include transform(translate(-50%, -50%));
          content: '';
        }
        .next-button {
          border-left: none;
        }
        .next-button::after {
          content: '';
          // width: 10px;
          position: absolute;
          left: 7px;
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 5px solid #717171;
          pointer-events: none;
          top: 50%;
          left: 50%;
          @include transform(translate(-50%, -50%));
          content: '';
        }
      }
      &.one-column {
        grid-template-columns: 1fr !important;
        margin-top: 10px;

        .selected {
          text-align: left;
          justify-content: left;
          z-index: 1;
          background-color: color(white);
          padding: rem(8) rem(48) rem(8) rem(21);
          font-size: 14px !important;
          font-weight: 600;
        }

        .select-time-container:after {
          content: '';
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #717171;
          right: rem(10);
          top: 50%;
          @include transform(translateY(-50%));
          z-index: 1;
          pointer-events: none;
          position: absolute;
        }
        .select-time-options .option {
          text-align: left;
          font-family: $ns-font-proxima;

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
    }
    .move-select .select-time-options .option {
      text-transform: uppercase;
    }
    .esios-btn__container {
      position: relative;
      flex-direction: column;
      gap: 10px;
      margin-top: rem(30);

      a {
        width: 100%;
        max-width: rem(216);
        margin: 0 auto;
        justify-content: center;
      }

      .options_select {
        background: color(white);
        padding: rem(20) rem(25);
        position: absolute;
        top: rem(90);
        left: 50%;
        border: rem(1) solid color(ns-light);
        transform: translate(-50%, -50%);
        box-shadow: rem(5) rem(5) 0 rgba(0, 0, 0, 0.05);
        display: none;

        &.active {
          display: grid;
        }

        .opt-ancle {
          font-size: rem(14);
          font-family: $ns-font-proxima-regular;
          cursor: pointer;
          color: color(ns-main-bg);
          text-align: center;

          &:hover {
            text-decoration: underline;
          }
        }

        &::after {
          content: '';
          position: absolute;
          top: rem(-14);
          left: rem(36);
          border-bottom: rem(15) solid white;
          border-left: rem(15) solid transparent;
          border-right: rem(15) solid transparent;
        }
        &::before {
          content: '';
          position: absolute;
          top: rem(-16);
          left: rem(35);
          border-bottom: rem(16) solid color(ns-light);
          border-left: rem(16) solid transparent;
          border-right: rem(16) solid transparent;
        }
      }
    }

    @media print {
      display: none !important;
    }
  }
  .is-analysis-page h3,
  .is-analysis-page h4 {
    color: #006699;
    margin-bottom: rem(10);
    margin-top: rem(16);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1rem;
  }
  .esios-listEnergyItems__list .esios-listEnergyItems__label .unit {
    margin-left: 3px !important;
    font-weight: 600;
  }
  .esios-listEnergyItems__list {
    border: rem(1) solid color(ns-light);
    padding: 1.4375rem 0 1.625rem;
    position: relative;
    right: -43px;
    margin-top: 27px;
    padding-top: 37px;
    padding-bottom: 0;

    .esios-listEnergyItems__list.row {
      padding-bottom: 0px;
    }

    .esios-listEnergyItems__item-with-value {
      margin-bottom: 2.375rem;
      padding-left: 9px;
      width: 43.5%;
      right: 0rem;
      &:nth-last-child(1),
      &:nth-last-child(2) {
        margin-bottom: rem(0);
        margin-left: rem(0);
      }
      &:nth-child(even) {
        margin-left: rem(14);
        .value.is-number.main-value {
          margin-left: 3px;
        }
        .value.is-number.secondary-value {
          margin-left: 4px;
        }
      }
    }
    .esios-listEnergyItems__decorator {
      margin-bottom: rem(21);
      margin-left: rem(2);
    }
    .esios-listEnergyItems__item-with-value:first-child {
      .main-value,
      .secondary-value {
        .caption {
          color: color(ns-main-bg) !important;
          font-size: 28px;
          font-family: $ns-font-cousine !important;
          font-weight: 800;
          letter-spacing: -1px;
        }
      }
    }

    .esios-listEnergyItems__label {
      display: grid;
      grid-template-columns: 20px 1fr;
      margin-bottom: 37px;
      .label {
        color: color(grey) !important;
        text-transform: uppercase !important;
        font-family: $ns-font-proxima !important;
        font-size: 14px;
        line-height: rem(19);
        font-weight: 600;
        margin-bottom: rem(-1);
      }
      .caption {
        color: color(ns-main-bg) !important;
        font-size: rem(25);
        font-family: $ns-font-cousine !important;
        font-weight: 500;
        line-height: rem(21);
        letter-spacing: -3px;
        margin-left: rem(11);
      }
      // .main-value:first-child {
      //   margin-top: -3px;
      //   margin-bottom: 3px;
      // }
      .main-value,
      .secondary-value {
        grid-column: 2/3;
        color: color(ns-main-bg) !important;
        font-size: rem(18);
        font-family: $ns-font-proxima !important;
      }
    }
  }
  .supplyCurvesWidgetView {
    right: rem(44);
    margin-bottom: rem(15);
  }

  @media screen and (max-width: 768px) {
    .esios-listEnergyItems__decorator {
      margin-bottom: 2px !important;
    }
  }

  @include media-query(xs, sm) {
    & > .row {
      .col-sm-4 {
        flex: 0 0 auto;
        width: 33.33333%;
      }

      .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66667%;
      }
    }
  }

  @media screen and (max-width: 540px) {
    .fixed-form {
      width: 100%;
      margin-bottom: 0;
      .esios-period-selector .datepicker-mod {
        width: 96%;
      }
      .esios-period-selector .esios-toolbar-time {
        left: -2px;
      }
      & .esios-period-selector .date-hour-selector {
        column-gap: 13px;
      }
      & .move-time-selector.one-column {
        grid-template-columns: 1fr !important;
        margin-top: 10px;

        .selected {
          padding: rem(9) rem(25);
        }
      }
      .move-time-selector {
        grid-template-columns: 159px 1fr;
        .selector {
          margin-right: 2px;
          .next-button {
            margin-left: -2px;
          }
        }
      }
      .esios-period-selector #supply-buttons .esios-btn__container {
        margin-top: 68px;
        width: 100%;
        .options_select.active {
          z-index: 1;
        }
        a {
          padding: 5px 123px;
          //margin-left: 54px;
          margin-top: 1px;
        }
      }
    }
    &__timeline-info {
      margin-left: 34px;
      margin-top: 17px;
    }
    .esios-listEnergyItems__list {
      top: -17px;
      margin-bottom: 11px;
      padding-bottom: 0px;
      width: 100%;
      right: 0;
      margin: 0px 0 11px;

      .esios-listEnergyItems__label {
        margin-left: 3px;
      }

      .esios-listEnergyItems__label .label {
        margin-bottom: 0 !important;
      }
      .esios-listEnergyItems__item-with-value:nth-child(even) {
        margin-left: rem(-1);
      }
      .esios-listEnergyItems__item-with-value:nth-child(2) {
        margin-bottom: rem(0) !important;
      }
      .esios-listEnergyItems__item-with-value:nth-child(3) {
        margin-bottom: -8px !important;
        margin-top: -6px;
      }
      .esios-listEnergyItems__item-with-value {
        width: 100%;
        margin-bottom: rem(1) !important;
      }
      .esios-listEnergyItems__decorator {
        margin-bottom: 21px !important;
      }
    }
    .supplyCurvesWidgetView {
      right: 0;
      margin-top: rem(3);
      margin-bottom: rem(0);
      width: 100%;

      .chari-chart svg {
        margin-left: 1px;
        margin-top: 1px;
        position: absolute;
        min-height: 700px;
        min-width: 700px;

        .g-main {
          width: 100%;
          // min-height: 600px;
          // transform: scale(1.775) translate(102px, 32px);
        }
      }

      & > .row {
        width: 100%;
        margin: 0;
      }
    }
  }
  .chari-chart.aspect {
    min-height: 400px !important;
    &.supply {
      aspect-ratio: 16/8;
      min-height: initial !important;
      min-width: 778px;
      position: relative;
      top: 1px;
      z-index: 0;
      left: 31px;
      @media screen and (max-width: 540px) {
        aspect-ratio: 16/20;
        min-width: auto;
        width: 100%;
        left: 0;
      }
    }
  }

  p {
    color: rgb(68, 68, 68);
    font-family: proxima-nova, Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.2;
  }
}

@media print {
  .#{$ns}supply-curves {
    h1 {
      margin-top: -15px;
    }
    .col-sm-8 {
      width: 100%;
    }
    .row {
      margin: 0px;
    }
    .fixed-form .esios-period-selector .esios-toolbar-time {
      left: -4px !important;
    }
    .esios-listEnergyItems__list {
      right: rem(-50);
    }
    .esios-listEnergyItems__list
      .esios-listEnergyItems__item-with-value
      .esios-listEnergyItems__label
      .esios-listEnergyItems__decorator {
      display: inline !important;
    }
  }
  .chari-chart.aspect {
    right: rem(-50);
  }
  .esios-layout__footer-image--curves {
    display: none;
  }
}

@media screen and (max-width: 1199px) {
  .#{$ns}supply-curves {
    padding-left: rem(27.2);
  }
}

@media screen and (max-width: 767px) {
  .#{$ns}supply-curves {
    padding-left: 0;
    margin-top: -32px;
    &__subtitle {
      margin-top: 12px;
    }
  }
}

@media screen and (max-width: 490px) {
  .#{$ns}supply-curves {
    .#{$ns}toolbar-time {
      left: 0;
    }
  }
}

.no-data-graphic {
  background: #f7f7f7;
  border-style: none none solid none;
  border-width: 1px;
  border-color: black;
  background-image: url('../../src/assets/images/background-graphic.png');
  background-repeat: repeat-x;
  width: 98%;
}

.rect-no-data {
  width: 288px;
  height: 60px;
  line-height: 60px;
  border-style: solid;
  border-color: #cf4634;
  border-width: 1px;
  position: absolute;
  margin: 160px calc(50% - 80px);
  background-color: #f7f7f7;
  color: #cf4634;
  text-align: center;
  font-size: 13px;
}

.hidden {
  visibility: hidden;
}

#rect19 {
  margin: 165px 270px;
}
