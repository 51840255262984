////
/// Layout Buttons component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}layoutbuttons
/// @since version 1.0.0
////

.#{$ns}layoutbuttons {
  width: 100%;
  &__content {
    margin: 1.25rem 0;
    text-align: center;

    @media screen and (min-width: 1024px) {
      margin-bottom: 2.5rem;
    }
    a {
      @media screen and (min-width: 1024px) {
        margin:  2.5rem;
      }
      @media screen and (min-width: 992px) {
        margin: 0.25rem 0;
      }
      @media screen and (min-width: 768px) {
        margin: 0.25rem 0;
      }
      @media screen and (min-width: 540px) {
        margin: 0.25rem 0;
      }
    }

    .btn-download,
    .btn-print {
      display: inline-block;
      padding: 0.4375rem 1.25rem;
      border-radius: 1.25rem;
      background-color: color(ns-primary);
      background-clip: padding-box;
      color: white !important;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      -webkit-font-smoothing: antialiased;
      font-size: 0.875rem;
    }
    .icon-download {
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgMjcgMjkiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgdmVyc2lvbj0iMS4xIgoJeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIKCXg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjdweCIgaGVpZ2h0PSIyOXB4Igo+Cgk8ZyBpZD0iTGF5ZXIlMjAxIj4KCQk8cGF0aCBkPSJNIDE3Ljc1IDEyIEwgMTMuNzg1IDE5IEwgMTcgMTkgTCAxNyAyMCBMIDEwIDIwIEwgMTAgMTkgTCAxMy4yMTUgMTkgTCA5LjI1IDEyIEwgMTIgMTIgTCAxMiAxMCBMIDE1IDEwIEwgMTUgMTIgTCAxNy43NSAxMiBaTSAxIDE0LjUgQyAxIDIxLjQwMzggNi41OTYzIDI3IDEzLjUgMjcgQyAyMC40MDM3IDI3IDI2IDIxLjQwMzggMjYgMTQuNSBDIDI2IDcuNTk2MiAyMC40MDM3IDIgMTMuNSAyIEMgNi41OTYzIDIgMSA3LjU5NjIgMSAxNC41IFoiIGZpbGw9IiNmZmZmZmYiLz4KCTwvZz4KPC9zdmc+);
      background-repeat: no-repeat;
      background-size: 1.5625rem;
    }
    .icon-print {
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJVbnRpdGxlZC1QX3gyNV9FMWdpbmFfeDI1XzIwMSIKCSB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDI1IDI1IgoJIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI1IDI1IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGcgaWQ9IkNhcGFfeDI1XzIwMSI+Cgk8cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNMTcsOUg4VjdoOVY5eiBNMTcsMThIOHYtM0g2di01aDFoMWg5aDFoMXY1aC0yVjE4eiBNMCwxMi41QzAsMTkuNCw1LjYsMjUsMTIuNSwyNVMyNSwxOS40LDI1LDEyLjUKCQlTMTkuNCwwLDEyLjUsMFMwLDUuNiwwLDEyLjV6IE0xNiwxN3YtNEg5djRIMTZ6IE0xMCwxNWg1djFoLTVWMTV6Ii8+CjwvZz4KPC9zdmc+);
      background-repeat: no-repeat;
      background-size: 1.5625rem;
    }

    .btn-download.icon-download {
      padding-right: 3.125rem;
      background-position: right 3px center;
    }

    .btn-print.icon-print {
      padding-right: 3.125rem;
      background-position: right 3px center;
    }
  }
}
