//  jQuery calendar

.ui-datepicker {
  display: block;
  position: relative;
  width: auto;
  height: auto;
  margin: 5px auto 0;
  font: 9pt Arial, sans-serif;
  -webkit-box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.05);

  //TRIÁNGULOS USANDO SPAN EN JAVASCRIPT
  &__triangle::before {
    display: inline-block;
    content: '';
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: rem(16) solid #c5c5c5;
    border-left: rem(16) solid transparent;
    border-right: rem(16) solid transparent;
  }

  &__triangle::after {
    display: inline-block;
    content: '';
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: rem(15) solid color(white);
    border-left: rem(15) solid transparent;
    border-right: rem(15) solid transparent;
  }

  &.ui-widget.ui-widget-content {
    border: 1px solid #c5c5c5;
    width: fit-content;
    padding: 15px;
    border-radius: 0;

    .ui-datepicker-unselectable.ui-state-disabled {
      .ui-state-default {
        border: 0;
        color: color(ns-grey-datepicker);
        padding: 0.2em;
        text-align: center;
        background: transparent;
      }

      &.ui-datepicker-week-end {
        .ui-state-default {
          opacity: 0.35;
        }
      }
    }
  }

  a {
    text-decoration: none;
    border: 1px solid color(ns-light);

    &:hover {
      background: none;
    }
  }

  .ui-datepicker-header {
    width: 296.625px;
    background: none;
    color: #e0e0e0;
    font-weight: bold;
    text-shadow: 1px -1px 0px color(black);
    line-height: 30px;
    border: none;
    align-items: center;

    select,
    a {
      border: 1px solid color(ns-light);
    }

    a {
      &.ui-datepicker-prev,
      &.ui-datepicker-next {
        span {
          pointer-events: none;
        }
      }
      &:first-child {
        border: 1px solid color(ns-light);
        width: rem(40);
        font-size: 0;
        height: rem(40);
        border-radius: 0;
        top: 3px;

        span {
          background: none;
          display: block;
          width: 0;
          height: 0;
          margin: 0;
          padding: 0;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-right: 5px solid color(ns-darker);
        }
      }

      // .ui-datepicker-next
      &:nth-child(2) {
        border: 1px solid color(ns-light);
        width: 2.5rem;
        font-size: 0;
        height: 2.5rem;
        border-radius: 0;
        top: 3px;

        span {
          background: none;
          display: block;
          width: 0;
          height: 0;
          margin: 0;
          padding: 0;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 5px solid color(ns-darker);
        }
      }
    }

    // .ui-datepicker-title
    div.ui-datepicker-title {
      // margin: 0 2.3em;
      margin-bottom: rem(8);
      line-height: 1.8em;
      text-align: center;
      height: rem(40);
      display: flex;
      justify-content: space-around;
      position: relative;

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-family: $ns-font-proxima-regular;
        font-size: rem(14);
        font-weight: 600;
        text-align: center;
        margin: 0;
        height: 100%;
        padding: 10px 0px;
        text-transform: uppercase;
        width: 45%;
        outline: none;
        box-shadow: none;

        option {
          font-family: $ns-font-proxima-regular;
          text-transform: capitalize;
          padding: rem(8) rem(16);
          line-height: rem(15);
          font-size: rem(14);
          font-weight: 400;
          outline: none;
          box-shadow: none;

          &:focus {
            box-shadow: none;
            outline: none;
          }
        }

        &:focus {
          box-shadow: none;
          outline: none;
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: 45%;
        left: 38%;
        border-top: 5px solid color(ns-darker);
        border-bottom: 5px solid transparent;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        pointer-events: none;
      }

      &::after {
        content: '';
        position: absolute;
        top: 45%;
        right: 7%;
        border-top: 5px solid color(ns-darker);
        border-bottom: 5px solid transparent;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        pointer-events: none;
      }

      //DIV de ul
      .chzn-container.chzn-container-single {
        position: absolute;
        top: 40px;
        width: 44%;
        height: rem(254);
        background-color: white;
        border: 1px solid color(ns-light);
        z-index: 5;

        &:first-of-type {
          left: 3%;
        }

        &:last-of-type {
          left: 53%;
        }

        //Uls
        .chzn-results {
          height: 100%;
          width: 100%;
          background-color: white;
          // border: 1px solid color(ns-primary);
          -webkit-box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.05);
          overflow-y: scroll;

          .active-result {
            text-shadow: none;
            color: color(ns-main-bg);
            font-family: $ns-font-proxima-regular;
            font-size: rem(14);
            font-weight: 400;
            list-style: none;
            cursor: pointer;
            display: list-item;
            padding: rem(8) rem(16);
            font-weight: 400;
            line-height: 15px;

            &:hover {
              background-color: color(ns-lighter);
            }
          }
        }
      }
    }
  }

  table.ui-datepicker-calendar {
    width: 100%;

    thead {
      tr {
        border-bottom: 1px solid color(ns-light);
        th {
          padding: rem(2) rem(3);
          span {
            font-family: $ns-font-proxima-regular;
            font-size: rem(14);
            font-weight: 400;
            color: color(ns-main-bg);
          }
        }
      }
    }

    tbody {
      tr {
        td {
          height: rem(20);
          line-height: 20px;

          a {
            background: none;
            border: none;
            font-family: $ns-font-cousine;
            font-size: rem(16);
            font-weight: 400;
            text-align: center;
            padding: 0.2em;
          }

          &.ui-datepicker-week-end {
            background-color: color(ns-lighter);
            opacity: 1;

            a {
              color: color(ns-primary);
            }
          }

          &.ui-datepicker-current-day {
            a {
              background-color: color(ns-primary);
              border-radius: 20px;
              color: color(white);
            }
          }

          &.ui-datepicker-today:not(.ui-datepicker-current-day) {
            a {
              border-bottom: 3px solid color(ns-primary);
              font-weight: 700;
              margin: 0 auto;
            }
          }

          .ui-state-default {
            font-family: $ns-font-cousine;
          }
        }
      }
    }
  }

  .ui-datepicker-buttonpane.ui-widget-content {
    margin: 0.7em 0 0 0;
    padding: 0 0.2em;
    border: none;

    button {
      background: none;
      text-transform: uppercase;
      box-sizing: border-box;
      border-radius: 0;
      width: 100%;
      padding: 10px;
      border: 1px solid color(ns-light);
      text-align: center;

      &:last-child {
        display: none;
      }
    }
  }
  &--disabled {
    color: color(black) !important;
  }
}

@include media-query(xs) {
  .ui-datepicker-header {
    width: 100%;
  }

  .ui-datepicker.ui-widget.ui-widget-content {
    left: 4px !important;
    width: calc(100% - 8px);
  }
}
