////
/// Tabla component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}tabla
/// @since version 1.0.0
////

.#{$ns}tabla {
  hr {
    background-color: #dbdbdb;
    opacity: 100%;
    margin-bottom: 15px;
    @media screen and (max-width: 540px) {
      position: relative;
      top: -20px;
      margin-bottom: 0px;
    }
  }

  .not-found {
    text-align: center;
    background-color: color(ns-lighter);
  }

  &__datepicker {
    border: 1px solid #dddddd;
    font-size: 14px;
    font-family: 'Proxima-Nova', Arial, sans-serif;
    padding-left: 20px;
    height: 38px;
    width: 100%;
  }

  &__boton-remove {
    position: absolute;
    right: 8px;
    top: 38%;
    background-image: url('../../src/assets/images/button-remove.svg');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    padding-right: 13px;
    opacity: 0.4;
    &.hidden {
      visibility: hidden;
    }
  }

  &__container-select {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    height: 5rem;
  }

  button#tableDownloadJSONBtn {
    margin-left: 19px;
    margin-bottom: 4px;
    font-size: 14px;
    font-family: $ns-font-proxima;
    font-weight: 800;
    width: 208px;
    height: 34px;

    &:focus {
      background-color: color(ns-primary);
      border-color: color(ns-primary);
      box-shadow: none;
    }
  }

  &__filters {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: rem(20);
    padding: 0rem 1rem 0rem 0rem;
    @media screen and (max-width: 540px) {
      flex-wrap: wrap;
      padding: 0px;
    }
  }
  &__title {
    color: color(ns-primary);
    font-size: 1.4375rem;
    font-weight: 800;
    font-family: $ns-font-proxima;
    margin: 0.87em 0;
    text-transform: uppercase;
    margin-top: 18px;
  }
  &__border {
    border: 1px solid #808080;
    padding: 25px 20px 25px 20px;
  }
  &__filtro {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    color: #444444;
    font-family: $ns-font-proxima;
    font-size: 1rem;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    background-color: #f7f7f7;
    margin: 0 rem(-16);
    margin-bottom: 1rem;
  }
  &__opinion {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    font-family: 'proxima-nova', Arial, sans-serif;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-collapse: collapse;
    margin-bottom: 0.1px;
    border-spacing: 0;
    color: #444444;
    font-size: 16px;
    line-height: 1.2;
    hyphens: auto;
    word-wrap: break-word;
    padding: 3px 10px;
    width: 100%;
    margin-top: 0.2px;
    margin-left: 7px;
  }
  &__divFecha {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    color: #444444;
    font-family: $ns-font-proxima;
    font-size: 1rem;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    position: relative;
    margin-bottom: 0;

    .esios-tabla__datepicker {
      width: 100%;
    }
  }
  &__fecha {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    -webkit-font-smoothing: antialiased;
    color: inherit;
    font: inherit;
    margin: 0;
    line-height: normal;
    appearance: none;
    box-shadow: none;
    background: none;
    border-radius: 0;
    box-sizing: border-box !important;
    padding: 0.625rem 1.25rem;
    border: 1px solid #dbdbdb;
    font-size: 0.875rem;
    background-color: white;
    width: 100% !important;
  }
  &__Subtitle {
    color: color(ns-primary);
    font-size: 1rem;
    font-weight: 800;
    font-family: 'proxima-nova', Arial, sans-serif;
    margin: 0.87em 0;
    margin-left: 20px;
    margin-top: 40px;
    margin-bottom: 50px;
    text-transform: uppercase;
  }
  &__DownloadDiv {
    margin-top: 50px;
    margin-left: 100px;
  }
  &__Download {
    margin-left: 3%;
    appearance: none;
    box-shadow: none;
    background: none;
    border: 0;
    display: inline-block;
    padding: 0.25rem 1.25rem;
    border-radius: 1.25rem;
    background-color: #006699;
    background-clip: padding-box;
    color: white !important;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    -webkit-font-smoothing: antialiased;
  }
  &__buscarFecha {
    margin-top: -0.5%;
    margin-left: 1%;
    appearance: none;
    box-shadow: none;
    background: none;
    border: 0;
    display: inline-block;
    font-family: $ns-font-proxima-bold;
    padding: 0.25rem 1.25rem;
    border-radius: 1.25rem;
    background-color: #006699;
    background-clip: padding-box;
    color: white !important;
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
    padding: 5px 2px 7px 15px;
    text-align: center;
    -webkit-font-smoothing: antialiased;
  }
  &__buttons {
    margin-top: -33px;
    margin-bottom: 33px;
    &--descargas {
      margin-top: 37px !important;
      margin-bottom: 40px !important;
    }
  }
  &__td {
    color: color(black);
    font-size: 0.8rem;
    font-family: $ns-font-proxima;
    margin: 0.87em 0;
    text-transform: uppercase;
    width: 500px;
    height: 300px !important;
  }
  &__tdContent {
    color: color(ns-main-bg);
    font-size: 0.8rem;
    font-weight: 700;
    font-family: $ns-font-montserrat-bold;
    height: 45px;
    width: 400px;
  }
  &__dropdown1 {
    margin: 0 1.5%;
    .ui-selectmenu-button.ui-button {
      width: 100%;
      background: #ffffff;
      padding: 9px 20px;
      position: relative;
      margin-bottom: 0;
      border: 1px solid #dbdbdb;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
      border-radius: 0;
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #717171;
        right: rem(13);
        z-index: 1;
        pointer-events: none;
        position: absolute;
        top: 50%;
        margin-top: -3px;
      }
      .ui-selectmenu-icon {
        display: none;
      }
      @media screen and (max-width: 540px) {
        width: 100%;
        height: 35px;
        display: flex;
        align-items: center;
      }
    }
    .ui-selectmenu-menu {
      width: 250px;
      .ui-menu {
        border-radius: 0;
        border: 1px solid #dbdbdb;
        box-sizing: border-box;
        width: 100%;
      }
      .ui-menu-item-wrapper {
        text-transform: capitalize;
        padding: 8px 16px;
        font-size: 14px;
        background: none;
        border: none;
        color: #444444;
        font-family: $ns-font-proxima;
        font-weight: 400;
        &.ui-state-active {
          background-color: #f7f7f7;
        }
      }
      .ui-menu-item {
        color: #444444;
      }
    }
    .ui-selectmenu-text {
      color: #444444;
      font-family: $ns-font-proxima;
      font-size: 14px;
      margin-top: 1px;
      margin-left: 2px;
      font-weight: 600;
    }
  }
  &__dropdown {
    width: 250px;
    text-transform: uppercase;
    height: 37px;
    font-family: $ns-font-proxima;
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    color: #000000;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    vertical-align: middle;
    padding-left: 20px;
    padding-right: 20px;
    zoom: 1;
    position: relative;
    border: 1px solid #dbdbdb;
    font-size: 0.875rem;
    font-weight: 500;
    .ui-selectmenu-button.ui-button {
      width: 248px;
      background: #ffffff;
      padding: 9px 20px;
      position: relative;
      margin-bottom: 0;
      border: 1px solid #dbdbdb;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
      border-radius: 0;
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #717171;
        right: rem(11);
        z-index: 1;
        pointer-events: none;
        position: absolute;
        top: 50%;
        margin-top: -3px;
      }
      .ui-selectmenu-icon {
        display: none;
      }
    }
    .ui-selectmenu-menu {
      width: 250px;
      .ui-menu {
        border-radius: 0;
        border: 1px solid #dbdbdb;
        box-sizing: border-box;
        width: 100%;
      }
      .ui-menu-item-wrapper {
        text-transform: capitalize;
        padding: 8px 16px;
        font-size: 14px;
        background: none;
        border: none;
        color: #444444;
        &.ui-state-active {
          background-color: #f7f7f7;
        }
      }
      .ui-menu-item {
        color: #444444;
      }
    }
    .ui-selectmenu-text {
      color: #000000;
      font-family: $ns-font-proxima;
    }
  }
  .option {
    height: 30px;
    margin-top: 20px;
  }

  &__dropdown2 {
    width: 350px;
    text-transform: uppercase;
    height: 55px;
    font-family: $ns-font-proxima;
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    color: #444444;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    vertical-align: middle;
    padding-left: 20px;
    padding-right: 20px;
    zoom: 1;
    position: relative;
    border: 1px solid #dbdbdb;
    font-size: 0.875rem;
    font-weight: 500;
  }
  &__dropdown3 {
    margin-top: 11%;
    width: 350px;
    text-transform: uppercase;
    height: 55px;
    font-family: $ns-font-proxima;
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    color: #444444;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    vertical-align: middle;
    padding-left: 20px;
    margin-left: 30%;
    padding-right: 20px;
    zoom: 1;
    position: relative;
    border: 1px solid #dbdbdb;
    font-size: 0.875rem;
    font-weight: 500;
  }
  &__tdContentDate {
    color: color(black);
    font-size: 0.8rem;
    font-family: 'proxima-nova', Arial, sans-serif;
    height: 25px;
  }
  &__td2 {
    color: color(black);
    font-size: 0.8rem;
    font-family: 'proxima-nova', Arial, sans-serif;
    margin: 0.87em 0;
    text-transform: uppercase;
    height: 40px;
    width: 100%;
  }
  &__input {
    padding-left: 20px;
    border: 1px solid color(ns-border-ligth);
    display: inline-block;
    height: 55px;
    width: 33%;
    margin-top: 29px;
    margin-left: 1%;
    margin-right: 0.4%;
    max-height: 15.625rem;
    overflow-y: scroll;
    &--search {
      font-size: 12px;
      margin-top: 0rem;
      width: 101.3%;
      margin-left: 1px;
    }
    span#ui-id-1-button {
      margin-left: 7px;
      width: 99.5%;
    }
  }
  &__calendarIcon {
    margin-bottom: -3%;
    margin-left: 5px;
  }
  &__inputCalendar {
    border: 1px solid color(ns-border-ligth);
    display: inline-block;
    height: 38px;
    width: 100%;
    padding-left: 20px;
    margin-right: 1.5%;
    max-height: rem(250);
    font-size: 14px;
    font-family: $ns-font-proxima;
    &--datepicker {
      border: none !important;
      position: relative;
      &::after {
        content: '';
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #717171;
        right: 0.625rem;
        z-index: 1;
        pointer-events: none;
        position: absolute;
        top: 50%;
        margin-top: -3px;
      }
    }
  }
  .thead {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    color: #444444;
    font-family: 'proxima-nova', Arial, sans-serif;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 0.875rem;
    hyphens: auto;
    word-wrap: break-word;
  }
  .content-paragraph__table {
    margin-left: 20px;
    &__text {
      color: color(black);
      &--bold {
        color: color(black);
      }
      &--link {
        color: color(black);
      }
    }
  }
  tr:nth-child(even) {
    background-color: #f7f7f7;
  }

  &.simple {
    padding-top: rem(20);
    padding-bottom: rem(10);
    .#{$ns}table > * {
      font-size: rem(14);
      font-family: $ns-font-proxima;
    }
    .#{$ns}table {
      margin-bottom: 0;
      text-transform: capitalize;
    }

    tr:nth-child(odd) {
      background-color: #f7f7f7;
      color: #006699;
      height: 32px;
    }
    tr:nth-child(even),
    .#{$ns}table__head .#{$ns}table__row {
      background-color: #ffffff !important;
      color: #006699;
      height: 30px;
    }
    .#{$ns}table__head .#{$ns}table__row {
      height: 35px !important;
    }
    tbody .#{$ns}table__row td {
      font-size: rem(25);
      color: color(ns-main-bg) !important;
    }
    .#{$ns}table__cell {
      font-family: $ns-font-proxima;
      color: color(ns-main-bg) !important;
      p {
        color: color(ns-main-bg);
      }
    }
  }
  .mypSubastasWidgetView &.simple {
    tbody .#{$ns}table__row td {
      font-size: rem(13);
      color: color(ns-main-bg) !important;
    }

    tbody .esios-table__row td {
      font-family: $ns-font-cousine !important;
    }
    tbody .esios-table__row td:first-child {
      font-size: rem(13) !important;
      font-family: $ns-font-proxima, Arial, sans-serif !important;
    }
  }

  .content-paragraph {
    &__text {
      color: color(black);
      &--bold {
        color: color(black);
      }
      &--link {
        color: color(black);
      }
    }
  }
  .intro-text {
    padding: rem(12) 0 0;
  }
}
.tabla--activation {
  .#{$ns}table {
    th {
      padding: rem(12) rem(10) rem(12) rem(12);
    }

    &__head-txt {
      font-size: rem(12);
    }
  }
}
.table-description {
  margin-top: 10px;
  color: color(grey);
  font-size: rem(14);
  font-style: italic;
}
.u-bold {
  font-weight: 500;
}

@media print {
  .mod-documents-download,
  .#{$ns}footer,
  .#{$ns}layout__banner-image,
  .#{$ns}layout__subcontent__wrapper,
  .#{$ns}layout__breadcrumbs,
  .#{$ns}header,
  .#{$ns}smercado__search,
  .#{$ns}tabla__buttons,
  .#{$ns}tabla__dropdown2,
  .#{$ns}tabla__dropdown3,
  .#{$ns}smercado__buttons,
  .#{$ns}unidadesprogramacion__tabla__input,
  .#{$ns}unidades__content,
  .habilitado-page,
  .habilitado-buscar,
  .#{$ns}glosario__letterselection,
  .glosario-buscar,
  .#{$ns}table--sujetomercado,
  .#{$ns}tablaUnidadesProgramacion,
  .#{$ns}tablaUnidades,
  .#{$ns}tablaHabilitado,
  .#{$ns}documentacion__form,
  .#{$ns}smercado__div-header,
  .#{$ns}unidadesprogramacion__wrap,
  #button-glosario,
  #pagination {
    display: none !important;
  }
  .#{$ns}layout__main {
    margin-top: 0 !important;
  }
  .#{$ns}layout__print-logo {
    display: block !important;
  }

  .#{$ns}tabla {
    &--descargas {
      margin-top: -2px;
      margin-left: 1px;

      .title {
        margin-bottom: 19px;
      }

      .intro-text {
        padding: 0.75rem 0 0;
        margin-left: 2.1px;
        line-height: normal;

        p {
          margin-bottom: 4px;
          font-size: 16px;
          margin-top: -1px;
          margin-left: 6px;
        }
      }
    }

    .content-paragraph {
      margin-bottom: 19px;
    }

    .#{$ns}tabla {
      &__title {
        margin-top: 18px;
      }
      &__container-select {
        margin-top: -4px;

        button#tableDownloadJSONBtn {
          font-family: $ns-font-proxima;
          font-weight: 700;
          font-size: 14px;
          margin-left: 19px;
          margin-top: -8px;
          .#{$ns}tabla__calendarIcon {
            margin-bottom: -3%;
            margin-left: 20px;
            background-repeat: no-repeat;
            background-position: right 3px center;
            padding-right: 0.25rem;
          }
        }
      }
      &__filters {
        margin-top: 41px;
        margin-bottom: 19px;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .#{$ns}tabla {
    &--descargas {
      // padding-left: rem(27.2);
    }
  }
}

@media screen and (max-width: 767px) {
  .#{$ns}tabla {
    &--descargas {
      padding-left: 0;
    }
  }
}

@media screen and (max-width: 540px) {
  .#{$ns}tabla {
    .intro-text {
      margin-bottom: rem(12);
    }
    &__container-select {
      flex-wrap: wrap;
      padding-bottom: 0.7rem;
    }

    &__filtro {
      margin: 0 0 rem(20);
      .#{$ns}tabla__wrap {
        width: calc(100% - 28px);
        margin: 0 rem(14) rem(12);
      }
    }
    &__dropdown1,
    &__divFecha {
      display: block;
      width: 100%;
      margin-top: 0;
      margin-bottom: rem(12);
    }
    &__inputCalendar {
      margin: 0;
      width: 100%;
    }
    &__wrap {
      display: block;
      width: 100%;
      margin-bottom: rem(12);
    }
    &__input,
    &__dropdown2,
    &__dropdown3 {
      display: block;
      width: 100%;
      margin: 0;
    }
    &__buscarFecha {
      margin: 0 auto;
      display: block;
    }

    &--descargas {
      .intro-text {
        margin-bottom: rem(19);
      }
      .#{$ns}tabla {
        &__dropdown1 {
          .ui-selectmenu-button.ui-button {
            height: rem(38);
          }
        }
        &__filtro {
          .#{$ns}tabla {
            &__container-select {
              margin-left: 0;

              button#tableDownloadJSONBtn {
                margin: rem(4) auto;
              }
            }
            &__wrap {
              margin: 0 rem(9) rem(9);
              width: calc(100% - 18px);
              top: auto;
            }
          }
        }
      }
      .#{$ns}documentacion {
        &__content {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .tabla-descargas tbody {
    display: block;
    margin-bottom: 0px;
  }
  html:lang(es) .tabla-descargas tbody td:first-child {
    &:before {
      content: 'Documento';
    }

    &.not-found {
      text-align: center;
      &:before {
        content: none;
      }
    }
  }
  html:lang(en) .tabla-descargas tbody td:first-child {
    &:before {
      content: 'Document';
    }

    &.not-found {
      text-align: center;
      &:before {
        content: none;
      }
    }
  }
  .blue-buttons {
    display: flex;
    justify-content: center;
  }
  .blue-buttons .#{$ns}btn__container {
    flex-wrap: wrap;
    width: 94%;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 10px;
    a {
      margin-bottom: 0;
    }
  }
  .blue-buttons .#{$ns}btn__container .#{$ns}btn__icon--print {
    display: none;
  }
  .paginador-descargas {
    margin-top: 0px !important;
  }
  .#{$ns}documentacion__content--border-bottom {
    margin-bottom: 0px !important;
  }
}

.paginador-descargas .#{$ns}pagination__results {
  margin-bottom: 1.7rem;
  margin-top: 10px;
  margin-left: 1px;
}
.#{$ns}pagination__results {
  &--descargas {
    @media screen and (max-width: 540px) {
      margin-left: 0 !important;
      text-align: left !important;
    }
    margin-bottom: 0rem !important;
  }
}

.#{$ns}tabla__select {
  margin-top: 26px;
  padding-left: 0;
  padding-right: 0;
  width: 33%;
  height: 53px;
  border: none;
  margin-right: 18px;
  position: relative;
  #ui-id-2-button {
    margin-left: -2px;
  }
  &--search {
    @media screen and (max-width: 540px) {
      width: 100%;
      order: 3;
      margin-top: 1rem;
    }
  }
  &--filters {
    @media screen and (max-width: 540px) {
      width: 100%;
      margin: 0px;
    }
  }
  .ui-selectmenu-text {
    color: #444444 !important;
    font-family: $ns-font-proxima;
    font-size: 14px !important;
    letter-spacing: 0px;
    font-weight: 600;
  }
  .ui-selectmenu-button.ui-button {
    width: 99.5%;
    background: #ffffff;
    position: relative;
    border: 1px solid #dbdbdb;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 0;
    padding: 19px 22px;
    margin-bottom: 0;
    letter-spacing: 0.7px;
    margin-left: 4px;
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #717171;
      right: 0.625rem;
      z-index: 1;
      pointer-events: none;
      position: absolute;
      top: 50%;
      margin-top: -3px;
    }
    .ui-selectmenu-icon {
      display: none;
    }
    @media screen and (max-width: 540px) {
      width: 100%;
      height: 35px;
      display: flex;
      align-items: center;
      margin-left: -2px;
    }
  }
  .ui-selectmenu-menu {
    width: calc(100% - 12px);
    .ui-menu {
      border-radius: 0;
      border: 1px solid #dbdbdb;
      box-sizing: border-box;
      position: relative;
      width: 100%;
      max-height: 240px;
      left: 0rem;
    }
    .ui-menu-item-wrapper {
      text-transform: capitalize;
      padding: 6px 16px;
      font-size: 12px;
      background: none;
      border: none;
      color: #444444;
      &.ui-state-active {
        background-color: #f7f7f7;
      }
    }
    .ui-state-disabled {
      display: none;
    }
    .ui-menu-item {
      color: #444444;
    }
  }
}

@media screen and (max-width: 539px) {
  .#{$ns}tabla {
    &--descargas {
      .#{$ns}tabla {
        &__select--filters {
          height: rem(48);
        }
        &__filters {
          margin-bottom: rem(6);
        }
      }
    }
  }
}

@media print {
  .#{$ns}layout__print-logo {
    display: block !important;
    img {
      max-width: 85%;
      font-style: italic;
    }
  }
  .#{$ns}tabla--descargas {
    margin-top: -37px !important;
    margin-left: 3px !important;
    .content-paragraph {
      margin-bottom: 19px;
      background-color: #f7f7f7 !important;
      .#{$ns}filtro {
        background-color: #f7f7f7 !important;
      }
    }
    .intro-text p {
      width: calc(100% + 50px);
    }
  }

  .#{$ns}tabla__filtro {
    margin-left: -18px;
  }

  .#{$ns}tabla__filtro {
    width: 94.2%;
    height: 236px;
  }
  .#{$ns}tabla {
    hr {
      background-color: #dbdbdb;
      opacity: 100%;
      margin-bottom: 15px;
      margin-top: -20px;
      width: 91.3% !important;
    }
    &__select {
      margin-top: 5px;
      .ui-selectmenu-button.ui-button {
        display: none;
      }
    }
    &--descargas {
      .#{$ns}documentacion__content--border-bottom.tabla-descargas {
        .line-table {
          display: none;
        }
        .#{$ns}documentacion__content--border-bottom.tabla-descargas {
          width: 92.2%;
        }
      }
      .#{$ns}table {
        margin-left: -12px;
        td {
          &:first-child {
            width: 270px;
          }
          &:nth-child(2),
          &:nth-child(3) {
            width: 224px !important;
          }
        }
      }
    }
    &--activation {
      .line-table {
        border-top: 1px solid #dbdbdb;
      }
    }
    &__input--search {
      width: calc(100% + 450px);
    }
  }
}
#tabwithcontent {
  .esios-table {
    tbody {
      td {
        font-size: rem(13) !important;
        font-family: $ns-font-proxima, Arial, sans-serif;
      }
    }
  }
}
#datepickerStartDate,
#datepickerEndDate {
  margin: 0 1.5%;
  .esios-tabla__inputCalendar {
    bottom: 0;
    right: 0;
  }
}

.esios-tabla__container-select {
  .col-3 {
    width: 22%;
  }
}

@media screen and (max-width: 540px) {
  .esios-tabla__filters {
    display: none;
  }

  .esios-tabla__filtro {
    padding-bottom: 100px;
  }

  #datepickerStartDate,
  #datepickerEndDate {
    margin-bottom: 10px;
    margin: 0 0.5625rem 0.5625rem;
    width: calc(100% - 18px);
    top: auto;
  }
}
