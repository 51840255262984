////
/// Date link component
///
/// Custom project
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}date-link
/// @since version 1.0.0
////

.#{$ns}date-link {
  position: relative;

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--year {
      .#{$ns}date-link__time-wrapper {
        width: 70%;
        flex-basis: auto;
      }

      .#{$ns}date-link__date-wrapper {
        &:after {
          display: none;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: rem(5);
    }
  }

  &__label {
    color: color(ns-darker);
    text-transform: uppercase;
    font-size: rem(14);
    font-family: $ns-font-proxima;
    font-weight: 600;
    width: 30%;
  }

  &__date-wrapper {
    position: relative;
    flex-grow: 2;
    margin-right: rem(2);
    max-width: rem(128);

    .esios-selector {
      &__selected {
        &--content {
          font-size: 0.75rem;
          font-weight: 600;
          display: block;
          max-width: rem(56);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    &--month {
      &:after {
        display: none;
      }

      & + .#{$ns}date-link__time-wrapper {
        flex-basis: 31%;
      }
    }

    &--day {
      max-width: none;
    }

    .esios-selector {
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 5px;

      & + .#{$ns}date-link__icon-cal {
        display: none;
      }
    }

    .#{$ns}date-link__icon-cal {
      background-image: url('../assets/images/calendar-grey.svg');
      background-repeat: no-repeat;
      background-position: center;
      display: block;
      width: rem(26);
      height: rem(32);
      position: absolute;
      top: calc(50% - 3px);
      transform: translateY(calc(-50% + 2px));
      right: rem(1);
      pointer-events: none;
      cursor: pointer;
    }

    & > .#{$ns}date-link__icon-cal {
      display: none;
    }
  }

  input {
    position: relative;
    font-family: $ns-font-proxima;
    font-size: rem(12);
    font-weight: 600;
    color: color(ns-main-bg);
    padding: rem(10) rem(15) rem(10) rem(6);
    border: solid 1px color(ns-light);
    cursor: pointer;
    word-spacing: rem(-2);
    width: 100%;
    text-align: center;

    & + .#{$ns}date-link__icon-cal {
      display: block;
    }

    &:disabled {
      cursor: default;

      & + .#{$ns}date-link__icon-cal {
        display: none;
      }
    }
  }

  &__time-wrapper {
    .#{$ns}toolbar-time {
      width: rem(80);

      &__mod-date {
        background-color: color(white);
        border: solid 1px color(ns-light);
        border-radius: 0;
        padding: 0;
        height: auto;

        span {
          color: color(ns-main-bg);
          font-family: $ns-font-proxima;
          font-weight: 600;
          font-size: rem(12);
          line-height: normal;
          padding: rem(10) rem(30) rem(9) rem(9);
        }

        &:after {
          content: none;
        }

        &:before {
          content: url('../assets/images/clock.svg');
          position: absolute;
          top: 50%;
          transform: translateY(calc(-50% + 4px));
          right: rem(6);
        }
      }

      &__select {
        box-shadow: rem(5) rem(5) 0 color(black, 0.05);

        &:not(:empty) {
          padding: rem(15);
          border: rem(1) solid color(ns-light);
          position: absolute;
          background-color: color(white);
          z-index: 6;
          right: 0;
          transform: translateY(13px);

          &:before {
            content: '';
            position: absolute;
            top: -16px;
            right: 0;
            border-bottom: 16px solid #dbdbdb;
            border-left: 16px solid transparent;
            border-right: 16px solid transparent;
          }

          &:after {
            content: '';
            position: absolute;
            top: -15px;
            right: rem(1);
            border-bottom: 15px solid white;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
          }
        }

        .#{$ns}selector {
          position: relative;
          line-height: rem(12);

          &__selected {
            font-family: $ns-font-proxima;
            font-weight: 600;
            font-size: rem(12);
            padding: rem(10) rem(20);

            &:after {
              content: '';
              width: 0;
              height: 0;
              border-left: rem(5) solid transparent;
              border-right: rem(5) solid transparent;
              border-top: rem(5) solid color(ns-darker);
              top: 50%;
              transform: translateY(-50%);
              right: rem(10);
              z-index: 1;
              position: absolute;
            }
          }

          &__options {
            position: absolute;
            font-family: $ns-font-proxima;
            font-size: rem(14);
            color: color(ns-main-bg);
            background-color: color(white);
            max-height: rem(240);
            overflow-y: auto;
            width: 100%;
            box-shadow: rem(5) rem(5) 0 color(black, 0.05);

            &:not(:empty) {
              border: rem(1) solid color(ns-light);
              padding: rem(8) 0;
            }
          }

          &__option {
            padding: rem(8) rem(16);
            font-family: $ns-font-proxima;
            font-size: rem(14);
            color: color(ns-main-bg);
            cursor: pointer;
          }
        }
      }
    }

    .esios-selector {
      margin-top: 0;
      margin-bottom: 0;

      &__selected {
        &--content {
          font-size: 0.75rem;
          font-weight: 600;
        }
      }
    }
  }

  &__btn {
    width: rem(20);
    height: rem(20);
    background-image: url('../assets/images/unlinked.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px;
    background-color: color(white);
    border-radius: 50%;
    border: rem(1) solid color(ns-light);
    position: absolute;
    transform: translateY(1.7rem);
    left: calc(30% - 33px);

    &--toggle {
      background-image: url('../assets/images/linked.svg');
      background-color: color(ns-primary);
      color: color(white);
      &:hover {
        background-color: color(ns-primary) !important;
      }
    }

    &:before,
    &:after {
      content: '';
      display: inline-block;
      width: rem(25);
      height: rem(10);
      position: absolute;
      left: rem(8);
      border-color: color(ns-light);
      border-style: solid;
    }

    &:before {
      border-width: rem(1) 0 0 rem(1);
      bottom: rem(19);
    }

    &:after {
      border-width: 0 0 rem(1) rem(1);
      top: rem(19);
    }

    &:hover {
      background-color: color(ns-lighter);
    }
  }

  .#{$ns}selector__options {
    max-height: rem(240);
  }
}

#analysis-form-compare-temp {
  .#{$ns}date-link {
    &__row {
      z-index: auto;

      .#{$ns}selector__options {
        z-index: 1;
      }
    }

    &__date-wrapper {
      max-width: none;
    }
  }
}
