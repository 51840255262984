////
/// Tabla component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}tabla
/// @since version 1.0.0
////

.#{$ns}table {
  border-spacing: 5px;
  word-break: break-word;
  table-layout: fixed;
  word-wrap: break-word;
  font-size: 11px;
  color: color(black) !important;
  width: 100%;
  margin-bottom: 2rem;
  &--sujetomercado {
    margin-top: 1rem;
    a {
      text-transform: capitalize;
    }
  }

  &__cell-txt {
    color: color(ns-main-bg) !important;
    font-family: $ns-font-proxima;
    font-size: rem(14);
    hyphens: auto;
    vertical-align: middle;
    line-height: normal;
  }
  &__figure {
    height: rem(15);
    width: rem(22);
    display: inline-block;
    vertical-align: middle;
    img {
      margin-left: rem(-5);
    }
  }
  &__title {
    font-weight: 700;
    font-family: $ns-font-proxima-bold !important;
    font-size: rem(14) !important;
    display: flex;
    a {
      color: color(ns-main-bg);
      display: flex;
      flex-wrap: wrap;
      margin-left: 28px;
      margin-top: -19px;
      line-height: normal;
    }
    a:hover {
      color: color(black);
    }
  }
  &__head-txt {
    font-family: $ns-font-proxima;
    font-size: rem(14);
    font-weight: 600;
    max-width: 88%;
    color: color(ns-main-bg);
    line-height: normal;
    margin-right: rem(-10);
    &:hover,
    &:focus {
      color: color(ns-main-bg);
      text-decoration: none;
    }
    &:hover {
      text-decoration: underline !important;
    }
    &--uprogramacion {
      font-weight: 600 !important;
      font-size: 12px !important;
      font-family: $ns-font-proxima;
      text-transform: capitalize;
    }
  }

  &__tableTitle {
    font-size: 13px;
    margin-bottom: 10px;
    color: color(black) !important;
  }
  th {
    font-family: $ns-font-proxima;
    font-weight: 600;
    padding: rem(10) rem(20);
    color: color(ns-main-bg) !important;
    font-size: rem(14);
    p {
      font-family: $ns-font-proxima;
      font-weight: 600;
      color: color(ns-main-bg) !important;
      font-size: rem(14);
    }
  }
  td {
    padding: rem(10) rem(20) rem(10) rem(23.2);
    color: color(ns-main-bg) !important;
    font-family: $ns-font-proxima;
    font-size: rem(14);
    hyphens: auto;
    vertical-align: middle;
    @media screen and (max-width: 540px) {
      padding: 0.5rem 1.25rem;
    }
  }
  p {
    color: color(ns-main-bg) !important;
    font-family: $ns-font-proxima;
    font-size: rem(14);
  }
  ul {
    padding: 0.5rem 0rem 0rem 2rem;
  }
  li {
    color: color(black) !important;
    font-size: 90%;
  }
  strong {
    color: color(black) !important;
  }

  &__head {
    color: color(black) !important;
    width: 60px;
  }
  &__row {
    height: 38px;
    vertical-align: middle;
    font-size: 13px;
    color: color(black) !important;
    align-items: center;
    line-height: 16.8px;
  }
  &__cell {
    width: 107%;
    font-family: $ns-font-proxima;
    color: color(black) !important;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    &--sort {
      color: color(black) !important;
      display: flex;
      margin-bottom: 0;
      flex-direction: column;
      .fa-sort {
        color: color(ns-light);
        display: inline-block;
        font-size: rem(16);
        &::before {
          position: relative;
        }
      }
      .fa-sort-up,
      .fa-sort-down {
        display: none;
        font-size: rem(16);
      }
      .fa-sort-up {
        &::before {
          position: relative;
          top: 1px;
        }
      }
      &.asc {
        .fa-sort-down {
          display: inline-block;
          position: relative;
        }
        .fa-sort {
          display: none;
        }
      }
      &.desc {
        .fa-sort-up {
          display: inline-block;
        }
        .fa-sort {
          display: none;
        }
      }
    }
  }
}
.tabla-descargas {
  .esios-table {
    table-layout: inherit;
    margin-bottom: 29px;
    &::after {
      content: '';
      display: block;
      width: 100%;
      border-top: 1px solid #dbdbdb;
      position: absolute;
      bottom: rem(-11);
    }

    &__title {
      a {
        margin-top: unset;
      }
    }
    th {
      width: 332px;
    }
    td {
      width: 290px;
      margin-left: 3px;
      &__figure {
        margin-right: -21px;
      }
    }
    td:nth-child(2) {
      width: 280px;
    }
    td:nth-child(3) {
      width: 250px;
    }
    td:nth-child(4) {
      width: 250px;
    }
    td:nth-child(5) {
      width: 250px;
    }
    i.fas.fa-sort-up {
      position: absolute;
    }
    &__cell {
      width: 109.8%;
      font-family: $ns-font-proxima;
      color: black !important;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
      margin-left: 0px;
      position: relative;
      &--sort {
        &.asc {
          .fa-sort-down {
            display: inline-block;
            margin-right: 1px;
          }
          .fa-sort {
            display: none;
          }
        }
        &.desc {
          .fa-sort-up {
            display: inline-block;
            margin-right: 0;
            top: rem(3);
            right: 0;
          }
          .fa-sort {
            display: none;
          }
        }
      }
    }
    &__cell:nth-child(2) {
      margin-left: 21px !important;
      padding-right: 20px !important;
    }
    &__cell-txt {
      vertical-align: center;
    }
    &__figure {
      margin-top: 1px;
      margin-left: -1px;
    }
    &__title a {
      margin-top: -1px;
      margin-left: 8px;
    }
    &__content-mobile {
      margin-left: -5px;
      margin-top: -5px;
    }
  }
  @media screen and (max-width: 540px) {
    .esios-table {
      &__title a {
        justify-content: flex-start;
        max-width: 233px;
        margin-left: 155px;
        margin-top: -16px;
        align-items: flex-start;
      }
      &__figure img {
        margin-left: rem(-4);
      }
      td:not(:first-child) {
        max-width: 100%;
        width: 100%;
        margin-bottom: 4px !important;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .esios-tabla--descargas {
    // .esios-tabla__filters {
    //   display: none;
    // }
    div#download-table {
      margin-top: -16px;
      .not-found {
        margin-bottom: rem(-28);
        margin-top: unset;
      }
      table.esios-table::after {
        display: none !important;
      }
      table.esios-table td.not-found {
        width: 100% !important;
        &::after {
          display: none;
        }
      }
    }
    #download-table + .paginador-descargas .esios-pagination__results {
      text-align: start;
      margin-top: unset;
    }
  }
  .esios-tabla hr {
    margin-top: -3px;
  }
  .esios-tabla__filtro {
    margin-left: -8px;
    margin-right: -8px;
    .grid-sm-24 {
      margin-left: -9px;
    }
    .esios-tabla__container-select {
      margin-left: -9px;
      button#tableDownloadJSONBtn {
        margin-right: 100px;
        margin-left: 100px;
        font-weight: 700;
        padding-top: unset;
        img {
          margin-bottom: -7px;
        }
      }
    }
  }
}

.esios-glosario {
  #glosario-table {
    .esios-table {
      table-layout: inherit;
      margin-bottom: 2.3rem;
      &__row {
        th:nth-child(2) {
          padding-left: 5px;
        }
        td,
        strong {
          font-weight: 600;
        }
        td {
          &:first-child {
            width: 34.9%;
            vertical-align: top;
            padding-top: 5px;
          }
          p {
            margin-left: -14px;
            margin-top: 12px;
            line-height: normal;
            margin-bottom: 16px;
            &:nth-child(n + 2) {
              width: 101%;
            }
            & * {
              font-size: 14px !important;
              font-family: $ns-font-proxima !important;
            }
          }
          ul {
            top: -10px;
            position: relative;
            left: -5px;
            & * {
              color: color(ns-main-bg) !important;
              font-size: 14px !important;
              font-family: $ns-font-proxima !important;
            }
          }
        }
      }
    }
    @media (max-width: 540px) {
      .esios-glosario {
        .esios-table {
          &__head {
            display: none;
          }
        }
        #glosario-table .esios-table__row td {
          width: 100%;
        }
      }
    }

    .esios-pagination__results {
      margin-top: 8px;
      margin-left: 4px;
    }
    .esios-pagination__paginator {
      margin-top: -27px;
      margin-left: 18px;
    }
    .fa-sort {
      &:before {
        content: '\f0dc';
        margin-right: 35px;
      }
    }
    i.fas.fa-sort-up {
      margin-top: 3px;
    }
  }

  #download-table .not-found {
    background-color: #f7f7f7;
    font-family: $ns-font-proxima;
    font-size: 14px;
  }

  .center-row {
    text-align: center;
    background-color: color(ns-lighter) !important;
  }

  .line-table {
    background-color: #dbdbdb;
    opacity: 100%;
  }

  .sort {
    &__image {
      margin-bottom: 1px;
      height: 7px;
      position: sticky;
    }
  }

  @media (max-width: 540px) {
    .#{$ns}table {
      display: block;
      word-break: normal;
      word-wrap: normal;
      margin: 0 rem(-20);
      width: calc(100% + 40px);
      margin-top: rem(-3);
      &__head {
        display: none;
      }
      &__row {
        // display: block;
        // height: auto;
        @media (max-width: 540px) {
          width: 100%;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: flex-start;
        }
        tr {
          &:nth-child(even) {
            background-color: color(ns-lighter) !important;
          }
        }
        tr {
          &:nth-child(odd) {
            background-color: color(white) !important;
          }
        }

        td:first-child {
          width: 100% !important;
          padding-top: 10px !important;
          margin-left: -4px;
          margin-bottom: -5px;
        }
        td {
          margin-top: -2px !important;
          p {
            margin-left: -3.1px !important;
            margin-top: 18px !important;
            max-width: 81vw !important;
            padding-bottom: 0px !important;
          }
        }
      }
      &__content-mobile {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 142px);
      }
      td {
        display: block;
        text-align: left;
        line-height: normal;
        &:before {
          content: attr(data-title);
          display: none;
          vertical-align: top;
          font-family: $ns-font-proxima;
          font-size: rem(14);
          font-weight: 600;
          width: rem(85);
          padding-right: rem(15);
        }

        &__figure {
          width: rem(22);
          display: inline-block;
          vertical-align: middle;
        }
        &__title {
          a {
            max-width: calc(100% - 25px);
            font-weight: normal;
          }
        }
      }
      #glosario-table .esios-table__row td ul,
      #glosario-table .esios-table__row td li {
        & * {
          font-size: 14px !important;
        }
      }
      #glosario-table .esios-table__row td p {
        margin-left: 0 !important;
        & * {
          font-family: $ns-font-proxima;
        }
      }
      #download-table .not-found {
        margin-top: unset;
        margin-bottom: 0;
        text-align: left;
      }
      #habilitados-table .not-found {
        margin-top: -10px;
        margin-bottom: 40px;
        text-align: left;
      }
    }

    @media screen and (max-width: 540px) {
      .#{$ns}table {
        display: block;
        word-break: normal;
        word-wrap: normal;
        margin: 0 rem(-20);
        width: calc(100% + 40px);
        margin-top: rem(-3);
        border-bottom: 1px solid #dfdfdf;
        &__head {
          display: none;
        }
        &__row {
          display: block;
          height: auto;
          @media screen and (max-width: 540px) {
            width: 540px;
            width: 100%;
          }
        }
        &__content-mobile {
          display: inline-block;
          vertical-align: top;
          width: calc(100% - 142px);
          margin-left: 20px;
        }
        td {
          display: block;
          text-align: left;
          line-height: normal;
          &:before {
            width: rem(85) !important;
          }
        }

        tr {
          &:nth-child(odd) {
            background-color: color(ns-lighter);
          }
          &:nth-child(even) {
            background-color: color(white);
          }
        }
        &__figure {
          width: rem(22);
          display: inline-block;
          vertical-align: middle;
        }
        &__title {
          a {
            max-width: calc(100% - 25px);
            font-weight: normal;
          }
        }
      }
      #download-table .not-found {
        margin-top: -7px;
        margin-bottom: 0;
      }
      #habilitados-table .not-found {
        margin-top: -10px;
        margin-bottom: 40px;
      }
    }
  }
  @media print {
    .#{$ns}table {
      tbody {
        .#{$ns}table {
          &__row {
            &:nth-child(even) {
              -webkit-print-color-adjust: exact;
              background-color: color(ns-lighter) !important;
            }
          }
        }
      }
    }
  }
}
@media print {
  .#{$ns}table {
    tbody {
      .#{$ns}table {
        &__row {
          &:nth-child(even) {
            -webkit-print-color-adjust: exact;
            background-color: color(ns-lighter) !important;
          }
        }
      }
    }
  }
  .tabla-descargas .esios-table th {
    width: auto;
  }
  .tabla-descargas .esios-table td {
    width: 200px;
  }

  .esios-tabla.esios-tabla--descargas {
    .intro-text {
      padding-bottom: 1rem;
    }
    .esios-tabla hr {
      margin-bottom: 0px;
    }
    .esios-tabla__filters {
      margin-top: 0px;
    }
    .esios-tabla__container-select {
      display: flex;
      align-items: start;
      flex-direction: column;
      width: 68.5%;
      margin-left: 5px;
      .esios-tabla__divFecha {
        top: 0;
      }
      .esios-tabla__inputCalendar {
        margin-top: 0px;
        margin-bottom: 0px;
        left: 0px;
      }
      .esios-tabla__buscarFecha.icon-download {
        display: none;
      }
      .col-3 {
        width: 70%;
        margin-left: 2%;
        margin-bottom: 11px;
        .ui-selectmenu-button.ui-button {
          width: 88%;
        }
      }
    }
  }
}

@media screen and (max-width: 539px) {
  .tabla-descargas {
    .#{$ns}table {
      margin-bottom: 0;

      &__title {
        a {
          margin-left: rem(198);
        }
      }
      &__row {
        &:nth-child(odd) {
          background-color: color(ns-lighter);
        }
        &:nth-child(even) {
          background-color: color(white);
        }
      }
      td {
        padding: rem(10) rem(20);
        line-height: 1.2;
        margin-bottom: 0 !important;
        width: 100%;

        &:before {
          width: rem(164);
        }

        &:not(:first-child) {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

// participantes habilitados //
.#{$ns}tablaHabilitado {
  #habilitados-table {
    .fa-sort {
      &::before {
        position: relative;
        left: -20px;
        top: 3px;
      }
    }
    .fa-sort-up {
      &::before {
        position: relative;
        left: -20px;
        top: 3px;
      }
    }

    .fa-sort-down {
      &::before {
        position: relative;
        left: -20px;
        top: 3px;
      }
    }

    .line-table {
      background-color: #555555;
    }
    .#{$ns}table {
      margin-bottom: rem(39);
      &__row {
        height: 36px;
        line-height: 16.8px;
      }
    }
  }
}
