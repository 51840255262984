.wrap-breadcrumb {
  display: block;
  margin: 0 auto;
  padding: 0 20px;
  max-width: rem(1161);
}

@media screen and (min-width: 1200px) {
  .wrap-breadcrumb {
    padding: 0 60px;
  }
}
