////
/// Nopeninsulares component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}noPeninsulares
/// @since version 1.0.0
////

.#{$ns}noPeninsulares {
  &__wrapper {
    margin-bottom: rem(80);
    area {
      background-color: red !important;
    }
    area:hover {
      cursor: pointer;
    }
  }
  &__wrapper-canarias {
    margin-top: rem(80);
    margin-bottom: rem(80);
  }
  &__mainCol {
    position: relative;
    padding: 0 rem(16);
    .row {
      .col-6 {
        padding-bottom: rem(16);

        &.#{$ns}noPeninsulares__date-container {
          padding-bottom: 0;
          padding-right: rem(2.5);

          .mod-date {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
          }
        }
        .esios-toolbar__component {
          margin-top: 3px !important;
        }
        &.#{$ns}toolbar {
          padding-bottom: 0;
        }
      }
    }
    area:hover {
      cursor: pointer;
    }
    &.ceuta__col {
      .esios-noPeninsulares__image-margin {
        margin-top: rem(3);
        padding-right: rem(6);
      }
    }
  }

  &__mainCol-melilla {
    position: relative;
    padding: 0 rem(16);
    .row {
      .col-6 {
        padding-bottom: rem(16);

        &.#{$ns}noPeninsulares__date-container {
          padding-bottom: 0;
          padding-right: rem(2.5);
        }
        .esios-toolbar__component {
          margin-top: rem(3);
          padding-right: rem(3);
        }
        &.#{$ns}toolbar {
          padding-bottom: 0;
        }
      }

      .esios-noPeninsulares__image-margin {
        padding-right: rem(4) !important;
      }
    }
  }
  &__mainCol-melilla:before {
    content: '';
    display: block;
    position: absolute;
    top: 16px;
    bottom: 0;
    left: 2px;
    width: 1px;
    background-color: color(ns-light);
  }
  &__hr-separator {
    color: #444444;
    margin-top: rem(24);
    margin-bottom: rem(21);

    &--hidden {
      color: #444444;
      margin: -19px 0 !important;
    }
    &--bottom {
      color: #444444;
      margin: 19px 0 !important;
      height: 0.004rem;
    }
  }
  &__hr-melilla {
    display: none;
    color: #444444;
    width: 94%;
    margin-left: 15px;
  }
  &__mainTitle {
    font-size: 1.4375rem;
    font-family: $ns-font-proxima;
    font-weight: 800;
    color: color(ns-primary);
    margin: rem(19) 0 rem(18);
  }
  &__title {
    h1 {
      text-transform: uppercase;
      font-size: 19px;
      font-family: $ns-font-proxima;
      color: color(ns-primary);
      font-weight: 800;
    }
  }
  &__label {
    text-transform: uppercase;
    color: color(ns-darker);
    font-family: $ns-font-proxima;
    font-weight: 600;
    font-size: 0.875rem;
  }
  &__little-label {
    text-transform: none;
    color: color(ns-darker);
    font-weight: 600;
    font-size: 0.875rem;
    font-family: $ns-font-proxima;
  }
  &__value {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    line-height: 1;
    margin-bottom: rem(12);
  }
  &__small {
    letter-spacing: -0.1rem;
    font-size: 1.4375rem;
    color: #444444;
    font-family: $ns-font-cousine;
  }
  &__unit {
    font-size: 0.75rem;
    font-weight: 600;
    padding-left: 0.3125rem;
    color: #444444;
  }
  &__image-margin {
    margin-bottom: rem(40);
    padding-right: rem(10);
  }
  hr {
    margin: rem(20) 0;
    background-color: #a1a1a1;
  }
  .mod-date {
    padding: 0 rem(10) !important;
  }
  &__date {
    color: color(ns-main-bg);
    font-weight: 600;
    padding: 0 1.5625rem;
  }
  &__date-container {
    display: flex;
    justify-content: flex-end;
    padding-top: rem(9);
    padding-bottom: 0;
  }
  &__tooltip-balearOne {
    width: rem(170);
    height: rem(130);
    position: absolute;
    top: 49%;
    left: 45%;
    background-color: color(white);
    box-shadow: 5px 5px 0 rgba(20, 15, 15, 0.05);
  }
  &__tooltip-balearOne:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid color(white);
    display: inline-block;
    position: absolute;
    z-index: 4;
    top: -19px;
    left: 60px;
  }
  &__tooltip-balearOne:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid color(ns-light);
    display: inline-block;
    position: absolute;
    z-index: 3;
    top: -20px;
    left: 60px;
  }
  @include media-query(xs) {
    &__mainCol-melilla:before {
      display: none;
    }
    &__hr-melilla {
      display: inline-block;
    }
    &__hr-separator--hidden {
      background: transparent;
    }
    &__wrapper {
      margin-bottom: 0;

      .#{$ns}toolbar__component {
        margin-top: rem(10);
        margin-bottom: rem(-4);
      }

      &-canarias {
        margin-top: rem(40);
        margin-bottom: rem(4);

        area {
          cursor: pointer;
        }

        & + .#{$ns}noPeninsulares__hr-separator {
          margin-bottom: 0;
        }
      }
    }
    &__value {
      margin-bottom: rem(12);
    }
    &__peninsula-baleares {
      width: auto;
    }
    &__row-flex {
      margin-top: rem(17);
      margin-bottom: rem(16);
    }
    &__image-margin {
      margin-top: rem(5);
    }
  }
  @media screen and (max-width: 540px) {
    &__show-mobile {
      color: #444444;
    }

    &__mainCol {
      .row {
        align-items: center;
        .col-6 {
          padding-bottom: 0;
        }
      }
      &-melilla {
        .esios-noPeninsulares__image-margin {
          margin-bottom: 14px;
        }

        .row {
          .col-6 {
            &.#{$ns}noPeninsulares {
              &__date-container {
                margin-top: rem(5);
              }
            }
          }
        }
      }
    }
    &__hr-separator {
      margin-top: 1px;
      margin-bottom: 16px;
      & + .row {
        & + .row {
          & + h3 + div {
            width: 50%;
            + div {
              width: 50%;
            }
          }
        }
      }
    }
    &__image-margin {
      margin-bottom: 17px;
    }
    &__hr-melilla {
      margin-bottom: 1px;
    }
    &__mainTitle {
      margin: 15px 0;
      line-height: rem(27);
    }
    &__title {
      margin-top: rem(21);
      margin-bottom: rem(-8);

      &--mb {
        margin-bottom: 0;
      }
    }
    &__title > .row {
      align-items: center !important;
      height: 10px !important;
      & > .col-6 {
        padding-bottom: 0 !important;
      }
    }
  }
  @media screen and (max-width: 540px) {
    .esios-layout__breadcrumbs {
      display: inline !important;
    }
  }
}

.esios-noPeninsulares__date.melilla {
  padding: 0px !important ;
}

.widget-area-tooltip {
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.05);
  border: 0;
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
  width: 11.5625rem;
  height: 8.75rem;
  margin-top: -10rem;
  margin-left: -5.78125rem;
  pointer-events: none;
  background-color: color(white);
}
.tooltip-container {
  height: 100%;
  width: 100%;
  padding: 0.9375rem 1.25rem;
  box-sizing: border-box;
  background: white;
  color: #444444;
  font-size: 0.875rem;
}
.widget-area-tooltip {
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.05);
  border: 0;
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
  width: 11.5625rem;
  height: 8.75rem;
  margin-top: -10rem;
  margin-left: -5.78125rem;
  pointer-events: none;
}
.is-hidden {
  display: none !important;
  visibility: hidden;
}
.widget-area-tooltip:after,
.widget-area-tooltip:before {
  content: '';
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid white;
  display: inline-block;
  position: absolute;
  z-index: 5;
  top: -18px;
  left: 73px;
}
.widget-area-tooltip:after {
  border-color: rgba(0, 0, 0, 0);
}
.tooltip-header {
  padding-bottom: 0.5rem;
  border-bottom: 4px solid #fff;
  div {
    color: color(ns-main-bg);
  }
}
.is-small-number {
  letter-spacing: 1px;
  font-size: 1.4375rem;
  color: color(ns-main-bg);
  .unit {
    font-size: 0.75rem;
    font-weight: 600;
    padding-left: 0.3125rem;
    color: color(ns-main-bg);
  }
}
.tooltip-data .label {
  color: #717171;
  font-weight: 600;
  font-size: 0.875rem;
}
.tooltip-row {
  .label {
    color: color(ns-main-bg);
  }
  margin: 0.7rem 0;
}
.is-hidden {
  display: none !important;
  visibility: hidden;
}

li.esios-toolbar__item:hover .fas {
  color: #006699 !important;
}

li.esios-toolbar__item:hover .ancle {
  color: #006699 !important;
}

li.esios-toolbar__item:hover ul li a:hover {
  color: #006699 !important;
  text-decoration: none !important;
}

hr {
  height: 1px;
}

@media screen and (max-width: 880px) {
  .esios-also__list.row#more {
    margin-top: 0px;
  }
}
