////
/// Layout component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}layout
/// @since version 1.0.0
////

.#{$ns}layout {
  &-back__container {
    background: #fff;
    top: 206px;
    position: relative;
    &.banner-aligned {
      top: 195px;
    }
    .banner-aligned {
      .esios-layout__main {
        top: 205px;
      }
    }
    @media screen and (max-width: 540px) {
      // top: 90px;
      &.banner-aligned {
        top: 206px;
      }
    }
  }
  &__main {
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    position: relative;
    margin-top: -206px;
    min-height: 200px;
    top: -204px;

    &.esios-layout__main--documents {
      top: 0;
    }

    .banner-aligned & {
      position: relative;
      top: rem(-114);

      @media screen and (max-width: 539px) {
        margin-top: -135px;
      }
    }

    @media screen and (max-width: 540px) {
      margin-top: 70px;
      overflow-x: hidden;
    }
  }

  &__main-myEsios {
    top: 0px !important;
  }

  &--home {
    .#{$ns}slider {
      @media screen and (max-width: 539px) {
        min-height: 4rem;
        display: block;
      }
    }
  }

  &__print-logo {
    width: 300px;
    display: none;
  }

  .print-page &__wrap {
    padding: 0;
  }
  &__wrap {
    min-height: 10.75rem;
    background-color: color(white);
    padding: 0 rem(20);
    padding-top: rem(20);
    margin-top: -1px !important;
    &--intercambios {
      @media screen and(max-width: 540px) {
        margin-top: -1px !important;
        padding: 20px !important;
      }
    }
    &--doc {
      padding-bottom: 5px;
    }

    &--pp {
      padding-top: 24px;
    }

    &--pvpc {
      margin-top: 9px !important;
    }

    &--myesios {
      margin-top: -1px !important;
    }

    &--balance {
      margin-top: 0px !important;
    }

    &--mercados-y-precios {
      @media (max-width: 540px) {
        &.esios-layout__wrap {
          padding: 0 20px !important;
        }
      }
    }

    &--gc {
      padding: 1.85rem 3.8rem 0rem 3.8rem !important;
      margin-top: -1px !important;

      #char2 {
        .#{$ns}toolbar-time {
          margin-top: rem(2);
        }
      }

      @media (max-width: 540px) {
        &.esios-layout__wrap {
          padding: 0px 20px !important;
        }
        .esios-consumo {
          margin-top: 2px;
          #char2 .esios-toolbar-time {
            left: 31px;
          }
          .esios-toolbar {
            margin-bottom: -1px;
          }
          #widget2 {
            margin-bottom: -38px;
          }
          #widget2Data {
            .widget-legend.visual-legend.in-line {
              margin-left: -14px;
            }
          }
        }
        .visual-legend ul {
          display: flex;
          flex-direction: column;
          margin-left: 15px;
          margin-top: 30px !important;
          li.legend-item {
            height: 8px;
            .label {
              font-family: $ns-font-proxima;
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
        #char2 {
          .#{$ns}toolbar {
            align-items: center;

            &-time {
              margin-top: 7px;
            }
          }
        }
        #char1 {
          .#{$ns}toolbar {
            align-items: center;
          }
        }
      }
    }
    &--curves {
      max-width: 95.5%;
      margin-top: rem(16) !important;
      margin-bottom: rem(-101);
      @media screen and (max-width: 768px) {
        margin-top: unset;
        margin-bottom: unset;

        &.#{$ns}layout__wrap {
          padding-top: rem(20);
        }
      }
      @media screen and (max-width: 540px) {
        .datepicker-mod.mod-date.mod-date-days.is-day-selectable {
          width: 96.5%;
        }
      }
    }

    &--no-peninsulares {
      .#{$ns}noPeninsulares__hr-separator:first-child {
        display: none;
      }
    }

    @media screen and (min-width: 1200px) {
      padding-right: rem(60);
      padding-left: rem(60);
      overflow: hidden;
      margin-top: 24px;
    }

    @media screen and (max-width: 768px) {
      padding: rem(1) rem(20);
    }
  }

  #esios-layout-banner {
    top: -154px;
    position: relative;
    height: 370px;
    z-index: -99 !important;
    &.no-banner {
      height: 205px;
      @media screen and (max-width: 540px) {
        height: 0;
      }
    }

    @media print {
      &.hideForPrint {
        display: none;
      }
      &.ly-descargas {
        height: 0 !important;
        display: none;
        .esios-layout__main,
        .esios-layout-back__container {
          top: 0 !important;
        }
        .esios-layout__main--descargas {
          top: 0 !important;
        }
      }
    }

    @media screen and (max-width: 768px) {
      height: 400px;
    }
    @media screen and (max-width: 540px) {
      height: 0px;
      &.banner-mb {
        height: 273px;
      }
    }

    .layout-header-banner {
      position: absolute;
      top: 154px;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
      width: 100%;
      z-index: -1;
      &.banner-aligned {
        top: 0 !important;
        position: fixed;
        @media screen and (max-width: 540px) {
          top: 18px !important;
          position: relative;
        }
      }
    }

    .mod-banner {
      height: 370px;
      position: relative;

      .wrapper {
        height: 100%;
        position: relative;
      }

      .banner-secondary-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }
    }
    .banner-aligned .mod-banner {
      height: 570px !important;
      @media screen and (max-width: 540px) {
        height: 148px !important;
        top: 136px !important;
      }
    }
  }
  &__banner-image {
    background-size: cover;
    min-height: 370px;
    height: auto;
    position: relative;
    display: flex;
    align-items: flex-start;

    &.banner-aligned {
      min-height: 357px !important;

      @media screen and (max-width: 540px) {
        display: flex;
      }
    }

    &.mod-banner-caption {
      min-height: 370px;
      background-attachment: fixed !important;
      background-position: center -115px !important;
      background-size: cover !important;
    }

    @media screen and (max-width: 540px) {
      display: none;
    }
  }

  &__indicator-caption {
    position: absolute;
    opacity: 20;
    top: 160px;
    font-weight: bold;
    color: white;
    padding: 25px 21px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-shadow: 2px 2px 0px #000000a6;
    @include transform(translateX(-50%));
    left: 50%;
    font-family: $ns-font-montserrat-bold;

    @media screen and (max-width: 768px) {
      top: 218px;
    }
    @media screen and (max-width: 540px) {
      top: 302px;
      background-color: #00a1da;
      text-shadow: none;
    }

    .banner-subtitle {
      display: grid;
      // margin-top: 10px;
      color: color(white);
      font-family: $ns-font-proxima;
      font-weight: 800;
      @include transform(translate3d(0px, 0px, 0px));
      font-size: rem(70);
      line-height: rem(59.2);
      letter-spacing: -1.6px;

      @media screen and (max-width: 540px) {
        color: color(white);
        margin-top: -8px;
        font-size: rem(30);
        line-height: rem(25);
      }

      .below-text {
        color: color(skyblue-light);
        font-family: $ns-font-proxima;
        font-weight: 800;

        @media screen and (max-width: 540px) {
          color: color(white);
        }
      }
      .banner-unit {
        color: color(skyblue-light);
        font-family: $ns-font-proxima;
        font-weight: 800;

        @media screen and (max-width: 540px) {
          color: color(white);
        }
      }
    }
  }

  &__extended {
    width: 100%;
    &--myesios {
      margin-left: 0rem !important;
      @media (max-width: 540px) {
        margin-top: 1rem;
        margin-left: 0.3rem !important;
      }
    }

    &--gd {
      #currentNews {
        .esios-layout__subcontent__wrapper {
          #document-list-myp {
            margin-top: 20px !important;
          }
        }
      }
    }
  }

  &__related-content {
    border-top: 1px solid color(ns-light);
    padding-top: rem(40);
    background-color: color(white);
    padding-bottom: rem(60);

    &--home {
      .homeBalanceWidgetView {
        padding-left: 35px;
        @media (max-width: 540px) {
          margin-top: 23px;
        }
      }
    }

    &__wrapper {
      margin: 0 auto;
      width: 95%;
      max-width: 1200px;
      display: grid;
      grid-template-columns: 7fr 5fr;

      @media screen and(max-width: 1200px) {
        grid-template-columns: 8fr 4fr;
      }

      @media screen and (max-width: 500px) {
        width: 90%;
      }

      .homeBalanceWidgetView {
        padding-right: 60px;
        display: grid;
        // grid-template-columns: 7fr 5fr;

        @media screen and (max-width: 1280px) {
          // grid-template-columns: 6fr 6fr;
          padding-right: 38px;
          padding-left: 61px;
        }
      }
    }
  }

  &__subcontent {
    position: relative;
    border-top: none;
    background: color(ns-lighter) url($ns-path-img + 'bg-static-page.png')
      no-repeat bottom;

    .title {
      margin-top: -4px;
    }

    &--analysis {
      background: #f7f7f7;
      background-image: none;
    }

    #document-list-myp {
      margin-top: 87px !important;
      margin-left: -2px !important;
      #dl-results {
        margin-bottom: 1.25rem;
        margin-top: 24px;
        margin-left: 12px;
        width: 98.8%;
      }
    }

    .myp-boxes.row.mb-5--myp {
      margin-bottom: -65px;
    }

    .row.is-strech {
      margin-top: 69px;
      margin-left: -6px;
    }

    @media screen and (max-width: 539px) {
      background-image: none;
    }

    .row.is-strech.row\.is-strech--no-peninsulares {
      margin-top: 63px;
      margin-left: -1px !important;
    }

    .row.is-strech.row\.is-strech--descargas {
      margin-top: 46px !important;
      margin-bottom: -1px !important;
    }

    .row.is-strech.row\.is-strech--sujetomercado {
      margin-top: 69px;
      margin-left: -2px;
    }

    .row.is-strech.row\.is-strech--unidadespro {
      margin-top: 58px;
      margin-left: -4px;
    }

    .row.is-strech.row\.is-strech--unidadesfisicas {
      margin-top: 59px;
      margin-left: -8px;
    }

    .row.is-strech.row\.is-strech--habilitados {
      margin-top: 62px;
      margin-left: -7px;
    }

    &.no-image {
      background-image: none;
    }

    &__wrapper {
      margin: 0 auto;
      width: 95%;
      max-width: 1200px;
      padding-top: rem(3);
      &--habilitados {
        .#{$ns}documents {
          .#{$ns}boxmarket {
            &:first-child {
              margin-bottom: 13px !important;
            }
          }
        }
      }
      &--gc {
        padding: 0.1875rem 0.9rem 0rem 0.9rem;
        margin-top: -5px;
        @media (max-width: 540px) {
          height: auto;
        }
        .esios-market-prices__form {
          margin-top: 0px;
          width: 101%;
          margin-left: -4px;
        }
        .esios-documents {
          margin-left: -1px;
          margin-bottom: -10px;
          @media (max-width: 540px) {
            margin-left: -1px;
            margin-bottom: -10px;
            margin-right: -41px;
            margin-top: 48px;
          }
          .mod-documents--gc {
            margin-right: -6px !important;
            width: 50.5%;
            @media (max-width: 540px) {
              width: calc(100% + 20px);
              margin: 0 -20px;
              padding: 4rem 20px 0.625rem 20px;
              border-bottom: 1px solid #dbdbdb;
              background-color: white;
              margin-left: -21px;
              margin-top: -36px;
              left: 5px;
              &:nth-child(2) {
                width: calc(100% + 0px);
                margin-left: 0;
                left: 4px;
                right: 0;
                margin-top: -4px;
                margin-bottom: -20px;
                min-height: 370px;
                height: 370px;
                // temp

                width: calc(100% + 20px);
                margin: 0 -20px;
                padding: 1.875rem 40px 0.625rem 30px;
                background-color: white;
                margin-left: -21px;
                margin-top: 0px;
                margin-bottom: 4px;
                left: 5px;

                .esios-boxmarket {
                  &__container {
                    width: 100%;
                    margin-left: -9px;
                  }
                  &__header {
                    margin-left: -12px;
                  }
                  &__body {
                    width: 100%;
                  }

                  &__list {
                    margin-bottom: -33px;
                    ul {
                      width: calc(100% + 10px);
                    }
                  }
                }
                .mod-buttons {
                  &--gc {
                    height: fit-content;
                    margin-top: 29px;
                    margin-left: auto;
                    margin-right: auto;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 100% !important;
                  }
                }
              }
              .esios-boxdoc {
                &__body {
                  margin-bottom: 52px;
                }
                &__footer {
                  margin-top: -12px;
                  margin-bottom: -25px !important;
                  width: 100%;
                  margin-left: 88px;
                  @media screen and (max-width: 540px) {
                    display: flex;
                    flex-direction: column;
                  }
                }
                &__button {
                  margin-bottom: 4px;
                  width: 203px;
                  margin-left: 1px;
                  &:nth-child(2) {
                    width: 207px;
                    margin-left: -2px;
                  }

                  @media screen and (max-width: 540px) {
                    width: fit-content;
                    &:nth-child(2) {
                      width: fit-content;
                    }
                  }
                }
              }
              .esios-boxmarket {
                padding-top: 2px;
                padding-left: 0px;
                border: none;
                width: 100%;
                padding-right: 0px;
                padding-bottom: 57px;
                &__container {
                  width: 94%;
                }
              }
            }
            .esios-boxdoc__list.esios-boxdoc__list--gc {
              margin-top: -20px;
              a {
                font-weight: 600;
                font-family: $ns-font-proxima;
                max-width: 77%;
              }
            }
            .esios-boxdoc__list.esios-boxdoc__list--no-peninsulares {
              background: red !important;
              li a {
                text-align: left !important;
              }
            }
            .esios-boxdoc__footer {
              margin-top: -12px;
              margin-bottom: 41px;
            }
            .esios-boxmarket ul {
              margin-top: 40px;
              margin-left: 2px;
              li:nth-child(3),
              li:nth-child(4) {
                margin-top: -5px;
              }
            }
            .mod-buttons--gc {
              bottom: 0;
              top: 65.5%;
              @media (max-width: 540px) {
                top: 275px;
                left: 91px;
              }
              .mod-buttons__btn {
                font-family: $ns-font-proxima;
              }
            }
          }
        }
        .esios-also__list.row.esios-also__list--gc {
          @media (max-width: 540px) {
            display: none;
          }
        }
        .title {
          width: 100%;
        }
        .row.is-strech {
          margin-left: -19px;
          @media (max-width: 540px) {
            display: flex;
            flex-direction: column;
            margin-right: 0;
            margin-left: 0px;
            width: 100%;
            margin-top: 0;
          }
          .col-md-9.col-sm-8 {
            width: 76%;
            @media (max-width: 540px) {
              width: 100%;
              #newsModView {
                margin-left: 40px;
                margin-top: -43px;
              }
            }
          }
          .col-md-3.col-sm-4.is-stretch {
            margin-left: -1px;
            width: 24%;
            @media (max-width: 540px) {
              width: 100%;
              #mapsModView {
                .is-section-title {
                  margin-left: 17px !important;
                  margin-top: 3px;
                  margin-bottom: 24px;
                }
                ul {
                  margin-left: -19px;
                  margin-bottom: 57px !important;
                  margin-top: -1px;
                }
                .mod-action-buttons {
                  width: 93%;
                  display: flex;
                  justify-items: center;
                }
              }
            }
            .is-section-title {
              margin-left: 2px;
            }
          }
        }
        .mod-maps {
          &--gc {
            margin-bottom: 100px !important;
            min-height: 353px !important;
            @media (max-width: 540px) {
              margin-bottom: 42px !important;
            }
            ul {
              width: 96.5% !important;
              margin-bottom: 0 !important;
            }
            .mod-action-buttons {
              height: 31.5px !important;
              position: relative;
              right: 0px !important;
              top: 3px !important;
            }
          }
        }
      }

      &--home {
        .row.is-strech {
          margin-top: -9px;
          margin-left: -6px;
        }
        .mod-maps--home {
          .mod-action-buttons {
            @media screen and (max-width: 540px) {
              padding-bottom: 50px;
            }
          }
        }
      }

      @media screen and (max-width: 500px) {
        width: 102%;
      }

      @media screen and (max-width: 539px) {
        width: 100%;

        .row.is-strech {
          margin-right: 0;
        }

        .row {
          margin-left: 0;
          margin-right: 0;
          width: 100%;
        }
        .esios-also__list {
          margin-top: initial;
        }

        .myp-boxes.row.mb-5--myp {
          width: 100%;
          margin-bottom: 0px;
          .esios-boxdoc {
            width: calc(100% + 23px);
            padding-top: 40px;
          }
          .esios-boxdoc__header {
            margin-bottom: 24px;
          }
          .esios-boxmarket {
            width: calc(100% + 25px);
            border-left: none;
            border-right: none;
            padding-right: 0px !important;
            overflow: hidden;
          }
          .esios-boxmarket__body {
            width: 100%;
          }
          .esios-boxmarket__list {
            margin-top: -17px !important;
            margin-bottom: 8px;
            margin-left: 6px;
          }
          .esios-boxmarket li {
            width: 98% !important;
            a {
              margin-top: 5px;
              max-width: 78%;
            }
          }
          .esios-boxdoc__footer {
            left: 10% !important;
          }
        }
      }

      .is-title-with-line {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: rem(74);
        margin-top: rem(55);
        margin-left: rem(6);
        font-family: $ns-font-proxima;
        letter-spacing: 0.55px;
        font-size: 42px;
        font-weight: 300;
        //font-weight: 400 !important;
        text-align: center;
        text-transform: uppercase;
        color: #006699;
        position: relative;
        overflow: hidden;

        @media screen and (max-width: 880px) {
          text-align: center;
          font-size: 2.5rem;
          margin-bottom: 30px;
          margin-top: 30px;
          width: 101%;
          margin-left: 4px;
        }

        &:after,
        &:before {
          position: relative;
          display: inline-block;
          content: '';
          height: 1px;
          background-color: #dbdbdb;
          vertical-align: middle;
          width: 36%;
        }

        &:before {
          right: rem(49);
          top: rem(2);
          margin-left: -40%;

          @media screen and (max-width: 880px) {
            right: 3rem;
            margin-left: -70%;
          }
        }
        &:after {
          left: rem(44);
          top: rem(2);
          margin-right: -40%;

          @media screen and (max-width: 880px) {
            left: 3rem;
            margin-right: -70%;
          }
        }
      }

      .is-section-title {
        margin-bottom: rem(24);
        text-transform: uppercase;
        font-family: $ns-font-proxima;
        font-weight: 400;
        font-size: 25px;
        letter-spacing: 0.2px;
        color: #444444;
        @media screen and (max-width: 540px) {
          text-align: center;
          &:first-child {
            margin-top: 4px;
            margin-bottom: 23px;
            margin-left: -5px;
          }
        }
        &--home {
          font-size: 25px !important;
          letter-spacing: 0px;
          @media (max-width: 540px) {
            margin-top: 4px !important;
            margin-left: 0px !important;
          }
        }
        &--gc {
          font-size: 25px !important;
          letter-spacing: 0px;
          width: 90%;
          @media screen and (max-width: 540px) {
            width: 100%;
          }
        }
      }

      .mod-maps {
        border-left: 1px solid color(ns-border-ligth);
        padding-left: 37px;
        text-transform: uppercase;
        margin-bottom: 124px;
        &--gc {
          ul {
            margin-bottom: 1.1rem !important;
          }
          li {
            line-height: 1.2 !important;
          }
        }

        @media screen and (max-width: 880px) {
          padding-left: 20px;
          border: none;
        }

        @media screen and (max-width: 540px) {
          margin-bottom: rem(113);
        }

        &--home {
          ul {
            @media screen and (max-width: 540px) {
              margin-top: 20px !important;
              width: 95% !important;
              margin-left: -16px !important;
            }
          }
        }
        ul {
          list-style: disc;
          padding-left: 1.25rem;
          margin-bottom: rem(16);
          margin-top: 20px;
          width: 90%;
          @media screen and (max-width: 540px) {
            margin-top: 20px;
            width: 82%;
            margin-bottom: 55px;
          }
        }
        li {
          color: #444444;
          margin-bottom: rem(13);
          line-height: 1.1;
          margin-left: rem(2);
          @media screen and (max-width: 542px) {
            margin-bottom: 12px;
          }
        }
        a {
          color: #444444;
          font-weight: 600;
          font-size: 14px;
          text-transform: uppercase;
          font-family: $ns-font-proxima;
          line-height: 18.2px;
        }

        .mod-action-buttons {
          display: flex;
          justify-content: center;
          // margin: rem(20) 0 rem(40) 0;
          height: rem(35);
          position: relative;
          right: rem(7.5);
          top: rem(4);
          @media (max-width: 540px) {
            right: 3px;
          }

          .btn-info {
            display: inline-block;
            padding: 0.4375rem 1.25rem;
            border-radius: 1.25rem;
            background-color: color(ns-primary);
            background-clip: padding-box;
            color: white !important;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
            -webkit-font-smoothing: antialiased;
            width: auto;
            transition: background 0.2s ease-in-out;

            &:hover {
              text-decoration: none;
              background-color: color(ns-primary, 0.8);
              transition: background 0.2s ease-in-out;
            }
          }
        }
      }
    }
  }

  &__footer-image {
    background: url($ns-path-img + 'bg-static-page.png') no-repeat;
    background-position: center bottom;
    background-size: contain;
    min-height: 145px;
    margin-top: 60px;
    max-width: 100%;
    position: relative;

    &--curves {
      position: relative;
      z-index: 1;
      margin-left: 104px;
    }

    &--balance {
      margin-bottom: 43px;
    }

    &--pp {
      margin-top: 76px;
      margin-bottom: 3px;
    }

    @media screen and (max-width: 768px) {
      background-size: auto;
    }

    @media screen and (max-width: 539px) {
      display: none;
    }
  }

  &__breadcrumbs__row {
    justify-content: space-between;
  }

  .hidden {
    display: none;
  }

  .icon-eye {
    cursor: pointer;
  }
  .icon-eye.disabled {
    color: color(ns-light) !important;
    background-color: color(ns-light) !important;
  }

  .ii .esios-boxdoc,
  .ii .esios-boxmarket {
    height: 100% !important;
  }
}

#newsModView {
  margin-left: 15px;
}

#currentNews {
  @media (max-width: 540px) {
    #newsModView {
      margin-left: 8px;
      width: 100%;
    }
    #mapsModView {
      width: 94%;
    }
    .esios-layout__subcontent__wrapper--home {
      @media screen and (max-width: 540px) {
        .row.is-strech {
          height: auto !important;
        }
      }
    }
    .esios-layout__subcontent__wrapper .mod-maps {
      .esios-toolbar-time {
        left: 0 !important;
      }
      ul {
        width: 102% !important;
        padding-left: 26px;
        margin-left: 0 !important;
      }
      .mod-action-buttons {
        height: 31px;
      }
    }
  }
}
.esios-layout__subcontent__wrapper {
  padding-bottom: 30px;
  margin-bottom: 20px;
}
.esios-layout__subcontent__wrapper--home {
  margin-bottom: 0;
  @media screen and (max-width: 540px) {
    margin-bottom: 120px;
  }
}

.esios-layout__related-content--myesios {
  top: 0px !important;
}

.esios-layout__related-content--gd {
  position: relative;
  top: 10px;
  .spinner-container {
    height: initial !important;
    position: absolute !important;
  }
}
// generacion de la demanda //
@media (max-width: 540px) {
  .esios-layout__related-content--gd {
    width: 100%;
    padding-bottom: 68px !important;
    .title {
      margin-bottom: 70px !important;
    }
  }
  aside#currentNews {
    width: 100%;
    margin-top: 6px;
    .esios-documents {
      margin-left: 0px;
      margin-top: -26px;
    }
    .esios-layout__subcontent .title {
      margin-top: -4px;
      width: 98%;
    }
    .esios-layout__subcontent__wrapper {
      .is-title-with-line {
        margin-top: 30px !important;
        margin-bottom: 32px;
        margin-left: 0px !important;
        width: 100% !important;
      }
      .esios-boxdoc__title h2 {
        margin-top: 28px;
      }
    }
    .esios-documents {
      .esios-boxdoc__container {
        width: 100%;
      }
      .esios-boxdoc {
        padding-top: 0px !important;
        margin-top: 15px !important;
        padding-left: 18px !important;
        margin-left: 3px !important;
        width: 99%;
        margin-bottom: 0px !important;
        border-bottom: 1px solid #dbdbdb;
      }
    }
    .esios-boxdoc__title h2 {
      margin-top: 0px !important;
      margin-left: -2px;
      margin-bottom: 12px;
    }
    .esios-documents .esios-boxdoc__list a {
      font-weight: 600;
      word-spacing: -2px;
    }
    .row.is-strech {
      margin-top: 19px !important;
      margin-right: 0px !important;
      width: 100%;
      .col-md-9.col-sm-8 {
        margin-left: 0px;
      }
      .col-md-3.col-sm-4.is-stretch {
        width: 100%;
        padding-right: 0px !important;
      }
      .mod-maps {
        .is-section-title {
          margin-left: 28px;
        }
        ul {
          width: 100% !important;
          padding-left: 11px;
        }
        .mod-action-buttons {
          height: 31px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 25px;
        }
      }
      #mapsModView {
        margin-bottom: 91px;
        @media screen and (max-width: 540px) {
          margin-bottom: 0px !important;
        }
      }
    }
  }
  .esios-layout__extended--gd aside#currentNews .esios-documents .esios-boxdoc {
    padding-top: 30px !important;
  }
}

@media (max-width: 540px) {
  // mercados y precios //
  aside#currentNews {
    width: 100%;
    margin-top: 0px;
    .esios-documents {
      margin-left: 0px;
      margin-top: -26px;
    }
    .esios-layout__subcontent .title {
      margin-top: -4px;
      width: 98%;
    }
    .esios-layout__subcontent__wrapper {
      .is-title-with-line {
        margin-top: 40px !important;
        margin-bottom: 32px;
        margin-left: 0px !important;
        width: 100% !important;
        &:before {
          right: 3rem;
          margin-left: -199px;
          width: 16%;
        }
        &:after {
          left: 3rem;
          margin-right: -196px;
          width: 16%;
        }
      }
    }
    .esios-layout__subcontent__wrapper .myp-boxes.row.mb-5--myp {
      .esios-boxdoc {
        &__footer {
          left: 14% !important;
          left: 50% !important;
          transform: translateX(-50%) !important;
        }
      }
    }
  }

  //generación y consumo //
  aside#currentNews {
    width: 100%;
    margin-top: 6px;
    .esios-documents {
      &--gc {
        margin-left: 0px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        width: calc(100% + 10px);
        .esios-boxdoc {
          margin-top: -10px;
        }
        .grid-xs-24.grid-xs-24--gc.documents-container {
          margin-top: 47px;
        }
        .#{$ns}boxdoc {
          border-bottom: none;
          &__container {
            margin-top: -45px;
            margin-left: -16px;
            width: calc(100% + 61px);
          }
          &__footer {
            margin-left: 0px;
            margin-bottom: -37px;
          }
        }
      }
    }
  }
}

@media (max-width: 540px) {
  .#{$ns}layout {
    &__wrap {
      padding-top: 20px;

      &--home {
        padding: 0px;
      }

      &--curves {
        width: 100%;
        max-width: none;
      }

      &--pvpc {
        margin-bottom: 31px;
      }

      &--no-peninsulares {
        padding-top: 0;
        overflow-y: hidden;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .#{$ns}layout {
    &__banner-image {
      &-not-show {
        min-height: rem(70) !important;
      }
    }

    &__subcontent__wrapper {
      &--unidades,
      &--habilitados {
        .#{$ns}documents {
          .mod-documents {
            &:first-child {
              .#{$ns}boxmarket {
                margin-bottom: 0 !important;
                border-bottom: 0;
              }
            }
            &:nth-child(2) {
              width: 100%;

              .#{$ns}boxmarket {
                margin-left: 0 !important;

                &__container {
                  padding-bottom: 0;
                  min-height: auto;
                }

                &:first-child {
                  .#{$ns}boxmarket {
                    &__text {
                      display: none;
                    }
                  }
                  .#{$ns}boxdoc {
                    &__footer {
                      display: none;
                    }
                  }
                }
              }
            }
          }

          .#{$ns}boxmarket {
            &:first-child {
              width: 100% !important;
              margin-left: 0 !important;
            }
          }
        }
      }
    }

    &--home,
    &--balance,
    &--gd {
      .#{$ns}layout {
        &__breadcrumbs {
          display: block;
        }
      }
    }
  }

  .esios-layout__extended--gd {
    .esios-boxdoc {
      height: auto !important;
      padding-top: 20px !important;
    }

    @media (max-width: 540px) {
      #tabwithcontent2 {
        display: none;
      }

      .tab__content {
        display: none;
      }
    }
  }
}

@media print {
  .#{$ns}layout {
    &__main {
      &--descargas {
        margin-left: 0;
      }
    }

    &__print-logo {
      &.documentation {
        margin-top: 0;
      }
    }
  }

  .ly-descargas {
    .layout-header-banner {
      display: none !important;
    }
  }
  .esios-layout__main--descargas,
  .#{$ns}layout__main--doc,
  .#{$ns}layout__main--glossary,
  .#{$ns}layout__main--habilitados,
  .#{$ns}layout__main--programming {
    top: 0 !important;

    .#{$ns}layout__wrap {
      margin-top: 0 !important;
    }

    .#{$ns}list-print-view {
      display: block;
      font-size: 0;

      &__item {
        vertical-align: top !important;
        float: left;

        &:nth-child(2n + 1) {
          clear: both;
        }
      }
    }
  }
  #layout-extended {
    display: none !important;
  }
  .esios-layout-back__container {
    top: 0 !important;

    .esios-tabla--descargas {
      .esios-tabla__container-select {
        display: grid !important;
        align-items: stretch !important;
        justify-content: stretch !important;
        justify-items: left !important;

        #datepickerEndDate,
        #datepickerStartDate,
        #ui-id-3-button {
          width: 500px !important;
        }
        #datepickerStartDate {
          top: 5px;
        }
        #datepickerEndDate {
          top: 15px;
        }
      }
    }
  }
  #footer-app {
    display: none !important;
  }
}
.iimb-3 {
  margin-bottom: 45px !important;
}
