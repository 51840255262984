////
/// Modal component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}modal
/// @since version 1.0.0
////

.#{$ns}modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;

  #typeOrder-button {
    border: none;
    border-radius: 0.625rem;
    background-color: #dbdbdb;
    height: 1.4375rem;
    width: 100%;
    text-align: center;
    padding: 0.3rem 1.5rem;
    margin-right: 4.5rem;
    min-width: rem(185);
    .ui-selectmenu-icon.ui-icon.ui-icon-triangle-1-s {
      background-position: rem(-64) rem(-18) !important;
    }
    .ui-selectmenu-text {
      text-align: center;
      font-weight: 600 !important;
      font-family: $ns-font-cousine;
      color: color(ns-main-bg) !important;
      font-size: rem(12);
    }
  }

  .select-typeOrder {
    ul {
      margin-bottom: 0 !important;
      .ui-menu-item {
        .ui-menu-item-wrapper {
          border: none !important;
          color: #444444 !important;
          padding: 3px 1em 3px 0.4em !important;
          text-align: center;
          font-family: $ns-font-cousine;
          color: color(ns-main-bg) !important;
          font-size: rem(12);
        }
        .ui-menu-item-wrapper.ui-state-active {
          background-color: #f7f7f7 !important;
          background-image: none;
          margin: 0;
          text-align: center;
          font-family: $ns-font-cousine;
          color: color(ns-main-bg) !important;
          font-size: rem(12);
        }
      }
    }
    .ui-selectmenu-menu.ui-front.ui-selectmenu-open {
      box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.05);
      position: absolute;
      margin-top: 2rem;
      &:before {
        content: '';
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid white;
        margin: 0 auto;
        position: absolute;
        z-index: 2;
        bottom: 0.5rem;
        top: rem(-14.5);
        right: 40%;
      }
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-bottom: 16px solid #dbdbdb;
        margin: 0 auto;
        position: absolute;
        z-index: 1;
        bottom: 0.5rem;
        top: rem(-14.5);
        right: 40%;
      }
    }
  }

  &__background {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 12;
    background: color(black);
    opacity: 0.5;
  }

  &__container {
    position: relative;
    width: 85%;
    max-width: 60rem;
    min-height: 15.625rem;
    // max-width: 37.5rem;
    // min-height: 18.28rem;
    height: auto;
    max-height: 90%;
    box-sizing: border-box;
    display: flex;
    margin: auto;
    z-index: 14;
    padding: 2.5rem;
    //margin-top: 176px;
    background: color(white);
    @media (max-width: 540px) {
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: 100%;
      min-height: 100%;
      max-height: 100%;
      margin-left: 0;
    }

    &--video-help {
      padding: 2.5rem 0 0 0;
      width: rem(850);
      height: auto;
    }

    &[class*='esios-modal__container--buttonShareModal'] {
      @media (max-width: 540px) {
        position: absolute;
        width: 100%;
        height: 100%;
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
        margin-left: 0;
      }
    }
  }

  &__container &__close {
    color: color(ns-primary);
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    cursor: pointer;
    font-weight: bold;
    font-size: 32px;
    line-height: 0.5em;
  }

  &__container &__close:hover,
  &__container &__close:focus {
    color: color(ns-secondary);
    text-decoration: none;
    cursor: pointer;
  }
  &__title {
    color: color(ns-primary);
    margin: rem(15) 0;
    font-size: rem(16);
    font-weight: bold;
  }
  &__content {
    // overflow: auto;
    overflow: auto;
    overflow-x: hidden;
    width: 100%;
    // max-height: 559.9px;
    max-height: 90vh;
    padding-right: 10px;
    color: #444444;

    @media screen and (max-width: 540px) {
      overflow: scroll;
      overflow-x: hidden;
    }

    & h1,
    & h2 {
      margin: 16.77px 0 15.77px 0;
      font-size: 1.1875rem;
      font-weight: bolder;
      color: #006699 !important;
      text-transform: uppercase;
      font-family: $ns-font-proxima;
    }

    & ul,
    & p {
      margin-bottom: 15px;
    }

    & p,
    & li,
    & strong {
      color: #444444;
      font-family: $ns-font-proxima;
    }

    li {
      font-size: 16px !important;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    margin-top: 1.875rem;
  }

  &__button {
    margin: 0 0.625rem;
    border: 1px solid #dbdbdb;
    font-weight: 700;
    text-transform: uppercase;
    background-clip: padding-box;
    border-radius: 1.25rem;
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: center right 4px;
    padding: 0.4375rem 1.25rem;

    &--cancel {
      color: #444444;
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgMjUgMjUiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgdmVyc2lvbj0iMS4xIg0JeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSINCXg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjVweCIgaGVpZ2h0PSIyNXB4Ig0+DQk8ZyBpZD0iTGF5ZXIlMjAxIj4NCQk8Zz4NCQkJPHBhdGggaWQ9IkVsbGlwc2UiIGQ9Ik0gMCAxMi41IEMgMCA1LjU5NjIgNS41OTYzIDAgMTIuNSAwIEMgMTkuNDAzNyAwIDI1IDUuNTk2MiAyNSAxMi41IEMgMjUgMTkuNDAzOCAxOS40MDM3IDI1IDEyLjUgMjUgQyA1LjU5NjMgMjUgMCAxOS40MDM4IDAgMTIuNSBaIiBzdHJva2U9IiNkZGRkZGQiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0iI2ZmZmZmZiIvPg0JCQk8cGF0aCBkPSJNIDcuODMzMyA5LjE2NjcgTCA5LjE2NjcgNy44MzMzIEwgMTcuMTY2NyAxNS44MzMzIEwgMTUuODMzMyAxNy4xNjY3IEwgNy44MzMzIDkuMTY2NyBaIiBmaWxsPSIjNzE3MTcxIi8+DQkJCTxwYXRoIGQ9Ik0gMTUuODMzMyA3LjgzMzMgTCAxNy4xNjY3IDkuMTY2NyBMIDkuMTY2NyAxNy4xNjY3IEwgNy44MzMzIDE1LjgzMzMgTCAxNS44MzMzIDcuODMzMyBaIiBmaWxsPSIjNzE3MTcxIi8+DQkJPC9nPg0JPC9nPg08L3N2Zz4=);
      padding-right: 2.3125rem;
    }

    &--confirm {
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgMjUgMjUiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgdmVyc2lvbj0iMS4xIg0JeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSINCXg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjVweCIgaGVpZ2h0PSIyNXB4Ig0+DQk8ZyBpZD0iTGF5ZXIlMjAxIj4NCQk8cGF0aCBkPSJNIDEwIDcgTCAxOCAxMi41IEwgMTAgMTggTCAxMCA3IFpNIDAgMTIuNSBDIDAgMTkuNDAzOCA1LjU5NjMgMjUgMTIuNSAyNSBDIDE5LjQwMzcgMjUgMjUgMTkuNDAzOCAyNSAxMi41IEMgMjUgNS41OTYyIDE5LjQwMzcgMCAxMi41IDAgQyA1LjU5NjMgMCAwIDUuNTk2MiAwIDEyLjUgWiIgZmlsbD0iI2ZmZmZmZiIvPg0JPC9nPg08L3N2Zz4=);
      display: inline-block;
      background-color: #006699;
      color: white;
      padding-right: 2.3125rem;
    }
  }

  .share-modal {
    p {
      line-height: rem(19.2);
      font-size: rem(16);
      margin: rem(16) 0;
      color: color(ns-main-bg);
      font-weight: 400;
      font-family: $ns-font-proxima;
    }

    input {
      width: 100%;
      height: rem(40);
      border: rem(1) solid #dbdbdb;
      padding: rem(10) rem(20);
      font-size: rem(14);
      color: #444444;
      margin-bottom: rem(10);
      font-family: $ns-font-proxima;
      font-weight: 400;
    }
    a {
      color: color(ns-primary);
      font-size: rem(16);
      font-family: $ns-font-proxima;
      font-weight: 600;
    }
  }

  &__container--buttonHeader {
    position: relative;
    width: 85%;
    max-width: 37.5rem;
    min-height: 18.28rem;
    height: auto;
    max-height: 90%;
    box-sizing: border-box;
    display: flex;
    margin: auto;
    z-index: 14;
    padding: 2.5rem;
    margin-top: 176px;
    background: color(white);

    select#typeOrder {
      font-weight: 600;
      font-family: $ns-font-cousine;
    }
  }

  //MODAL ORDENAR FAVORITOS //
  &__content--buttonHeader {
    ul#sortable {
      margin-top: 14px;
      margin-bottom: 39px;
      .esios-myesios__item.esios-myesios__order-manual.ui-sortable-handle {
        padding: 6px 10px;
        font-size: 14px !important;
      }
      .esios-myesios__item.icon-drag.ui-sortable-handle {
        padding-left: 40px;
        font-size: 14px !important;
      }
      li {
        height: auto;
        min-height: 33px;
        font-weight: 700;
        font-size: 14px;
        font-family: $ns-font-cousine;
        text-transform: uppercase;
      }
    }
  }

  // MODAL ELIMINAR FAVORITOS //
  &__container {
    &[class*='esios-modal__container--buttonFav'] {
      position: relative;
      width: 85%;
      max-width: 37.5rem;
      min-height: 15.625rem;
      height: auto;
      max-height: 90%;
      box-sizing: border-box;
      display: flex;
      margin: auto;
      z-index: 14;
      padding: 2.5rem;
      background: white;
      margin-left: 653px;
      @media (max-width: 540px) {
        position: absolute;
        width: 100%;
        height: 100%;
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
        margin-left: 0;
      }
    }
  }

  &__content {
    &[class*='esios-modal__content--buttonFav'] {
      margin-top: 2px;
      .esios-pvpc__modal-title {
        margin-bottom: 31px;
      }
      .esios-modal__buttons {
        margin-top: 44px;
        margin-left: 11px;
      }
    }
  }
}
