////
/// Mapa component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}mapa
/// @since version 1.0.0
////

.#{$ns}mapa {
  width: 100%;
  font-family: $ns-font-proxima;

  &__title {
    h1 {
      font-family: $ns-font-proxima;
      font-weight: 800;
      color: color(ns-primary);
      margin: rem(15) 0 rem(5) 0;
      font-size: 1.4375rem;
      font-weight: 800;
    }
  }

  &__content {
    width: 100%;
    margin-top: rem(20);
    h2 {
      font-family: $ns-font-proxima;
      font-weight: 700;
      color: color(ns-primary);
      margin: rem(15) 0 rem(5) 0;
      font-weight: bold;
      font-size: 1.1875rem;
    }
    &--row {
      margin-top: rem(10);
      a {
        color: color(ns-primary);
        font-weight: bold;
        font-size: 0.875rem;
      }
      a.ColorOption {
        color: color(grey);
        line-height: 16.8px;
        font-weight: 700;
      }

      ul {
        padding-left: 1.25rem;
        list-style-type: none;
        line-height: normal;
        width: calc(100% + 4px);
      }
      li {
        margin: 0.5rem 0;
      }
      .col-lg-3 {
        max-width: 219px;
        //margin-top: rem(6);
        &:first-child {
          width: 209px;
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .#{$ns}mapa {
    padding-left: rem(27.2);
  }
}

@media screen and (max-width: 767px) {
  .#{$ns}mapa {
    padding-left: 0;
  }
}
