////
/// static-page component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}static-page
/// @since version 1.0.0
////

.#{$ns}static-page {
  &__title {
    font-size: 1.4375rem;
    font-family: $ns-font-proxima;
    text-transform: uppercase;
    color: color(ns-primary);
    font-weight: 800;
    margin-bottom: 1rem;
    line-height: rem(27);
  }

  strong {
    color: #444444;
  }

  &__container {
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;
  }

  &__content {
    padding: 2rem 4.6rem 2rem 3.75rem;
    width: 100%;
    p {
      color: color(ns-main-bg);
      font-weight: 400;
      font-size: rem(16);
      line-height: 1.2rem;
      font-family: $ns-font-proxima;
      margin: 1rem 0;
      a {
        font-weight: 600;
        color: #006699;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__list-news {
    padding: 0rem 3rem 0rem 1rem;
    li {
      margin-bottom: 1rem;
      a {
        text-transform: uppercase;
        font-size: 0.875rem;
        color: #717171;
        line-height: 1.05rem;
        font-family: 'Proxima-Nova', Arial, sans-serif;
        font-weight: 600;
      }
    }
  }

  h2 {
    color: #006699;
    text-transform: uppercase;
    font-size: rem(19);
    font-family: $ns-font-proxima-bold;
  }

  ul {
    margin: 16px 0;
    padding-left: 40px;
  }

  li {
    color: color(ns-main-bg);
    font-size: 16px;
    line-height: 1.2;
  }

  .btn-download.icon-download {
    padding-right: 50px;
    background-position: right 3px center;
    background-color: color(ns-primary);
    color: #fff !important;
    font-family: $ns-font-proxima-bold;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 1.2;

    &:hover {
      text-decoration: none;
      background-color: rgba(0, 102, 153, 0.8);
    }
  }

  .icon-download {
    background-image: url($ns-path-img + '/download-icon.svg');
    background-repeat: no-repeat;
    background-size: 25px;
  }

  table {
    thead {
      th {
        text-transform: capitalize;
        vertical-align: top;
        font-weight: 600;
      }
    }

    th,
    td {
      padding: rem(10) rem(20);
      color: color(ns-main-bg);
      font-size: rem(14);
      line-height: 1.2;
    }
  }

  .main-value.is-sm-hidden {
    display: none;
  }

  em {
    color: color(ns-main-bg);
    font-style: italic;
  }

  .mod-action-buttons {
    margin: rem(20) 0 rem(40);
    text-align: center;
  }
}

@include media-query(xs) {
  .#{$ns}static-page {
    margin-bottom: rem(12);

    &__content {
      padding: 0 rem(20);
    }

    .btn-download {
      width: 63%;
    }

    .main-value.is-sm-hidden {
      display: flex;
      position: relative;

      &:before,
      &:after {
        content: '';
        position: absolute;
        right: 0;
        width: rem(8);
        height: rem(8);
        pointer-events: none;
      }

      &:before {
        top: rem(7);
        border-top: rem(3) solid color(ns-light);
        border-left: rem(3) solid color(ns-light);
        transform: rotate(225deg);
      }

      &:after {
        top: rem(-2);
        border-top: rem(3) solid color(ns-light);
        border-left: rem(3) solid color(ns-light);
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      h2 {
        margin: rem(16) 0;
      }
    }

    .secondary-values {
      display: none;
    }
  }
}
