///
/// Pagination component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}pagination
/// @since version 1.0.0
////

.#{$ns}pagination {
  display: block;
  margin-top: 36px;
  font-family: $ns-font-proxima;
  font-size: 14px;
  &--habilitado {
    margin-left: -3px;
    #pagination-habilitados {
      margin-top: -12px;
      margin-bottom: -2px;
      margin-left: 25px;
    }
  }
  &--doc {
    margin-left: -5px;
  }
  &__ellipsis {
    padding: rem(7) rem(14);
    display: inline-block;
  }
  &__buttonLeftArrow,
  &__buttonRightArrow {
    display: inline-block;
    vertical-align: top;
    width: rem(40);
    height: rem(40);
    border: 1px solid color(ns-light);
    background: transparent;
    &:disabled {
      cursor: default;
      border-color: color(ns-lighter);
      background: color(ns-lighter);
    }
  }
  &__buttonRightArrow {
    margin-left: rem(15);
    &:disabled {
      &:after {
        border-left: 5px solid color(ns-light);
      }
    }
    &:after {
      content: '';
      display: block;
      position: relative;
      top: 0;
      left: 50%;
      width: 0;
      height: 0;
      -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid color(ns-darker);
    }
  }
  &__buttonLeftArrow {
    margin-right: rem(15);
    &:disabled {
      &:after {
        border-right: 5px solid color(ns-light);
      }
    }
    &:after {
      content: '';
      display: block;
      position: relative;
      top: 0;
      left: 50%;
      width: 0;
      height: 0;
      -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 5px solid color(ns-darker);
    }
  }
  &__buttonNumber {
    display: inline-block;
    vertical-align: top;
    border: 1px solid transparent;
    width: auto;
    height: auto;
    padding: 0.5rem 0.625rem;
    background: transparent;
    font-family: $ns-font-cousine;
    font-size: rem(14);
    color: color(ns-strong);
    font-weight: 600;
    margin-top: rem(3);
    span {
      font-family: $ns-font-cousine;
      font-size: rem(14);
      color: color(ns-strong);
      font-weight: 600;
    }
    &--active {
      border: 1px solid color(ns-light);
    }
  }
  &__select {
    width: auto;
    border: 0;
    padding-right: 0.625rem;
    margin-right: 0.3125rem;
    background: none;
    font-family: $ns-font-proxima;
    font-size: rem(14);
    font-weight: 400;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin-left: rem(-1);
  }
  &__txt {
    font-family: $ns-font-proxima;
    font-size: rem(14);
    color: color(ns-main-bg);
    font-weight: 600;
  }
  &__results {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    text-align: left;
    margin-left: 4px;
    margin-top: 1px;
  }
  &__paginator {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 64px;
    margin-left: 8px;
    margin-top: -40px;
    &--doc {
      margin-top: 20px;
      margin-bottom: -6px;
    }
    &--descargas {
      margin-top: -12px !important;
      margin-bottom: -20px !important;
    }
    @media screen and (max-width: 540px) {
      margin-top: -37px;
      margin-left: 10px;
      margin-bottom: 64px;
    }
  }
  &__arrow {
    position: relative;
    &:after {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #444444;
      content: '';
      display: block;
      position: absolute;
      right: 4px;
      top: 7px;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 540px) {
  .#{$ns}pagination {
    margin-top: 48px;
    &__results {
      margin-bottom: 24px;
      background-color: white;
      margin-top: -32px;
      height: 56px;
      padding-top: 11px;
      width: 100%;
      text-align: center;
      margin-left: 24px;
    }
  }
}
