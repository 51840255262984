////
/// Codigos de Operacion component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}operacion
/// @since version 1.0.0
////

.#{$ns}operacion {
  margin-top: -5px;
  &__title,
  h2 {
    line-height: 1.2;
    color: color(ns-primary);
    text-transform: uppercase;
  }

  &__title {
    font-size: 1.4375rem;
    font-weight: 800;
    font-family: $ns-font-proxima-extrabold;
    margin-block: 20px;
    margin-bottom: 13px;
  }

  h2 {
    font-size: 1.1875rem;
    font-family: $ns-font-proxima-bold;
    margin-top: 23px;
    margin-bottom: 4.9px;
  }

  & h2 + p {
    margin-top: 15px;
    margin-bottom: -1px;
  }

  & ul + p {
    margin-top: 13px;
  }

  h3 {
    color: color(ns-darker);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1rem;
    margin-top: 17px;
    letter-spacing: 0.25px;
  }

  strong {
    font-weight: 700;
    color: color(ns-darker);
  }

  p {
    font-weight: 400;
    color: color(ns-main-bg);
    margin-block: 10px;
    line-height: normal;
    margin-bottom: 0;
    max-width: 1024px;
    a {
      font-weight: 600;
    }
  }

  ol {
    margin-left: 37px;
    line-height: normal;
    margin-top: 15px;
    list-style: auto;
    margin-bottom: 17px;
    + h3 {
      margin-top: 20px;
      margin-left: -2px;
    }
    li {
      color: color(ns-main-bg);
      margin-bottom: 0;
      line-height: 18.7px;
    }
  }
  ul {
    list-style-type: disc;
    list-style: none;
    padding: 16px 0 3px 38px;
    font-size: 1rem;
    line-height: 19px;
    // + h3 {
    //   margin-top: 20px;
    //   margin-left: -2px;
    // }
    li {
      color: color(ns-main-bg);
      margin-bottom: -1px;
    }
  }

  a {
    color: color(ns-primary);
    text-decoration: none;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }

  hr {
    background-color: #666;
    height: 1px;
    border: 0;
    margin: 0;
    width: 1032px;
    h2 {
      &:nth-child(2) {
        & p:first-child {
          margin-bottom: 12px;
        }
      }
    }
  }

  @media screen and (min-width: 540px) {
    .grid-sm-23 {
      max-width: 1042px;
      margin-bottom: 28px;
    }

    hr {
      margin: 1.875rem 0;
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 1199px) {
  .#{$ns}operacion {
    padding-left: rem(27.2);
  }
}

@media screen and (max-width: 767px) {
  .#{$ns}operacion {
    padding-left: 0;
  }
}

@media screen and (max-width: 539px) {
  .#{$ns}operacion {
    &__title {
      margin-top: 0;
    }
  }
}
