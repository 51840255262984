////
/// Document list component
///
/// Custom project
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}document-list
/// @since version 1.0.0
////
.#{$ns}document-list {
  &__pdf {
    width: 100%;
    height: 100%;
  }
  &__zip {
    width: 100%;
    height: 100%;
  }
  &__xls {
    width: 100%;
    height: 100%;
  }
  &__xml {
    width: 100%;
    height: 100%;
  }
  &__button-remove {
    position: absolute;
    right: 23px;
    top: 11px;
    background-image: url('../../src/assets/images/button-remove.svg');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    padding-right: 13px;
    opacity: 0.4;
    &.hidden {
      visibility: hidden;
    }
    @media screen and (max-width: 540px) {
      top: 50px;
    }
  }

  .move-time-selector {
    display: grid;
    grid-template-columns: 100px 1fr;
    align-items: center;
    justify-content: space-between;

    & > * {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
    }

    .esios-toolbar-time {
      width: 100% !important;
      @media screen and (max-width: 540px) {
        left: 0 !important;
      }
    }

    .selector {
      display: grid;
      grid-template-columns: 30px 1fr 30px;

      &.dropdown {
        grid-template-columns: 1fr !important;
      }

      .selected {
        border: none !important;
        padding: 6px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        display: inline-block;

        &::before {
          display: none;
        }
      }
      .select-time-container {
        grid-template-columns: 1fr;
        position: relative;
      }

      .select-time-container.active .select-time-options {
        display: grid !important;
        max-height: rem(245);
        overflow: hidden;
        overflow-y: auto;
      }
      .select-time-container .select-time-options {
        width: 100%;
        top: rem(36.6);
        position: absolute;
        z-index: 2;
        background: white;
        box-shadow: 5px 5px 0px color(ns-light);
        border: 1px solid color(ns-light);
        border-top: none;
        display: none;
      }

      .button {
        background: color(ns-lighter);
        position: relative;
        cursor: pointer;
        border: 1px solid color(ns-light);
      }
      .prev-button {
        border-right: none;
      }
      .prev-button::after {
        content: '';
        position: absolute;
        // left: 7px;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 5px solid #717171;
        pointer-events: none;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        content: '';
      }
      .next-button {
        border-left: none;
      }
      .next-button::after {
        content: '';
        // width: 10px;
        position: absolute;
        left: 7px;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid #717171;
        pointer-events: none;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        content: '';
      }
    }

    &.one-column {
      grid-template-columns: 1fr !important;

      .selected {
        text-align: left;
        justify-content: left;
        z-index: 1;
        background-color: color(white);
        border: rem(1) solid color(ns-light) !important;
        font-family: $ns-font-proxima-regular;
      }

      .select-time-container:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #717171;
        right: rem(10);
        top: 50%;
        @include transform(translateY(-50%));
        z-index: 1;
        pointer-events: none;
        position: absolute;
      }
      .select-time-options .option {
        text-align: left;
        margin-top: rem(5) !important;
        margin-bottom: rem(4) !important;
        min-height: rem(30);
        height: auto;
        line-height: rem(18);
        font-family: $ns-font-proxima-regular;

        &.selected-opt {
          &:first-child {
            color: color(ns-main-bg) !important;
          }
          background: color(ns-lighter) !important;
          color: color(ns-primary) !important;
        }

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
  .no-dl-results {
    color: color(ns-main-bg) !important;
  }
}
.is-loading {
  min-height: 50px;
  position: relative;
}
.is-loading::after {
  z-index: 5;
  content: '';
  height: 30px;
  width: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -19px;
  margin-left: -19px;
  -webkit-transition: all 0.75s ease 0s;
  transition: all 0.75s ease 0s;
  border-radius: 100%;
  border-top: 4px solid #444444;
  border-right: 4px solid #eeeeee;
  border-bottom: 4px solid #eeeeee;
  border-left: 4px solid #eeeeee;
  -webkit-animation: standard 0.75s infinite linear;
  animation: standard 0.75s infinite linear;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
}
@keyframes standard {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.select-filter {
  .ui-selectmenu-text {
    color: #444444;
    font-family: $ns-font-proxima;
    font-weight: 600;
    font-size: 14px;
    @media screen and (max-width: 540px) {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .ui-selectmenu-button.ui-button {
    width: 100%;
    background: #ffffff;
    padding: 13px 20px;
    position: relative;
    margin-bottom: 0rem;
    border: none;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 0;
    height: 40px;
    @media screen and (max-width: 540px) {
      padding: 8px 0;
      width: 100%;
    }
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #717171;
      right: 0.625rem;
      z-index: 1;
      pointer-events: none;
      position: absolute;
      top: 50%;
      margin-top: -3px;
    }
    .ui-selectmenu-icon {
      display: none;
    }
  }

  .ui-selectmenu-menu {
    width: 194px;
    .ui-menu {
      border-radius: 0;
      border: 1px solid #dbdbdb;
      box-sizing: border-box;
      position: relative;
      width: 100%;
      max-height: 240px;
      left: 0rem;
    }
    .ui-menu-item-wrapper {
      text-transform: capitalize;
      padding: 8px 16px;
      font-size: 14px;
      background: none;
      border: none;
      color: #444444;
      &.ui-state-active {
        background-color: #f7f7f7;
        color: #006699;
      }
    }
    .ui-state-disabled {
      display: none;
    }
    .ui-menu-item {
      color: #444444;
      font-family: $ns-font-proxima;
      font-size: 14px;
    }
  }
}

#document-list-myp {
  .ui-selectmenu-button.ui-button {
    border: rem(1) solid color(ns-light);
  }
}
#document-list-ges {
  ul {
    margin-top: 20px;
  }
}

@media screen and (max-width: 540px) {
  #document-list-myp {
    display: none;
  }
}
