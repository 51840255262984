////
/// buttons component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}-button
/// @since version 1.0.0
////

.#{$ns}btn {
  font-family: $ns-font-proxima;
  display: flex;
  width: auto;
  border-radius: 1.25rem;
  background-color: color(ns-primary);
  color: color(white) !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  text-align: left;
  font-size: 0.875rem;
  padding: rem(5) rem(20);
  margin: 0 0.5rem;
  &--document {
    margin: 0rem !important;
  }

  &:hover {
    text-decoration: none;
    background-color: rgba(0, 102, 153, 0.8);
  }

  &__container {
    display: flex;
    justify-content: center;
    &--descargas {
      @media screen and (max-width: 540px) {
        flex-wrap: wrap;
        width: 100%;
        flex-direction: column;
      }
    }
    @media screen and (max-width: 540px) {
      width: 96%;
      margin-top: -44px;
      margin-left: 16px;
    }
    &--calculator {
      @media screen and (max-width: 540px) {
        width: 96%;
        margin-top: 0px;
        margin-left: 16px;
      }
    }
  }

  &__icon {
    background-repeat: no-repeat;
    background-size: 1.5625rem;
    padding-right: 3.125rem;
    background-position: right 3px center;

    &:focus {
      text-decoration: none;
    }

    &--download {
      background-image: url($ns-path-img + '/download-icon.svg');
    }

    &--print {
      background-image: url($ns-path-img + '/print-icon.svg');
    }
  }
}

@media screen and (max-width: 540px) {
  .#{$ns}btn {
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    &__icon {
      margin-bottom: 8px;
      width: 69%;
      flex-direction: row;
      justify-content: space-around;
      &:last-child {
        margin-bottom: 0;
        display: none;
      }
    }
  }
}
@media print {
  #button-fisicas {
    display: none;
  }
}
