////
/// Contact component
///
/// Custom project
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}contact
/// @since version 1.0.0
////
.#{$ns}contact {
  max-width: 847px;
  width: 95%;
  color: color(ns-main-bg);
  min-height: 300px;
  margin-top: 19px;
  padding-left: 1px;
  margin-bottom: -2px;

  h1 {
    color: color(ns-primary);
    margin: 15px 0;
    font-size: 23px;
    font-family: $ns-font-proxima;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: -2px;
  }
  h2 {
    color: color(ns-primary);
    margin: 15px 0;
    font-size: 19px;
    font-weight: bold;
  }

  hr {
    border: 0;
    margin: 1.875rem 0;
    background-color: color(ns-border-ligth);
    height: 1px;
    box-sizing: content-box;
  }
  p {
    margin-top: rem(21);
    margin-left: rem(1);
    color: color(ns-main-bg) !important;
    font-family: $ns-font-proxima;
    line-height: 19.2px;
    margin-bottom: rem(-5);
  }
  a {
    color: color(ns-primary);
    font-size: rem(16);
    font-family: $ns-font-proxima;
    font-weight: 600;
  }
}

@media screen and (max-width: 1199px) {
  .#{$ns}contact {
    padding-left: rem(28);
  }
}

@media screen and (max-width: 539px) {
  .#{$ns}contact {
    padding-left: 0;
  }
}
