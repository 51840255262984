////
/// Dashboard component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}mapas-links
/// @since version 1.0.0
////

.#{$ns}mapas-links {
  // max-width: 700px;
  width: 100%;

  * {
    font-family: $ns-font-proxima;
    width: 99.6%;
  }

  a {
    text-transform: uppercase;
    font-size: rem(14);
    color: color(ns-darker);
    line-height: rem(16.8);
    font-family: $ns-font-proxima;
    font-weight: 600;
  }

  h2 {
    font-size: rem(19);
    font-weight: 700;
    color: color(ns-primary);
    margin: 0.67em 0;
    text-transform: uppercase;
    margin-top: 18px;
    margin-bottom: 17px;
  }

  li {
    margin-bottom: rem(20);
    width: 75%;
  }

  &__layoutPadding {
    padding-top: 0;
    padding-right: 0;
  }

  &__wrap {
    display: flex;
    width: 100%;
    margin: 0 auto;
    padding-bottom: rem(14);
  }

  &__row {
    position: relative;
    flex: 1;
    padding-right: rem(60);
  }

  &__row:before,
  &__row:after {
    display: table;
    content: '';
  }

  &__main-title {
    font-family: $ns-font-proxima;
    margin: 0.67em 0;
    font-size: rem(23);
    font-weight: 800;
    text-transform: uppercase;
    color: color(ns-primary);
    line-height: 1.2;
  }

  &__mod-article-item {
    padding: rem(20) 0;

    p {
      color: color(ns-main-bg);
      padding-bottom: rem(15);
      font-size: rem(16);
      font-family: $ns-font-proxima;
      font-weight: 400;
      margin-top: -0.1px;
      margin-bottom: -3.1px;
    }

    span {
      color: color(ns-main-bg);
    }
  }

  &__mod-article-item:before,
  &__mod-article-item:after {
    display: table;
    content: '';
  }

  &__aside {
    display: block;
    overflow: hidden;
    padding: rem(20) rem(20);
    background-color: color(ns-lighter);
    max-width: 27%;
    line-height: normal;
    padding-bottom: rem(13);
  }

  @include media-query(xs) {
    .#{$ns}mapas-links {
      * {
        margin-top: -10px !important;
      }
      &__aside {
        width: 108%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: flex-start;
        margin-left: -20px;
        padding-bottom: 0px;
        margin-bottom: 19px;
        margin-top: rem(-18);
        h2 {
          margin-top: 0;
        }
      }
    }

    &__wrap {
      flex-direction: column;
      margin-top: -24px;
      margin-left: -3px;
      width: 354vw;
    }

    &__main-title {
      width: 109%;
      margin-bottom: 16px;
    }

    &__mod-article-item:last-child {
      border-bottom: none;
      width: 90vw;
      margin-bottom: -8px;
    }

    &__mod-article-item {
      p {
        line-height: normal;
        margin-bottom: 3px;
        margin-top: -2px;
      }
    }
    &__aside {
      li {
        margin-bottom: rem(19);
      }
      ul {
        width: 140.5%;
      }
    }
  }
}
