////
/// Glosario component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}dashboard
/// @since version 1.0.0
////

.#{$ns}glosario {
  margin-left: 2px;
  &__title {
    text-transform: uppercase;
    font-size: 1.4375rem;
    font-weight: 800;
    color: color(ns-primary);
    font-family: $ns-font-proxima;
    margin: 0.67em 0;
    margin-top: 18px;
  }
  &__letterselection {
    padding-top: 12px;
    margin-left: 8px;
    font-size: 19px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: $ns-font-proxima;
    @media (max-width: 540px) {
      margin-left: 20px;
    }
  }
  &__letterselection div {
    width: rem(40);
    margin: -3px 0.2em 0 -1px;
    padding: 0.2em 0.2em 0;
    text-align: center;
    @media (max-width: 540px) {
      width: 38px;
    }
  }
  .selectletra {
    color: color(ns-primary);
    font-weight: 600;
    font-size: 19px;
    line-height: 1.25rem;
    cursor: pointer;
    text-decoration: none;
    font-family: $ns-font-proxima;
    padding: 0 rem(3);
  }
  .selectletra.active {
    display: inline-block;
    position: relative;
    font-size: 19px;
    line-height: 1.25rem;
    text-decoration: none;
    border-bottom: 5px solid #006699;
    font-family: $ns-font-proxima;
    font-weight: 600;
  }

  p {
    font-weight: 16px;
    color: color(ns-main-bg);
  }

  @media screen and (min-width: 540px) {
    .grid-sm-24 {
      width: 97%;
    }
  }

  #pagination-glosario {
    margin-top: -25px;
    margin-left: -2px;
    margin-bottom: 130px;
  }
}

@media (max-width: 539px) {
  .#{$ns}glosario {
    &__letterselection {
      flex-wrap: wrap;

      div {
        min-width: rem(40);
        width: auto;
      }
    }
  }
}

.#{$ns}tablaGlosario {
  position: relative;

  .glosario-buscar {
    width: 99.5%;
    margin-left: 3px;
    font-size: 14px;
    font-weight: 400;
    margin-top: 16px;
    margin-bottom: 40px;
    height: 39px;
    color: #444444;
    font-family: $ns-font-proxima;
    @media (max-width: 540px) {
      width: 102.2%;
      margin-top: 21px;
    }
  }

  // table thead {
  //   &__head {
  //     background-color: color(white) !important;
  //   }
  // }

  table tr:nth-child(2n + 1) {
    background-color: color(ns-lighter);
  }

  table tr:nth-child(2n) {
    background-color: color(white);
  }

  .esios-table__cell p {
    color: color(ns-main-bg);
    font-weight: 800;
    font-size: 0.875rem !important;
  }
  .search-filter {
    position: absolute;
    background-repeat: no-repeat;
    background-position: 95% 50%;
    width: 20px;
    height: 75px;
    right: 16px;
    top: 27px;
    @media (max-width: 540px) {
      top: 11px;
      right: -3px;
    }
  }
  .search-filter img {
    width: 100%;
    height: auto;
  }

  td {
    padding: 5px;
    color: color(ns-main-bg) !important;
    font-family: $ns-font-proxima;
    font-size: 0.875rem !important;
    hyphens: auto;
    vertical-align: middle;
  }

  .glosario-page {
    padding: 2rem 0rem;
    .page {
      color: color(ns-main-bg);
      font-weight: 600;
      font-size: 0.875rem;
    }

    .select {
      border: 0;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  #glosario-table {
    font-size: 2.875rem !important;
    margin-left: 4px;
    .esios-table__head {
      th:nth-child(1) {
        width: 34.9%;
      }
      th:nth-child(2) {
        width: 65.1%;
      }
      .fas {
        width: 30px !important;
      }
    }

    .#{$ns}table__row {
      td:first-child {
        &.not-found {
          font-weight: normal;
        }
      }
    }
  }

  @media screen and (min-width: 540px) {
    table tr:nth-child(2n + 1) {
      background-color: color(white);
    }

    table tr:nth-child(2n) {
      background-color: color(ns-lighter);
    }

    table th,
    table td {
      display: table-cell;
      font-weight: 400;
      padding: 0.625rem 1.25rem;
    }
  }
}

.esios-layoutbuttons__content a {
  margin: 0 0.5rem;
}

.esios-boxmarket {
  &__button {
    width: 100%;
    margin-bottom: rem(10);
    @media (max-width: 492px) {
      margin-left: -29px;
    }
  }

  &__btn {
    font-weight: 600;
    font-size: 14px;
    font-family: $ns-font-proxima;
    text-transform: uppercase;
    color: color(ns-primary);
    @media (max-width: 492px) {
      max-width: 80% !important;
    }
    @media screen and (max-width: 355px) {
      max-width: 100% !important;
    }
  }

  a {
    text-decoration: none;
  }
}

.btn-download,
.btn-print {
  text-align: center;
  border: 1px solid color(ns-light);
  color: color(ns-primary) !important;
  background-color: color(white);
  display: inline-block;
  padding: 0.4375rem 1.25rem;
  border-radius: 1.25rem;
  background-clip: padding-box;
  //color: white !important;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.875rem;

  &:hover {
    text-decoration: none;
    background-color: rgba(0, 102, 153, 0.8);
  }
}
#button-glosario {
  padding: 0;
  margin-bottom: 56px;
}
.styleRow {
  font-size: 1.875rem;
}

@include media-query(xs) {
  .#{$ns}tablaGlosario {
    #glosario-table {
      margin-left: 0;

      .#{$ns}table {
        display: table;

        &__row {
          td:first-child {
            &.not-found {
              margin: 0;
              text-align: center;
              padding-bottom: rem(8);

              &::after {
                content: none;
              }
            }
          }
        }
      }
    }

    .#{$ns}pagination {
      &__results {
        margin-left: 0;
      }
    }

    #pagination-glosario {
      margin-bottom: 0;
    }
  }

  #button-glosario {
    .#{$ns}btn__container {
      margin-top: 0;
    }
  }
}

@media print {
  .app--glossary {
    #esios-layout-banner {
      display: none;
    }
  }

  #glosario-table {
    .esios-table__head {
      display: table-header-group !important;
      th {
        display: table-cell !important;
        width: 50% !important;
      }
    }
    .esios-table__row {
      display: table-row !important;
      height: auto !important;
    }
    .esios-table__row td {
      width: 30% !important;
      height: auto !important;
      display: table-cell !important;
    }
    .esios-tablaGlosario table tr {
      height: auto !important;
      width: 100%;
      display: flex;
    }
    .esios-table__row td {
      width: 100%;
      height: auto !important;
    }
    .esios-table__row td p {
      height: auto !important;
    }
  }
  .esios-layout__footer-image {
    display: none;
  }
  .esios-tablaGlosario .search-filter img {
    display: none;
  }
  .esios-tablaGlosario table tr:nth-child(2n + 1) {
    background-color: color(white);
  }
}
