////
/// Balance component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}balance
/// @since version 1.0.0
////

.#{$ns}balance {
  & svg g path.bar__line {
    stroke-width: 2.5;
  }

  &__container.row {
    margin-top: 3px;
    margin-left: -11px;
  }
  &__title.col-lg-12.col-md-12.col-xs-12 {
    margin-bottom: -6px;
  }
  .is-charging {
    visibility: hidden;
    max-height: rem(250);
  }
  .not-show {
    display: none;
  }
  .spinner-container {
    padding-top: rem(100) !important;
  }

  &__title {
    white-space: normal;
    h1 {
      font-size: 1.4375rem;
      font-weight: 800;
      font-family: $ns-font-proxima;
      color: color(ns-primary);
      margin: rem(15) 0 rem(5) 0;
    }
  }

  &__listEnergyMobile {
    display: none;
  }
  &__tabs-row {
    background-color: color(ns-lighter) !important;
    height: rem(34);
    td {
      padding: rem(7) rem(20) rem(7) rem(20) !important;
    }
  }
  .esios-listEnergyItems__item p {
    font-family: $ns-font-proxima;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.8px;
    color: #444444;
    padding-left: 3px;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  .move-time-selector {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 100px 1fr;
    align-items: center;
    justify-content: space-between;
    label {
      margin-top: 2px;
    }
    .selector {
      display: grid;
      grid-template-columns: 30px 1fr 30px;
      margin-bottom: 14px;

      .selected {
        border: none !important;
        padding: 6px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        display: inline-block;
        &[value='hour'] {
          font-size: 12px !important;
          font-weight: 600 !important;
          font-family: $ns-font-proxima;
        }
        &::before {
          display: none;
        }
      }
      .select-time-container {
        grid-template-columns: 1fr;
        position: relative;
      }

      .select-time-container.active .select-time-options {
        display: grid !important;
        max-height: rem(245);
        margin-top: rem(3);
        overflow: hidden;
        overflow-y: auto;
      }
      .select-time-container .select-time-options {
        width: 100%;
        top: 35px;
        position: absolute;
        z-index: 2;
        background: white;
        box-shadow: 5px 5px 0px color(ns-light);
        border: 1px solid color(ns-light);
        border-top: none;
        display: none;
      }

      .button {
        background: color(ns-lighter);
        position: relative;
        cursor: pointer;
        border: 1px solid color(ns-light);
      }
      .prev-button {
        border-right: none;
      }
      .prev-button::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 5px solid #717171;
        pointer-events: none;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        content: '';
      }
      .next-button {
        border-left: none;
      }
      .next-button::after {
        content: '';
        // width: 10px;
        position: absolute;
        left: 7px;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid #717171;
        pointer-events: none;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        content: '';
      }
    }
    &.one-column {
      grid-template-columns: 1fr !important;
      margin-top: 10px;

      .selected {
        text-align: left;
        justify-content: left;
        z-index: 1;
        background-color: color(white);
        padding: rem(8) rem(48) rem(8) rem(21);
        font-size: 14px !important;
        font-weight: 600;
      }

      .select-time-container:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #717171;
        right: rem(10);
        top: 50%;
        @include transform(translateY(-50%));
        z-index: 1;
        pointer-events: none;
        position: absolute;
      }
      .select-time-options .option {
        text-align: left;
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }

  .esios-listEnergyItems__decorator {
    margin-top: -2px;
  }

  .esios-select__value {
    line-height: rem(15);
    color: color(ns-main-bg);
  }
  .esios-modal__content {
    h1 {
      font-size: rem(19) !important;
      font-family: $ns-font-proxima;
      font-weight: 700;
      line-height: 22.8px;
      text-transform: uppercase;
    }
    p {
      font-family: $ns-font-proxima !important;
      font-size: rem(16) !important;
      font-weight: 400;
      line-height: 19.2px;
    }
  }

  .xaxis.bottom {
    .tick {
      line {
        stroke: transparent !important;
      }
    }
  }
  .g-main {
    .baseline {
      stroke: transparent;
      stroke-dasharray: rem(10);
      fill: none;
      stroke-width: rem(0.8);
      stroke-dashoffset: rem(8);
    }
  }
  .esios-select__label {
    margin-top: 0 !important;
    font-family: $ns-font-proxima;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: -2px;
  }
  #select_One.esios-select__selected {
    width: calc(100% + 6px);
    margin-bottom: 7px;
  }

  #select_Two.esios-select__selected {
    width: calc(100% + 6px);
    margin-bottom: -9px;
    margin-top: 1px;
  }

  .esios-select__arrow i {
    color: #717171;
    position: relative;
    left: 10px;
    top: -2px;
  }

  .esios-tab .mod-tabs.ui-widget.ui-widget-content {
    border: none !important;
    width: calc(100% + 106px);
    margin-left: -32px;
    margin-top: -6px;
    margin-bottom: 52px;
  }

  .esios-tab table {
    margin-bottom: 2.5rem;
    width: calc(100% - 12px);
    margin-top: -2px;
    margin-left: -3px;
  }

  &__tabs-row:nth-child(2n + 1) {
    background-color: color(white) !important;
  }
  .tab-border-bottom {
    border-bottom: 1px solid color(black) !important;
    white-space: nowrap;

    & > td {
      font-weight: bold;
    }
  }
  .tab-row-bold {
    font-family: $ns-font-proxima !important;
    padding: rem(10) rem(20) rem(10) rem(20) !important;
    text-align: left;
    width: rem(270);
    vertical-align: middle !important;
    font-weight: 700;
    font-size: rem(12);
    color: color(ns-main-bg);
  }
  .tab-row-weigth {
    font-family: $ns-font-cousine !important;
    font-weight: bold;
    color: color(ns-main-bg);
    text-align: right;
    font-size: rem(12);
    padding: rem(10) rem(20) rem(10) rem(20) !important;
  }
  &__tabs-row-title {
    padding: rem(10) rem(20) rem(10) rem(20) !important;
    text-align: left;
    width: rem(270);
    vertical-align: middle !important;
    font-weight: 600;
    font-size: rem(12);
    font-family: $ns-font-proxima !important;
    color: color(ns-main-bg);
  }
  &__tabs-title {
    text-transform: uppercase;
    font-family: $ns-font-proxima;
    vertical-align: middle;
    p {
      font-family: $ns-font-proxima !important;
      font-size: rem(12);
      font-weight: 600;
      color: color(ns-main-bg);
      text-transform: uppercase;
      line-height: 14.4px;
    }
  }
  &__tabs-column-title {
    text-transform: uppercase;
    font-family: $ns-font-proxima !important;
    text-align: right;
    vertical-align: middle;
    p {
      font-family: $ns-font-proxima !important;
      font-size: rem(12);
      font-weight: 600;
      color: color(ns-main-bg);
    }
  }
  &__tabs-row-value {
    font-family: $ns-font-cousine;
    color: color(ns-main-bg);
    text-align: right;
    font-size: rem(12);
    padding: rem(10) rem(20) rem(10) rem(20) !important;
  }
  .val_numbers {
    position: absolute;
    right: 0;
    border: none !important;
  }
  .esios-tab {
    margin-top: rem(12);
  }
  @include media-query(sm) {
    .esios-select__selected {
      max-width: 250px;
    }
    .esios-embed-indicators__balance .esios-balance .esios-tab table {
      display: block;
    }
    .ui-tabs-panel.ui-widget-content.ui-corner-bottom {
      display: block;
    }
  }

  @include media-query(xs) {
    &__listEnergyMobile {
      display: inline;
    }
    &__listEnergyDesktop {
      display: none;
    }
    &__title {
      white-space: normal;
      margin-top: 0;
      h1 {
        line-height: 27.5px;
      }
    }
    .#{$ns}toolbar {
      margin-top: -8px;
      &__wrapper {
        span.#{$ns}toolbar__items {
          margin-right: -2px;
        }
      }
    }
    .row {
      padding-right: 0px;
    }

    &__wrap.col-lg-3.col-md-3.col-xs-3 {
      margin-top: -1.2px;
      padding-right: 0px;
      width: 100%;
    }
    .#{$ns}select__label {
      margin-bottom: 0.1px;
    }

    .#{$ns}select {
      margin-bottom: 8px;
      &__selected {
        max-width: 100%;
        padding: 9px 20px 9px 22px;
      }
    }
    .esios-tab {
      display: flex;
      .ui-tabs-nav {
        display: none;
      }
      table {
        .table-title-row {
          display: none;
        }
        #table-0 tbody tr:first-child,
        #table-1 tbody tr:first-child,
        #table-2 tbody tr:first-child,
        #table-3 tbody tr:first-child {
          display: none;
        }
        td {
          display: none;
        }
        td:first-child {
          display: inline-block;
        }
      }
    }
  }
}

.no-data-graphic-balances {
  background-color: #f7f7f7;
  aspect-ratio: 16/12 !important;
}

.balances-noDataRect {
  width: 250px;
  height: 60px;
  border-style: solid;
  border-color: #cf4634;
  border-width: 2px;
  position: absolute;
  margin: 33% 34%;
  padding: 10px 20px;
  background-color: #f7f7f7;
  color: #cf4634;
  text-align: center;
  font-size: 13px;
  z-index: 1;
}
.medium {
  display: none;
}
@media (max-width: 540px) {
  // balance generación programada //
  .#{$ns}layout {
    &--balance {
      .esios-layout__breadcrumbs__wrapper--balance {
        max-width: calc(100% + 1px) !important;
        margin-right: 0px !important;
      }
      .t-max-height {
        height: 100%;
        padding-right: 0px;
      }
      .esios-balance #select_One.esios-select__selected {
        width: 100%;
        margin-bottom: -2px;
      }
      .esios-balance #select_Two.esios-select__selected {
        width: 100%;
        margin-bottom: 20px;
      }
      .esios-balance__graph.chari-chart {
        padding: 0 !important;
      }
      .esios-listEnergyItems__list {
        margin-bottom: 34px;
      }
    }
  }
  .medium {
    display: flex !important;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .selector-title {
      color: color(ns-main-bg);
      font-weight: bold;
    }
    .esios-toolbar-time {
      border: 1px solid color(ns-light);
    }
  }
  .#{$ns}balance {
    &__container {
      &.row {
        width: 100%;
        margin: rem(3) 0 0;

        & > .row {
          padding: 0;
          margin: 0;

          .#{$ns}balance__wrap {
            padding: 0;
          }
        }
      }
    }
    &__listEnergyMobile {
      padding: 0;
    }
    &__row-table {
      margin: 0 rem(-20);
      width: calc(100% + 40px);

      #tabwithcontent {
        padding: 0;
        margin-top: 0;

        .#{$ns}tab {
          display: block;

          & > .row {
            margin: 0;

            & > .grid-sm-24 {
              padding: 0;
            }
          }

          .mod-tabs.ui-widget.ui-widget-content {
            width: 100%;
            margin-left: 0;
            margin-top: 0;
            padding: rem(4) 0;

            & > .ui-tabs-panel {
              padding-left: 0 !important;

              table {
                margin-left: 0;
                width: 100%;
              }
            }
          }
        }
      }
    }
    .#{$ns}tab {
      .ui-tabs .ui-tabs-panel {
        border-top: 0 !important;
        padding-top: 0 !important;
      }
    }
  }
}

// INSERTADO DE BALANCE //
.#{$ns}embed-indicators {
  &__balance {
    position: relative;
    &::after {
      background-image: url(../assets/images/logo-ree-mapas.png);
      background-repeat: no-repeat;
      background-position: center top;
      background-size: contain;
      content: '';
      position: absolute;
      max-width: 639px;
      min-width: 20rem;
      width: 95%;
      height: 50%;
      top: 251px;
      left: 15px;
      z-index: -1;
      opacity: 0.2;
      min-height: 500px;
    }
    .col-xs-6 {
      width: 409px;
    }
    .col-xs-6 {
      width: 48% !important;
    }
    .esios-home__title {
      margin-top: 0px;
      margin-left: 1px;
      line-height: normal;
      margin-bottom: 5px !important;
    }
    .esios-select__label {
      font-family: $ns-font-proxima;
      margin-bottom: 1px;
    }
    td {
      border: 2px solid #fff !important;
      padding-right: 15px !important;
    }

    .esios-tab .mod-tabs.ui-widget.ui-widget-content {
      border: none !important;
      width: calc(100% + 94px);
      margin-left: -32px;
      margin-top: -6px;
      margin-bottom: 34px;
    }

    .esios-balance #select_One {
      margin-left: 0 !important;
      .esios-select__selected {
        width: 100%;
        margin-bottom: 10px;
        height: 36px;
        background: #ffffff;
      }
    }

    .esios-balance #select_Two {
      margin-left: 0 !important;
      .esios-select__selected {
        width: 100%;
        margin-bottom: 5px;
        margin-top: 1px;
        height: 36px;
        background: #ffffff;
      }
    }

    .esios-select {
      margin-bottom: -2px;

      &__selected {
        justify-content: center;
        position: relative;
      }

      &__text {
        &#selectOne,
        &#selectTwo {
          margin: rem(-1) 0 0;
        }
      }

      &__arrow {
        position: absolute;
        top: 50%;
        right: rem(20);
        transform: translateY(-50%);
      }
    }
    .esios-select__selectable {
      top: 88.5% !important;
    }
    .esios-listEnergyItems__list {
      padding-right: 20px;
    }
    .esios-listEnergyItems__item {
      white-space: normal;
      align-items: flex-start;
    }

    .esios-listEnergyItems__decorator {
      margin-top: 4px !important;
    }

    .esios-listEnergyItems__line {
      margin-top: 7px;
    }

    .esios-select__selected {
      width: 152px;
    }
    .esios-balance__wrap.col-lg-3.col-md-3.col-xs-3 {
      width: 190px;
    }

    .esios-balance__listEnergyDesktop {
      margin-top: -13px;
      margin-left: 1px;
    }
    .esios-listEnergyItems__item p {
      color: #444444;
      font-weight: 600;
      margin-top: 1px;
      margin-left: 0px;
      text-transform: initial;
      margin-bottom: -1px;
      line-height: 16.8px;
    }

    #balance-graph {
      background-color: transparent;
      padding-left: 11px;
    }

    .esios-listEnergyItems__decorator {
      margin-top: 0px;
    }
    #tabwithcontent {
      padding: 0;
      margin-top: 0;

      .row {
        margin: 0;
        @media screen and (max-width: 540px) {
          width: 100%;
        }
      }
    }
    .esios-tab .grid-sm-24 {
      width: 63%;
      padding: 0;
    }
    .esios-tab .ui-state-default.ui-corner-top.ui-tabs-active,
    .esios-tab .ui-state-default.ui-corner-top {
      margin-right: 4px !important;
    }

    .esios-balance .esios-tab table {
      margin: rem(1) 0 rem(6);
      width: 100%;
    }

    .esios-tab table td {
      padding: rem(10) rem(5) !important;
      vertical-align: middle;
    }

    .#{$ns}balance {
      &__tabs-row {
        height: 35.8px;

        &-title {
          width: rem(170);
          line-height: 1.2;
        }

        &-value {
          padding: rem(10) rem(5) !important;
          line-height: 1.2;
        }
      }
      .tab-row-weigth {
        padding: rem(10) rem(5) !important;
      }
    }

    td.esios-balance__tabs-row-title.tab-row-bold {
      padding-right: 0 !important;
      padding-top: 8px !important;
      padding-bottom: 8px !important;
    }

    td.esios-balance__tabs-row-title:first-child {
      max-width: rem(170);
      padding: rem(10) rem(5) !important;
    }
  }
}

@include media-query(xs) {
  .#{$ns}embed-indicators {
    &__balance {
      .#{$ns}select {
        &__text {
          &#selectOne,
          &#selectTwo {
            margin-left: 0;
          }
        }
      }

      .#{$ns}balance {
        &__tabs-row {
          &-title,
          &-value {
            font-size: rem(14);
          }
        }
      }
    }
  }
}
