////
/// Ayuda component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}help
/// @since version 1.0.0
////

.#{$ns}habilitado {
  margin-block: 16px;
  &__boton-remove {
    position: absolute;
    top: 20px;
    right: 7px;
    background-image: url('../../src/assets/images/button-remove.svg');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    padding-right: 13px;
    opacity: 0.4;
    &.hidden {
      visibility: hidden;
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: 1.4375rem;
    font-weight: 800;
    color: color(ns-primary);
    font-family: $ns-font-proxima;
    margin: 0.67em 0;
    margin-top: 17px;
    line-height: 27.6px;
    margin-bottom: 14px;
  }

  p {
    font-weight: 400;
    color: color(ns-main-bg);
    margin-bottom: -4px;
  }

  @media screen and (min-width: 540px) {
    .grid-sm-24 {
      width: 97%;
    }
    &__boton-remove {
      top: 39px;
      right: 7px;
    }
  }
}

.#{$ns}tablaHabilitado {
  position: relative;
  .habilitado-buscar {
    width: 100%;
    margin-left: 0%;
    font-size: 12px;
    font-weight: 600;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .esios-table__cell p {
    color: color(ns-main-bg);
    font-weight: 600;
  }

  .habilitado-page {
    .page {
      color: color(ns-main-bg);
      font-weight: 600;
      font-size: 0.875rem;
    }

    .select {
      border: 0;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  table tr:nth-child(2n + 1) {
    background-color: color(white);
  }

  table tr:nth-child(2n) {
    background-color: color(ns-lighter);
  }
  @media screen and (min-width: 540px) {
    table th,
    table td {
      display: table-cell;
      padding: 0.625rem 1.25rem;
    }
  }
}

.esios-layoutbuttons__content a {
  margin: 0 0.5rem;
}

.esios-boxmarket {
  &__button {
    width: 100%;
  }

  &__btn {
    font-weight: 600;
    font-size: 14px;
    font-family: $ns-font-proxima;
    text-transform: uppercase;
    color: color(ns-primary);
  }

  a {
    text-decoration: none;
    color: color(ns-primary);
  }
}

.btn-download,
.btn-print {
  text-align: center;
  border: 1px solid color(ns-light);
  color: color(ns-primary) !important;
  background-color: color(white);
  display: inline-block;
  padding: 0.4375rem 1.25rem;
  border-radius: 1.25rem;
  background-clip: padding-box;
  //color: white !important;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.875rem;

  &:hover {
    text-decoration: none;
    background-color: rgba(0, 102, 153, 0.8);
  }
}

@media screen and (max-width: 1199px) {
  .#{$ns}habilitado {
    padding-left: rem(27.2);
  }

  .#{$ns}tablaHabilitado {
    padding-left: rem(27.2);
  }
}

@media screen and (max-width: 767px) {
  .#{$ns}habilitado {
    padding-left: 0;
  }

  .#{$ns}tablaHabilitado {
    padding-left: 0;
  }
}

@include media-query(xs) {
  .#{$ns}habilitado {
    &__title + p {
      line-height: 1.2;
    }
  }

  .#{$ns}tablaHabilitado {
    .#{$ns}table {
      display: table;
    }
  }
}
