///*-------------------------------------------------------------------------
//   #SETTINGS CONFIG
//--------------------------------------------------------------------------*/

//	CONTENT THIS FILE:
//		# NAMESPACE
//      # CONFIG - FONTS
//		# BASIC VALUE FOR PROJECT
//      # CONFIG - SETTINGS

///*-------------------------------------------------------------------------
//   	# NAMESPACE
//--------------------------------------------------------------------------*/
$ns: esios-;

///*-----------------------------------------------------
///     # CONFIG - FONTS
///-----------------------------------------------------*/
$ns-font-proxima: 'Proxima-Nova', Arial, sans-serif;
$ns-font-proxima-thin: 'Proxima-Nova-Thin', Arial, sans-serif;
$ns-font-proxima-regular: 'Proxima-Nova-Regular', Arial, sans-serif;
$ns-font-proxima-bold: 'Proxima-Nova-Bold', Arial, sans-serif;
$ns-font-proxima-extrabold: 'Proxima-Nova-ExtraBold', Arial, sans-serif;
$ns-font-proxima-black: 'Proxima-Nova-Black', Arial, sans-serif;

$ns-font-proxima-alt-bold: 'Proxima-Nova-Alt-Bold', Arial, sans-serif;
$ns-font-proxima-alt-light: 'Proxima-Nova-Alt-Light', Arial, sans-serif;
$ns-font-proxima-alt-thin: 'Proxima-Nova-Alt-Thin', Arial, sans-serif;

$ns-font-montserrat-thin: 'Montserrat-Thin';
$ns-font-montserrat-light: 'Montserrat-Light';
$ns-font-montserrat-medium: 'Montserrat-Medium';
$ns-font-montserrat-bold: 'Montserrat-Bold';
$ns-font-montserrat-black: 'Montserrat-Black';

$ns-font-default: 'Proxima-Nova-Regular', Helvetica, sans-serif;
$ns-font-roboto-thin: 'Montserrat-Thin';
$ns-font-roboto-light: 'Montserrat-Light';
$ns-font-roboto-bold: 'Montserrat-Bold';
$ns-font-roboto-black: 'Montserrat-Black';
$ns-font-alternative: 'Source Sans Pro', verdana;
$ns-font-icon-solid: 'Font-Awesome-Solid';
$ns-font-icon-regular: 'Font-Awesome-regular';
$ns-font-icon-brands: 'Font-Awesome-brands';

$ns-font-cousine: 'Cousine';
$ns-font-cousine-bold: 'Cousine-Bold';

///*-------------------------------------------------------------------------
//   	# BASIC VALUE FOR PROJECT
//--------------------------------------------------------------------------*/

$ns-font-family: $ns-font-default;
$ns-font-size: 16;
$ns-line-height: 1.2;
$ns-background-color: white;
$ns-path-font: '../assets/fonts/';
$ns-path-img: '../assets/images/';

// Screens
$screen-sm: 540px;
$screen-md: 980px;
$screen-tablet: 1024px;
$screen-lg: 1200px;

// Grid
$grid-columns: 24;
$grid-max-width: 1161px;
$grid-gutter-width: 1.5%;
$grid-padding: 20px;

$wrap-padding: 60px;

// Typography
$font-face-name-1: 'proxima-nova';
$font-face-light-1: 'proxima-nova';
$font-face-regular-1: 'proxima-nova';
$font-face-semibold-1: 'proxima-nova';
$font-face-bold-1: 'proxima-nova';
$font-face-extrabold-1: 'proxima-nova';
$font-face-black: 'proxima-nova';

$font-face-name-2: 'Cousine';
// $font-face-regular-2: "Cousine";
// $font-face-bold-2: "Cousine";

$font-family: $font-face-name-1, Arial, sans-serif;
$font-h1-size: 23px;
$font-h2-size: 19px;
$font-h3-size: 16px;
$font-h4-size: 16px;
$font-h5-size: 14px;
$text-size: 16px;

$big-size: 42px;
$medium-size: 25px;
$small-size: 14px;
$micro-size: 12px;

$number-big: 38px;
$number-medium: 28px;
$number-small: 23px;
$number-micro: 16px;

$line-height: 1.2;

// Margins
$large-margin: 40px;
$medium-margin: 30px;
$small-margin: 20px;

// Colors
$gray-soft: #f7f7f7;
$gray-light: #dbdbdb;
$gray: #717171;
$gray-dark: #444;

$white: #fff;
$blue: #006699;
$blue-light: #00a1da;
$red: #cf4634;
$green: #85b200;

// Theme
$bg-color-0: $white;
$bg-color-1: $gray-soft;
$bg-color-2: $gray-light;
$bg-color-3: $gray;
$bg-color-4: $gray-dark;
$bg-color-5: $blue;
$bg-color-6: $red;
$bg-color-7: $blue-light;
$bg-color-8: $green;

$border-color-1: $gray-light;
$border-color-2: $gray;

$text-color-0: $white;
$text-color-1: $gray-dark;
$text-color-2: $gray;
$text-color-3: $blue;
$text-color-4: $red;
$text-color-5: $green;
$text-color-6: $gray-light;
