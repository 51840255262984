////
/// BODY element
///
/// Here we got the body styles
////
body {
  position: relative;
  font-family: $ns-font-proxima;
  color: color(ns-tertiary);
  background-color: color(white);
  height: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // overflow: hidden;
}
