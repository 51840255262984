////
/// Tooltip component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}tooltip
/// @since version 1.0.0
////

.#{$ns}tooltip {
  display: none;
  position: absolute;
  background-color: color(white);
  width: rem(125);
  border: rem(1) solid color(ns-light);
  font-family: $ns-font-proxima;
  text-transform: uppercase;
  font-size: rem(11);
  text-align: center;
  padding: rem(12) rem(20) rem(6);
  z-index: 1;
  bottom: rem(34);
  left: 50%;
  transform: translateX(-50%);

  &:before,
  &:after {
    content: '';
    border: solid transparent;
    position: absolute;
  }

  &:before {
    border-color: rgba(0, 0, 0, 0);
    border-top-color: color(ns-light);
    border-width: 17px;
    margin-left: -17px;
    bottom: -34px;
  }

  &:after {
    border-color: rgba(0, 0, 0, 0);
    border-top-color: color(white);
    border-width: 15px;
    margin-left: -15px;
    bottom: -30px;
  }

  &__title {
    color: color(ns-darker);
    margin-bottom: rem(12);
    line-height: rem(16);
  }

  &__content {
    border-top: rem(1) solid color(ns-light);
    padding-top: rem(12);

    &:first-child {
      border-top: 0;
    }
  }

  &__link {
    color: color(ns-darker);
    display: block;

    &:hover {
      color: color(ns-darker);
      text-decoration: underline;
    }
  }

  &--opened {
    display: block;
  }

  &--responsive {
    left: rem(-23);
    bottom: auto;
    top: rem(32);
    padding: rem(10);
    width: rem(130);

    &:before,
    &:after {
      border-top-color: transparent;
      border-top: 0;
      bottom: auto;
    }

    &:before {
      right: rem(13);
      border-bottom-color: color(ns-light);
      top: rem(-17);
    }

    &:after {
      right: rem(15);
      border-bottom-color: color(white);
      top: rem(-15);
    }

    .#{$ns}tooltip {
      &__content {
        padding-top: 0;
      }

      &__link {
        padding: rem(3);
      }
    }
  }
}
