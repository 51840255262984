.esios-analysis__info-chart {
  .widget-legend {
    ul {
      list-style: none;
      padding: 0;
    }

    .legend-item {
      position: relative;
      font-size: rem($small-size);
      margin: 0.5rem 0;
      font-weight: 600;
      cursor: default;
    }

    .icon-widget-analysis {
      margin-left: rem(5);
      align-self: center;
    }
  }

  .legend-circle {
    display: inline-block;
    //  vertical-align: top;

    margin-right: rem(5);
    height: rem(10);
    width: rem(10);
    border-radius: rem(25);

    .is-analysis-page & {
      margin-top: 0;
    }
  }

  .legend-line {
    display: inline-block;
    vertical-align: top;

    margin-right: rem(5);
    margin-top: rem(3);
    height: rem(2);
    width: rem(10);
  }

  .legend-square {
    height: rem(10);
    width: rem(10);
    display: inline-block;
    vertical-align: middle;
    margin-right: rem(5);
  }

  .legend-icon {
    height: rem(10);
    width: rem(10);
    border-radius: rem(25);
    display: inline-block;
    vertical-align: middle;
    margin-right: rem(5);
  }

  .legend-icon-eye {
    /* Don't change the height, width and flex-* properties unless the ratio of the icon changes */
    height: 18px;
    width: 25px;
    flex-basis: 25px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: rem(5);
    cursor: pointer;
  }

  .unit {
    font-size: rem($micro-size);
    font-weight: 600;
    font-family: $font-family;
    text-transform: none;
    padding-left: rem(5);
    letter-spacing: 0;
    text-transform: none;
  }

  .value {
    @extend .is-small-number;
    font-weight: 400;
    color: $text-color-1;
    display: -ms-box;
    display: flex;
    align-items: baseline;

    #mypPrecioWidgetView & {
      flex-wrap: wrap;
    }

    &.small {
      @extend .is-small-number;
    }

    &.highlighted {
      @extend .is-big-number;
      font-weight: 400;
    }
  }

  /**
 * Basic Legend
 */
  .basic-legend {
    ul ul {
      margin-bottom: rem($small-margin);
    }

    .title {
      padding-left: 0;
      font-weight: 700;
      color: $text-color-2;
      text-transform: uppercase;
    }

    .legend-item,
    .legend-subitem {
      display: -ms-box;
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: $text-color-1;
      cursor: default;
    }

    .value {
      @extend .is-micro-number;
      //min-width: 40%;
      text-align: right;
    }

    .item {
      position: relative;
      max-width: 70%;
      padding-left: rem(15);

      > span {
        position: absolute;
        left: 0;
        top: rem(3);
      }

      &.no-number {
        max-width: 100%;
      }
    }

    .legend-subitem {
      font-weight: 600;
      font-size: rem($small-size);
      text-transform: none;
    }

    .widget-24 & {
      margin-bottom: rem($large-margin);

      @media screen and (min-width: $screen-sm) {
        margin-bottom: 0;
      }
    }
  }

  /**
 * Visual Legend
 */
  .visual-legend {
    .legend-item {
      display: -ms-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: $text-color-2;
      text-transform: uppercase;

      &.disabled .legend-icon-eye {
        background-color: $bg-color-2 !important;
      }
    }

    &.highlighted-legend .legend-item:first-child {
      .item {
        width: 47%;
      }
    }

    .item {
      display: -ms-box;
      display: flex;
      align-items: center;
    }

    .label {
      width: 85%;
    }

    .value {
      margin-left: rem(25);
    }

    @media screen and (min-width: $screen-sm) {
      flex-direction: row;

      .legend-item {
        flex-direction: row;
      }

      .item {
        width: 55%;
      }

      &.in-line ul {
        display: -ms-box;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .legend-item {
          margin-right: rem($small-margin);
        }

        .label {
          width: auto;
        }

        .item {
          width: 100%;
        }
      }

      .value {
        margin-left: 0;
      }
    }
  }

  /**
 * Highlighted Legend
 */
  .highlighted-legend {
    .legend-item .value {
      @extend .is-medium-number;
      font-weight: 400;
    }

    .legend-item:first-child .value {
      @extend .is-big-number;
      font-weight: 700;

      .caption {
        padding-right: 0.2rem;
      }
    }
  }

  /**
 * Other Legend
 */
  .other-legend {
    ul,
    li {
      margin-bottom: 0;
    }

    .legend-item {
      position: relative;
      box-sizing: border-box;
      padding-left: rem(15);
    }

    .legend-circle {
      position: absolute;
      left: 0;
    }

    .label {
      color: $text-color-2;
      text-transform: uppercase;
    }

    .variation {
      font-size: rem($small-size);
      text-transform: none;
      font-weight: 400;
    }

    .legend-resume {
      text-transform: uppercase;
      text-align: center;

      &.down {
        color: $text-color-4;

        .direction-arrow {
          /* Aliasing issue in FF if the widths are different */
          border-top: rem(20) solid #df4a32;
          border-left: rem(20) solid transparent;
          border-right: rem(20) solid transparent;
        }
      }

      &.up {
        color: $text-color-5;

        .direction-arrow {
          /* Aliasing issue in FF if the widths are different */
          border-bottom: rem(20) solid #74ba05;
          border-left: rem(20) solid transparent;
          border-right: rem(20) solid transparent;
        }
      }
    }

    .direction-arrow {
      width: rem(1);
      margin: rem(10) auto 0;
    }

    @media screen and (min-width: $screen-sm) {
      .legend-item {
        margin-bottom: 0;
      }
    }
  }

  .other-legend.other-legend-pvpc {
    .legend-circle {
      position: static;
    }

    .legend-item {
      padding: 0;
    }
  }

  .gyc-summary {
    .legend-circle {
      margin-top: 5px;
      float: left;
      margin-right: rem(11);
    }
  }

  #analysis-comp-summary {
    .esios-listEnergyItems__item-with-value {
      margin-bottom: 0 !important;

      .esios-listEnergyItems__label {
        align-items: flex-start;
        margin-bottom: rem(10);

        .esios-listEnergyItems__decorator {
          margin-top: rem(2) !important;
        }
      }
      .value.is-number {
        letter-spacing: 0rem;
        font-size: 1rem;
      }
    }
  }
}
