////
/// Documentacion component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}documentacion
/// @since version 1.0.0
////

.#{$ns}documentacion {
  width: 100%;
  font-family: $ns-font-proxima;

  &__tabla {
    margin-top: 19px;
    margin-left: 1px;
  }

  &__boton-remove {
    position: absolute;
    right: 20px;
    top: 50%;
    background-image: url('../../src/assets/images/button-remove.svg');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    padding-right: 13px;
    opacity: 0.4;
    &.hidden {
      visibility: hidden;
    }
  }

  &__title {
    h1 {
      font-family: $ns-font-proxima-extrabold;
      color: color(ns-primary);
      margin: rem(15) 0 rem(5) 0;
      font-size: 1.4375rem;
      font-weight: 800;
      text-transform: uppercase;
      position: relative;
    }
  }
  &__wrap {
    margin-right: 6px;
  }
  &__wrap2 {
    width: 240px;
    margin-left: 0;
    margin-right: 0;
  }

  &__tabla__input {
    height: 57px !important;
  }

  &__content {
    font-size: 0.875rem;
    margin-bottom: rem(18);
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    margin-top: 20px;
    &--border-bottom {
      border-bottom: 1px solid #dbdbdb;
      margin-bottom: rem(28);
      margin-top: rem(-1);
      padding-bottom: rem(8);
      &.tabla-descargas {
        padding-top: 3px;
        margin-left: 1px;
        .#{$ns}table {
          .fa-sort-up {
            &::before {
              position: relative;
              left: -9px;
              top: 1px;
            }
          }
          .fa-sort {
            &::before {
              position: relative;
              left: -1px;
            }
          }
          &::after {
            display: none;
          }
        }
      }
    }

    .#{$ns}table {
      &__row {
        height: 40px;
      }
      .fa-sort {
        &::before {
          position: relative;
          left: 3px;
        }
      }
      .fa-sort-up {
        &::before {
          position: relative;
          top: 4px;
        }
      }

      td {
        padding-right: 1px;
      }
      &__cell-txt {
        //margin-left: -3px;
        line-height: 16.8px;
      }
      &__content-mobile {
        margin-left: -1px;
      }
    }

    th {
      color: color(ns-main);
      font-weight: bold;
    }
    td {
      color: color(ns-main);
    }
  }
  &__subtitle {
    color: color(black);
    font-family: $ns-font-proxima;
  }
  h1 {
    font-family: $ns-font-proxima;
    color: color(ns-primary);
    margin: rem(15) 0 rem(5) 0;
    font-size: 1.4375rem;
    font-weight: 800;
    text-transform: uppercase;
  }
  h2 {
    font-size: 1.5625rem;
    color: color(ns-primary);
    text-transform: uppercase;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    text-align: center;
    margin-bottom: 2.5rem;
  }
  p {
    color: color(ns-main);
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  &__input {
    padding-left: 20px;
    border: 1px solid color(ns-border-ligth);
    display: inline-block;
    height: 55px;
    width: 350px;
    margin-top: 2.8%;
    margin-left: 1%;
    margin-right: 0.4%;
    margin-bottom: 5%;
    max-height: rem(250);
    overflow-y: scroll;
  }

  &__dropdown {
    text-transform: uppercase;
    height: 55px;
    font-family: $ns-font-proxima;
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    color: #444444;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    vertical-align: middle;
    padding-left: 20px;
    padding-right: 20px;
    zoom: 1;
    position: relative;
    border: 1px solid #dbdbdb;
    font-size: 0.875rem;
    font-weight: 500;
  }
  &__buttons {
    text-align: center;
    margin: 2.25rem;
    @include media-query(xs) {
      margin-top: 0;

      .#{$ns}btn__container {
        display: block;
        text-align: center;
        margin: 0 auto;
      }
      .#{$ns}btn {
        display: block;
        width: 100%;
        margin: 0 auto rem(8);
        max-width: rem(272);
        text-align: center;
      }
    }
  }

  &__tabla {
    .#{$ns}select__value {
      font-family: $ns-font-proxima;
      color: #717171;
      padding: 0.5rem 1rem;
      font-weight: 600;
      font-size: 0.875rem;
    }
    &--dropdown2 {
      border: none;
      top: 1.2rem;
      color: #444444;
      text-transform: uppercase;
      font-family: $ns-font-proxima;
      -webkit-text-size-adjust: 100%;
      zoom: 1;
      position: relative;
      font-size: 12px;
      font-weight: 600;
    }
    .ui-selectmenu-menu .ui-front .ui-selectmenu-open ul {
      width: 20% !important;
    }
    &__tdContentDate {
      color: color(black);
      font-size: 0.8rem;
      font-family: 'proxima-nova', Arial, sans-serif;
      height: 25px;
    }
    &__td2 {
      color: color(black);
      font-size: 0.8rem;
      font-family: 'proxima-nova', Arial, sans-serif;
      margin: 0.87em 0;
      text-transform: uppercase;
      height: 40px;
      width: 100%;
      width: 100%;
    }
    &__input {
      padding-left: 20px;
      padding-right: 50px;
      border: 1px solid color(ns-border-ligth);
      display: inline-block;
      height: 55px;
      width: calc(100% + 14px);
      margin-top: rem(18);
      margin-left: -1px;
      margin-right: 0.4%;
      max-height: 15.625rem;
      overflow-y: scroll;
      font-size: 12px;
      color: #444444 !important;
    }
    &__calendarIcon {
      margin-bottom: -3%;
      margin-left: 5px;
    }
    &__inputCalendar {
      border: 1px solid color(ns-border-ligth);
      display: inline-block;
      height: 37px;
      width: 98%;
      margin-top: 8%;
      padding-left: 20px;
      margin-right: 7%;
      margin-bottom: 5%;
      max-height: rem(250);
    }
    .thead {
      -webkit-text-size-adjust: 100%;
      -webkit-box-direction: normal;
      color: #444444;
      font-family: 'proxima-nova', Arial, sans-serif;
      line-height: 1.2;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      border-collapse: collapse;
      border-spacing: 0;
      font-size: 0.875rem;
      hyphens: auto;
      word-wrap: break-word;
    }
    .content-paragraph__table {
      margin-left: 20px;
      &__text {
        color: color(black);
        &--bold {
          color: color(black);
        }
        &--link {
          color: color(black);
        }
      }
    }
    tr:nth-child(even) {
      background-color: #f7f7f7;
    }

    &.simple {
      .#{$ns}table > * {
        font-size: rem(14);
        font-family: $ns-font-proxima-regular;
      }
      .#{$ns}table {
        margin-bottom: 0;
        text-transform: capitalize;
      }

      tr:nth-child(odd) {
        background-color: #f7f7f7;
        height: 32px;
      }
      tr:nth-child(even),
      .#{$ns}table__head .#{$ns}table__row {
        background-color: #ffffff !important;
        height: 30px;
      }
      .#{$ns}table__head .#{$ns}table__row {
        height: 35px !important;
      }
      tbody .#{$ns}table__row td {
        font-size: rem(13);
        color: color(ns-main-bg) !important;
      }
      .#{$ns}table__cell {
        font-family: $ns-font-proxima-regular;
        color: color(ns-main-bg) !important;
        p {
          color: color(ns-main-bg);
        }
      }
    }

    .content-paragraph {
      &__text {
        color: color(black);
        &--bold {
          color: color(black);
        }
        &--link {
          color: color(black);
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .#{$ns}documentacion {
    &__tabla {
      padding-left: rem(27.2);
    }
  }
}

@media screen and (max-width: 767px) {
  .#{$ns}documentacion {
    &__tabla {
      padding-left: 0;
    }
  }
}

@media screen and (max-width: 540px) {
  .#{$ns}documentacion {
    &__content {
      display: flex;
      flex-direction: column-reverse;
      width: 107%;
      margin-bottom: 0;
      margin-top: 39px;
    }
    &__form {
      width: 100% !important;
    }
    &__wrap {
      width: 97% !important;
    }
    &__wrap,
    &__wrap2 {
      display: block;
      width: 100%;
    }

    &__wrap2 {
      margin-top: 0 !important;
      width: 100% !important;
      margin-left: 13px !important;
      margin-bottom: -7px !important;
    }
    &__tabla__input {
      margin-left: 0;
      margin-right: 0;
      width: 104%;
    }
    &__tabla {
      .#{$ns}documentacion {
        &__content {
          margin-top: rem(20);

          .#{$ns}table {
            margin-top: 0;
          }
        }
      }
    }
  }
  .line-table {
    display: none;
  }
  .#{$ns}documentacion__wrap2 {
    .ui-selectmenu-button.ui-button {
      padding: 10px 20px !important;
      width: 100% !important;
    }
  }
}

.#{$ns}documentacion__wrap2 {
  padding-left: 0;
  padding-right: 0;
  width: 259px;
  height: 53px;
  border: none;
  margin-right: -28px;
  margin-top: 18px;
  margin-left: 11px;
  .ui-selectmenu-text {
    color: #444444 !important;
    font-family: 'Proxima-Nova-Regular' !important;
    font-size: 12px !important;
  }
  .ui-selectmenu-button.ui-button {
    width: 229px;
    background: #ffffff;
    position: relative;
    border: 1px solid #dbdbdb;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 0;
    padding: 20px 20px;
    margin-bottom: 0;
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #717171;
      right: 0.625rem;
      z-index: 1;
      pointer-events: none;
      position: absolute;
      top: 50%;
      margin-top: -3px;
    }
    .ui-selectmenu-icon {
      display: none;
    }
  }
  .ui-selectmenu-menu {
    width: 100%;
    // width: calc(100% - 12px);
    .ui-menu {
      border-radius: 0;
      border: 1px solid #dbdbdb;
      box-sizing: border-box;
      position: relative;
      width: 20.6% !important;
      max-height: 240px;
      left: 0rem;
      @media screen and (max-width: 540px) {
        width: 93.1% !important;
      }
    }
    .ui-menu-item-wrapper {
      text-transform: initial;
      padding: 6px 16px;
      font-size: 12px;
      background: none;
      border: none;
      color: #444444;
      &.ui-state-active {
        background-color: #f7f7f7;
      }
    }
    .ui-state-disabled {
      display: none;
    }
    .ui-menu-item {
      color: #444444;
    }
  }
}

@media print {
  .#{$ns}layout__print-logo {
    width: 315px;
    padding-left: 15px;
    padding-top: 15px;
  }
  .#{$ns}documentacion__wrap.col-4 {
    width: 100%;
  }
  .#{$ns}table__head-txt {
    margin-right: -1.1rem !important;
  }
  .#{$ns}documentacion__content--border-bottom.tabla-descargas {
    width: 105%;
    margin-left: -5px;
  }
}
