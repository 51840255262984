////
/// Ayuda component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns} No compatible
/// @since version 1.0.0
////

.#{$ns}error {
  width: 600px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__title {
    font-family: $ns-font-proxima-extrabold;
    font-size: 1.4375rem;
    font-weight: 800;
    color: color(ns-primary);
    text-transform: uppercase;
    padding-block: 8px;
  }

  &__text {
    font-weight: 400;
    line-height: 1.2;
    color: color(ns-main-bg);
    padding-block: 10px;
  }

  a {
    color: color(ns-primary);
    font-weight: 600;
    text-transform: none;
  }

  .btn {
    display: inline-block;
    font-size: 0.875rem;
    border-radius: 1.25rem;
    background-color: #069;
    background-clip: padding-box;
    color: #fff !important;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    padding: 0.4375rem 1.25rem;

    &:hover {
      text-decoration: none;
      background-color: rgba(0, 102, 153, 0.8);
    }
  }
}
