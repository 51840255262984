////
/// Layout component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}layout
/// @since version 1.0.0
////

.#{$ns}layoutAnalisis {
  &__main {
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    position: relative;
    margin-top: -200px;
    min-height: 200px;

    &.banner-aligned {
      margin-top: -120px;
    }

    @media screen and (max-width: 768px) {
      margin-top: 0px;
    }
    @media screen and (max-width: 768px) {
      overflow-x: hidden;
    }
  }

  &__wrap {
    background-color: color(white);
    padding: 0 rem(20);
    padding-top: rem(20);

    @media screen and (min-width: 1200px) {
      padding-right: rem(60);
      padding-left: rem(60);
    }

    @media screen and (max-width: 768px) {
      padding: 0 rem(20);
    }
  }

  .esios-layout__banner-image {
    background-size: cover;
    min-height: 209px;
    height: auto;
    position: relative;
    display: flex;
    align-items: flex-start;

    &.banner-aligned {
      min-height: 360px !important;

      @media screen and (max-width: 768px) {
        display: flex;
      }
    }

    &.mod-banner-caption {
      min-height: 370px;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &__indicator-caption {
    position: absolute;
    opacity: 20;
    top: 10px;
    font-weight: bold;
    color: white;
    padding: 20px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-shadow: 2px 2px 0px #000000a6;
    @include transform(translateX(-50%));
    left: 50%;
    font-family: $ns-font-montserrat-bold;

    @media screen and (max-width: 768px) {
      top: 150px;
      background-color: color(skyblue);
      text-shadow: none;
    }

    .banner-subtitle {
      display: grid;
      margin-top: 10px;
      color: color(white);
      font-family: $ns-font-montserrat-bold;
      @include transform(translate3d(0px, 0px, 0px));
      font-size: rem(64);
      line-height: rem(60);

      @media screen and (max-width: 768px) {
        color: color(white);
        margin-top: 0;
        font-size: rem(30);
        line-height: rem(25);
      }

      .below-text {
        color: color(skyblue-light);

        @media screen and (max-width: 768px) {
          color: color(white);
        }
      }
      .banner-unit {
        color: color(skyblue-light);

        @media screen and (max-width: 768px) {
          color: color(white);
        }
      }
    }
  }

  &__extended {
    width: 100%;
  }

  &__related-content {
    border-top: 1px solid color(ns-light);
    padding-top: rem(40);
    background-color: color(white);
    padding-bottom: rem(60);
    // background: blue;

    &__wrapper {
      margin: 0 auto;
      width: 95%;
      max-width: 1200px;
      display: grid;
      grid-template-columns: 7fr 5fr;

      @media screen and(max-width: 1200px) {
        grid-template-columns: 8fr 4fr;
      }

      @media screen and (max-width: 500px) {
        width: 90%;
      }

      .homeBalanceWidgetView {
        padding-right: 60px;
        display: grid;
        // grid-template-columns: 7fr 5fr;

        @media screen and (max-width: 1280px) {
          // grid-template-columns: 6fr 6fr;
          padding-right: 40px;
        }
      }
    }
  }

  &__subcontent {
    border-top: 1px solid color(ns-light);
    padding-top: rem(40);
    background: color(ns-lighter) url($ns-path-img + 'bg-static-page.png')
      no-repeat bottom;
    padding: 1.25rem 0 2.5rem 0;

    &__wrapper {
      margin: 0 auto;
      width: 95%;
      max-width: 1200px;

      @media screen and (max-width: 500px) {
        width: 90%;
      }

      .is-title-with-line {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: rem(20);
        margin-top: rem(20);
        font-size: 2.625rem;
        font-weight: 300;
        text-align: center;
        text-transform: uppercase;
        color: #006699;
        position: relative;
        overflow: hidden;

        @media screen and (max-width: 880px) {
          text-align: center;
          font-size: 2rem;
        }

        &:after,
        &:before {
          position: relative;
          display: inline-block;
          content: '';
          height: 1px;
          background-color: #dbdbdb;
          vertical-align: middle;
          width: 50%;
        }

        &:before {
          right: 3rem;
          margin-left: -40%;

          @media screen and (max-width: 880px) {
            right: 3rem;
            margin-left: -70%;
          }
        }
        &:after {
          left: 3rem;
          margin-right: -40%;

          @media screen and (max-width: 880px) {
            left: 3rem;
            margin-right: -70%;
          }
        }
      }

      .is-section-title {
        margin-bottom: rem(20);
        text-transform: uppercase;
        @media screen and (max-width: 880px) {
          text-align: center;
        }
      }

      .mod-maps {
        border-left: 1px solid color(ns-border-ligth);
        padding-left: 40px;
        text-transform: uppercase;

        @media screen and (max-width: 880px) {
          padding-left: 20px;
          border: none;
        }

        ul {
          list-style: disc;
          padding-left: 1.25rem;
          margin-bottom: 1.25rem;
        }
        li {
          color: #444444;
          margin-bottom: rem(10);
        }
        a {
          color: #444444;
          font-weight: 600;
          font-size: 0.875rem;
          text-transform: uppercase;
        }

        .mod-action-buttons {
          display: flex;
          justify-content: center;
          margin: rem(20) 0 rem(40) 0;

          .btn-info {
            display: inline-block;
            padding: 0.4375rem 1.25rem;
            border-radius: 1.25rem;
            background-color: color(ns-primary);
            background-clip: padding-box;
            color: white !important;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
            -webkit-font-smoothing: antialiased;
            width: auto;
            transition: background 0.2s ease-in-out;

            &:hover {
              text-decoration: none;
              background-color: color(ns-primary, 0.8);
              transition: background 0.2s ease-in-out;
            }
          }
        }
      }
    }
  }

  &__footer-image {
    background: url($ns-path-img + 'bg-static-page.png') no-repeat;
    background-position: center bottom;
    background-size: contain;
    min-height: 200px;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}
