.#{$ns}login {
  &__container {
    margin: 2.5rem 0;
    border: 1px solid #dbdbdb;
    padding: 40px;
    @media (max-width: 540px) {
      margin-bottom: 3.3rem;
    }
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__fb-button,
  &__google-button {
    margin-top: 0.5rem;
    width: 100%;
    border-radius: 0.9375rem;
    color: #ffffff;
    font-size: 13px;
    font-weight: bold;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
  }

  &__fb-button {
    background-color: #3a569c;
  }

  &__google-button {
    background-color: #cd4c3b;
  }

  p {
    font-family: $ns-font-proxima;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.2;
    color: color(ns-main-bg);
    margin: rem(16) 0;
  }

  h1,
  h2 {
    font-family: $ns-font-proxima-extrabold;
    color: color(ns-primary);
    text-transform: uppercase;
  }

  h1 {
    font-size: 1.4375rem;
    margin: 0.37em 0;
    font-weight: 800;
  }
}
