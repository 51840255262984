////
/// breadcrumbsNav component
///
///
/// @access public
/// @author Dfront Specialist Team
/// @group
/// @name .#{$ns}breadcrumbs-nav
/// @since version 1.0.0
////

.#{$ns}breadcrumbs-nav {
  background-color: color(white);
  width: 100%;
  &__wrapper {
    padding: 0;
    height: 50px;
    background-color: white;
    border-bottom: 1px solid #dbdbdb;
    box-sizing: border-box;
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    .wrap-breadcrumb {
      padding: 0;
    }
    .row {
      width: 100%;
      padding: 0;
      margin: 0;

      @include media-query(xs) {
        padding: 0;
      }

      @include media-query(xs, lg) {
        width: 100%;
        padding: 0;
      }
    }

    // PVPC //
    .breadcrumbs-pvpc {
      position: relative;
      bottom: 3px;
    }
    .unit-select-pvpc {
      position: relative;
      bottom: 7px;
    }
    .calendar-select-pvpc {
      position: relative;
      bottom: 7px;
    }
  }
  &__container {
    height: 100%;
    display: block;
    max-width: 72.5625rem;
    display: flex;
    align-items: center;
  }
  &__wrap {
    padding: 0px 16px;
    display: flex;
    align-items: center;
    p {
      font-size: 0.875rem;
      margin-right: 5px;
      color: color(black);
    }
  }
  &__home {
    font-family: $ns-font-proxima-regular;
    text-decoration: none;
    color: color(ns-darker);
    font-size: 0.875rem;
    font-weight: 600;
    margin-right: 5px;
  }
  &__home:hover {
    color: color(ns-darker);
  }
  &__separator {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3px 0 3px 6px;
    border-color: transparent transparent transparent color(ns-darker);
    vertical-align: 3px;
    margin-right: 5px;
  }
  &__page {
    font-family: $ns-font-proxima-regular;
    font-size: 0.875rem;
    font-weight: 600;
    color: color(ns-darker);
  }

  &__calendar-container {
    display: flex;
    align-items: center;
  }

  &__calendar {
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    -webkit-font-smoothing: antialiased;
    color: inherit;
    font: inherit;
    margin: 0;
    line-height: normal;
    appearance: none;
    box-shadow: none;
    background: none;
    border-radius: 0;
    box-sizing: border-box !important;
    padding: 0;
    border: 0;
    font-size: inherit;
    background-color: transparent;
    text-align: center;
    min-width: 40px;
    cursor: pointer;
    padding-right: 1.5625rem;
    padding-left: 0.625rem;
    width: 10.0625rem !important;
    -webkit-text-size-adjust: 100%;
    -webkit-box-direction: normal;
    color: color(ns-main-bg);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-family: 'Cousine';
    float: right;
    height: 1.4375rem;
    line-height: 1.4375rem;
    padding: 0 0.625rem;
    border-radius: 0.625rem;
    font-size: 0.75rem;
    font-weight: 600;
    background-color: color(ns-light);
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    padding-right: 0;
    padding-left: 0;
    width: auto;
  }

  @include media-query(xs) {
    &__wrapper {
      top: 70px;
      position: static !important;
    }
  }

  @include media-query(xs, sm) {
    &__wrapper {
      height: auto;
    }
  }
}
